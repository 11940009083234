import { ImpressionedProductVerticalCreditCard } from "@bwll/bw-types";
import { noop } from "@bwll/bw-utils";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

/**
 * Applies for a product. May navigate away from the application.
 * @param item The product to apply for.
 * @param onClickEvent An optional callback that will be called before applying.
 * The callback will be awaited if the user is leaving the application.
 */
export const navigateToProduct = async (
  item: ImpressionedProductVerticalCreditCard,
  onClickEvent: () => unknown = noop,
) => {
  if (item.isQuickApply) {
    try {
      await onClickEvent();
    } finally {
      window.location.href = `${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#/app/product-partnership/creditcards/${item.productReferenceNumber}/${item.id}?impressionId=${item.productImpressionId}`;
    }
  } else if (item.impressionedProductLink) {
    onClickEvent();
    window.open(item.impressionedProductLink);
  }
};
