import { View } from "react-native";

import { Skeleton, Spacer } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";

import { LoadingSkeletonProps } from "./LoadingSkeleton.types";

export const LoadingSkeleton = ({ testID, headerSection, cardHeight, cardWidth }: LoadingSkeletonProps) => (
  <View testID={testID}>
    {headerSection}
    <Skeleton width={cardWidth} height={cardHeight} variant="rounded" />
    <Spacer height={spacing.xs} />
    <Skeleton width={cardWidth} height={cardHeight} variant="rounded" />
    <Spacer height={spacing.xs} />
    <Skeleton width={cardWidth} height={cardHeight} variant="rounded" />
  </View>
);
