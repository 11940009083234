import { useLocation, useNavigate } from "react-router-dom";
import type { Path, To } from "react-router-dom";
import { navigateToUrl } from "single-spa";

function isTo(to: unknown): to is Partial<Path> {
  return to !== undefined && to !== null && typeof to === "object" && "pathname" in to;
}

/**
 * Gets a function that will navigate back to the previous route.
 * @param fallbackTo Route to go back to if no previous route is found. If not supplied, will simply call navigate(-1).
 */
export const useGoBack = (fallbackTo?: To): (() => void) => {
  const navigate = useNavigate();
  const location = useLocation();

  if (isTo(location.state?.returnTo)) {
    return () => navigate(location.state.returnTo);
  }

  if (Boolean(location.search) && new URLSearchParams(location.search).has("returnUrl")) {
    // Typescript does not detect the has check above
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const returnUrl = new URLSearchParams(location.search).get("returnUrl")!;
    return () => navigateToUrl(returnUrl);
  }

  return () => (fallbackTo ? navigate(fallbackTo) : navigate(-1));
};
