import { Icon } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import {
  NavBarDropdownLinkContainer,
  NavBarDropdownLinkIconContainer,
  NavBarDropdownLinkTitle,
} from "./NavBarDropdownLink.styles";
import { NavBarDropdownLinkProps } from "./NavBarDropdownLink.types";

/**
 * Component for rendering the NavBarDropdownLink.
 *
 * @component
 * @example
 * return (
 *   <NavBarDropdownLink
 *     title="Loans"
 *     icon="loans"
 *     link="/loans"
 *     linkId="loans-link"
 *     isSelected={false}
 *     onLinkClick={() => {}}
 *   />
 * )
 */
export const NavBarDropdownLink = ({
  title,
  icon,
  link,
  linkId,
  isSelected,
  onLinkClick,
}: NavBarDropdownLinkProps) => {
  return (
    <NavBarDropdownLinkContainer to={link} onClick={onLinkClick} data-cy={linkId}>
      <NavBarDropdownLinkIconContainer isSelected={isSelected}>
        <Icon icon={icon} size={32} color={COLORS.WHITE} />
      </NavBarDropdownLinkIconContainer>
      <NavBarDropdownLinkTitle isSelected={isSelected}>{title}</NavBarDropdownLinkTitle>
    </NavBarDropdownLinkContainer>
  );
};
