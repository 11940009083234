import { useEffect } from "react";
import { Modal as ReactNativeModal, ScrollView, TouchableWithoutFeedback } from "react-native";
import { useWindowDimensions } from "react-native";

import { IconButton } from "@bwll/bw-components/next";
import { usePlatformContext } from "@bwll/bw-hooks";
import { BREAKPOINTS, COLORS, fontSize } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";

import { CenteredView, Content, ModalView, StyledIconButton } from "./MarketplaceModal.styles";
import { MarketplaceModalProps } from "./MarketplaceModal.types";

/**
 * Marketplace modal component.
 * @param testID The Test ID used for testing the component
 * @param children The children component that the modal will display
 * @param onClose The function that will be called when the modal closes
 */
export const MarketplaceModal = ({ testID, children, visible, onClose }: MarketplaceModalProps) => {
  const { width } = useWindowDimensions();
  const { platform } = usePlatformContext();

  const isMobileView = width <= BREAKPOINTS.MOBILE_MAXIMUM_WIDTH;

  return (
    <ReactNativeModal
      testID={`${testID}-modal`}
      animationType={platform === PLATFORM.MOBILE_APP ? "slide" : "fade"}
      transparent={isMobileView ? false : true}
      visible={visible}
      onRequestClose={onClose}
    >
      <CenteredView isMobileView={isMobileView}>
        <ScrollView>
          <TouchableWithoutFeedback onBlur={onClose}>
            <ModalView isMobileView={isMobileView}>
              <StyledIconButton height={fontSize.xl}>
                <IconButton
                  icon={"close"}
                  size={fontSize.xl}
                  testID={`${testID}-close-button`}
                  onPress={onClose}
                  color={COLORS.PRIMARY[900]}
                />
              </StyledIconButton>
              <Content>{children}</Content>
            </ModalView>
          </TouchableWithoutFeedback>
        </ScrollView>
      </CenteredView>
    </ReactNativeModal>
  );
};
