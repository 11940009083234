import { Outlet } from "react-router-dom";

import { ScreenContainer } from "./ProductList.styles";

export const ProductList = () => {
  return (
    <ScreenContainer>
      <Outlet />
    </ScreenContainer>
  );
};
