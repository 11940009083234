import React from "react";

import { Body2 } from "../../atoms/Typography/Body2";
import { BorderedView, StyledDescriptionTerm, StyledRow, Wrapper } from "./DescriptionRow.styles";
import {
  DescriptionDefinitionProps,
  DescriptionRowProps,
  DescriptionTermProps,
} from "./DescriptionRow.types";

/**
 * A React component for a description definition in a `<DescriptionRow>` component. Modeled after the `<dd>` tag.
 * @see {@link DescriptionRow}
 * @returns The element to be rendered by React.
 */
export const DescriptionDefinition = (props: DescriptionDefinitionProps) => (
  <Body2 accessibilityRole="text" variant="semi-bold" {...props} />
);

/**
 * A React component for a description term in a `<DescriptionRow>` component. Modeled after the `<dt>` tag.
 * @see {@link DescriptionRow}
 * @returns The element to be rendered by React.
 */
export const DescriptionTerm = ({ hasUnderline, ...props }: DescriptionTermProps) => {
  if (hasUnderline) {
    return (
      <Wrapper>
        <BorderedView hasUnderline>
          <StyledDescriptionTerm accessibilityRole="text" {...props} />
        </BorderedView>
      </Wrapper>
    );
  }

  return <StyledDescriptionTerm accessibilityRole="text" {...props} />;
};

/**
 * A React component for showing a full-width row with a term and definition. Modeled after the `<dl>` HTML tag.
 * Use with the `<DescriptionTerm>` (`<dt>` tag) and `<DescriptionDefinition>` (`<dd>` tag) components.
 * @example
 * <DescriptionRow hasSeparator>
 *  <DescriptionTerm>Annual interest</DescriptionRow>
 *  <DescriptionDefinition>12.99%</DescriptionDefinition>
 * </DescriptionRow>
 * @param {boolean} hasSeparator - Whether the row will render a line underneath as a separator. Defaults to false.
 * @param {string} height - The row height. Defaults to 48 px.
 * @returns {JSX.Element} The JSX element to be rendered by React.
 */
export const DescriptionRow = ({
  children,
  hasSeparator = false,
  height,
  hasPadding = true,
}: DescriptionRowProps): JSX.Element => {
  return (
    <StyledRow hasSeparator={hasSeparator} height={height} hasPadding={hasPadding}>
      {children}
    </StyledRow>
  );
};
