import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, QUERY_KEYS } from "@bwll/bw-types";
import { ProductVerticalBankAccount } from "@bwll/bw-types/src/types/bankAccount";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

export interface IBankAccountResponseData {
  productVerticalBankAccounts: ProductVerticalBankAccount[];
}

const getBankAccounts = async (accessToken: string, baseUrl: string): Promise<IBankAccountResponseData> => {
  const url = new URL("/api/product-verticals/products/bank-accounts", baseUrl).href;
  const response = await axios.get<IBankAccountResponseData>(url, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

export const useGetBankAccounts = <TReturn = IBankAccountResponseData>(
  options: IApiHookOptions<IBankAccountResponseData, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();
  const { accessToken, expiresIn } = userData;

  const queryKey = generateQueryKey(QUERY_KEYS.GET_BANK_ACCOUNTS, accessToken);

  const queryFn = () => getBankAccounts(accessToken, envConfigs.API_GATEWAY_URL);

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: Boolean(accessToken) && options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
};
