import { useMarketplaceMortgagesExperiment } from "@bwll/bw-hooks";
import { GEMSTONE_APP_ROUTES } from "@bwll/bw-types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

/**
 * Hook that redirects the member to Gemstone Web if they are not in the `sspaMarketplaceMortgages` Split flag experiment.
 */
export const useMortgageExperimentRedirect = () => {
  const url = new URL(`#${GEMSTONE_APP_ROUTES.MORTGAGES}`, ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL);
  const isSspaMarketplaceMortgagesOn = useMarketplaceMortgagesExperiment();

  if (!isSspaMarketplaceMortgagesOn) window.location.replace(url.href);
};
