import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, ShowMore } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";

import * as Styled from "./FilterCheckboxGroup.styles";
import { FilterCheckboxGroupProps } from "./FilterCheckboxGroup.types";

// One checkbox item * 5 items
const heightCutoff = 175;
const showMoreLocaleKey = "marketplace:productDetails:showMore";
const showLessLocaleKey = "marketplace:productDetails:showLess";

export const FilterCheckboxGroup = ({ filterOptions, onFilterChange }: FilterCheckboxGroupProps) => {
  const i18next = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();
  const isSmallScreen = isMobile || isTablet;

  const renderItems = useCallback(
    () => (
      <Styled.CheckboxContainer>
        <Checkbox onSelect={onFilterChange} options={filterOptions} />
      </Styled.CheckboxContainer>
    ),
    [onFilterChange, filterOptions],
  );

  const shouldRenderShowMore = useMemo(() => filterOptions.length > 5, [filterOptions]);

  if (!shouldRenderShowMore) {
    return <Styled.ListContainer isMobile={isSmallScreen}>{renderItems()}</Styled.ListContainer>;
  }

  // Render items in a ShowMore component to allow remainder to be expandable
  return (
    <Styled.ListContainer isMobile={isSmallScreen}>
      <ShowMore
        showMoreLabel={i18next.t(showMoreLocaleKey)}
        showLessLabel={i18next.t(showLessLocaleKey)}
        showBorder={false}
        heightCutoff={heightCutoff}
      >
        {renderItems()}
      </ShowMore>
    </Styled.ListContainer>
  );
};
