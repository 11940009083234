import { QueryClient } from "react-query";
import { IndexRouteObject, Navigate, NonIndexRouteObject, RouteObject, redirect } from "react-router-dom";

import { GEMSTONE_APP_ROUTES } from "@bwll/bw-types";

import { creditCardRoutes } from "./creditCardRoutes";
import { profileRoutes } from "./profileRoutes";
import { quickApplyRoutes } from "./quickApplyRoutes";
import { ROUTES } from "./routes";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { AppShell, AppShellRouteProps } from "@app/screens/AppShell";
import { ProductList } from "@app/screens/ProductList";
import { BankAccountProductDetails } from "@app/screens/bankAccounts/BankAccountProductDetails";
import { BankAccountProductList } from "@app/screens/bankAccounts/BankAccountProductList";
import { MortgageProductList } from "@app/screens/mortgages/MortgageProductList";

type NonIndexMarketplaceRouteObject = Omit<NonIndexRouteObject, "children"> & {
  children?: MarketplaceRouteObject[];
};

export type MarketplaceRouteObject = (IndexRouteObject | NonIndexMarketplaceRouteObject) & AppShellRouteProps;

const gemstoneRedirectLoader = (appRoute: string) => {
  const url = new URL(`#${appRoute}`, ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL);
  return redirect(url.href);
};

export const getAppRoutes = ({ queryClient }: { queryClient: QueryClient }): RouteObject[] => {
  return [
    {
      element: <AppShell queryClient={queryClient} />,
      children: marketplaceRoutes,
    },
  ];
};

export const marketplaceRoutes: MarketplaceRouteObject[] = [
  { path: ROUTES.ROOT, element: <Navigate to={ROUTES.CREDIT_CARDS} /> },
  {
    path: ROUTES.BANK_ACCOUNTS,
    Component: ProductList,
    children: [{ index: true, Component: BankAccountProductList }],
  },
  { path: ROUTES.BANK_ACCOUNTS_DETAILS, Component: BankAccountProductDetails },
  ...creditCardRoutes,
  ...quickApplyRoutes,
  {
    path: ROUTES.LOANS,
    loader: gemstoneRedirectLoader.bind(this, GEMSTONE_APP_ROUTES.LOANS),
  },
  {
    path: ROUTES.CAR_LOANS,
    loader: gemstoneRedirectLoader.bind(this, GEMSTONE_APP_ROUTES.CAR_LOANS),
  },
  {
    path: ROUTES.MORTGAGES,
    Component: ProductList,
    children: [{ index: true, Component: MortgageProductList }],
  },
  {
    path: ROUTES.INSURANCES,
    loader: gemstoneRedirectLoader.bind(this, GEMSTONE_APP_ROUTES.INSURANCE),
  },
  {
    path: ROUTES.BUILD_CREDIT,
    loader: gemstoneRedirectLoader.bind(this, GEMSTONE_APP_ROUTES.BUILD_CREDIT),
  },
  {
    path: ROUTES.INVESTMENTS,
    loader: gemstoneRedirectLoader.bind(this, GEMSTONE_APP_ROUTES.INVESTING),
  },
  ...profileRoutes,
  { path: "*", loader: () => redirect(ROUTES.ROOT) },
];
