import { useMemo } from "react";

import {
  MarketplaceButtonClickedProps,
  MarketplaceCategoriesButtonClickedProps,
  MarketplaceCompareButtonClickedProps,
  MarketplaceCompareCardAddedProps,
  MarketplaceDropdownClickedProps,
  MarketplaceDropdownOptionClickedProps,
  MarketplaceFilterButtonClickedProps,
  MarketplaceFilterSelectedProps,
  MarketplaceProductClickedProps,
  MarketplaceProductExpandedProps,
  MarketplaceProductViewedProps,
  MarketplaceTabSwitchedProps,
  TrackEventFuncAsync,
} from "@bwll/bw-types";

import { IMPRESSION_EVENT_NAMES, ON_LINK_CLICK_EVENT_NAMES } from "../../../contexts";

const stringifyDates = (eventProps: Record<string, unknown>) => {
  return Object.entries(eventProps)
    .filter(([_, v]) => v instanceof Date)
    .map(([k, v]) => [k, (v as Date).toISOString()])
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v,
      }),
      eventProps,
    );
};

export const useMarketplaceAnalyticsEvents = (trackEvent: TrackEventFuncAsync) => {
  return useMemo(
    () => ({
      productViewed: (eventProps: MarketplaceProductViewedProps) =>
        trackEvent(IMPRESSION_EVENT_NAMES.PRODUCT_VIEWED, stringifyDates(eventProps)),
      productClicked: (eventProps: MarketplaceProductClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.PRODUCT_CLICKED, stringifyDates(eventProps)),
      productExpanded: (eventProps: MarketplaceProductExpandedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.PRODUCT_EXPANDED, eventProps),
      categoriesButtonClicked: (eventProps: MarketplaceCategoriesButtonClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.BUTTON_CLICKED, eventProps),
      buttonClicked: (eventProps: MarketplaceButtonClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.BUTTON_CLICKED, eventProps),
      tabSwitched: (eventProps: MarketplaceTabSwitchedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.TAB_SWITCHED, eventProps),
      dropdownClicked: (eventProps: MarketplaceDropdownClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.DROPDOWN_CLICKED, eventProps),
      dropdownOptionClicked: (eventProps: MarketplaceDropdownOptionClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.DROPDOWN_OPTION_CLICKED, eventProps),
      filterSelected: (eventProps: MarketplaceFilterSelectedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.FILTER_SELECTED, eventProps),
      filterButtonClicked: (eventProps: MarketplaceFilterButtonClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.FILTER_BUTTON_CLICKED, eventProps),
      compareButtonClicked: (eventProps: MarketplaceCompareButtonClickedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.COMPARE_BUTTON_CLICKED, eventProps),
      compareCardAdded: (eventProps: MarketplaceCompareCardAddedProps) =>
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.COMPARE_CARD_ADDED, eventProps),
    }),
    [trackEvent],
  );
};
