import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useBreakpoints,
  useGetMember,
  useImpressionedCreditCards,
  usePostQuickApply,
  useQuickApplyAnalytics,
  useQuickApplyPartner,
} from "@bwll/bw-hooks";
import { QuickApplyForm } from "@bwll/bw-modules";
import { PRODUCT_VERTICAL_GUID, QuickApplyPreferredLanguage } from "@bwll/bw-types";
import { ErrorReporting, generateTo, getQuickApplyCreditCardProduct } from "@bwll/bw-utils";

import { useQuickApplyLoaderData } from "./QuickApply.loader";
import * as Styled from "./QuickApply.styles";
import { QuickApplyMember, QuickApplyProps } from "./QuickApply.types";
import { prepareFormData } from "./QuickApply.utils";

import { useGoBack, useMarketplaceTrackEvent, useQuickApplyExperimentRedirect } from "@app/hooks";
import { ROUTES } from "@app/router";

/**
 * Renders the Quick Apply form on Marketplace Web.
 */
const QuickApplyInternal = ({ product, vertical }: QuickApplyProps) => {
  useQuickApplyExperimentRedirect();
  const { isMobile } = useBreakpoints();

  const {
    isLoading: isMemberLoading,
    data: member,
    error: memberError,
  } = useGetMember({
    transform: ({ individual, creditScoreRequest }): QuickApplyMember => {
      const { legalName, birthDate, primaryPhoneNumber, email, primaryAddress } = individual;
      const { individualClientIdReferenceNumber } = creditScoreRequest;
      return {
        legalName,
        birthDate,
        primaryPhoneNumber,
        primaryAddress,
        email,
        individualClientIdReferenceNumber,
      };
    },
  });

  const navigate = useNavigate();
  const goBack = useGoBack(
    vertical === PRODUCT_VERTICAL_GUID.CREDIT_CARD ? ROUTES.CREDIT_CARDS : ROUTES.LOANS,
  );
  const location = useLocation();
  const { i18n } = useTranslation();

  const trackEvent = useMarketplaceTrackEvent();
  const {
    trackPrepopulationFlowStarted,
    trackPrepopulationFlowSubmitted,
    trackPrepopulationFlowSuccess,
    trackPrepopulationFlowError,
    trackEditProfileInformationClicked,
  } = useQuickApplyAnalytics(
    trackEvent,
    {
      current_step_name: "Profile Summary",
      partner: product.companyName ?? "",
      product_id: product.id,
    },
    product.productImpressionId,
    product.trackingTag ?? "",
  );

  useEffect(() => {
    trackPrepopulationFlowStarted();
  }, [product, trackPrepopulationFlowStarted]);

  const partner = useQuickApplyPartner({
    product,
    individualClientIdReferenceNumber: member?.individualClientIdReferenceNumber ?? -1,
    handlers: {
      onSuccessRedirect: (redirectUrl: string) => {
        trackPrepopulationFlowSuccess();
        window.location.href = redirectUrl;
      },
      onError: (error: string) => {
        trackPrepopulationFlowError(500, error);
        ErrorReporting.logError(
          new Error(
            `Quick Apply application for user [${member?.email}] failed for company [${product.companyName}] with error [${error}].`,
          ),
          { product },
        );
        navigate(
          generateTo(ROUTES.CREDIT_CARDS_QUICK_APPLY_ERROR, {
            pathParams: { productId: product.id },
          }),
        );
      },
    },
  });
  const { mapRequest, onError, onSuccess } = partner;

  const { mutate: submitQuickApply } = usePostQuickApply({ onSuccess, onError });

  // Event handling
  const handleSubmitApplication = useCallback(() => {
    if (!member) return;
    const language = i18n.language as QuickApplyPreferredLanguage;
    const formData = prepareFormData(member, language);

    trackPrepopulationFlowSubmitted();

    submitQuickApply({
      ...mapRequest(formData),
      productImpressionToken: product.productImpressionId,
      productReferenceNumber: product.productReferenceNumber,
    });
  }, [
    member,
    i18n.language,
    trackPrepopulationFlowSubmitted,
    submitQuickApply,
    mapRequest,
    product.productImpressionId,
    product.productReferenceNumber,
  ]);

  const navigateToProfileEdit = useCallback(() => {
    trackEditProfileInformationClicked("edit_button", "prepopulation_flow");

    navigate(ROUTES.PROFILE_EDIT, { state: { returnTo: location } });
  }, [location, navigate, trackEditProfileInformationClicked]);

  const handleConsentLinkClicked = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  // TODO: Handle errors
  if (memberError) {
    return <div>Error occurred. Please try again later.</div>;
  }

  // TODO: Handle loading
  if (isMemberLoading || !member) {
    return <div>Loading</div>;
  }

  return (
    <Styled.ResetMargin isMobile={isMobile}>
      <Styled.InnerContainer isMobile={isMobile}>
        <QuickApplyForm
          quickApplyProduct={product}
          productVertical={vertical}
          partnerSchema={partner}
          onPersonalDetailsUpdate={navigateToProfileEdit}
          onSubmit={handleSubmitApplication}
          onBack={goBack}
          onConsentLinkClicked={handleConsentLinkClicked}
        />
      </Styled.InnerContainer>
    </Styled.ResetMargin>
  );
};

/**
 * Wrapper component for {@link QuickApplyInternal} that fetches credit card product data.
 */
export const QuickApplyCreditCard = () => {
  const { productId, impressionId } = useQuickApplyLoaderData();

  const {
    isLoading: isProductLoading,
    data: product,
    error: productError,
  } = useImpressionedCreditCards({
    transform: (response) => {
      const creditCard = response.productVerticalCreditCards.find((card) => card.id === productId);
      if (!creditCard) return undefined;
      return getQuickApplyCreditCardProduct(creditCard);
    },
  });

  // TODO: Handle errors
  if (productError) {
    return <div>Error occurred. Please try again later.</div>;
  }

  // TODO: Handle loading
  if (isProductLoading || !product) {
    return <div>Loading</div>;
  }

  return (
    <QuickApplyInternal
      product={{
        ...product,
        productImpressionId: impressionId || product.productImpressionId,
      }}
      vertical={PRODUCT_VERTICAL_GUID.CREDIT_CARD}
    />
  );
};
