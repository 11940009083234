import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Body2, Button, Heading1 } from "@bwll/bw-components/next";

import { QuickApplyProductHeader } from "../QuickApplyProductHeader";
import { ButtonContainer, Container, ContentContainer, ImageContainer } from "./QuickApplyError.styles";
import { QuickApplyErrorProps } from "./QuickApplyError.types";
import RetryImage from "./assets/errorIcon.png";

export const QuickApplyError = ({
  productName,
  productImageUrl,
  companyName,
  onApplyAnyway,
  onBackToRecommendations,
}: QuickApplyErrorProps) => {
  const i18next = useTranslation();

  return (
    <Container>
      {productName && productImageUrl && (
        <QuickApplyProductHeader name={productName} image={{ uri: productImageUrl }} />
      )}
      <ContentContainer>
        <Heading1>{i18next.t("productCatalog:quickApply:error:title")}</Heading1>
        {companyName && (
          <Body2>
            {i18next.t("productCatalog:quickApply:error:description", { partnerName: companyName })}
          </Body2>
        )}
      </ContentContainer>
      <ImageContainer source={{ uri: RetryImage }} />
      <ButtonContainer>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          title={i18next.t("productCatalog:quickApply:error:buttons:applyOnPartnerWebsite")}
          onPress={onApplyAnyway}
        />
        <Button
          type={BUTTON_TYPE.TERTIARY}
          title={i18next.t("productCatalog:quickApply:error:buttons:backToRecommendations")}
          onPress={onBackToRecommendations}
        />
      </ButtonContainer>
    </Container>
  );
};
