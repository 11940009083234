import { useMemo } from "react";
import { ScrollView, useWindowDimensions } from "react-native";

import { COLORS } from "@bwll/bw-styles";

import {
  CenterAlign,
  CenteredImage,
  ContentContainer,
  FullScreenScreenContainer,
} from "./SplashScreen.styles";
import { SplashScreenProps } from "./SplashScreen.types";

export const SplashScreen = ({
  backgroundColor = COLORS.NEUTRAL.WARM["700"],
  image,
  textContent,
  buttonContent,
}: SplashScreenProps) => {
  const { height: screenHeight } = useWindowDimensions();

  const imageSource = useMemo(() => (typeof image === "string" ? { uri: image } : image), [image]);

  return (
    <FullScreenScreenContainer backgroundColor={backgroundColor}>
      <ContentContainer screenHeight={screenHeight}>
        <ScrollView>
          <CenterAlign>
            <CenteredImage source={imageSource} alt="splash-screen-image" resizeMode="contain" />
            {textContent && textContent}
          </CenterAlign>
        </ScrollView>
        {buttonContent && buttonContent}
      </ContentContainer>
    </FullScreenScreenContainer>
  );
};
