import React, { useEffect, useMemo, useRef, useState } from "react";
import { Animated, Easing, TouchableOpacity } from "react-native";

import { COLORS } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";
import { Padding } from "../../atoms/Padding";
import { CenteredView, ExpandableHeading, ExpandableWrapper } from "./Expandable.styles";
import { ExpandableProps } from "./Expandable.types";

/**
 * Component for showing the Expandable component that hides/shows extra content.
 *
 * @component
 * @example
 * return (
 *  <Expandable title={"legal details"}>
 *   <Text>{`Expandable`}</Text>
 *   <Text>{`Expandable`}</Text>
 *   <Text>{`Expandable`}</Text>
 *   <Text>{`Expandable`}</Text>
 *   <Text>{`Expandable`}</Text>
 *  </Expandable>
 * )
 */

export const Expandable = ({ title, children, testID, onExpandableToggled }: ExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const animatedValue = useRef(new Animated.Value(0)).current;

  const toggle = () => {
    onExpandableToggled?.(!isExpanded);
    setIsExpanded(!isExpanded);
  };

  const animatedRotation = useMemo(
    () => ({
      transform: [
        {
          rotate: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: ["0deg", "180deg"],
          }),
        },
      ],
    }),
    [animatedValue],
  );

  const animatedOpacity = useMemo(
    () => ({
      opacity: animatedValue,
    }),
    [animatedValue],
  );

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isExpanded ? 1 : 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  }, [isExpanded, animatedValue]);

  return (
    <ExpandableWrapper borderEnabled={!isExpanded}>
      <CenteredView>
        <TouchableOpacity onPress={toggle} testID={testID}>
          <CenteredView>
            <ExpandableHeading color={COLORS.PRIMARY.DEFAULT}>{title.toUpperCase()}</ExpandableHeading>
          </CenteredView>
          <Animated.View style={animatedRotation}>
            <Icon icon={"arrow_down"} size={20} color={COLORS.PRIMARY.DEFAULT} style={{ width: "100%" }} />
          </Animated.View>
        </TouchableOpacity>
      </CenteredView>
      <Animated.View style={animatedOpacity}>
        {isExpanded && <Padding top={0}>{children}</Padding>}
      </Animated.View>
    </ExpandableWrapper>
  );
};
