import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSessionContext } from "@bwll/bw-hooks";

import { useMarketplaceTrackEvent } from "./useMarketplaceTrackEvent";

/**
 * Sets up an effect that will send a "page view" event to Analytics Proxy when navigation changes.
 */
export const usePageViewAnalyticsEvent = () => {
  const { pathname: path } = useLocation();
  const { userData } = useSessionContext();
  const { individualClientId, bwsid: authenticatedSessionId } = jwtDecode(userData.accessToken);
  const trackEvent = useMarketplaceTrackEvent();

  useEffect(() => {
    trackEvent(path, {
      pageView: true,
      path,
      uri: window.location.href,
      session_id: userData.sessionId,
      authenticated_session_id: authenticatedSessionId,
      individual_client_id: individualClientId,
    });
    // "trackEvent" is not a dependency as it changes when search params are modified
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedSessionId, individualClientId, path, userData.sessionId]);
};
