import { useMemo } from "react";

import { SVG_IMAGES, SvgPlaceholder } from "./SvgImage.constants";
import { SvgImageContainer } from "./SvgImage.styles";
import { SvgImageProps } from "./SvgImage.types";

/**
 * Component for rendering SVG images cross-platform.
 * Add new SVG images to `SVG_IMAGES` in `SvgImage.constants.ts`.
 * On web, `@svgr/webpack` is used to convert the SVG to a React component.
 * On mobile, `react-native-svg` is used to convert the SVG to a React component.
 *
 * @param {SvgImageKey} imageKey - The key of the SVG image registered in SVG_IMAGES.
 * @param {ViewStyle} [containerStyle] - The styles to be applied to the container.
 * @param {CSSProperties} [imageStyle] - The styles to be applied to the SVG image.
 *
 * @component
 * @example
 * return (
 *   <SvgImage
 *     imageKey={"marketplace-loading"}
 *   />
 * )
 */
export const SvgImage = ({ imageKey, containerStyle = {}, imageStyle = {} }: SvgImageProps) => {
  const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = useMemo(
    () => SVG_IMAGES.find(({ key }) => key === imageKey)?.component ?? SvgPlaceholder,
    [imageKey],
  );

  return (
    <SvgImageContainer style={containerStyle}>
      <SvgComponent width="100%" height="100%" style={imageStyle} />
    </SvgImageContainer>
  );
};
