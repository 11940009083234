import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useNavigate } from "react-router-dom";

import { Spacer } from "@bwll/bw-components/next";
import { useImpressionedMortgages, useMortgageProductListAnalytics } from "@bwll/bw-hooks";
import { ScreenHeader } from "@bwll/bw-modules";
import { LoadingSkeleton, MarketplaceErrorScreen, MortgageProductCard } from "@bwll/bw-modules";
import { spacing } from "@bwll/bw-styles";
import { ImpressionedProductVerticalMortgage, MARKETPLACE_ANALYTICS_ORIGINS } from "@bwll/bw-types";
import { generateTo } from "@bwll/bw-utils";

import {
  useMarketplaceProductApplication,
  useMarketplaceTrackEvent,
  useMortgageExperimentRedirect,
} from "@app/hooks";
import { ROUTES } from "@app/router";

const SKELETON_CARD_WIDTH = "100%";
const SKELETON_CARD_HEIGHT = 345;
const SKELETON_TEST_ID = "MORTGAGE_LIST_SKELETON_TEST_ID";

const originSearchParams = {
  origin: MARKETPLACE_ANALYTICS_ORIGINS.MORTGAGES_LIST,
};

export const MortgageProductList = () => {
  useMortgageExperimentRedirect();
  const navigate = useNavigate();
  const productApply = useMarketplaceProductApplication();
  const i18next = useTranslation();
  const trackEvent = useMarketplaceTrackEvent();
  const { trackProductClicked, trackProductViewed, trackFilterSelected, trackButtonClicked } =
    useMortgageProductListAnalytics(trackEvent);

  const {
    data: mortgages,
    isLoading,
    isError,
    refetch: refetchImpressionIds,
  } = useImpressionedMortgages({
    transform: ({ productVerticalMortgages }) => productVerticalMortgages,
  });

  const hasMortgages = mortgages && mortgages.length > 0;

  useEffect(() => {
    if (isLoading || isError) return;

    refetchImpressionIds();
  }, [refetchImpressionIds, isLoading, isError]);

  const handleDetailsPress = useCallback(
    (item: ImpressionedProductVerticalMortgage, index: number) => {
      const to = generateTo(ROUTES.MORTGAGES_DETAILS, {
        pathParams: { productId: item.id },
        searchParams: originSearchParams,
      });
      trackButtonClicked(item, index, to.pathname);
      navigate(to);
    },
    [navigate, trackButtonClicked],
  );

  const handleApplyPress = useCallback(
    (item: ImpressionedProductVerticalMortgage, index: number) => {
      productApply(item, () => trackProductClicked(item, index));
    },
    [productApply, trackProductClicked],
  );

  const headerComponent = (
    <>
      <ScreenHeader title={i18next.t("marketplace:mortgages:title")} isAdDisclosureHidden={false} />
      <Spacer height={spacing.xs} />
    </>
  );

  if (isLoading || mortgages === undefined) {
    return (
      <LoadingSkeleton
        testID={SKELETON_TEST_ID}
        headerSection={<>{headerComponent}</>}
        cardHeight={SKELETON_CARD_HEIGHT}
        cardWidth={SKELETON_CARD_WIDTH}
      />
    );
  }

  if (isError || (!isLoading && !hasMortgages)) {
    return (
      <>
        {headerComponent}
        <MarketplaceErrorScreen errorType="default" />
      </>
    );
  }

  return (
    <>
      {headerComponent}
      {hasMortgages &&
        mortgages.map((mortgage, index) => {
          // TODO: Implement a new MortgageProductCard component (https://borrowell.atlassian.net/browse/GMS-14374)
          return (
            <View key={mortgage.id}>
              <MortgageProductCard
                product={mortgage}
                index={index}
                onApplyPress={handleApplyPress}
                onDetailsPress={handleDetailsPress}
                onProductViewed={trackProductViewed}
              />
              <Spacer height={spacing.xs} />
            </View>
          );
        })}
    </>
  );
};
