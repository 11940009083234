import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { IQuickApplyErrorResponse, IQuickApplyRequest, MutationOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey } from "@bwll/bw-utils";

export type PostQuickApplyMutationOptions = Omit<MutationOptions, "onSuccess" | "onError"> & {
  onSuccess?: (data: unknown) => void;
  onError?: (response: IQuickApplyErrorResponse) => void;
};

const sendQuickApplyRequest = async <TRequest extends IQuickApplyRequest>(
  accessToken: string,
  baseUrl: string,
  data: TRequest,
): Promise<AxiosResponse<unknown>> => {
  return axios.post<unknown>(`${baseUrl}/api/product-partnerships/product-application`, data, {
    headers: generateApiHeaders(accessToken),
  });
};

const isErrorResponse = (data: unknown): data is IQuickApplyErrorResponse => {
  return (
    typeof data === "object" &&
    data !== null &&
    "error" in data &&
    typeof data.error === "string" &&
    data.error !== undefined
  );
};

const isErrorsResponse = (data: unknown): data is { errors: string } => {
  return (
    typeof data === "object" &&
    data !== null &&
    "errors" in data &&
    typeof data.errors === "string" &&
    data.errors !== undefined
  );
};

export const usePostQuickApply = <TRequest extends IQuickApplyRequest>({
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: PostQuickApplyMutationOptions = {}) => {
  const queryClient = useQueryClient();
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken } = userData;

  return useMutation(
    (data: TRequest) => sendQuickApplyRequest<TRequest>(accessToken, envConfigs.API_GATEWAY_URL, data),
    {
      mutationKey: generateQueryKey(QUERY_KEYS.QUICK_APPLY, accessToken),
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries(
          generateQueryKey(`${QUERY_KEYS.GET_PREAPPROVALS}` as QUERY_KEYS, accessToken),
        );

        onSettled?.(data, error, variables, context);
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            if (isErrorResponse(error.response.data)) {
              onError?.(error.response.data);
              return;
            }

            if (isErrorsResponse(error.response.data)) {
              onError?.({ error: error.response.data.errors });
              return;
            }
          } else {
            // Something happened in setting up the request that triggered an Error
            onError?.({ error: error.message });
            return;
          }
        }

        onError?.({
          error: "An error occurred during Quick Apply that was not mappable to an error response.",
        });
      },
      onSuccess: (data) => {
        onSuccess?.(data.data);
      },
      onMutate,
    },
  );
};
