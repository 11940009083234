import { parseISO } from "date-fns";

import { isDateBefore } from "../dateTime";

/**
 * Returns whether the provided offer end date is expired as of the current date and time in EST.
 * @param offerEndDate The offer end date as a string or Date.
 * @returns True if the offer has expired (end date is before current date) or false if not.
 */
export const isOfferExpired = (offerEndDate: Date | string) => {
  const date = offerEndDate instanceof Date ? offerEndDate : parseISO(offerEndDate);

  const nowEastern = parseISO(
    new Date().toISOString().substring(0, new Date().toISOString().length - 1) + "+04:00",
  );

  return isDateBefore(date, nowEastern);
};

export const reducePredicates = <T>(predicates: ((t: T) => boolean)[]) => {
  if (predicates.length === 0) return () => true;

  return (t: T) => predicates.some((pred) => pred(t));
};
