export const BANK_ACCOUNT_PRODUCT_DETAILS_TEST_IDS = {
  SCROLLVIEW: "scrollview",
  PRODUCT_BANNER_DESKTOP: "product-banner-desktop",
  PRODUCT_BANNER_MOBILE: "product-banner-mobile",
  APPLY_BUTTON_DESKTOP: "apply-button-desktop",
  APPLY_BUTTON_MOBILE: "apply-button-mobile",
  LEGAL: "legal",
  OFFER_CHECKLIST: "offer-checklist",
  PRODUCT_NAME: "product-name",
  COMPANY_ICON: "company-icon",
  OFFER_END_DATE: "offer-end-date",
} as const;
