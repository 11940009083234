import styled from "@emotion/native";

import { Body2, Heading1 } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  display: flex;
  gap: ${styledValue(spacing.m)};
  align-items: center;
`;

export const ContentContainer = styled.View`
  gap: ${styledValue(spacing.xxs)};
`;

export const ImageContainer = styled.Image`
  margin: auto;
  height: ${styledValue(200)};
  width: ${styledValue(200)};
`;

export const ButtonContainer = styled.View`
  max-width: ${styledValue(420)};
  width: 100%;
`;

export const ErrorTitle = styled(Heading1)`
  text-align: center;
`;

export const ErrorDescriptionText = styled(Body2)`
  text-align: center;
`;
