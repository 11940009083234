import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

export type BankAccountsDetailsSection = "offerSummary" | "learnMore" | "minimumBalance";

const treatments: Record<string, { hideOfferChecklistAndMinBalance: boolean; viewProductCta: boolean }> = {
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS].V2]: {
    hideOfferChecklistAndMinBalance: false,
    viewProductCta: true,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS].V3]: {
    hideOfferChecklistAndMinBalance: true,
    viewProductCta: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS].V4]: {
    hideOfferChecklistAndMinBalance: true,
    viewProductCta: true,
  },
};

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceBankAccounts".
 */
export const useBankAccountDetailsExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const experimentValue =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS]?.treatment;

    const treatment =
      experimentValue !== undefined
        ? experimentValue
        : WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS].V2;

    return (
      treatments[treatment] ?? {
        order: ["minimumBalance", "offerSummary", "learnMore"],
        hideOfferChecklistAndMinBalance: false,
        viewProductCta: false,
      }
    );
  }, [experimentsMap.userConfig]);
};
