import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button, ElementTracker, Markdown, Spacer } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { ProductVerticalBankAccount } from "@bwll/bw-types";
import {
  companyIconExists,
  getPromoBannerText,
  isChequing,
  isOfferExpired,
  isSavings,
  makeTestIdGenerator,
} from "@bwll/bw-utils";

import { MajorValueProposition } from "../MajorValueProposition";
import { OfferEndDate } from "../OfferEndDate";
import { ProductBanner } from "../ProductBanner";
import { STYLED, TEST_IDS } from "./BankAccountProductCard.constants";
import * as Styled from "./BankAccountProductCard.styles";
import { BankAccountProductCardProps } from "./BankAccountProductCard.types";

export const BankAccountProductCard = <TBankAccount extends ProductVerticalBankAccount>({
  product,
  onDetailsPress,
  onApplyPress,
  onProductViewed,
  index,
  customCtaCopy,
  hideIcon,
}: BankAccountProductCardProps<TBankAccount>) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const {
    id,
    productName,
    companyIcon,
    isSponsored,
    monthlyFeeMarkdown,
    transactionMarkdown,
    offerSummary,
    majorValuePropositionText,
    majorValuePropositionType,
    offerEndDate,
  } = product;

  const uri = companyIcon ?? "";
  const offerExpired = isOfferExpired(offerEndDate);

  const generateTestId = useMemo(() => makeTestIdGenerator(id), [id]);

  const handleProductViewed = () => {
    onProductViewed(product, index);
  };

  const handleApplyPress = () => {
    onApplyPress(product, index);
  };

  const handleDetailsPress = () => {
    onDetailsPress(product, index);
  };

  const getProductOffers = () => {
    if (isChequing(product)) {
      return (
        <Styled.OfferRow testID={generateTestId(TEST_IDS.CHEQUING_OFFERS)}>
          <Markdown testID={generateTestId(TEST_IDS.MONTHLY_FEE)}>{monthlyFeeMarkdown ?? ""}</Markdown>
          <Markdown testID={generateTestId(TEST_IDS.TRANSACTIONS)}>{transactionMarkdown ?? ""}</Markdown>
        </Styled.OfferRow>
      );
    } else if (isSavings(product)) {
      return (
        <Styled.OfferRow testID={generateTestId(TEST_IDS.SAVING_OFFER)}>
          <Styled.OfferText
            $textAlign={isMobile ? "left" : "center"}
            testID={generateTestId(TEST_IDS.SAVINGS_OFFER_TEXT)}
          >
            {offerSummary}
          </Styled.OfferText>
        </Styled.OfferRow>
      );
    } else return null;
  };

  const promoBannerText = getPromoBannerText(product);
  const cardMinHeight = useMemo(() => (isMobile ? 300 : 324), [isMobile]);

  return (
    <Styled.FlexLayout testID={generateTestId(TEST_IDS.CARD)} minHeight={cardMinHeight}>
      {promoBannerText && !offerExpired && (
        <ProductBanner rounded testID={generateTestId(TEST_IDS.PRODUCT_BANNER)}>
          {promoBannerText}
        </ProductBanner>
      )}
      <Styled.InnerContent>
        <Styled.Container mt={promoBannerText ? 0 : STYLED.CARD_MARGIN_TOP} isMobile={isMobile}>
          <Styled.TitleContainer testID={generateTestId(TEST_IDS.COMPANY_ICON)}>
            {companyIconExists(product) && <Styled.CompanyIcon source={{ uri }} />}
            <Styled.TitleText
              isMobile={isMobile}
              variant="semi-bold"
              numberOfLines={2}
              color={COLORS.NEUTRAL.WARM["800"]}
              testID={generateTestId(TEST_IDS.PRODUCT_NAME)}
            >
              {productName}
            </Styled.TitleText>
          </Styled.TitleContainer>

          <Styled.OfferContainer>
            <MajorValueProposition
              testID={generateTestId(TEST_IDS.VALUE_PROPOSITION)}
              text={majorValuePropositionText || ""}
              type={majorValuePropositionType || undefined}
            />
            <Styled.ProductOfferContainer>{getProductOffers()}</Styled.ProductOfferContainer>
          </Styled.OfferContainer>
          <Styled.Footer>
            {!offerExpired && (
              <>
                <Styled.OfferEndDateContainer>
                  <OfferEndDate testID={generateTestId(TEST_IDS.OFFER_END_DATE)} date={offerEndDate} />
                </Styled.OfferEndDateContainer>
                <Spacer height={spacing.xxs} />
              </>
            )}
            <Styled.ButtonGroup>
              <Styled.ButtonContainer>
                <Button
                  title={i18next.t("productCatalog:all:buttons:details")}
                  onPress={handleDetailsPress}
                  testID={generateTestId(TEST_IDS.DETAILS_BUTTON)}
                  type={BUTTON_TYPE.SECONDARY}
                />
              </Styled.ButtonContainer>
              <Spacer width={spacing.xxs} />
              <Styled.ButtonContainer>
                <Button
                  title={customCtaCopy ?? i18next.t("productCatalog:all:buttons:apply")}
                  iconName={hideIcon ? undefined : "new_tab"}
                  onPress={handleApplyPress}
                  testID={generateTestId(TEST_IDS.APPLY_BUTTON)}
                  type={BUTTON_TYPE.PRIMARY}
                />
              </Styled.ButtonContainer>
            </Styled.ButtonGroup>
            <Spacer height={spacing.xxxs} />
            <Styled.DisclaimerContainer>
              {isSponsored && (
                <Styled.DisclaimerText
                  color={COLORS.NEUTRAL.COOL["600"]}
                  testID={generateTestId(TEST_IDS.DISCLAIMER_TEXT)}
                >
                  {i18next.t("productCatalog:all:sponsored")}
                </Styled.DisclaimerText>
              )}
            </Styled.DisclaimerContainer>
          </Styled.Footer>
        </Styled.Container>
      </Styled.InnerContent>
      <ElementTracker
        trackingHandler={handleProductViewed}
        shouldStartTracking={true}
        trackingKey={product.id}
      />
    </Styled.FlexLayout>
  );
};
