import { isValidElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { Body1, Heading1, Spacer } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { Breadcrumbs } from "@bwll/bw-components/next/molecules/Breadcrumbs";
import { useBreakpoints } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";

import { MarketplaceModal } from "../MarketplaceModal";
import { screenHeaderTestIds } from "./ScreenHeader.constants";
import {
  AdDisclosureButton,
  AdDisclosureText,
  LeftSection,
  RightSection,
  ScreenHeaderContainer,
  Title,
} from "./ScreenHeader.styles";
import { ScreenHeaderProps } from "./ScreenHeader.types";

/**
 * Component for showing the Credit Cards Screen Header used for each of the Credit Cards screens.
 *
 * @param {BreadcrumbItemProps[]} [breadcrumbItems] - List of breadcrumb items to be shown on the left side.
 * @param {string} [title] - Title to be shown on the left side.
 * @param {boolean} [isAdDisclosureHidden] - Flag to hide the Advertiser Disclosure button on the right side.
 */
export const ScreenHeader = ({ breadcrumbItems, title, isAdDisclosureHidden = false }: ScreenHeaderProps) => {
  const { isMobile } = useBreakpoints();

  const i18next = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <ScreenHeaderContainer>
      <LeftSection>
        {breadcrumbItems && (
          <>
            <Breadcrumbs items={breadcrumbItems} />
          </>
        )}
        {title && typeof title === "string" && (
          <Title isMobile={isMobile} testID={screenHeaderTestIds.title}>
            {title}
          </Title>
        )}
        {title && isValidElement(title) && title}
      </LeftSection>
      <RightSection>
        <MarketplaceModal visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <Heading1>{i18next.t("productCatalog:all:advertiserDisclosure:title")}</Heading1>
          <Spacer height={spacing.xs} />
          <Body1>{i18next.t("productCatalog:all:advertiserDisclosure:content")}</Body1>
        </MarketplaceModal>
        {!isAdDisclosureHidden && (
          <AdDisclosureButton
            testID={screenHeaderTestIds.adDisclosure}
            onPress={() => setIsModalVisible(true)}
            isWeb={isWeb}
          >
            <AdDisclosureText isWeb={isWeb}>
              {i18next.t("marketplace:productList:adDisclosureLink")}
            </AdDisclosureText>
          </AdDisclosureButton>
        )}
      </RightSection>
    </ScreenHeaderContainer>
  );
};
