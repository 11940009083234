import styled from "@emotion/native";

import { Button, Heading2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

export const InlineButton = styled(Button)`
  width: auto;
`;

export const Container = styled.View`
  gap: ${sv(40)};
`;

export const HeaderContainer = styled.View`
  gap: ${sv(spacing.m)};
`;

export const PersonalDetailsContainer = styled.View`
  gap: ${sv(spacing.xxs)};
`;

export const PersonalDetailsHeading = styled.View`
  flex-direction: row;
  gap: ${sv(spacing.xs)};
  align-items: center;
`;

export const H2Mobile = styled(Heading2)`
  font-size: ${sv(fontSize.m)};
  line-height: ${sv(lineHeight.xl)};
`;

export const Footer = styled.View`
  padding: ${sv(spacing.xs)};
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.WHITE};
  border-top-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-top-width: ${sv(1)};

  gap: ${sv(spacing.xxs)};
  align-items: center;
`;
