import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BankAccountSortOption, bankAccountSorters } from "@bwll/bw-utils";

import { ProductSortDropdown } from "../creditCards";
import { BankAccountSortDropdownProps } from "./BankAccountSortDropdown.types";

/**
 * This is a thin wrapper around <ProductSortDropdown /> to make it work with sorter objects rather than just keys and functions.
 * This functionally should ideally be moved up into ProductSortDropdown and this component removed.
 */
export const BankAccountSortDropdown = ({
  initialSort,
  onSortChanged,
  onDropdownToggle,
}: BankAccountSortDropdownProps) => {
  const i18next = useTranslation();

  const options = useMemo(
    () =>
      Object.values(bankAccountSorters).map(({ key, localeKey }) => ({
        value: key,
        label: i18next.t(localeKey),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ProductSortDropdown
      options={options}
      initialSort={initialSort}
      onValueChange={(key) => onSortChanged(bankAccountSorters[key as BankAccountSortOption])}
      onDropdownToggle={onDropdownToggle}
    />
  );
};
