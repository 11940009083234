import { useTranslation } from "react-i18next";

import { GenericCarousel, Spacer } from "@bwll/bw-components/next";
import { useSimilarOffersExperiment } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";

import * as Styled from "./SimilarOffers.styles";
import { SimilarOffersProps } from "./SimilarOffers.types";

const headingViewSimilarCardsLocaleKey = "marketplace:creditCards:similarOffers:headingViewSimilarCards";
const headingCompareSimilarOffersLocaleKey =
  "marketplace:creditCards:similarOffers:headingCompareSimilarOffers";

/**
 * A component that displays related product cards below a product's details.
 *
 * @component
 * @example
 * return <SimilarOffers products={carouselData} />
 */
export const SimilarOffers = <TProductCardProps,>({
  products,
  testID,
}: SimilarOffersProps<TProductCardProps>) => {
  const i18next = useTranslation();

  const { showCompareCta } = useSimilarOffersExperiment();

  const hideControls = products.length < 4;

  return (
    <Styled.Container testID={testID}>
      <GenericCarousel
        data={products}
        hideControls={hideControls}
        title={
          <>
            <Styled.Heading>
              {showCompareCta
                ? i18next.t(headingCompareSimilarOffersLocaleKey)
                : i18next.t(headingViewSimilarCardsLocaleKey)}
            </Styled.Heading>
            <Spacer height={spacing.xxs} />
          </>
        }
      />
    </Styled.Container>
  );
};
