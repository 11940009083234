import { useCallback, useMemo } from "react";

import { ElementTracker, LoadingScreen } from "@bwll/bw-components/next";
import {
  useAnalyticsContext,
  useCreateSubscriptionV3,
  useEnvironmentContext,
  useExperiments,
  useSubscriptionProductAndPlan,
} from "@bwll/bw-hooks";
import {
  ANALYTICS_ATTRIBUTES,
  CheckoutMode,
  CreateSubscriptionResponse,
  ENGLISH_LANGUAGE,
  HISTORICAL_REPORTING_EVENT_VALUES,
  RegionalLocale,
  STANDARD_TREATMENTS,
  WEB_EXPERIMENT_IDS,
} from "@bwll/bw-types";

import { HistoricalReportingLandingPage as HistoricalReportingLandingPageUI } from "./HistoricalReportingLandingPage.component";
import { HistoricalReportingLandingPageContainerProps } from "./HistoricalReportingLandingPage.types";

export const HistoricalReportingLandingPageContainer = ({
  onCtaPress,
  onFaqPress,
}: HistoricalReportingLandingPageContainerProps) => {
  const { envConfigs } = useEnvironmentContext();

  const { isLoading: isSubscriptionProductLoading, plan } = useSubscriptionProductAndPlan(
    envConfigs.HISTORICAL_REPORTING_PRODUCT_ID,
    false,
  );

  const enableV1Flow = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.RA_HISTORICAL_REPORTING_V1,
  });

  const onCreateSubscriptionSuccess = (data: CreateSubscriptionResponse) => {
    window.location.assign(data.url);
  };

  const { mutate, isLoading: isCheckoutLinkLoading } = useCreateSubscriptionV3({
    onSuccess: onCreateSubscriptionSuccess,
  });

  const { historicalReportingEvents } = useAnalyticsContext();

  const commonAnalyticsAttributes = useMemo(() => {
    return {
      // TODO: Add onboarding state logic
      [ANALYTICS_ATTRIBUTES.ONBOARDING_STATE]: "",
      [ANALYTICS_ATTRIBUTES.TREATMENT]: STANDARD_TREATMENTS.ON,
      [ANALYTICS_ATTRIBUTES.VERSION]: HISTORICAL_REPORTING_EVENT_VALUES.V1,
    };
  }, []);

  const trackViewEvent = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingScreenViewed({
      [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]: HISTORICAL_REPORTING_EVENT_VALUES.EDUCATION_STEP_VIEWED,
      ...commonAnalyticsAttributes,
    });
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const trackCTAClickEvent = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingButtonClicked({
      [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
        HISTORICAL_REPORTING_EVENT_VALUES.START_BUILDING_CREDIT_BUTTON_CLICKED,
      ...commonAnalyticsAttributes,
    });
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const trackFAQClickEvent = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingButtonClicked({
      [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
        HISTORICAL_REPORTING_EVENT_VALUES.VIEW_ALL_FAQS_BUTTON_CLICKED,
      ...commonAnalyticsAttributes,
    });
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const ctaPressHandler = () => {
    trackCTAClickEvent();

    if (enableV1Flow) {
      onCtaPress();
    } else {
      if (plan?.id && !isCheckoutLinkLoading) {
        const baseUrl = `${window.location.origin}${envConfigs.RENT_REPORTING_REDIRECT_URI_PATH}/historical-reporting`;

        mutate({
          priceId: plan.id,
          successUrl: `${baseUrl}/confirmation`,
          cancelUrl: baseUrl,
          checkoutMode: CheckoutMode.Payment,
          locale: RegionalLocale[ENGLISH_LANGUAGE],
          shouldSkipRentProofAndTransactionCheck: true,
        });
      }
    }
  };

  const faqPressHandler = () => {
    trackFAQClickEvent();
    onFaqPress();
  };

  // TODO - figure out loading state
  if (isSubscriptionProductLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ElementTracker trackingHandler={trackViewEvent} shouldStartTracking={true} />
      <HistoricalReportingLandingPageUI
        showCTA={true}
        onCtaPress={ctaPressHandler}
        onFaqPress={faqPressHandler}
      />
    </>
  );
};
