import React from "react";

import { Body1, Icon, Spacer, Subheading2, Tooltip } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { COLORS, spacing } from "@bwll/bw-styles";

import { DollarBillWrapper, FirstYearValueWrapper, tooltipStyle } from "./FirstYearValue.styles";
import { FirstYearValueProps } from "./FirstYearValue.types";

/**
 * Component for showing the FirstYearValue.
 *
 * @component
 * @example
 * return (
 *   <FirstYearValue
 *    amount="$ 1,000"
 *    copy="first year value"
 *    tooltipCopy="Estimated sum of welcome bonus, yearly rewards and/or Borrowell Bonus."
 *   />
 * )
 */

export const FirstYearValue = ({ amount, copy, tooltipCopy }: FirstYearValueProps) => {
  const tooltipProps = isWeb
    ? {
        content: <Body1>{tooltipCopy}</Body1>,
      }
    : {
        bodyText: `${tooltipCopy}`,
        tooltipWidth: 300,
      };

  return (
    <Tooltip {...tooltipProps} style={tooltipStyle}>
      <FirstYearValueWrapper>
        <DollarBillWrapper>
          <Icon icon={"dollar"} size={16} color={COLORS.GREEN["600"]} />
        </DollarBillWrapper>
        <Spacer width={spacing.xxxs} />
        <Subheading2 color={COLORS.GREEN["600"]}>
          {amount}
          <Subheading2 color={COLORS.GREEN["600"]} underline>
            {` ${copy}`}
          </Subheading2>
        </Subheading2>
      </FirstYearValueWrapper>
    </Tooltip>
  );
};
