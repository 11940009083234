import React, { useMemo } from "react";
import { Pressable, View } from "react-native";

import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { Button } from "../../atoms/Button";
import { BUTTON_TYPE } from "../../atoms/Button/Button.types";
import { Cell } from "../../atoms/Cell";
import { Icon } from "../../atoms/Icon";
import { Spacer } from "../../atoms/Spacer";
import { Heading1, Paragraph } from "../../atoms/Typography";
import { CARD_TEST_IDS as TEST_IDS } from "./Card.constants";
import * as Styled from "./Card.styles";
import { CardProps } from "./Card.types";

/**
 * Component for showing the Card.
 *
 * @component
 * @example
 * return (
 * <Card
 *   buttonText="PLEASE CLICK ME"
 *   imageSource={{
 *     uri: 'https://images..png'
 *   }}
 *   subtitle="Lorem ipsum dolor"
 *   title="Card is cool"
 * />
 * )
 */
export const Card = ({
  title,
  subtitle,
  buttonText,
  imageSource,
  buttonOnPress,
  buttonType,
  testID,
  cardImageBackgroundColor,
  icon,
  onClose,
}: CardProps) => {
  const getTestId = useMemo(() => makeTestIdGenerator(testID ?? ""), [testID]);

  return (
    <Styled.CardCell borderRadius={borderRadius.s} hasShadow testID={testID}>
      <Styled.CardContentContainer>
        {imageSource && (
          <>
            <Cell
              borderRadius={borderRadius.s}
              testID={`CardImageBackground_${testID}`}
              backgroundColor={cardImageBackgroundColor}
            >
              <Styled.CardImage testID={`CardImage_${testID}`} source={imageSource} />
            </Cell>
            <Spacer height={spacing.xs} />
          </>
        )}
        {title && (
          <>
            <Styled.HorizontalView>
              {icon && (
                <Styled.CardIconContainer>
                  <Icon icon={icon} size={26} />
                </Styled.CardIconContainer>
              )}
              <Heading1>{title}</Heading1>
            </Styled.HorizontalView>
            <Spacer height={spacing.xxxs} />
          </>
        )}
        <Styled.HorizontalView>
          {icon && !title && (
            <Styled.CardIconContainer>
              <Icon icon={icon} size={16} />
            </Styled.CardIconContainer>
          )}
          <Paragraph>{subtitle}</Paragraph>
        </Styled.HorizontalView>
        {buttonText && buttonOnPress && (
          <>
            <Spacer height={spacing.xs} />
            <Button type={buttonType || BUTTON_TYPE.ACCENT} title={buttonText} onPress={buttonOnPress} />
          </>
        )}
      </Styled.CardContentContainer>
      {onClose && (
        <View>
          <Pressable onPress={onClose} testID={getTestId(TEST_IDS.CLOSE_BUTTON)}>
            <Icon icon="close" size={20} color={COLORS.NEUTRAL.COOL[600]} />
          </Pressable>
        </View>
      )}
    </Styled.CardCell>
  );
};
