import type { LoaderFunctionArgs } from "react-router-dom";

import { CreditCardSortOptionKey, SORT_OPTIONS, creditCardSorters } from "@bwll/bw-modules";
import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";
import {
  CreditCardFilterer,
  StaticCreditCardFilterOption,
  createCompanyNameFilterer,
  creditCardFilterers,
  parseCreditCardCategoryKeyFromPathname,
} from "@bwll/bw-utils";

import { getTypedLoaderDataHook } from "@app/router/utils";

const isStaticCreditCardFilterOption = (key: string): key is StaticCreditCardFilterOption =>
  Object.prototype.hasOwnProperty.call(creditCardFilterers, key);

const getSortBy = (searchParams: URLSearchParams): CreditCardSortOptionKey | undefined => {
  const sortBy = searchParams.get("sortBy");
  if (sortBy && Object.keys(SORT_OPTIONS).includes(sortBy)) return sortBy as CreditCardSortOptionKey;
};

const getFilterers = (searchParams: URLSearchParams): CreditCardFilterer[] | undefined => {
  const filterBy = searchParams.get("filterBy");
  if (!filterBy) return;
  const filtererKeys = filterBy.split(",");

  return filtererKeys.map((f) =>
    isStaticCreditCardFilterOption(f) ? creditCardFilterers[f] : createCompanyNameFilterer(f),
  );
};

/** Prepares data for rendering CreditCardProductList from react-router-dom.  */
export const creditCardProductListLoader = ({ request, params }: LoaderFunctionArgs) => {
  const searchParams = new URL(request.url).searchParams;

  // Prepare category
  const categoryKey =
    parseCreditCardCategoryKeyFromPathname(params.category) ?? CREDIT_CARD_CATEGORY_KEYS.ALL;

  // Prepare filterer
  const filterers = getFilterers(searchParams);

  // Prepare sorter
  const sortBy = getSortBy(searchParams);
  const sorter =
    categoryKey === "trending"
      ? creditCardSorters.Trending
      : sortBy
      ? creditCardSorters[sortBy]
      : creditCardSorters.Recommended;

  return { categoryKey, sorter, filterers };
};

export const useCreditCardProductListLoaderData =
  getTypedLoaderDataHook<typeof creditCardProductListLoader>();
