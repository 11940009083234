import { BreadcrumbItemButton, BreadcrumbItemLabel, BreadcrumbItemLink } from "./BreadcrumbItem.styles";
import { BreadcrumbItemProps } from "./BreadcrumbItem.types";

/**
 * Component for showing the Breadcrumb item
 *
 * @param {string} label - The text displayed in the Breadcrumbs.
 * @param {string} [linkTo] - The link to which the breadcrumb item should navigate. Used only on web.
 * @param {function} [onPress] - The function to be called when the breadcrumb item is pressed.
 * @param {string} testID - Test ID for the breadcrumb item.
 */
export const BreadcrumbItem = ({ label, linkTo, onPress, testID }: BreadcrumbItemProps) => {
  if (linkTo) {
    return (
      <BreadcrumbItemLink data-testid={testID} to={linkTo} onClick={onPress}>
        {label}
      </BreadcrumbItemLink>
    );
  }
  if (onPress) {
    return (
      <BreadcrumbItemButton testID={testID} onPress={onPress}>
        {label}
      </BreadcrumbItemButton>
    );
  }
  return <BreadcrumbItemLabel testID={testID}>{label}</BreadcrumbItemLabel>;
};
