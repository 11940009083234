import { useMemo, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { Icon } from "@bwll/bw-components/next/atoms/Icon";
import { COLORS, spacing } from "@bwll/bw-styles";

import { Container, Dot, Label, Link, Submenu } from "./MenuLink.styles";
import { MenuLinkProps } from "./MenuLink.types";

/**
 * MenuLink is a React component that renders a navigational link item, potentially with a nested submenu.
 *
 * Props:
 * - `onPress`: Function to execute when the link is pressed.
 * - `isCloseToViewportLimit`: Boolean indicating if the submenu should adjust its positioning to stay within the viewport.
 * - `label`: String representing the text label for the link.
 * - `icon`: String representing the icon to display alongside the label.
 * - `testID`: String used for testing purposes to identify the element.
 * - `highlight`: Boolean indicating if the link should be highlighted, typically to denote an active or important link.
 * - `links`: Array of objects representing sub-links for creating a nested menu. Each object should match the `MenuLinkProps` type.
 *
 * Usage:
 * ```
 * <MenuLink
 *   onPress={() => { console.log('Link pressed'); }}
 *   isCloseToViewportLimit={false}
 *   label="Home"
 *   icon="home"
 *   testID="menu-link-home"
 *   highlight={true}
 *   links={[{ label: 'Sublink 1', onPress: () => { console.log('Sublink 1 pressed'); }, icon: 'sublink1', testID: 'sublink-1' }]}
 * />
 * ```
 */
export const MenuLink = ({
  onPress,
  isCloseToViewportLimit,
  label,
  icon,
  testID,
  highlight,
  links,
}: MenuLinkProps) => {
  const ref = useRef<View>(null);
  const [hovered, setHovered] = useState(false);
  const [menuWidth, setMenuWidth] = useState(100);

  const onLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setMenuWidth(width);
  };

  const controlMenuHoverState = (offsetX: number, offsetY: number) => {
    if (!links || offsetX < 0 || offsetY < 0 || offsetX > menuWidth - spacing.xxxs || offsetY > 100) {
      setHovered(false);
    }
  };

  const subLinks = useMemo(
    () =>
      links?.map((link) => ({
        ...link,
        onPress: () => {
          setHovered(false);
          link.onPress();
        },
      })),
    [links],
  );

  return (
    <Container
      hovered={hovered}
      isCombinedLink={!!links}
      onHoverIn={() => setHovered(true)}
      onHoverOut={({ nativeEvent: { offsetX, offsetY } }) => controlMenuHoverState(offsetX, offsetY)}
      // onPress is duplicated here to make the entire container clickable
      onPress={onPress}
      testID={`${testID}-link`}
      ref={ref}
      onLayout={onLayout}
    >
      <Link onPress={onPress}>
        {icon && <Icon icon={icon} size={spacing.xl} color={COLORS.BLACK} />}
        <Label hovered={hovered} isCombinedLink={!!links}>
          {label}
        </Label>
        {highlight && <Dot testID={`${testID}-highlight`} />}
      </Link>
      {links && (
        <Icon icon={"arrow_down"} color={hovered ? COLORS.PRIMARY.DEFAULT : COLORS.BLACK} size={spacing.xs} />
      )}
      {hovered && subLinks && (
        <Submenu testID={`${testID}-submenu`} isCloseToViewportLimit={isCloseToViewportLimit}>
          {subLinks.map((link, index) => (
            <MenuLink {...link} key={index}>
              {link.label}
            </MenuLink>
          ))}
        </Submenu>
      )}
    </Container>
  );
};
