import { Picker } from "@react-native-picker/picker";
import { useCallback, useState } from "react";

import {
  StyledCaptionComponent,
  StyledDropdownComponent,
  StyledDropdownContainer,
  StyledLabelComponent,
  VerticalMarginFix,
} from "./Dropdown.styles";
import { DropdownProps } from "./Dropdown.types";

/**
 * Component for showing a Dropdown.
 *
 * @component
 * @example
 * return (
 *   <Dropdown
 *      label="Label"
 *      options=[{label: "label1", value: "value1"}, {label: "label2", value: "value2"}]
 *   />
 * )
 */
export const Dropdown = ({
  testID,
  label,
  caption,
  onValueChange,
  currentState,
  captionColor,
  options,
  value,
  matchTextInputMargin = false,
}: DropdownProps) => {
  const [focused, setFocused] = useState(false);

  const focus = useCallback(() => {
    setFocused(true);
  }, []);

  const blur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <StyledDropdownContainer>
      {!!label && <StyledLabelComponent errored={!!currentState?.errored}>{label}</StyledLabelComponent>}
      <VerticalMarginFix matchTextInputMargin={matchTextInputMargin}>
        <StyledDropdownComponent
          testID={testID}
          errored={!!currentState?.errored}
          disabled={!!currentState?.disabled}
          onValueChange={onValueChange}
          selectedValue={value}
          focused={focused}
          onFocus={focus}
          onBlur={blur}
        >
          {options.map((option, index) => (
            <Picker.Item key={index} label={option.label} value={option.value} testID={option.testID} />
          ))}
        </StyledDropdownComponent>
      </VerticalMarginFix>
      {!!caption && (
        <StyledCaptionComponent errored={!!currentState?.errored} color={captionColor}>
          {caption}
        </StyledCaptionComponent>
      )}
    </StyledDropdownContainer>
  );
};
