import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native";

import {
  BUTTON_TYPE,
  Button,
  Display2,
  Dropdown,
  Heading2,
  Spacer,
  TextInput,
} from "@bwll/bw-components/next";
import { useBreakpoints, useGetMember } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { DATE_FORMATS, REGIONS_ORDERED } from "@bwll/bw-types";
import { formatDate, formatPhoneNumber } from "@bwll/bw-utils";

import { PROFILE_EDIT_TEST_IDS as TEST_IDS } from "./ProfileEditForm.constants";
import * as Styled from "./ProfileEditForm.styles";
import { ProfileEditFormProps } from "./ProfileEditForm.types";

export const ProfileEditForm = ({ onSubmit, onNavigateBack }: ProfileEditFormProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const { isLoading, data: member } = useGetMember({
    transform: (response) => {
      const { legalName, birthDate, primaryPhoneNumber, email } = response.individual;

      const { streetAddress, streetAddress2, city, regionName, regionId, postalCode } =
        response.individual.primaryAddress;

      return {
        legalName,
        dateOfBirth: formatDate({
          dateString: birthDate,
          dateFormat: DATE_FORMATS.MMMM_dd_yyyy,
          ignoreOffset: true,
        }),
        phoneNumber: formatPhoneNumber(primaryPhoneNumber) ?? primaryPhoneNumber,
        email,
        streetAddress,
        streetAddress2,
        city,
        regionName,
        regionId,
        postalCode,
      };
    },
  });

  const [formData, setFormData] = useState(member);

  const editHeading = i18next.t("profile:edit:headings:edit");

  return isLoading || !formData ? (
    <ActivityIndicator size="small" color={COLORS.NEUTRAL.COOL["600"]} />
  ) : (
    <Styled.Container>
      {isMobile ? <Styled.H2Mobile>{editHeading}</Styled.H2Mobile> : <Display2>{editHeading}</Display2>}
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:identity")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:fullNameLabel")}
              value={formData.legalName}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:dateOfBirthLabel")}
              value={formData.dateOfBirth}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:contact")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:phoneNumberLabel")}
              value={formData.phoneNumber}
              onChangeText={(value) => setFormData({ ...formData, phoneNumber: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:emailAddressLabel")}
              value={formData.email}
              onChangeText={(value) => setFormData({ ...formData, email: value })}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:address")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:streetAddress1Label")}
              value={formData.streetAddress}
              onChangeText={(value) => setFormData({ ...formData, streetAddress: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:streetAddress2Label")}
              value={formData.streetAddress2}
              onChangeText={(value) => setFormData({ ...formData, streetAddress2: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:cityLabel")}
              value={formData.city}
              onChangeText={(value) => setFormData({ ...formData, city: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Dropdown
              label={i18next.t("profile:edit:form:regionLabel")}
              options={REGIONS_ORDERED.map((region) => ({ label: region.name, value: region.id }))}
              value={formData.regionId}
              onValueChange={(value) => setFormData({ ...formData, regionId: value })}
              matchTextInputMargin={true}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:postalCodeLabel")}
              value={formData.postalCode}
              onChangeText={(value) => setFormData({ ...formData, postalCode: value })}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Spacer height={spacing.m} />
      <Styled.SectionContainer>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onPress={() => onSubmit(formData)}
          title={i18next.t("profile:edit:buttons:updateProfile")}
          testID={TEST_IDS.BUTTON_UPDATE_PROFILE}
        />
        <Button
          type={BUTTON_TYPE.TERTIARY}
          onPress={onNavigateBack}
          title={i18next.t("profile:edit:buttons:discardChanges")}
          testID={TEST_IDS.BUTTON_DISCARD_CHANGES}
        />
      </Styled.SectionContainer>
    </Styled.Container>
  );
};
