import React, { useEffect, useState } from "react";

import { Icon } from "@bwll/bw-components/next/atoms/Icon";
import { COLORS } from "@bwll/bw-styles";

import {
  StyledCheckboxComponent,
  StyledCheckboxLabel,
  StyledCheckboxOptionComponent,
  StyledCheckboxSquareComponent,
} from "./Checkbox.styles";
import { CheckboxOption, CheckboxProps } from "./Checkbox.types";

/**
 * Component for showing the Checkbox.
 *
 * @component
 * @example
 * return (
 *   <Checkbox
 *      options={[
 *        {
 *          label: "Option 1",
 *          value: 1
 *        }
 *        ...
 *      ]}
 *      onSelect={(selectedOptions) => console.log(selectedOptions)}
 *   />
 * )
 */
export const Checkbox = ({ testID, onSelect, options }: CheckboxProps) => {
  const [selectedOptions, setSelectedOptions] = useState(options);

  const selectOption = (option: CheckboxOption, index: number) => {
    const newSelectedOptions = [
      ...selectedOptions.slice(0, index),
      {
        ...option,
        checked: !option.checked,
      },
      ...selectedOptions.slice(index + 1, selectedOptions.length),
    ];

    setSelectedOptions(newSelectedOptions);
    onSelect?.({ toggled: newSelectedOptions[index], all: newSelectedOptions });
  };

  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);

  return (
    <StyledCheckboxComponent testID={testID}>
      {selectedOptions.map((option, index) => (
        <StyledCheckboxOptionComponent
          key={index}
          onPress={() => selectOption(option, index)}
          isFirst={index === 0}
        >
          <StyledCheckboxSquareComponent selected={!!option?.checked}>
            {option?.checked && <Icon icon={"success"} color={COLORS.WHITE} />}
          </StyledCheckboxSquareComponent>
          <StyledCheckboxLabel>{option?.label}</StyledCheckboxLabel>
        </StyledCheckboxOptionComponent>
      ))}
    </StyledCheckboxComponent>
  );
};
