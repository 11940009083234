import { createContext, useMemo, useState } from "react";
import React from "react";

import { WithChildren } from "@bwll/bw-components/next/types";
import { CreditCardComparison, ImpressionedProductVerticalCreditCard } from "@bwll/bw-types";
import { noop } from "@bwll/bw-utils";

import { ContextName, useContextWrapper } from "./contextWrapper";

// Context
type CreditCardComparisonProviderProps = WithChildren<{
  initialState?: CreditCardComparison;
}>;
type CreditCardComparisonContext = [
  CreditCardComparison,
  React.Dispatch<React.SetStateAction<CreditCardComparison>>,
];

const emptyComparison: CreditCardComparison = {
  cards: [],
};

// Contexts
export const CreditCardComparisonContext = createContext<CreditCardComparisonContext>([
  emptyComparison,
  noop,
]);

// API
/**
 * Provides the CreditCardComparisonContext to its child components.
 */
export const CreditCardComparisonProvider = ({
  initialState,
  children,
}: CreditCardComparisonProviderProps) => {
  const state = useState(initialState ?? emptyComparison);

  return (
    <CreditCardComparisonContext.Provider value={state}>{children}</CreditCardComparisonContext.Provider>
  );
};

/**
 * Gets the current CreditCardComparison state and functions to modify it.
 * @returns The CreditCardComparison context state and functions to modify it.
 */
export const useCreditCardComparison = () => {
  const [comparison, setComparison] = useContextWrapper(
    CreditCardComparisonContext,
    ContextName.CreditCardComparisonContext,
  );

  return useMemo(
    () => ({
      /**
       * The current Compare collection state.
       */
      comparison,
      /**
       * Adds a credit card to the Compare collection.
       * @param creditCard The card to add.
       */
      addCreditCard: (creditCard: ImpressionedProductVerticalCreditCard) => {
        if (comparison.cards.length > 3) return;

        setComparison({
          cards: comparison.cards.concat(creditCard),
        });
      },
      /**
       * Adds a range of credit cards to the Compare collection.
       * @param creditCards the cards to add.
       * @returns
       */
      addCreditCardRange: (creditCards: ImpressionedProductVerticalCreditCard[]) => {
        if (comparison.cards.length + creditCards.length > 4) return;

        setComparison({
          cards: comparison.cards.concat(creditCards),
        });
      },
      /**
       * Removes a credit card from the Compare collection.
       * @param id The card to remove's ID.
       */
      removeCreditCard: (id: string) => {
        setComparison({
          cards: comparison.cards.filter((c) => c.id !== id),
        });
      },
      /**
       * Clears the Compare collection.
       */
      clear: () => {
        setComparison({ cards: [] });
      },
    }),
    [comparison, setComparison],
  );
};
