import { useCallback } from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useImpressionedCreditCards } from "@bwll/bw-hooks";
import { QuickApplyError } from "@bwll/bw-modules";
import { getQuickApplyCreditCardProduct } from "@bwll/bw-utils";

import { useQuickApplyLoaderData } from "../QuickApply.loader";
import {
  QUICK_APPLY_PRODUCT_ERROR_TEST_IDS,
  SKELETON_HEIGHT,
  SKELETON_VARIANT,
  SKELETON_WIDTH,
} from "./QuickApplyProductError.constants";

import { useGoBack } from "@app/hooks";
import { ROUTES } from "@app/router";

export const QuickApplyProductError = () => {
  const { productId } = useQuickApplyLoaderData();
  const goBack = useGoBack(ROUTES.CREDIT_CARDS);

  const {
    isLoading: isProductLoading,
    data: product,
    error: productError,
  } = useImpressionedCreditCards({
    transform: (response) => {
      const creditCard = response.productVerticalCreditCards.find((card) => card.id === productId);
      if (!creditCard) return undefined;
      return getQuickApplyCreditCardProduct(creditCard);
    },
  });

  const { productImageUrl, companyName, productName, websiteLink } = product ?? {};

  const handleApplyAnyway = useCallback(() => {
    websiteLink ? window.open(websiteLink, "_blank") : null;
  }, [websiteLink]);

  // TODO: Handle errors
  if (productError) {
    return <div>Error occurred. Please try again later.</div>;
  }

  if (isProductLoading || !product) {
    return (
      <Skeleton
        width={SKELETON_WIDTH}
        height={SKELETON_HEIGHT}
        variant={SKELETON_VARIANT}
        testID={QUICK_APPLY_PRODUCT_ERROR_TEST_IDS.SKELETON}
      />
    );
  }

  return (
    <QuickApplyError
      productName={productName}
      productImageUrl={productImageUrl}
      companyName={companyName}
      onApplyAnyway={handleApplyAnyway}
      onBackToRecommendations={goBack}
    />
  );
};
