import react, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useNavigate } from "react-router-dom";

import { Spacer } from "@bwll/bw-components/next";
import {
  useBankAccountProductListAnalytics,
  useExperiments,
  useImpressionedBankAccounts,
} from "@bwll/bw-hooks";
import {
  BankAccountFilterPills,
  BankAccountProductCard,
  BankAccountSortDropdown,
  LoadingSkeleton,
  MarketplaceErrorScreen,
  ScreenHeader,
} from "@bwll/bw-modules";
import { spacing } from "@bwll/bw-styles";
import {
  ImpressionedProductVerticalBankAccount,
  MARKETPLACE_ANALYTICS_ORIGINS,
  ProductVerticalBankAccount,
  WEB_EXPERIMENT_IDS,
  WEB_TREATMENT_IDS,
} from "@bwll/bw-types";
import {
  BankAccountFilterer,
  BankAccountSorter,
  bankAccountSorters,
  filterBankAccounts as filter,
  generateTo,
} from "@bwll/bw-utils";

import * as Styled from "./BankAccountProductList.styles";

import { useBankAccountExperimentRedirect, useMarketplaceTrackEvent } from "@app/hooks";
import { ROUTES } from "@app/router";

const SKELETON_CARD_WIDTH = "100%";
const SKELETON_CARD_HEIGHT = 345;
const SKELETON_TEST_ID = "SKELETON_TEST_ID";

const originSearchParams = {
  origin: MARKETPLACE_ANALYTICS_ORIGINS.BANK_ACCOUNTS_LIST,
};

export const BankAccountProductList = () => {
  useBankAccountExperimentRedirect();

  const i18next = useTranslation();
  const navigate = useNavigate();
  const trackEvent = useMarketplaceTrackEvent();
  const {
    trackProductClicked,
    trackProductViewed,
    trackDropdownClicked,
    trackDropdownOptionClicked,
    trackFilterSelected,
    trackButtonClicked,
  } = useBankAccountProductListAnalytics(trackEvent);
  const [filters, setFilters] = useState<BankAccountFilterer[]>([]);
  const [sort, setSort] = useState<BankAccountSorter>(bankAccountSorters.Recommended);

  const {
    data: bankAccounts,
    isLoading,
    isError,
    refetch: refetchImpressionIds,
  } = useImpressionedBankAccounts({
    transform: (resp) => sort.sortFn(filter(filters, resp.productVerticalBankAccounts)),
  });

  const shouldUseViewProductCtaCopy = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS,
    treatments: [
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V2,
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V3,
    ],
  });

  const numberOfProductsShown = useMemo(() => {
    return bankAccounts?.length ?? 0;
  }, [bankAccounts]);

  const handleDetailsPress = useCallback(
    (item: ImpressionedProductVerticalBankAccount) => {
      const to = generateTo(ROUTES.BANK_ACCOUNTS_DETAILS, {
        pathParams: { productId: item.id },
        searchParams: originSearchParams,
      });
      trackButtonClicked(item, to.pathname);
      navigate(to);
    },
    [navigate],
  );

  const handleApplyPress = useCallback(
    (item: ImpressionedProductVerticalBankAccount, index: number) => {
      trackProductClicked(item, index, numberOfProductsShown, filters[0]?.key);
      // National Bank requires users to visit the details page first. We've added this line here as a temporary solution.
      if (item.companyName === "National Bank") return handleDetailsPress(item);
      if (item.impressionedProductLink) window.open(item.impressionedProductLink);
    },
    [trackProductClicked, handleDetailsPress, filters, numberOfProductsShown],
  );

  const handleSortChange = useCallback(
    (newSort: BankAccountSorter) => {
      setSort(newSort);
      trackDropdownOptionClicked(newSort.key);
    },
    [trackDropdownOptionClicked],
  );

  const handleFilterChange = useCallback(
    (newFilters: BankAccountFilterer[]) => {
      setFilters(newFilters);

      // Events are not sent when both filters are selected
      newFilters.length === 1 && trackFilterSelected(newFilters[0].key);
    },
    [trackFilterSelected],
  );

  useEffect(() => {
    if (isLoading || isError) return;

    refetchImpressionIds();
  }, [refetchImpressionIds, isLoading, isError]);

  const headerComponent = (
    <ScreenHeader title={i18next.t("marketplace:bankAccounts:title")} isAdDisclosureHidden={false} />
  );
  const sortAndFilterComponents = (
    <Styled.SortAndFilterContainer>
      <BankAccountSortDropdown onSortChanged={handleSortChange} onDropdownToggle={trackDropdownClicked} />
      <BankAccountFilterPills onFilterChanged={handleFilterChange} />
    </Styled.SortAndFilterContainer>
  );

  const customApplyCtaCopy = shouldUseViewProductCtaCopy ? i18next.t("misc:common:viewProduct") : undefined;

  if (isLoading || bankAccounts === undefined) {
    return (
      <LoadingSkeleton
        testID={SKELETON_TEST_ID}
        headerSection={
          <>
            {headerComponent}
            {sortAndFilterComponents}
          </>
        }
        cardHeight={SKELETON_CARD_HEIGHT}
        cardWidth={SKELETON_CARD_WIDTH}
      />
    );
  }

  if (isError) {
    return (
      <>
        {headerComponent}
        <MarketplaceErrorScreen errorType="default" />
      </>
    );
  }

  if (!isLoading && bankAccounts && bankAccounts.length === 0) {
    // TODO: Empty state
    return (
      <>
        {headerComponent}
        {sortAndFilterComponents}
        <MarketplaceErrorScreen errorType="empty-credit-cards" />
      </>
    );
  }

  return (
    <>
      {headerComponent}
      {sortAndFilterComponents}
      {bankAccounts &&
        bankAccounts.length > 0 &&
        bankAccounts.map((bankAccount, index) => {
          return (
            <View key={bankAccount.id}>
              <BankAccountProductCard
                product={bankAccount}
                index={index}
                onApplyPress={handleApplyPress}
                onDetailsPress={handleDetailsPress}
                onProductViewed={trackProductViewed}
                customCtaCopy={customApplyCtaCopy}
                hideIcon={shouldUseViewProductCtaCopy}
              />
              <Spacer height={spacing.xs} />
            </View>
          );
        })}
    </>
  );
};
