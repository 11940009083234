import styled from "@emotion/styled";
import { Link as _Link } from "react-router-dom";

import { COLORS, WEB_SCREEN_MAX_WIDTH_WITH_PADDING, WEB_SCREEN_PADDING, mq } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const SmallButtonContainer = styled.div(() =>
  mq({
    justifyContent: "center",
    width: ["25%", "160px", "160px"],
  }),
);

export const Link = styled(_Link)({
  color: COLORS.PURPLE.DEFAULT,
  textDecoration: "none",
  "&:hover": {
    color: COLORS.PRIMARY["300"],
  },
});

export const AppOuterContainer = styled.div`
  width: 100%;
  flex: 1 0 auto;
  background: ${COLORS.BRIGHT_GREEK["100"]};
`;

export const AppWidthContainer = styled.div({
  margin: "auto",
  padding: `${styledValue(WEB_SCREEN_PADDING)}`,
  maxWidth: `${styledValue(WEB_SCREEN_MAX_WIDTH_WITH_PADDING)}`,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});
