import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbItemProps, LoadingScreen } from "@bwll/bw-components/next";
import {
  useBankAccountProductDetailsAnalytics,
  useBreakpoints,
  useImpressionedBankAccounts,
} from "@bwll/bw-hooks";
import {
  MarketplaceErrorScreen,
  ProductBanner,
  BankAccountProductDetails as ProductDetails,
} from "@bwll/bw-modules";
import { ScreenHeader } from "@bwll/bw-modules";
import { MARKETPLACE_ANALYTICS_ORIGINS } from "@bwll/bw-types";
import { generateTo, getPromoBannerText, isOfferExpired, noop } from "@bwll/bw-utils";

import { BANK_ACCOUNT_PRODUCT_DETAILS_TEST_IDS as TEST_IDS } from "./BankAccountProductDetails.constants";
import * as Styled from "./BankAccountProductDetails.styles";

import {
  useBankAccountExperimentRedirect,
  useMarketplaceProductApplication,
  useMarketplaceTrackEvent,
} from "@app/hooks";
import { ROUTES } from "@app/router";

export const BankAccountProductDetails = () => {
  useBankAccountExperimentRedirect();

  const { productId } = useParams();

  const i18next = useTranslation();
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoints();

  const {
    data: bankAccount,
    isLoading,
    isError,
  } = useImpressionedBankAccounts({
    transform: ({ productVerticalBankAccounts }) =>
      productVerticalBankAccounts.find((ba) => ba.id === productId),
  });

  const headerComponent = useMemo(() => {
    const breadcrumbItems: BreadcrumbItemProps[] = [
      {
        label: i18next.t("marketplace:bankAccounts:title"),
        onPress: () =>
          navigate(
            generateTo(ROUTES.BANK_ACCOUNTS, {
              searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.BANK_ACCOUNTS_DETAILS },
            }),
          ),
        testID: `${TEST_IDS.BREADCRUMB}-list`,
      },
      ...(bankAccount
        ? [
            {
              label: bankAccount.productName ?? "",
              testID: `${TEST_IDS.BREADCRUMB}-product`,
            },
          ]
        : []),
    ];

    return <ScreenHeader breadcrumbItems={breadcrumbItems} />;
  }, [bankAccount, i18next, navigate]);

  const trackEvent = useMarketplaceTrackEvent();
  const { trackProductViewed, trackProductClicked, trackProductExpanded } =
    useBankAccountProductDetailsAnalytics(trackEvent, bankAccount);

  useEffect(() => {
    trackProductViewed();
    trackProductExpanded(false);
  }, [trackProductViewed, trackProductExpanded]);

  // Handlers
  const productApply = useMarketplaceProductApplication();
  const handleApplyPress = useCallback(
    () => bankAccount && productApply(bankAccount, trackProductClicked),
    [bankAccount, productApply, trackProductClicked],
  );

  const handleLegalDetailsToggle = useCallback(
    (isExpanded: boolean) => {
      trackProductExpanded(isExpanded);
    },
    [trackProductExpanded],
  );

  const handleExternalLinkPress = useCallback((url: string) => window.open(url), []);

  // Loading and error handling
  if (isLoading || isError || !bankAccount)
    return (
      <Styled.DesktopContainer>
        {headerComponent}
        {isLoading && <LoadingScreen />}
        {isError && <MarketplaceErrorScreen errorType="default" />}
        {!isLoading && !bankAccount && <MarketplaceErrorScreen errorType="empty-bank-accounts" />}
      </Styled.DesktopContainer>
    );

  const offerExpired = isOfferExpired(bankAccount.offerEndDate);
  const promoBannerText = getPromoBannerText(bankAccount);

  return isDesktop ? (
    <Styled.DesktopContainer>
      {headerComponent}
      <ProductDetails
        product={bankAccount}
        onApplyPress={handleApplyPress}
        onExternalLinkPress={handleExternalLinkPress}
        onLegalDetailsToggle={handleLegalDetailsToggle}
      />
    </Styled.DesktopContainer>
  ) : (
    <Styled.ResetMargin hasOfferEndDate={bankAccount.offerEndDate && !offerExpired}>
      {promoBannerText && !offerExpired && <ProductBanner>{promoBannerText}</ProductBanner>}
      <Styled.NonDesktopContainer>
        <ProductDetails
          product={bankAccount}
          onApplyPress={handleApplyPress}
          onExternalLinkPress={handleExternalLinkPress}
          onLegalDetailsToggle={handleLegalDetailsToggle}
          HeaderComponent={headerComponent}
        />
      </Styled.NonDesktopContainer>
    </Styled.ResetMargin>
  );
};
