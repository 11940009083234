import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";

import { ReadonlyInput } from "@bwll/bw-components/next";
import { useGetMember } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";
import { DATE_FORMATS } from "@bwll/bw-types";
import { formatDate, formatPhoneNumber } from "@bwll/bw-utils";

import * as Styled from "./QuickApplyPersonalDetailsForm.styles";

export const QuickApplyPersonalDetailsForm = () => {
  const { t } = useTranslation();

  const { isLoading, data: member } = useGetMember({
    transform: (response) => {
      const { legalName, birthDate, primaryPhoneNumber, email, primaryAddress } = response.individual;

      return {
        legalName,
        dateOfBirth: formatDate({
          dateString: birthDate,
          dateFormat: DATE_FORMATS.MMMM_dd_yyyy,
          ignoreOffset: true,
        }),
        phoneNumber: formatPhoneNumber(primaryPhoneNumber) ?? primaryPhoneNumber,
        email,
        address: primaryAddress.formattedAddress,
      };
    },
  });

  return isLoading || !member ? (
    <ActivityIndicator size="small" color={COLORS.NEUTRAL.COOL["600"]} />
  ) : (
    <View>
      <Styled.Input>
        <ReadonlyInput
          label={t("marketplace:quickApply:personalDetails:form:fullNameLabel")}
          value={member.legalName}
        />
      </Styled.Input>
      <Styled.Input>
        <ReadonlyInput
          label={t("marketplace:quickApply:personalDetails:form:dateOfBirthLabel")}
          value={member.dateOfBirth}
        />
      </Styled.Input>
      <Styled.Input>
        <ReadonlyInput
          label={t("marketplace:quickApply:personalDetails:form:phoneNumberLabel")}
          value={member.phoneNumber}
        />
      </Styled.Input>
      <Styled.Input>
        <ReadonlyInput
          label={t("marketplace:quickApply:personalDetails:form:emailAddressLabel")}
          value={member.email}
        />
      </Styled.Input>
      <Styled.Input>
        <ReadonlyInput
          label={t("marketplace:quickApply:personalDetails:form:addressLabel")}
          value={member.address}
        />
      </Styled.Input>
    </View>
  );
};
