import styled from "@emotion/native";

import { COLORS, borderRadius, borderWidth, boxShadow, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Footer = styled.View<{ isDesktop: boolean; customWidth?: number; isMobile?: boolean }>`
  position: fixed;
  z-index: 201;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${({ customWidth }) => (customWidth ? styledValue(customWidth) : "100%")};
  height: ${styledValue(64)};
  bottom: ${styledValue(0)};
  background-color: ${COLORS.WHITE};
  border-top-width: ${styledValue(borderWidth.small)};
  border-top-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-left-width: ${({ isDesktop }) => (isDesktop ? styledValue(borderWidth.small) : styledValue(0))};
  border-left-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-radius: ${styledValue(borderRadius.s)} ${styledValue(borderRadius.s)} ${styledValue(0)}
    ${styledValue(0)};
  box-shadow: ${boxShadow};
  left: ${({ isDesktop, isMobile }) => {
    if (isMobile) return styledValue(-spacing.xs);
    return isDesktop ? styledValue(351) : styledValue(0);
  }};
  max-width: ${({ isDesktop }) => (isDesktop ? "calc(100vw - 351px)" : "none")};
`;

export const CompareButtonContainer = styled.View<{ isDesktop: boolean }>`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? styledValue(300) : styledValue(200))};
`;

export const ClearButtonContainer = styled.View<{ isDesktop: boolean }>`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? styledValue(300) : styledValue(127))};
`;
