import { useTranslation } from "react-i18next";

import { Icon, Markdown } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";

import * as Styled from "./RatesTable.styles";
import type { RatesTableProps } from "./RatesTable.types";
import { DollarText, PercentageText } from "./components";

export const RatesTable = ({ rates, testID }: RatesTableProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <Styled.Table testID={testID} id={testID} isMobile={isMobile}>
      {rates.map((rate) => (
        <Styled.Cell key={rate.key} testID={rate.key}>
          <Styled.Label testID={`${rate.key}-label`}>{i18next.t(rate.labelLocaleKey)}</Styled.Label>
          {rate.type === "currency" ? (
            <DollarText
              testID={`${rate.key}-value`}
              value={rate.value ?? 0}
              isWaived={rate.isFirstYearWaived}
            />
          ) : rate.type === "percentage" ? (
            <PercentageText testID={`${rate.key}-value`} value={rate.value} />
          ) : (
            <Styled.TextValue>
              <Markdown
                color={COLORS.NEUTRAL.WARM["800"]}
                style={{ fontWeight: "600", textAlign: "center" }}
                testID={`${rate.key}-value`}
              >
                {rate.value}
              </Markdown>
              {rate.asteriskIcon && (
                <Icon icon={rate.asteriskIcon} size={fontSize.xxs} color={COLORS.PRIMARY["400"]} />
              )}
            </Styled.TextValue>
          )}
        </Styled.Cell>
      ))}
    </Styled.Table>
  );
};
