import { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { CreditCardComparisonProvider, useCreditCardComparisonAnalytics } from "@bwll/bw-hooks";
import { CreditCardCompareFooter } from "@bwll/bw-modules";
import { MARKETPLACE_ANALYTICS_ORIGINS } from "@bwll/bw-types";
import { generateTo } from "@bwll/bw-utils";

import { CREDIT_CARD_SHELL_TEST_IDS as TEST_IDS } from "./CreditCardShell.constants";
import { useCreditCardShellProps } from "./CreditCardShell.hooks";
import { CreditCardShellProps } from "./CreditCardShell.types";

import { useMarketplaceTrackEvent } from "@app/hooks";
import { ROUTES } from "@app/router";

/**
 * Wraps the Credit Card components with Credit Card-specific providers.
 */
export const CreditCardShell = ({ comparisonInitialState }: CreditCardShellProps = {}) => {
  const navigate = useNavigate();
  const { showFooter } = useCreditCardShellProps();

  const trackEvent = useMarketplaceTrackEvent();
  const { trackCompareButtonClicked } = useCreditCardComparisonAnalytics(trackEvent);

  const handleOnComparePress = useCallback(
    (productTrackingTags: string[]) => {
      trackCompareButtonClicked(productTrackingTags);
      navigate(
        generateTo(ROUTES.CREDIT_CARDS_COMPARE, {
          searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_LIST },
        }),
      );
    },
    [navigate, trackCompareButtonClicked],
  );

  return (
    <CreditCardComparisonProvider initialState={comparisonInitialState}>
      <Outlet />
      {showFooter && (
        <CreditCardCompareFooter
          onComparePress={(productTrackingTags: string[]) => handleOnComparePress(productTrackingTags)}
          testID={TEST_IDS.FOOTER}
        />
      )}
    </CreditCardComparisonProvider>
  );
};
