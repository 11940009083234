import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button, ElementTracker, Icon, Markdown, Spacer } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { ProductVerticalMortgage } from "@bwll/bw-types";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import {
  CLOSED_INFORMATION_MARKDOWN_MAX_CHARACTERS,
  MORTGAGE_PRODUCT_CARD_TEST_IDS,
} from "./MortgageProductCard.constants";
import * as Styled from "./MortgageProductCard.styles";
import { MortgageProductCardProps } from "./MortgageProductCard.types";

export const MortgageProductCard = <TMortgage extends ProductVerticalMortgage>({
  product,
  onDetailsPress,
  onApplyPress,
  onProductViewed,
  index,
}: MortgageProductCardProps<TMortgage>) => {
  const i18next = useTranslation();
  const { isMobile, isDesktop } = useBreakpoints();
  const [isExpanded, setIsExpanded] = useState(false);

  const { id, productName, companyLogo, informationMarkdown, callToAction } = product;
  const shouldExpand =
    !!informationMarkdown && informationMarkdown?.length > CLOSED_INFORMATION_MARKDOWN_MAX_CHARACTERS;

  const generateTestId = useMemo(() => makeTestIdGenerator(id), [id]);

  const handleDescriptionPress = () => {
    setIsExpanded((value) => !value);
  };

  const handleProductViewed = () => {
    onProductViewed(product, index);
  };

  const handleApplyPress = () => {
    onApplyPress(product, index);
  };

  const handleDetailsPress = () => {
    onDetailsPress(product, index);
  };

  const cardMinHeight = useMemo(() => (isMobile ? 300 : 324), [isMobile]);

  if (!productName || !companyLogo || !informationMarkdown) {
    return null;
  }

  return (
    <Styled.FlexLayout testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.CARD)}>
      <Styled.InnerContent minHeight={cardMinHeight}>
        <Styled.Container isMobile={isMobile}>
          <Styled.TitleContainer>
            <Styled.TitleText
              isMobile={isMobile}
              variant="semi-bold"
              numberOfLines={2}
              color={COLORS.NEUTRAL.WARM["800"]}
              testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.PRODUCT_NAME)}
            >
              {productName}
            </Styled.TitleText>
          </Styled.TitleContainer>
          <Styled.ImageContainer testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.COMPANY_LOGO)}>
            <Styled.CompanyImage isDesktop={isDesktop} source={{ uri: companyLogo }} resizeMode="contain" />
          </Styled.ImageContainer>

          {shouldExpand && (
            <>
              <Styled.ExpandableInformationContainer isExpanded={isExpanded} onPress={handleDescriptionPress}>
                <Markdown testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.INFORMATION_TEXT)}>
                  {informationMarkdown}
                </Markdown>
              </Styled.ExpandableInformationContainer>
              <Styled.IconContainer
                onPress={handleDescriptionPress}
                testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.EXPAND_BUTTON)}
              >
                <Icon
                  icon={isExpanded ? "arrow_up" : "arrow_down"}
                  size={fontSize.xxl}
                  color={COLORS.PRIMARY["500"]}
                />
              </Styled.IconContainer>
            </>
          )}
          {!shouldExpand && (
            <Styled.InformationContainer>
              <Markdown testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.INFORMATION_TEXT)}>
                {informationMarkdown}
              </Markdown>
            </Styled.InformationContainer>
          )}
        </Styled.Container>
        <Styled.Footer>
          <Styled.ButtonGroup>
            <Styled.ButtonContainer>
              <Button
                title={i18next.t("productCatalog:all:buttons:learnMore")}
                onPress={handleDetailsPress}
                testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.LEARN_MORE_BUTTON)}
                type={BUTTON_TYPE.SECONDARY}
              />
            </Styled.ButtonContainer>
            <Spacer width={spacing.xxs} />
            <Styled.ButtonContainer>
              <Button
                title={callToAction ?? i18next.t("productCatalog:all:buttons:viewProduct")}
                onPress={handleApplyPress}
                testID={generateTestId(MORTGAGE_PRODUCT_CARD_TEST_IDS.APPLY_BUTTON)}
                type={BUTTON_TYPE.PRIMARY}
              />
            </Styled.ButtonContainer>
          </Styled.ButtonGroup>
          <Spacer height={spacing.xxxs} />
        </Styled.Footer>
      </Styled.InnerContent>
      <ElementTracker
        trackingHandler={handleProductViewed}
        shouldStartTracking={true}
        trackingKey={product.id}
      />
    </Styled.FlexLayout>
  );
};
