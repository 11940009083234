import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button } from "@bwll/bw-components/next";

import { CreditCardApplyButtonProps } from "./CreditCardApplyButton.types";

/**
 * A button component for the various kinds of apply title and icons for a credit card.
 */
export const CreditCardApplyButton = ({
  type,
  isQuickApply,
  useApplyTitle,
  showIcon,
  onPress,
  testID,
}: CreditCardApplyButtonProps) => {
  const { t } = useTranslation();

  const title = isQuickApply
    ? t("productCatalog:all:buttons:quickApply")
    : useApplyTitle
    ? t("productCatalog:all:buttons:apply")
    : t("misc:common:viewProduct");

  return (
    <Button
      iconName={showIcon ? "new_tab" : undefined}
      title={title}
      onPress={onPress}
      type={type ?? BUTTON_TYPE.PRIMARY}
      testID={testID}
    />
  );
};
