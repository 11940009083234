import axios from "axios";
import { useQuery } from "react-query";

import { IApiHookOptions, QUERY_KEYS, QualifyResponse } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

import { useEnvironmentContext, useSessionContext } from "../../../contexts";

type PostQualifyApiHookOptions<TReturn = QualifyResponse> = IApiHookOptions<QualifyResponse, TReturn>;

const postQualify = async (accessToken: string, baseUrl: string) => {
  const url = new URL(`/api/product-verticals/qualification/v2/qualify`, baseUrl);

  const { data } = await axios.post<QualifyResponse>(
    url.href,
    {},
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return data;
};

export const usePostQualify = <TTransformReturn = QualifyResponse>(
  options: PostQualifyApiHookOptions<TTransformReturn> = {},
) => {
  const {
    userData: { accessToken, expiresIn },
  } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const queryKey = generateQueryKey(QUERY_KEYS.POST_QUALIFY, accessToken);

  const queryFn = () => postQualify(accessToken, envConfigs.API_GATEWAY_URL);

  const queryOptions = queryBehavior({
    expiresIn,
    ...options,
    enabled: Boolean(accessToken) && options.enabled,
  });

  return useQuery(queryKey, queryFn, queryOptions);
};
