import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useTrackEventDirect } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, LAYOUT_TYPE } from "@bwll/bw-types";

/**
 * Gets a function that sends any Analytics event for the Marketplace app.
 *
 * If you are looking for event-specific functions, see `useMarketplaceAnalyticsEvents`.
 * @returns A function that accepts an event label and attributes and sends the event.
 */
export const useMarketplaceTrackEvent = () => {
  const trackEvent = useTrackEventDirect();
  const { pathname, search } = useLocation();

  const commonAttributes = useMemo(() => {
    // Determine layout type by the user agent
    const layoutType = window?.navigator?.userAgent?.match(/(iPad)|(iPhone)|(android)|(webOS)/i)
      ? LAYOUT_TYPE.MOBILE_WEB
      : LAYOUT_TYPE.WEB;
    const origin = new URLSearchParams(search).get("origin") ?? "";

    return {
      [ANALYTICS_ATTRIBUTES.LAYOUT_TYPE]: layoutType,
      [ANALYTICS_ATTRIBUTES.ORIGIN]: origin,
      [ANALYTICS_ATTRIBUTES.SCREEN_NAME]: pathname,
    };
  }, [pathname, search]);

  return useCallback(
    (eventLabel: string, attributes: Record<string, string | boolean | number | string[] | undefined>) =>
      trackEvent({
        ...commonAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_LABEL]: eventLabel,
        ...attributes,
      }),
    [commonAttributes, trackEvent],
  );
};
