export const MORTGAGE_PRODUCT_CARD_TEST_IDS = {
  CARD: "card",
  APPLY_BUTTON: "apply-button",
  LEARN_MORE_BUTTON: "learn-more-button",
  PRODUCT_NAME: "product-name",
  COMPANY_LOGO: "company-logo",
  INFORMATION_TEXT: "information-text",
  EXPAND_BUTTON: "expand-button",
};

export const CLOSED_INFORMATION_MARKDOWN_MAX_CHARACTERS = 75;
