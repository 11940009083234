import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PRODUCT_APPLICATION_DESTINATION_TYPES, useProductApplication } from "@bwll/bw-hooks";
import { ImpressionedApplyableProduct, ImpressionedQuickApplyableProduct } from "@bwll/bw-types";
import { noop } from "@bwll/bw-utils";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

type OnClickEvent = () => undefined | void | Promise<unknown>;

/**
 * Returns a function which, when called, will apply for the product on behalf of the user.
 *
 * - If product is not Quick Applyable, a window will be opened to the partner application page.
 * - If the product is Quick Applyable and the user is in the `sspaMarketplaceQuickApply` treatment, they will be navigated to the Quick Apply screen.
 * - If not in the `sspaMarketplaceQuickApply` treatment, they will be navigated to Gemstone Web's Quick Apply.
 */
export const useMarketplaceProductApplication = (
  origin?: string,
): ((
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
  onClick?: OnClickEvent,
) => Promise<void>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = {
    ...(origin ? { origin } : {}),
  };
  const getApplyDestination = useProductApplication(ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL, {
    searchParams,
  });

  return useCallback(
    async (product, onClick = noop) => {
      const destination = getApplyDestination(product);
      const onClickCallback = onClick ?? noop;

      switch (destination.type) {
        case PRODUCT_APPLICATION_DESTINATION_TYPES.MARKETPLACE_QUICK_APPLY:
          onClickCallback();
          navigate(destination.localTo, { state: { returnTo: location } });
          break;
        case PRODUCT_APPLICATION_DESTINATION_TYPES.GEMSTONE_WEB_QUICK_APPLY:
          try {
            await onClickCallback();
          } finally {
            window.location.href = destination.url;
          }
          break;
        case PRODUCT_APPLICATION_DESTINATION_TYPES.EXTERNAL_WEBSITE:
        default:
          if (destination.url) {
            onClickCallback();
            window.open(destination.url);
          }
      }

      return Promise.resolve();
    },
    [getApplyDestination, location, navigate],
  );
};
