import { useTranslation } from "react-i18next";

import { ShowMore } from "@bwll/bw-components/next";

import { LearnMoreShowMoreProps } from "./LearnMoreShowMore.types";

const heightCutoff = 64;
const showMoreLocaleKey = "marketplace:productDetails:showMore";
const showLessLocaleKey = "marketplace:productDetails:showLess";

export const LearnMoreShowMore = ({ children, testID }: LearnMoreShowMoreProps) => {
  const i18next = useTranslation();

  return (
    <ShowMore
      showLessLabel={i18next.t(showLessLocaleKey)}
      showMoreLabel={i18next.t(showMoreLocaleKey)}
      heightCutoff={heightCutoff}
      testID={testID}
    >
      {children}
    </ShowMore>
  );
};
