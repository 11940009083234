import styled from "@emotion/native";

import { WEB_SCREEN_PADDING } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

/**
 * Resets the AppShell central column margin. Used for full-width designs.
 */
export const ResetMargin = styled.View`
  margin: ${styledValue(-WEB_SCREEN_PADDING)};
`;
