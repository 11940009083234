import {
  ImpressionedProductVerticalCreditCard,
  ImpressionedProductVerticalLoan,
  QuickApplyProduct,
} from "@bwll/bw-types";

/**
 * Maps an ImpressionedProductVerticalCreditCard to a QuickApplyProduct.
 * @param creditCard The credit card product.
 * @returns A QuickApplyProduct.
 */
export const getQuickApplyCreditCardProduct = ({
  companyName,
  id,
  productImageUrl,
  productImpressionId,
  productName,
  productReferenceNumber,
  productVerticalId,
  trackingTag,
  impressionedProductLink,
}: ImpressionedProductVerticalCreditCard): QuickApplyProduct => ({
  companyName,
  id,
  productImageUrl,
  productImpressionId,
  productName,
  productReferenceNumber,
  productVerticalId,
  trackingTag,
  websiteLink: impressionedProductLink,
});

/**
 * Maps an ImpressionedProductVerticalLoan to a QuickApplyProduct.
 * @param loan The loan product.
 * @returns A QuickApplyProduct.
 */
export const getQuickApplyLoanProduct = ({
  id,
  companyLogo,
  productImpressionId,
  companyName,
  productReferenceNumber,
  productVerticalId,
  trackingTag,
  impressionedProductLink,
}: ImpressionedProductVerticalLoan): QuickApplyProduct => ({
  companyName,
  id,
  productImageUrl: companyLogo,
  productImpressionId,
  productName: companyName,
  productReferenceNumber,
  productVerticalId,
  trackingTag,
  websiteLink: impressionedProductLink,
});
