import styled from "@emotion/native";

import { Link1 } from "@bwll/bw-components/next";
import { fontSize, letterSpacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const StyledLink1 = styled(Link1)`
  font-size: ${styledValue(fontSize.xxs)};
  letter-spacing: ${styledValue(letterSpacing.l)};
`;
