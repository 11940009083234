import React from "react";
import { useTranslation } from "react-i18next";

import { Highlights } from "../../Highlights";
import { ApprovalChanceProps } from "./ApprovalChance.types";
import { getApprovalChanceProps } from "./getApprovalChanceProps";

/**
 * Renders a UI component for Approval Chance, with an icon and tooltip that corresponds to the LOA type.
 */
export const ApprovalChance = ({
  likelihoodOfApprovalType,
  approvalChance,
  testID,
  shortened = false,
}: ApprovalChanceProps) => {
  const i18next = useTranslation();

  const { text, tooltipText, iconColor, iconName } = getApprovalChanceProps({
    likelihoodOfApprovalType,
    approvalChance,
  });

  return (
    <Highlights
      testID={testID}
      hasTooltip
      iconProps={{
        iconColor,
        iconName,
      }}
      tooltipTextProps={{
        tooltipText,
        tooltipKey: text ?? "",
      }}
    >
      {shortened ? text : text + " " + i18next.t("productCatalog:all:metrics:approvalChance").toLowerCase()}
    </Highlights>
  );
};
