import { useLoaderData } from "react-router-dom";
import type { LoaderFunctionArgs } from "react-router-dom";

import { QuickApplyRouteParams } from "./QuickApply.types";

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

/**
 * Prepares parameters for rendering QuickApply from react-router-dom.
 */
export const quickApplyLoader = ({
  params,
  request,
}: LoaderFunctionArgs): QuickApplyRouteParams | Response => {
  const productId = params.productId;

  if (!productId || !uuidRegex.test(productId)) {
    return new Response("Product not found", { status: 404 });
  }

  const searchParams = new URL(request.url).searchParams;
  const paramImpressionId = searchParams.get("impressionId");
  const impressionId = paramImpressionId && uuidRegex.test(paramImpressionId) ? paramImpressionId : undefined;

  return {
    productId,
    impressionId,
  };
};

/**
 * Returns the parameters for the QuickApply component.
 */
export const useQuickApplyLoaderData = () => useLoaderData() as QuickApplyRouteParams;
