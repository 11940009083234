import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@bwll/bw-components/next";
import { useCreditCardFiltersExperiment, useMarketplaceTrendingExperiment } from "@bwll/bw-hooks";

import { CreditCardTrendingTipCardProps } from "./CreditCardTrendingTipCard.types";

const localStorageTipClosedKey = "bwll.marketplace.tips.trending.closed";

export const CreditCardTrendingTipCard = ({ testID }: CreditCardTrendingTipCardProps) => {
  const { t } = useTranslation();
  const [closed, setClosed] = useState(
    () => Boolean(localStorage.getItem(localStorageTipClosedKey)) || false,
  );
  const { showTrending } = useMarketplaceTrendingExperiment();
  const { showFilters } = useCreditCardFiltersExperiment();

  useEffect(() => {
    if (closed) localStorage.setItem(localStorageTipClosedKey, "true");
  }, [closed]);

  return !closed && showTrending && showFilters ? (
    <Card
      subtitle={t("marketplace:creditCards:tips:trending")}
      icon="lightbulb"
      onClose={() => setClosed(true)}
      testID={testID}
    />
  ) : null;
};
