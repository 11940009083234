import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { ErrorBoundary } from "@bwll/bw-components/next";
import favicon from "@bwll/bw-components/src/assets/favicon-32x32.png";
import { ErrorReporting } from "@bwll/bw-utils";

import { ErrorBoundaryScreen } from "../ErrorBoundaryScreen";
import { useAppShellProps } from "./AppShell.hooks";
import { AppOuterContainer, AppWidthContainer } from "./AppShell.styles";
import { AppShellProps } from "./AppShell.types";

import { ProductVerticalsNavBar } from "@app/components/ProductVerticalsNavBar";
import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { ContextProviders } from "@app/contexts";
import { usePageViewAnalyticsEvent } from "@app/hooks";
import "@app/styles/index.css";

/**
 * Initializes error reporting services.
 */
ErrorReporting.init({
  sentry: {
    dsn: ENVIRONMENT_VARIABLES.SENTRY_DSN,
    environment: ENVIRONMENT_VARIABLES.ENVIRONMENT,
    tracesSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_TRACES_SAMPLE_RATE) || 0,
    replaysOnErrorSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_REPLAYS_ERROR_SAMPLE_RATE) || 0,
    replaysSessionSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 0,
  },
  appInsights: {
    connectionString: ENVIRONMENT_VARIABLES.APP_INSIGHTS_CONNECTION_STRING,
  },
});

// Scroll to top on navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // https://github.com/Microsoft/TypeScript/issues/28755
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Remove after upgrading to TypeScript 5.2+
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return null;
};

// Send page view on navigation
const PageViewEvent = () => {
  usePageViewAnalyticsEvent();

  return null;
};

export const AppShell = ({ queryClient }: AppShellProps) => {
  const { showNavbar } = useAppShellProps();

  // TODO: Refactor helmet to be handled in a separate component
  const appMetaTags = (
    <Helmet>
      <title>Borrowell Marketplace</title>
      <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
    </Helmet>
  );

  return (
    <ErrorBoundary onError={ErrorReporting.logError} FallbackComponent={<ErrorBoundaryScreen />}>
      <ContextProviders queryClient={queryClient}>
        {appMetaTags}
        <AppOuterContainer>
          {showNavbar && <ProductVerticalsNavBar />}
          <AppWidthContainer>
            <ScrollToTop />
            <Outlet />
            <PageViewEvent />
          </AppWidthContainer>
        </AppOuterContainer>
      </ContextProviders>
    </ErrorBoundary>
  );
};
