import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { ResetMargin as AppResetMargin } from "@app/components";

export const ResetMargin = styled(AppResetMargin)<{ isMobile: boolean }>`
  background: white;

  // Stretch the container to the bottom of the screen on desktop
  ${({ isMobile }) => !isMobile && "height: calc(-160px + 100vh)"};
`;

export const InnerContainer = styled.View<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? `
  padding: ${sv(spacing.xs)};
  padding-bottom: ${sv(spacing.m)};`
      : `
  max-width: 62.5%;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${sv(40)};
  padding-bottom: ${sv(spacing.xs)};`}
`;
