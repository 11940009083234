import { useMemo } from "react";

import {
  AutoLoansWebsiteDomains,
  IQuickApplyErrorResponse,
  MapRequestFormData,
  PartnerSchema,
  QuickApplyPartner,
  QuickApplyProduct,
  ResponseMapper,
} from "@bwll/bw-types";
import {
  coreFields,
  getQuickApplyProductPartner,
  mapAutoLoansRequestBody,
  mapAutoLoansResponse,
  mapCapitalOneResponse,
  mapCarsfastResponse,
  mapCommonRequestBody,
  mapFairstoneRequestBody,
  mapFairstoneResponse,
  mapSpringResponse,
} from "@bwll/bw-utils";

type QuickApplySuccessCallback = (mapResponse: ResponseMapper) => (data: unknown) => void;
type QuickApplyErrorCallback = (response: IQuickApplyErrorResponse) => void;

type QuickApplyPartnerLookupOptions = {
  product: QuickApplyProduct;
  getRedirectToSuccessPage: QuickApplySuccessCallback;
  getRedirectToExternalUrl: QuickApplySuccessCallback;
  redirectToErrorPage: QuickApplyErrorCallback;
  isSpringFinancialV2ApiOn: boolean;
};

export const useQuickApplyPartnerLookup = ({
  product,
  getRedirectToSuccessPage,
  getRedirectToExternalUrl,
  redirectToErrorPage,
  isSpringFinancialV2ApiOn,
}: QuickApplyPartnerLookupOptions) => {
  const springFinancialPartnerSchema = useMemo((): PartnerSchema => {
    return {
      formSchema: isSpringFinancialV2ApiOn
        ? [...coreFields, "loanAmount", "springEmploymentStatus", "monthlyIncome"]
        : ["firstName", "lastName", "email"],
      consent: {
        messageTranslationKey: isSpringFinancialV2ApiOn ? "springFinancialV2" : "default",
      },
      mapRequest: mapCommonRequestBody,
      onSuccess: getRedirectToExternalUrl(mapSpringResponse),
      onError: redirectToErrorPage,
    };
  }, [isSpringFinancialV2ApiOn]);

  /**
   * Configurable partner schema, new quick apply partners are added here
   * Defines their form schema, maps their request/response objects
   * And sets their onSuccess/onError functions
   */
  const partners: Record<QuickApplyPartner, PartnerSchema> = {
    CapitalOne: {
      formSchema: [...coreFields],
      mapRequest: mapCommonRequestBody,
      onSuccess: getRedirectToExternalUrl(mapCapitalOneResponse),
      onError: redirectToErrorPage,
    },
    Autoloans: {
      formSchema: [...coreFields],
      mapRequest: (form: MapRequestFormData) =>
        mapAutoLoansRequestBody(form, AutoLoansWebsiteDomains.Autoloans),
      onSuccess: getRedirectToSuccessPage(mapAutoLoansResponse),
      onError: redirectToErrorPage,
    },
    Carloans: {
      formSchema: [...coreFields],
      mapRequest: (form: MapRequestFormData) =>
        mapAutoLoansRequestBody(form, AutoLoansWebsiteDomains.Carloans),
      onSuccess: getRedirectToSuccessPage(mapAutoLoansResponse),
      onError: redirectToErrorPage,
    },
    Carsfast: {
      consent: {
        messageTranslationKey: "carsfast",
        links: [
          { linkTranslationKey: "carsfastPrivacyPolicy", url: "https://carsfast.ca/privacy-policy/" },
          { linkTranslationKey: "carsfastTermsAndConditions", url: "https://carsfast.ca/terms-of-use/" },
        ],
      },
      formSchema: [
        ...coreFields,
        "employmentStatus",
        "monthlyIncome",
        "housingStatus",
        "employmentDuration",
        "housingDuration",
        "companyName",
        "desiredVehicle",
      ],
      mapRequest: mapCommonRequestBody,
      onSuccess: getRedirectToSuccessPage(mapCarsfastResponse),
      onError: redirectToErrorPage,
    },
    Fairstone: {
      formSchema: [
        ...coreFields,
        "language",
        "annualIncome",
        "housingStatus",
        "housingMonthlyCost",
        "housingTimeAtAddress",
        "loanAmount",
      ],
      mapRequest: mapFairstoneRequestBody,
      onSuccess: getRedirectToExternalUrl(mapFairstoneResponse),
      onError: redirectToErrorPage,
    },
    SpringFinancial: springFinancialPartnerSchema,
  };

  // Default behavior if partner can't be found, should never be used
  // Because we don't allow user to open a quick apply screen for a non-quick apply partner
  const defaultPartner: PartnerSchema = {
    formSchema: [],
    mapRequest: mapCommonRequestBody,
    onSuccess: getRedirectToSuccessPage(() => ({ successDescriptionKey: "autoloans" })),
    onError: redirectToErrorPage,
  };

  const quickApplyProduct = getQuickApplyProductPartner(product);
  const partnerSchema = partners[quickApplyProduct] ?? defaultPartner;

  return partnerSchema;
};
