import { useCallback, useMemo } from "react";

import {
  AutoLoansWebsiteDomains,
  IQuickApplyErrorResponse,
  MapRequestFormData,
  PartnerSchema,
  QuickApplyPartner,
  QuickApplyProduct,
  ResponseMapper,
} from "@bwll/bw-types";
import {
  ErrorReporting,
  coreFields,
  getQuickApplyProductPartner,
  mapAutoLoansRequestBody,
  mapAutoLoansResponse,
  mapCapitalOneResponse,
  mapCarsfastResponse,
  mapCommonRequestBody,
  mapFairstoneRequestBody,
  mapFairstoneResponse,
  mapSpringResponse,
} from "@bwll/bw-utils";

type QuickApplyResponseHandlers = {
  onSuccessRedirect: (redirectUrl: string, product: QuickApplyProduct) => void;
  onSuccessPage: (successDescriptionKey: string, product: QuickApplyProduct) => void;
  onError: (error: string, product: QuickApplyProduct) => void;
};

type QuickApplyPartnerLookupOptions = {
  product: QuickApplyProduct;
  individualClientIdReferenceNumber: number;
  handlers?: Partial<QuickApplyResponseHandlers>;
};

const defaultHandlers: QuickApplyResponseHandlers = {
  onSuccessRedirect: (redirectUrl, product) =>
    ErrorReporting.logError(
      new Error(
        `Quick Apply success redirect called for ${product.companyName} but no callback was supplied.`,
      ),
      {
        product,
        redirectUrl,
      },
    ),
  onSuccessPage: (_, product) =>
    ErrorReporting.logError(
      new Error(`Quick Apply success page called for ${product.companyName} but no callback was supplied.`),
      {
        product,
      },
    ),
  onError: (error, product) =>
    ErrorReporting.logError(
      new Error(`Quick Apply error page called for ${product.companyName} but no callback was supplied.`),
      {
        product,
        error,
      },
    ),
};

const redirectToExternalUrl =
  (
    product: QuickApplyProduct,
    individualClientIdReferenceNumber: number,
    handlers: QuickApplyResponseHandlers,
    mapResponse: ResponseMapper,
  ): ((data: unknown) => void) =>
  (data: unknown) => {
    const response = mapResponse(data, product, individualClientIdReferenceNumber);

    if ("redirectUrl" in response && response.redirectUrl) {
      handlers.onSuccessRedirect(response.redirectUrl, product);
    } else if ("error" in response) {
      handlers.onError(response.error, product);
    }
  };

const redirectToSuccessPage =
  (
    product: QuickApplyProduct,
    individualClientIdReferenceNumber: number,
    handlers: QuickApplyResponseHandlers,
    mapResponse: ResponseMapper,
  ): ((data: unknown) => void) =>
  (data: unknown) => {
    const response = mapResponse(data, product, individualClientIdReferenceNumber);

    if ("successDescriptionKey" in response && response.successDescriptionKey) {
      handlers.onSuccessPage(response.successDescriptionKey, product);
    } else if ("error" in response) {
      handlers.onError(response.error, product);
    }
  };

const redirectToErrorPage =
  (
    product: QuickApplyProduct,
    { onError }: QuickApplyResponseHandlers,
  ): ((response: IQuickApplyErrorResponse) => void) =>
  ({ error }) =>
    onError(error, product);

export const useQuickApplyPartner = ({
  product,
  individualClientIdReferenceNumber,
  handlers,
}: QuickApplyPartnerLookupOptions) => {
  const callbacks = useMemo(
    () => ({
      ...defaultHandlers,
      ...handlers,
    }),
    [handlers],
  );

  const externalUrlHandler = useCallback(
    (responseMapper: ResponseMapper) =>
      redirectToExternalUrl(product, individualClientIdReferenceNumber, callbacks, responseMapper),
    [callbacks, individualClientIdReferenceNumber, product],
  );
  const successPageHandler = useCallback(
    (responseMapper: ResponseMapper) =>
      redirectToSuccessPage(product, individualClientIdReferenceNumber, callbacks, responseMapper),
    [product, individualClientIdReferenceNumber, callbacks],
  );
  const errorHandler = useMemo(() => redirectToErrorPage(product, callbacks), [product, callbacks]);

  /**
   * Configurable partner schema, new quick apply partners are added here
   * Defines their form schema, maps their request/response objects
   * And sets their onSuccess/onError functions
   */
  const partners: Record<QuickApplyPartner, PartnerSchema> = useMemo(
    () => ({
      CapitalOne: {
        formSchema: [...coreFields],
        mapRequest: mapCommonRequestBody,
        onSuccess: externalUrlHandler(mapCapitalOneResponse),
        onError: errorHandler,
      },
      Autoloans: {
        formSchema: [...coreFields],
        mapRequest: (form: MapRequestFormData) =>
          mapAutoLoansRequestBody(form, AutoLoansWebsiteDomains.Autoloans),
        onSuccess: successPageHandler(mapAutoLoansResponse),
        onError: errorHandler,
      },
      Carloans: {
        formSchema: [...coreFields],
        mapRequest: (form: MapRequestFormData) =>
          mapAutoLoansRequestBody(form, AutoLoansWebsiteDomains.Carloans),
        onSuccess: successPageHandler(mapAutoLoansResponse),
        onError: errorHandler,
      },
      Carsfast: {
        consent: {
          messageTranslationKey: "carsfast",
          links: [
            { linkTranslationKey: "carsfastPrivacyPolicy", url: "https://carsfast.ca/privacy-policy/" },
            { linkTranslationKey: "carsfastTermsAndConditions", url: "https://carsfast.ca/terms-of-use/" },
          ],
        },
        formSchema: [
          ...coreFields,
          "employmentStatus",
          "monthlyIncome",
          "housingStatus",
          "employmentDuration",
          "housingDuration",
          "companyName",
          "desiredVehicle",
        ],
        mapRequest: mapCommonRequestBody,
        onSuccess: successPageHandler(mapCarsfastResponse),
        onError: errorHandler,
      },
      Fairstone: {
        formSchema: [
          ...coreFields,
          "language",
          "annualIncome",
          "housingStatus",
          "housingMonthlyCost",
          "housingTimeAtAddress",
          "loanAmount",
        ],
        mapRequest: mapFairstoneRequestBody,
        onSuccess: externalUrlHandler(mapFairstoneResponse),
        onError: errorHandler,
      },
      SpringFinancial: {
        formSchema: [...coreFields, "loanAmount", "springEmploymentStatus", "monthlyIncome"],
        consent: {
          messageTranslationKey: "springFinancialV2",
        },
        mapRequest: mapCommonRequestBody,
        onSuccess: externalUrlHandler(mapSpringResponse),
        onError: errorHandler,
      },
    }),
    [errorHandler, externalUrlHandler, successPageHandler],
  );

  // Default behavior if partner can't be found, should never be used
  // Because we don't allow user to open a quick apply screen for a non-quick apply partner
  const defaultPartner: PartnerSchema = useMemo(
    () => ({
      formSchema: [],
      mapRequest: mapCommonRequestBody,
      onSuccess: successPageHandler(() => ({ successDescriptionKey: "autoloans" })),
      onError: errorHandler,
    }),
    [errorHandler, successPageHandler],
  );

  const quickApplyProduct = getQuickApplyProductPartner(product);
  const partnerSchema = partners[quickApplyProduct] ?? defaultPartner;

  return partnerSchema;
};
