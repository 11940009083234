import {
  CREDIT_CARD_CATEGORY_CARDTYPES,
  CreditCardCategoryKey,
  ProductVerticalCreditCard,
} from "@bwll/bw-types";

import { isTrendingProduct } from "./creditCardFilterersUtils";

/**
 * Returns the credit card's categories.
 * @param param0 The credit card.
 * @returns An array of categories the Credit Card is in.
 */
export const getCreditCardCategories = ({ cardTypes }: ProductVerticalCreditCard): CreditCardCategoryKey[] =>
  cardTypes.map((ct) => CREDIT_CARD_CATEGORY_CARDTYPES[ct]).filter(Boolean);

/**
 * Returns a filtered array of credit cards belonging to the provided credit card category.
 * @param category The category to filter by.
 * @param cards The credit cards.
 * @returns An array of credit cards of the provided category.
 */
export const getCreditCardsByCategory = <TCreditCard extends ProductVerticalCreditCard>(
  category: CreditCardCategoryKey,
  cards: TCreditCard[],
): TCreditCard[] =>
  cards.filter(
    (card) =>
      getCreditCardCategories(card).includes(category) ||
      (category === "preQualifiedOffers" && card.isPreSelection) ||
      (category === "trending" && isTrendingProduct(card)),
  );
