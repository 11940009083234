import { useBreakpoints } from "@bwll/bw-hooks";

import {
  MajorValuePropositionBox,
  MajorValuePropositionContent,
  MajorValuePropositionType,
} from "./MajorValueProposition.styles";
import { MajorValuePropositionProps } from "./MajorValueProposition.types";

export const MajorValueProposition = ({ text, type, testID }: MajorValuePropositionProps) => {
  const majorValuePropositionTypeExists = !!type;

  const { isDesktop } = useBreakpoints();

  return (
    <>
      <MajorValuePropositionBox
        testID={testID}
        majorValuePropositionTypeExists={majorValuePropositionTypeExists}
        isDesktop={isDesktop}
      >
        <MajorValuePropositionContent majorValuePropositionTypeExists={majorValuePropositionTypeExists}>
          {text}
        </MajorValuePropositionContent>
        {majorValuePropositionTypeExists && <MajorValuePropositionType>{type}</MajorValuePropositionType>}
      </MajorValuePropositionBox>
    </>
  );
};
