import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  ANALYTICS_VALUES,
  ImpressionedProductVerticalMortgage,
  MARKETPLACE_ANALYTICS_ORIGINS,
  TrackEventFuncAsync,
} from "@bwll/bw-types";
import { mapMortgageToProductEventProps } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
} as const;

export const useMortgageProductListAnalytics = (trackEvent: TrackEventFuncAsync) => {
  const { filterSelected, productViewed, productClicked, buttonClicked } =
    useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(
    () => ({
      trackFilterSelected: (filterValue: string) => {
        filterSelected({
          [ANALYTICS_ATTRIBUTES.FILTER_NAME]: "Mortgage Type",
          [ANALYTICS_ATTRIBUTES.FILTER_CATEGORY]: "product_catalog_filter_chips",
          [ANALYTICS_ATTRIBUTES.FILTER_LOCATION]: "product_catalog",
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "Mortgages",
          [ANALYTICS_ATTRIBUTES.FILTER_VALUE]: filterValue,
        });
      },
      trackProductViewed: (card: ImpressionedProductVerticalMortgage, index: number) => {
        productViewed({
          ...mapMortgageToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        });
      },
      trackProductClicked: (card: ImpressionedProductVerticalMortgage, index: number) => {
        productClicked({
          ...mapMortgageToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        });
      },
      trackButtonClicked: (
        card: ImpressionedProductVerticalMortgage,
        index: number,
        destinationPath?: string,
      ) => {
        const { impression_id, tracking_tag, product_id } = mapMortgageToProductEventProps(card);
        return buttonClicked({
          ...pageEventProps,
          product_id,
          impression_id,
          tracking_tag,
          [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: "LEARN MORE",
          [ANALYTICS_ATTRIBUTES._BUTTON_NAME]: ANALYTICS_VALUES.PRODUCT_CATALOG_VIEW_DETAILS,
          [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: destinationPath ?? "",
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "Mortgages",
          [ANALYTICS_ATTRIBUTES.BUTTON_CONTEXT]: MARKETPLACE_ANALYTICS_ORIGINS.MORTGAGES_DETAILS,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        });
      },
    }),
    [buttonClicked, filterSelected, productClicked, productViewed],
  );
};
