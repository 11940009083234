import { UseQueryResult, useQuery } from "react-query";

import { IApiHookOptions, ImpressionedProductVerticalBankAccount, QUERY_KEYS } from "@bwll/bw-types";
import { generateQueryKey, getImpressionedBankAccount, queryBehavior } from "@bwll/bw-utils";

import { useSessionContext } from "../../../contexts";
import { useCreditScoreRequest } from "../../member";
import { IBankAccountResponseData, useGetBankAccounts } from "./useGetBankAccounts";

type ImpressionedBankAccountResponse = {
  productVerticalBankAccounts: ImpressionedProductVerticalBankAccount[];
};

const useGetImpressionedBankAccounts = <TTransformReturn = ImpressionedBankAccountResponse>(
  bankAccountsResponse: IBankAccountResponseData,
  individualClientIdReferenceNumber: number,
  options: IApiHookOptions<ImpressionedBankAccountResponse, TTransformReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { accessToken, expiresIn } = userData;

  const queryKey = generateQueryKey(QUERY_KEYS.GET_IMPRESSIONED_BANK_ACCOUNTS, accessToken);

  const queryFn = () => ({
    productVerticalBankAccounts: bankAccountsResponse?.productVerticalBankAccounts.map((bankAccount) =>
      getImpressionedBankAccount(bankAccount, individualClientIdReferenceNumber),
    ),
  });

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
};

export const useImpressionedBankAccounts = <TTransformReturn = ImpressionedBankAccountResponse>(
  options: IApiHookOptions<ImpressionedBankAccountResponse, TTransformReturn> = {},
) => {
  const {
    data: creditScoreRequest,
    isLoading: isCreditScoreRequestLoading,
    isError: isCreditScoreRequestError,
    isFetched: isCreditScoreFetched,
  } = useCreditScoreRequest();

  const {
    data: bankAccountsResponse,
    isLoading: isBankAccountsLoading,
    isError: isBankAccountsError,
    isFetched: isBankAccountsFetched,
  } = useGetBankAccounts({
    ...((options.enabled && { enabled: options.enabled }) || {}),
  });

  const result = useGetImpressionedBankAccounts(
    bankAccountsResponse || { productVerticalBankAccounts: [] },
    creditScoreRequest?.individualClientIdReferenceNumber || 0,
    {
      ...options,
      enabled: Boolean(bankAccountsResponse) && Boolean(creditScoreRequest),
    },
  );

  return {
    ...result,
    isLoading: isBankAccountsLoading || isCreditScoreRequestLoading,
    isError: isBankAccountsError || isCreditScoreRequestError,
    isFetched: isCreditScoreFetched || isBankAccountsFetched,
  } as UseQueryResult<TTransformReturn, unknown>;
};
