import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Caption } from "@bwll/bw-components/next";

import { QUICK_APPLY_CONSENT_TEST_IDS as TEST_IDS } from "./QuickApplyConsent.constants";
import * as Styled from "./QuickApplyConsent.styles";
import { QuickApplyConsentProps } from "./QuickApplyConsent.types";

export const QuickApplyConsent = ({ schema, companyName, onConsentLinkClicked }: QuickApplyConsentProps) => {
  const i18next = useTranslation();

  const config = { messageTranslationKey: "default", ...schema };

  return (
    <View>
      <Caption testID={TEST_IDS.MESSAGE}>
        {i18next.t(`productCatalog:quickApply:view:consent:messages:${config.messageTranslationKey}`, {
          companyName,
        })}
      </Caption>
      {config.links &&
        config.links.map((link) => (
          <Styled.StyledLink1
            onPress={() => onConsentLinkClicked?.(link.url)}
            hasUnderline
            key={link.linkTranslationKey}
            testID={`${TEST_IDS.LINK}-${link.linkTranslationKey}`}
          >
            {i18next.t(`productCatalog:quickApply:view:consent:links:${link.linkTranslationKey}`)}
          </Styled.StyledLink1>
        ))}
    </View>
  );
};
