import React, { useMemo } from "react";

import { Spacer, SvgImage } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";

import {
  ContentContainer,
  ErrorDescription1,
  ErrorDescription2,
  ErrorTitle,
  FullScreenContainer,
} from "./ErrorScreen.styles";
import { ErrorScreenProps } from "./ErrorScreen.types";

/**
 * Component for rendering fullscreen error screen on the app.
 *
 * @param {string} title - The title of the error screen.
 * @param {SvgImageKey} [imageKey] - The SVG key registered in SVG_IMAGES.
 * @param {string} [description1] - The description #1 of the error screen.
 * @param {string} [description2] - The description #2 of the error screen.
 * @param {string} [backgroundColor] - The background color of the error screen.
 *
 * @component
 * @example
 * return (
 *   <ErrorScreen
 *     title={"Title"}
 *     imageKey={imageKey}
 *     description1={"Description 1"}
 *     description2={"Description 2"}
 *   />
 * )
 */
export const ErrorScreen = ({
  title,
  imageKey,
  description1,
  description2,
  backgroundColor = COLORS.BRIGHT_GREEK["100"],
}: ErrorScreenProps) => {
  const { isMobile } = useBreakpoints();

  const imageStyle = useMemo(
    () => ({
      width: isMobile ? 200 : 300,
      height: isMobile ? 200 : 300,
    }),
    [isMobile],
  );

  return (
    <FullScreenContainer backgroundColor={backgroundColor}>
      <ContentContainer>
        <ErrorTitle>{title}</ErrorTitle>
        {imageKey && (
          <>
            <Spacer height={spacing.xl} />
            <SvgImage imageKey={imageKey} containerStyle={imageStyle} />
          </>
        )}

        {description1 && (
          <>
            <Spacer height={spacing.xl} />
            <ErrorDescription1>{description1}</ErrorDescription1>
          </>
        )}
        <Spacer height={spacing.xs} />

        {description2 && (
          <>
            <Spacer height={spacing.xxs} />
            <ErrorDescription2>{description2}</ErrorDescription2>
          </>
        )}
      </ContentContainer>
    </FullScreenContainer>
  );
};
