import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  BUTTON_TYPE,
  Body2,
  Button,
  ElementTracker,
  InfoTabs,
  Spacer,
  TabID,
} from "@bwll/bw-components/next";
import { useBreakpoints, useSimilarOffersExperiment } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { LIKELIHOOD_OF_APPROVAL, ProductVerticalCreditCard, TabView } from "@bwll/bw-types";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { CardLayout } from "../../CardLayout";
import { OfferEndDate } from "../../OfferEndDate";
import { PreQualifiedBanner } from "../../PreQualifiedBanner";
import { ProductBanner } from "../../ProductBanner";
import { ProductHighlights } from "../../ProductHighlights";
import { ApprovalChance } from "../ApprovalChance";
import { CreditCardCompareButton } from "../CreditCardCompareButton";
import { CreditCardImage } from "../CreditCardImage";
import { CreditCardRatesTable } from "../CreditCardRatesTable";
import { PRODUCT_CARD_TEST_IDS, STYLED, TABS } from "./CreditCardProductCard.constants";
import * as Styled from "./CreditCardProductCard.styles";
import { ApplyButton } from "./CreditCardProductCard.styles";
import type { CreditCardProductCardProps } from "./CreditCardProductCard.types";

export const CreditCardProductCard = <TCreditCard extends ProductVerticalCreditCard>({
  product,
  index,
  onApplyPress,
  onDetailsPress,
  onAddComparePress,
  onRemoveComparePress,
  onProductViewed,
  onTabSwitched,
  flex = false,
  customCtaCopy,
  hideIcon,
  hideApply = false,
  trackingKey = "",
  showComparison,
}: CreditCardProductCardProps<TCreditCard>) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();
  const { showCompareCta } = useSimilarOffersExperiment();

  const {
    promotionalBadges,
    productName,
    id,
    productImageUrl,
    companyLogo,
    companyName,
    likelihoodOfApprovalType,
    approvalChance,
    isPreSelection,
    toolTipTitle,
    toolTipTextMarkdown,
    annualFee,
    purchaseInterestRate,
    balanceTransferRate,
    isFirstYearWaived,
    cashAdvanceRate,
    sponsoredPlacementProductCatalog,
    firstYearValueAmount,
    signupBonusMarkdown,
    rewardsCategoryMarkdown,
    isQuickApply,
    cashbackBannerText,
    offerEndDate,
  } = product;

  const hasProductHighlights = signupBonusMarkdown || rewardsCategoryMarkdown || firstYearValueAmount;
  const firstTabViewed =
    flex && !isMobile && hasProductHighlights
      ? "Both"
      : hasProductHighlights
      ? "Highlights"
      : "Rates and Fees";

  const ratesTableData = useMemo(
    () => ({
      annualFee,
      purchaseInterestRate,
      balanceTransferRate,
      isFirstYearWaived,
      cashAdvanceRate,
    }),
    [annualFee, purchaseInterestRate, balanceTransferRate, isFirstYearWaived, cashAdvanceRate],
  );

  const isSponsored =
    sponsoredPlacementProductCatalog !== undefined && sponsoredPlacementProductCatalog !== null;

  // TODO remove this when a backend solution is implemented to support string formatted rates/fee table
  // Currently hardcoding for GMS-14240
  const isCapitalOne = companyName === "Capital One";

  const generateTestId = useMemo(() => makeTestIdGenerator(id), [id]);

  const handleProductViewed = () => {
    onProductViewed(product, index);
  };

  const handleApplyPress = useCallback(() => {
    onApplyPress(product, index);
  }, [index, onApplyPress, product]);

  const handleDetailsPress = () => {
    onDetailsPress(product, index);
  };

  const handleTabPress = (tabID: TabID) => {
    const latestTabViewed: TabView = tabID === TABS.HIGHLIGHTS ? "Highlights" : "Rates and Fees";
    onTabSwitched?.(product, index, latestTabViewed, firstTabViewed);
  };

  const applyButtonText = isQuickApply
    ? i18next.t("productCatalog:all:buttons:quickApply")
    : customCtaCopy
    ? customCtaCopy
    : i18next.t("productCatalog:all:buttons:apply");

  /**
   * In case of the horizontally scrollable container (e.g. horizontal carousel)
   * the `flex` should be `false` since we want to restrict a ProductCard's width
   * by wrapping it into the CardLayout component. Otherwise it's flexible and depends
   * on its parent container (e.g. fills all available space by X-axis).
   */
  const Wrapper = flex ? Styled.FlexLayout : CardLayout;

  const hasPromotionalBadges = promotionalBadges.filter(Boolean).length > 0;
  const hasCashBackBannerText = !!cashbackBannerText;

  const productBannerText = hasCashBackBannerText
    ? cashbackBannerText
    : hasPromotionalBadges
    ? promotionalBadges[0]
    : null;

  const showLikelihoodOfApproval =
    approvalChance !== LIKELIHOOD_OF_APPROVAL.NONE && approvalChance !== LIKELIHOOD_OF_APPROVAL.NOTELIGIBLE;

  // TODO: Replace with CreditCardApplyButton
  const applyButton = useMemo(() => {
    return (
      !hideApply && (
        <Styled.ButtonContainer>
          <ApplyButton
            title={applyButtonText}
            iconName={hideIcon ? undefined : !isQuickApply ? "new_tab" : undefined}
            onPress={handleApplyPress}
            testID={generateTestId(PRODUCT_CARD_TEST_IDS.APPLY_BUTTON)}
            type={BUTTON_TYPE.PRIMARY}
          />
        </Styled.ButtonContainer>
      )
    );
  }, [applyButtonText, generateTestId, handleApplyPress, hideIcon, isQuickApply, hideApply]);

  return (
    <Wrapper testID={generateTestId(PRODUCT_CARD_TEST_IDS.CARD)}>
      {(hasPromotionalBadges || hasCashBackBannerText) && (
        <ProductBanner testID={generateTestId(PRODUCT_CARD_TEST_IDS.PRODUCT_BANNER)} rounded>
          {productBannerText}
        </ProductBanner>
      )}
      <Styled.InnerContent>
        <Styled.Container
          isMobile={isMobile}
          mt={hasPromotionalBadges || hasCashBackBannerText ? 0 : STYLED.PRODUCT_NAME_MARGIN_TOP}
        >
          <Styled.TitleContainer>
            <Styled.TitleText
              isMobile={isMobile}
              variant="semi-bold"
              numberOfLines={2}
              color={COLORS.NEUTRAL.WARM["800"]}
            >
              {productName}
            </Styled.TitleText>
          </Styled.TitleContainer>
          <Styled.ImageContainer>
            <CreditCardImage
              height={STYLED.PRODUCT_CARD_IMAGE_HEIGHT}
              width={STYLED.PRODUCT_CARD_IMAGE_WIDTH}
              uri={productImageUrl || companyLogo || ""}
            />
          </Styled.ImageContainer>
          <Styled.BannerContainer>
            {isPreSelection ? (
              <Styled.PreQualifiedBannerContainer isDesktopFlexSmallBanner={flex && !isMobile}>
                <PreQualifiedBanner
                  testID={generateTestId(PRODUCT_CARD_TEST_IDS.PRE_QUALIIED_BANNER)}
                  toolTipTitle={toolTipTitle}
                  toolTipTextMarkdown={toolTipTextMarkdown}
                />
              </Styled.PreQualifiedBannerContainer>
            ) : (
              showLikelihoodOfApproval && (
                <Styled.ApprovalChanceContainer>
                  <ApprovalChance
                    likelihoodOfApprovalType={likelihoodOfApprovalType}
                    approvalChance={approvalChance}
                    testID={generateTestId(PRODUCT_CARD_TEST_IDS.APPROVAL_CHANCE)}
                  />
                </Styled.ApprovalChanceContainer>
              )
            )}
          </Styled.BannerContainer>
          {flex && !isMobile ? (
            <Styled.Row>
              {hasProductHighlights && (
                <>
                  <Styled.Cell>
                    <Styled.CellTitle>
                      <Body2 color={COLORS.NEUTRAL.WARM["800"]}>
                        {i18next.t("productCatalog:creditCards:highlights")}
                      </Body2>
                    </Styled.CellTitle>
                    <Styled.CellContent isMobile={isMobile}>
                      <ProductHighlights
                        rewardsCategoryMarkdown={rewardsCategoryMarkdown}
                        signupBonusMarkdown={signupBonusMarkdown}
                        firstYearValueAmount={firstYearValueAmount}
                      />
                    </Styled.CellContent>
                  </Styled.Cell>
                  <Spacer width={spacing.xxs} />
                </>
              )}
              <Styled.Cell>
                <Styled.CellTitle>
                  <Body2 color={COLORS.NEUTRAL.WARM["800"]}>
                    {i18next.t("productCatalog:creditCards:ratesAndFees")}
                  </Body2>
                </Styled.CellTitle>
                <CreditCardRatesTable data={ratesTableData} isCapitalOne={isCapitalOne} />
              </Styled.Cell>
            </Styled.Row>
          ) : (
            <InfoTabs
              initialTabID={hasProductHighlights ? TABS.HIGHLIGHTS : TABS.RATES}
              onPress={(tabID) => hasProductHighlights && handleTabPress(tabID)}
            >
              <InfoTabs.Header>
                {hasProductHighlights && (
                  <InfoTabs.Tab
                    tabID={TABS.HIGHLIGHTS}
                    testID={generateTestId(PRODUCT_CARD_TEST_IDS.HIGHLIGHTS_TAB)}
                  >
                    {i18next.t("productCatalog:creditCards:highlights")}
                  </InfoTabs.Tab>
                )}
                <InfoTabs.Tab
                  tabID={TABS.RATES}
                  testID={generateTestId(PRODUCT_CARD_TEST_IDS.RATES_AND_FEES_TAB)}
                >
                  {i18next.t("productCatalog:creditCards:ratesAndFees")}
                </InfoTabs.Tab>
              </InfoTabs.Header>
              {hasProductHighlights && (
                <InfoTabs.Content tabID={TABS.HIGHLIGHTS}>
                  <Styled.CellContent isMobile={isMobile}>
                    <ProductHighlights
                      rewardsCategoryMarkdown={rewardsCategoryMarkdown}
                      signupBonusMarkdown={signupBonusMarkdown}
                      firstYearValueAmount={firstYearValueAmount}
                    />
                  </Styled.CellContent>
                </InfoTabs.Content>
              )}
              <InfoTabs.Content tabID={TABS.RATES}>
                <CreditCardRatesTable data={ratesTableData} isCapitalOne={isCapitalOne} />
              </InfoTabs.Content>
            </InfoTabs>
          )}
          <Styled.Footer>
            <Styled.OfferContainer>
              {offerEndDate && <OfferEndDate date={offerEndDate} />}
            </Styled.OfferContainer>
            {showComparison && !hideApply && (
              <Styled.ButtonGroup showComparison={showComparison}>{applyButton}</Styled.ButtonGroup>
            )}
            <Styled.ButtonGroup>
              <Styled.ButtonContainer>
                <Button
                  title={i18next.t("productCatalog:all:buttons:details")}
                  onPress={handleDetailsPress}
                  type={hideApply && showCompareCta ? BUTTON_TYPE.TERTIARY : BUTTON_TYPE.SECONDARY}
                  testID={generateTestId(PRODUCT_CARD_TEST_IDS.DETAILS_BUTTON)}
                />
              </Styled.ButtonContainer>
              {(showComparison || (hideApply && showCompareCta)) && (
                <>
                  <Spacer width={spacing.xxs} />
                  <Styled.ButtonContainer>
                    <CreditCardCompareButton
                      creditCard={product}
                      onAddComparePress={onAddComparePress}
                      onRemoveComparePress={onRemoveComparePress}
                      testID={generateTestId(PRODUCT_CARD_TEST_IDS.COMPARE_BUTTON)}
                    />
                  </Styled.ButtonContainer>
                </>
              )}
              {!showComparison && applyButton}
            </Styled.ButtonGroup>
            <Styled.DisclaimerContainer>
              {isSponsored && (
                <Styled.DisclaimerText
                  color={COLORS.NEUTRAL.COOL["600"]}
                  testID={generateTestId(PRODUCT_CARD_TEST_IDS.DISCLAIMER_TEXT)}
                >
                  {i18next.t("productCatalog:all:sponsored")}
                </Styled.DisclaimerText>
              )}
            </Styled.DisclaimerContainer>
          </Styled.Footer>
        </Styled.Container>
      </Styled.InnerContent>
      <ElementTracker
        trackingHandler={handleProductViewed}
        shouldStartTracking={true}
        trackingKey={product.id + trackingKey}
      />
    </Wrapper>
  );
};
