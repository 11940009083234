export const PRODUCT_CARD_TEST_IDS = {
  CARD: "card",
  PRODUCT_BANNER: "product-banner",
  APPLY_BUTTON: "apply-button",
  DETAILS_BUTTON: "details-button",
  APPROVAL_CHANCE: "approval-chance",
  PRE_QUALIIED_BANNER: "pre-qualified-banner",
  DISCLAIMER_TEXT: "disclaimer-text",
  HIGHLIGHTS_TAB: "highlights-tab",
  RATES_AND_FEES_TAB: "rates-and-fees-tab",
  COMPARE_BUTTON: "compare-button",
} as const;

export const TABS = {
  HIGHLIGHTS: "highlights",
  RATES: "rates",
} as const;

export const STYLED = {
  PRODUCT_NAME_MARGIN_TOP: 24,
  PRODUCT_CARD_IMAGE_WIDTH: 127,
  PRODUCT_CARD_IMAGE_HEIGHT: 80,
} as const;
