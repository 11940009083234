import { RewardIconMap } from "./Rewards.types";

export const rewardsIcons: RewardIconMap = {
  Cashback: {
    icon: "cashback_resting",
  },
  Points: {
    icon: "wallet_resting",
  },
  Travel: {
    icon: "airplane_resting",
  },
};
