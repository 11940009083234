import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceCreditCardComparison".
 */
export const useCreditCardComparisonExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_COMPARISON]?.treatment ??
      "off";

    return { showComparison: treatment === "on" };
  }, [experimentsMap.userConfig]);
};
