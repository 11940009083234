/** A regular expression pattern that validates a North American phone number, with or without dashes. */
export const phoneNumberValidationPattern = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

/** A regular expression pattern that validates an email address. */
export const emailValidationPattern =
  // Disabling the check for this regex, retrieved from OWASP's list of regexes
  // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
  // eslint-disable-next-line security/detect-unsafe-regex
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

/** A regular expression pattern that validates a Canadian postal code. */
export const postalCodeValidationPattern =
  /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[a-zA-Z]{1} *\d{1}[a-zA-Z]{1}\d{1}$/;

/** A regular expression to validate street addresses & unit/apartment numbers. */
export const streetAddressValidationPattern = /^[a-zA-Z0-9áàâçéèêëîïôùûÁÀÂÄÃÇÉÈÊËÎÓÒÔÚÙÛ.\-'#\s]*$/;

/** Regular expression pattern for validating city names. */
export const cityValidationPattern = /^[^~^&={}[\]<>/]+$/;

/**
 * Checks if a phone number is valid based on {@link phoneNumberValidationPattern}.
 *
 * @param phoneNumber - The phone number to validate.
 * @returns A boolean indicating whether the phone number is valid or not.
 */
export const isPhoneNumberValid = (phoneNumber: string): boolean =>
  phoneNumberValidationPattern.test(phoneNumber);

/**
 * Checks if an email address is valid based on {@link emailValidationPattern}.
 *
 * @param email - The email address to validate.
 * @returns A boolean indicating whether the email address is valid or not.
 */
export const isEmailValid = (email: string): boolean => emailValidationPattern.test(email);

/**
 * Checks if a Canadian postal code is valid based on {@link postalCodeValidationPattern}.
 *
 * @param postalCode - The postal code to validate.
 * @returns A boolean indicating whether the postal code is valid or not.
 */
export const isPostalCodeValid = (postalCode: string): boolean =>
  postalCodeValidationPattern.test(postalCode);

/**
 * Checks if a street address is valid based on {@link streetAddressValidationPattern}.
 *
 * @param streetAddress - The street address to validate.
 * @returns A boolean indicating whether the street address is valid or not.
 */
export const isStreetAddressValid = (streetAddress: string): boolean =>
  streetAddressValidationPattern.test(streetAddress);

/**
 * Checks if a city name is valid based on {@link cityValidationPattern}.
 *
 * @param city - The city name to validate.
 * @returns A boolean indicating whether the city name is valid or not.
 */
export const isCityValid = (city: string): boolean => cityValidationPattern.test(city);
