import React, { useCallback, useState } from "react";
import { NativeSyntheticEvent, TextInputFocusEventData } from "react-native";

import { COLORS } from "@bwll/bw-styles";

import {
  StyledCaptionComponent,
  StyledLabelComponent,
  StyledTextInputComponent,
  StyledTextInputContainer,
} from "./TextInput.styles";
import { TextInputProps } from "./TextInput.types";

/**
 * Component for showing the TextInput.
 *
 * @component
 * @example
 * return (
 *   <TextInput
 *      label="Label"
 *      placeholder="Input field text"
 *      caption="Assistive text"
 *   />
 * )
 */
export const TextInput = ({
  testID,
  label,
  caption,
  placeholder,
  style,
  onBlur,
  onFocus,
  onChangeText,
  currentState,
  multiline,
  captionColor,
  value,
  keyboardType,
  maxLength,
  returnKeyType,
  id,
}: TextInputProps) => {
  const [focused, setFocused] = useState(false);

  const focus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onFocus?.(e);
      setFocused(true);
    },
    [onFocus],
  );

  const blur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur?.(e);
      setFocused(false);
    },
    [onBlur],
  );

  return (
    <StyledTextInputContainer>
      {!!label && <StyledLabelComponent errored={!!currentState?.errored}>{label}</StyledLabelComponent>}
      <StyledTextInputComponent
        style={style}
        id={id}
        testID={testID}
        errored={!!currentState?.errored}
        disabled={!!currentState?.disabled}
        editable={!currentState?.disabled}
        focused={focused}
        placeholder={placeholder}
        placeholderTextColor={COLORS.NEUTRAL.COOL["300"]}
        onBlur={blur}
        onFocus={focus}
        onChangeText={onChangeText}
        multiline={multiline}
        value={value}
        keyboardType={keyboardType}
        maxLength={maxLength}
        returnKeyType={returnKeyType}
      />
      {!!caption && (
        <StyledCaptionComponent errored={!!currentState?.errored} color={captionColor}>
          {caption}
        </StyledCaptionComponent>
      )}
    </StyledTextInputContainer>
  );
};
