import { COLORS } from "@bwll/bw-styles";
import { LIKELIHOOD_OF_APPROVAL, LikelihoodOfApprovalType } from "@bwll/bw-types";

export const getApprovalChanceColor = (
  likelihoodOfApproval: LikelihoodOfApprovalType | LIKELIHOOD_OF_APPROVAL,
  v2?: boolean,
): string => {
  const approvalChanceColorMap: Record<string, string> = {
    [LikelihoodOfApprovalType.FAIR]: COLORS.YELLOW["900"],
    [LIKELIHOOD_OF_APPROVAL.FAIR]: COLORS.YELLOW["900"],
    [LikelihoodOfApprovalType.GOOD]: COLORS.TURQUOISE["900"],
    [LIKELIHOOD_OF_APPROVAL.GOOD]: COLORS.TURQUOISE["900"],
    [LikelihoodOfApprovalType.EXCELLENT]: COLORS.TURQUOISE["700"],
    [LIKELIHOOD_OF_APPROVAL.EXCELLENT]: COLORS.TURQUOISE["700"],
  };

  const approvalChanceColorMapV2: Record<string, string> = {
    [LikelihoodOfApprovalType.FAIR]: COLORS.YELLOW["600"],
    [LIKELIHOOD_OF_APPROVAL.FAIR]: COLORS.YELLOW["600"],
    [LikelihoodOfApprovalType.LOW]: COLORS.YELLOW["900"],
    [LIKELIHOOD_OF_APPROVAL.LOW]: COLORS.YELLOW["900"],
    [LikelihoodOfApprovalType.NONE]: COLORS.NEUTRAL.WARM["700"],
    [LIKELIHOOD_OF_APPROVAL.NONE]: COLORS.NEUTRAL.WARM["700"],
    [LikelihoodOfApprovalType.NOTELIGIBLE]: COLORS.NEUTRAL.WARM["700"],
    [LIKELIHOOD_OF_APPROVAL.NOTELIGIBLE]: COLORS.NEUTRAL.WARM["700"],
    [LikelihoodOfApprovalType.GOOD]: COLORS.TURQUOISE["900"],
    [LIKELIHOOD_OF_APPROVAL.GOOD]: COLORS.TURQUOISE["900"],
    [LikelihoodOfApprovalType.EXCELLENT]: COLORS.TURQUOISE["700"],
    [LIKELIHOOD_OF_APPROVAL.EXCELLENT]: COLORS.TURQUOISE["700"],
  };

  const colorMap = v2 ? approvalChanceColorMapV2 : approvalChanceColorMap;

  return colorMap[likelihoodOfApproval as LikelihoodOfApprovalType] ?? COLORS.NEUTRAL.WARM["800"];
};
