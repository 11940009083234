import { useCallback, useMemo } from "react";

import { ElementTracker } from "@bwll/bw-components/next";
import { useAnalyticsContext, useEnvironmentContext, useExperiments } from "@bwll/bw-hooks";
import {
  ANALYTICS_ATTRIBUTES,
  HISTORICAL_REPORTING_EVENT_VALUES,
  STANDARD_TREATMENTS,
  WEB_EXPERIMENT_IDS,
} from "@bwll/bw-types";

import { HistoricalReportingConfirmationPage as HistoricalReportingConfirmationPageUI } from "./HistoricalReportingConfirmationPage.component";

export const HistoricalReportingConfirmationPageContainer = () => {
  const { envConfigs } = useEnvironmentContext();

  const { historicalReportingEvents } = useAnalyticsContext();

  const enableV1Flow = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.RA_HISTORICAL_REPORTING_V1,
  });

  const commonAnalyticsAttributes = useMemo(() => {
    return {
      // TODO: Add onboarding state logic
      [ANALYTICS_ATTRIBUTES.ONBOARDING_STATE]: "",
      [ANALYTICS_ATTRIBUTES.TREATMENT]: STANDARD_TREATMENTS.ON,
      [ANALYTICS_ATTRIBUTES.VERSION]: HISTORICAL_REPORTING_EVENT_VALUES.V1,
    };
  }, []);

  const trackViewEvent = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingScreenViewed({
      [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
        HISTORICAL_REPORTING_EVENT_VALUES.CHECKOUT_CONFIRMATION_STEP_VIEWED,
      ...commonAnalyticsAttributes,
    });
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const onButtonPressHandler = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingButtonClicked(
      {
        [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
          HISTORICAL_REPORTING_EVENT_VALUES.GO_TO_DASHBOARD_BUTTON_CLICKED,
        ...commonAnalyticsAttributes,
      },
      onCtaPress,
    );
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const onCtaPress = () => {
    window.location.href = enableV1Flow
      ? `${window.location.origin}${envConfigs.RENT_REPORTING_REDIRECT_URI_PATH}/historical-reporting/dashboard`
      : envConfigs.WEB_APP_URL;
  };

  return (
    <>
      <ElementTracker trackingHandler={trackViewEvent} shouldStartTracking={true} />
      <HistoricalReportingConfirmationPageUI onCtaPress={onButtonPressHandler} enableV1Flow={enableV1Flow} />
    </>
  );
};
