import React from "react";

import { BANNER_TEST_ID } from "./ProductBanner.constants";
import * as Styled from "./ProductBanner.styles";
import type { ProductBannerProps } from "./ProductBanner.types";

export const ProductBanner = ({
  children,
  rounded,
  tall,
  backgroundColor,
  testID = BANNER_TEST_ID,
}: ProductBannerProps) => {
  return (
    <Styled.Container backgroundColor={backgroundColor} rounded={rounded} tall={tall}>
      {typeof children === "string" ? <Styled.Text testID={testID}>{children}</Styled.Text> : children}
    </Styled.Container>
  );
};
