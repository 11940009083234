import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const CardContainer = styled.View<{
  padding?: number;
  borderColor?: string;
  backgroundColor?: string;
}>`
  padding: ${({ padding }) => (padding !== undefined ? styledValue(padding) : styledValue(spacing.xs))};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.WHITE};
  border: 1px solid ${({ borderColor }) => borderColor ?? COLORS.WHITE};
  border-radius: ${styledValue(spacing.xxs)};
`;

export const CardTitle = styled.View<{
  marginBottom?: number;
}>`
  font-weight: bold;
  color: ${COLORS.BLACK};
  margin-bottom: ${styledValue(spacing.xxs)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom !== undefined ? styledValue(marginBottom) : styledValue(spacing.xs)};
`;
