import { View } from "react-native";

import { Body1 } from "../../../atoms/Typography";
import * as Styled from "./ReadonlyInput.styles";
import { ReadonlyInputProps } from "./ReadonlyInput.types";

/**
 * Renders a readonly input field. This component can be used for forms where a static value is shown within a dynamic form.
 */
export const ReadonlyInput = ({ label, value, testID }: ReadonlyInputProps) => {
  const values = Array.isArray(value) ? value : [value];

  return (
    <Styled.Container testID={testID}>
      {label && <Styled.InputLabel>{label}</Styled.InputLabel>}
      <View>
        {values.map((val, i) => (
          <Body1 key={i}>{val}</Body1>
        ))}
      </View>
    </Styled.Container>
  );
};
