import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, CheckboxOption, Icon, Pill, Subheading2 } from "@bwll/bw-components/next";
import {
  CreditCardCategoryContext,
  useBreakpoints,
  useCreditCardFilters,
  useImpressionedCreditCards,
  useMarketplaceTrendingExperiment,
} from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";
import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";
import { CreditCardFilterer, createCompanyNameFilterer, creditCardFilterers } from "@bwll/bw-utils";

import { FilterCheckboxGroup } from "../../FilterCheckboxGroup";
import { FilterInputContainer } from "../../FilterInputContainer";
import { CREDIT_CARD_FILTERS_TEST_IDS as TEST_IDS } from "./CreditCardFilters.constants";
import * as Styled from "./CreditCardFilters.styles";
import { CreditCardFiltersProps } from "./CreditCardFilters.types";
import { getUniqueCompanyNames } from "./CreditCardFilters.utils";

const filtersLocaleKey = "marketplace:productList:sortAndFilters:filters";

export const CreditCardFilters = ({ resetKey }: CreditCardFiltersProps) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();
  const isSmallScreen = isMobile || isTablet;

  const {
    filters: { filterers },
    toggleFilter,
    resetFilters,
  } = useCreditCardFilters();
  const [providerFilterOptions, setProviderFilterOptions] = useState<CheckboxOption[]>([]);

  const { data } = useImpressionedCreditCards({
    transform: ({ productVerticalCreditCards }) => {
      return {
        providerCheckboxOptions: getUniqueCompanyNames(productVerticalCreditCards).map((companyName) => ({
          label: companyName,
          value: createCompanyNameFilterer(companyName),
          checked: filterers.has(companyName),
        })),
      };
    },
    onSuccess: ({ providerCheckboxOptions }) => setProviderFilterOptions(providerCheckboxOptions),
  });

  // Resetting
  const reset = useCallback(() => {
    const providers = (data?.providerCheckboxOptions ?? []).map((option) => ({
      ...option,
      checked: false,
    }));
    setProviderFilterOptions(providers);
    resetFilters();
  }, [data?.providerCheckboxOptions, resetFilters]);

  const firstRender = useRef(true);

  useEffect(() => {
    // Don't reset the filters on the first render unnecessarily
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetKey]);

  // Trending
  const { showTrending } = useMarketplaceTrendingExperiment();
  const categoryKey = useContext(CreditCardCategoryContext);

  return (
    <Styled.FiltersContainer>
      <Styled.HeadingContainer>
        <Styled.FiltersHeading>
          <Icon icon="filter_inactive" size={fontSize.s} color={COLORS.NEUTRAL.WARM[800]} />
          <Subheading2>{t(`${filtersLocaleKey}:title`)}</Subheading2>
        </Styled.FiltersHeading>
        {!isSmallScreen && (
          <Styled.ResetButton
            type={BUTTON_TYPE.TERTIARY}
            title={t("productCatalog:creditCards:sortAndFilters:buttons:reset")}
            onPress={reset}
            testID={TEST_IDS.RESET_BUTTON}
          />
        )}
      </Styled.HeadingContainer>
      <FilterInputContainer heading={t(`${filtersLocaleKey}:creditCards:offers:title`)}>
        <Styled.PillContainer>
          <Pill
            isActive={filterers.has(creditCardFilterers.TopOffers.key)}
            label={t(`${filtersLocaleKey}:creditCards:offers:topOffers`)}
            onPress={() => toggleFilter(creditCardFilterers.TopOffers)}
            testID={TEST_IDS.TOP_OFFERS_PILL}
          />
          {showTrending && categoryKey !== CREDIT_CARD_CATEGORY_KEYS.TRENDING && (
            <Pill
              isActive={filterers.has(creditCardFilterers.TrendingOffers.key)}
              label={t(`${filtersLocaleKey}:creditCards:offers:trending`)}
              onPress={() => toggleFilter(creditCardFilterers.TrendingOffers)}
              testID={TEST_IDS.TRENDING_PILL}
            />
          )}
        </Styled.PillContainer>
      </FilterInputContainer>
      <FilterInputContainer heading={t(`${filtersLocaleKey}:creditCards:benefits:title`)}>
        <Styled.PillContainer>
          <Pill
            isActive={filterers.has(creditCardFilterers.CashBack.key)}
            label={t(`${filtersLocaleKey}:creditCards:benefits:cashBack`)}
            onPress={() => toggleFilter(creditCardFilterers.CashBack)}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.Travel.key)}
            label={t(`${filtersLocaleKey}:creditCards:benefits:travel`)}
            onPress={() => toggleFilter(creditCardFilterers.Travel)}
            testID={TEST_IDS.TRAVEL_PILL}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.Rewards.key)}
            label={t(`${filtersLocaleKey}:creditCards:benefits:rewards`)}
            onPress={() => toggleFilter(creditCardFilterers.Rewards)}
          />
        </Styled.PillContainer>
      </FilterInputContainer>
      <FilterInputContainer heading={t(`${filtersLocaleKey}:creditCards:features:title`)}>
        <Styled.PillContainer>
          <Pill
            isActive={filterers.has(creditCardFilterers.NoFee.key)}
            label={t(`${filtersLocaleKey}:creditCards:features:noFee`)}
            onPress={() => toggleFilter(creditCardFilterers.NoFee)}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.BalanceTransfer.key)}
            label={t(`${filtersLocaleKey}:creditCards:features:balanceTransfer`)}
            onPress={() => toggleFilter(creditCardFilterers.BalanceTransfer)}
            testID={TEST_IDS.BALANCE_TRANSFER_PILL}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.CreditBuilder.key)}
            label={t(`${filtersLocaleKey}:creditCards:features:creditBuilder`)}
            onPress={() => toggleFilter(creditCardFilterers.CreditBuilder)}
          />
        </Styled.PillContainer>
      </FilterInputContainer>
      <FilterInputContainer heading={t(`${filtersLocaleKey}:creditCards:network:title`)}>
        <Styled.PillContainer>
          <Pill
            isActive={filterers.has(creditCardFilterers.Visa.key)}
            label={t(`${filtersLocaleKey}:creditCards:network:visa`)}
            onPress={() => toggleFilter(creditCardFilterers.Visa)}
            testID={TEST_IDS.VISA_PILL}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.MasterCard.key)}
            label={t(`${filtersLocaleKey}:creditCards:network:mastercard`)}
            onPress={() => toggleFilter(creditCardFilterers.MasterCard)}
          />
          <Pill
            isActive={filterers.has(creditCardFilterers.AmericanExpress.key)}
            label={t(`${filtersLocaleKey}:creditCards:network:americanExpress`)}
            onPress={() => toggleFilter(creditCardFilterers.AmericanExpress)}
          />
        </Styled.PillContainer>
      </FilterInputContainer>
      {providerFilterOptions.length > 0 && (
        <FilterInputContainer heading="Providers">
          <FilterCheckboxGroup
            filterOptions={providerFilterOptions}
            onFilterChange={({ toggled, all }) => {
              setProviderFilterOptions(all);
              toggleFilter(toggled.value as CreditCardFilterer);
            }}
          />
        </FilterInputContainer>
      )}
    </Styled.FiltersContainer>
  );
};
