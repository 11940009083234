import { IconNames } from "@bwll/bw-components/next";
import { LikelihoodOfApprovalType } from "@bwll/bw-types";
import { getApprovalChanceColor, getApprovalChanceTooltipText } from "@bwll/bw-utils";

import { ApprovalChanceProps } from "./ApprovalChance.types";

export const getApprovalChanceProps = ({ likelihoodOfApprovalType, approvalChance }: ApprovalChanceProps) => {
  const approvalChanceTooltipText = getApprovalChanceTooltipText(likelihoodOfApprovalType);
  const iconName: IconNames =
    likelihoodOfApprovalType === LikelihoodOfApprovalType.NONE ||
    likelihoodOfApprovalType === LikelihoodOfApprovalType.NOTELIGIBLE
      ? "na_circle"
      : "check_circle_filled";

  const iconColor = getApprovalChanceColor(likelihoodOfApprovalType, true);

  return {
    text: approvalChance ?? "",
    tooltipText: approvalChanceTooltipText,
    iconName,
    iconColor,
  };
};
