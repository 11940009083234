import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { Icon, Label, Spacer } from "@bwll/bw-components/next";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { DATE_FORMATS } from "@bwll/bw-types";
import { formatDate, isDateValid, isOfferExpired } from "@bwll/bw-utils";

import { Container } from "./OfferEndDate.styles";
import { OfferEndDateProps } from "./OfferEndDate.types";

/**
 * Component for showing the OfferEndDate.
 *
 * @component
 * @example
 * return (
 *   <OfferEndDate
 *    date="Feb 22"
 *   />
 * )
 *
 * or
 *
 * return (
 *   <OfferEndDate
 *    date="actual date string"
 *   />
 * )
 */

export const OfferEndDate = ({ date, justifyContent, testID }: OfferEndDateProps) => {
  const i18next = useTranslation();
  if (!date || !isDateValid(date) || isOfferExpired(date)) return null;

  const offerEndDate = date instanceof Date ? date : parseISO(date);

  return (
    <Container justifyContent={justifyContent} testID={testID}>
      <Icon icon="alarm" size={fontSize.xs} color={COLORS.RED["700"]} />
      <Spacer width={spacing.xxxs} />
      <Label color={COLORS.RED["700"]}>
        {i18next.t("productCatalog:all:offerEndDate", {
          date: formatDate({
            dateString: offerEndDate.toISOString(),
            dateFormat: DATE_FORMATS.MMM_d,
          }),
        })}
      </Label>
    </Container>
  );
};
