import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InteractionManager } from "react-native";

import { BUTTON_TYPE, Button, SplashScreen } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";

import { BORROWELL_GUARANTEE_SPLASH_SCREEN_TEST_IDS as TEST_IDS } from "./BorrowellGuaranteeSplashScreen.constants";
import * as Styled from "./BorrowellGuaranteeSplashScreen.styles";
import borrowellGuaranteeLogo from "./borrowellGuaranteeLogo.png";

/**
 * Displays a Borrowell Guarantee splash screen. The splash screen is visible for 3 seconds or until the button is pressed.
 *
 * @component
 * @example
 * return (
 *   <BorrowellGuaranteeSplashScreen />
 * )
 */
export const BorrowellGuaranteeSplashScreen = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const { isMobile } = useBreakpoints();

  InteractionManager.runAfterInteractions(() => {
    setTimeout(() => setIsVisible(false), 3000);
  });

  return isVisible ? (
    <SplashScreen
      image={borrowellGuaranteeLogo}
      backgroundColor={COLORS.PRIMARY["400"]}
      textContent={
        <Styled.TextContainer isMobile={isMobile}>
          <Styled.Heading>{t("marketplace:borrowellGuarantee:splashScreen:title")}</Styled.Heading>
          <Styled.Subheading>{t("marketplace:borrowellGuarantee:splashScreen:subtitle")}</Styled.Subheading>
        </Styled.TextContainer>
      }
      buttonContent={
        <Button
          onPress={() => setIsVisible(false)}
          title={t("marketplace:borrowellGuarantee:splashScreen:continueToDetails")}
          type={BUTTON_TYPE.SECONDARY}
          state="dark"
          testID={TEST_IDS.BUTTON_CONTINUE}
        />
      }
    />
  ) : null;
};
