import { useTranslation } from "react-i18next";

import { Body2, Icon, Spacer } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";

import {
  CREDIT_CARD_CATEGORY_ICONS,
  creditCardsCategoryHeaderTestIds,
} from "./CreditCardsCategoryHeader.constants";
import {
  CreditCardsCategoryHeaderContainer,
  IconContainer,
  TitleContainer,
  TitleDefault,
  TitleDefaultMobile,
  TitleLarge,
} from "./CreditCardsCategoryHeader.styles";
import { CreditCardsCategoryHeaderProps } from "./CreditCardsCategoryHeader.types";

/**
 * Component for rendering the Credit Cards Category Header.
 * If the provided `categoryKey` is not valid, it will return null.
 *
 * @param {CreditCardCategoryKey} categoryKey - Key for the credit card category.
 * @param {boolean} [isScreenHeader] - Flag to show the header in larger size used in screen header.
 */
export const CreditCardsCategoryHeader = ({
  categoryKey,
  isScreenHeader,
}: CreditCardsCategoryHeaderProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  if (!Object.values(CREDIT_CARD_CATEGORY_KEYS).includes(categoryKey)) {
    return null;
  }
  const TitleComponent = isScreenHeader ? TitleLarge : isMobile ? TitleDefaultMobile : TitleDefault;

  return (
    <CreditCardsCategoryHeaderContainer>
      <TitleContainer>
        <IconContainer isMobile={isMobile} testID={creditCardsCategoryHeaderTestIds.titleIcon}>
          <Icon
            icon={CREDIT_CARD_CATEGORY_ICONS[categoryKey]}
            size={isScreenHeader ? 40 : isMobile ? 20 : 32}
            color={COLORS.NEUTRAL.WARM["800"]}
          />
        </IconContainer>
        <TitleComponent testID={creditCardsCategoryHeaderTestIds.title}>
          {i18next.t(`marketplace:creditCards:categories:${categoryKey}:title`)}
        </TitleComponent>
      </TitleContainer>
      {!isMobile && <Spacer height={spacing.xxs} />}
      <Body2 testID={creditCardsCategoryHeaderTestIds.description}>
        {i18next.t(`marketplace:creditCards:categories:${categoryKey}:description`)}
      </Body2>
    </CreditCardsCategoryHeaderContainer>
  );
};
