import {
  ANALYTICS_ATTRIBUTES,
  ImpressionedProductVerticalBankAccount,
  ImpressionedProductVerticalCreditCard,
  ImpressionedProductVerticalMortgage,
  MarketplaceBankAccountEventProps,
  MarketplaceProductEventProps,
} from "@bwll/bw-types";

import { getPromoBannerText } from "../bankAccounts";
import { isOfferExpired } from "../productUtils";

type ImpressionedProductVertical =
  | ImpressionedProductVerticalBankAccount
  | ImpressionedProductVerticalCreditCard;

const mapToProductEventProps = ({
  id,
  productImpressionId,
  trackingTag,
  preferredOrder,
  likelihoodOfApproval,
  likelihoodOfApprovalType,
  approvalChance,
  sponsoredPlacement,
  cashbackBannerText,
  rank,
  offerEndDate,
}: ImpressionedProductVertical): MarketplaceProductEventProps => {
  return {
    [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: id,
    [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: productImpressionId,
    [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: trackingTag ?? "",
    [ANALYTICS_ATTRIBUTES.PREFERRED_ORDER]: preferredOrder ?? undefined,
    [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL]: likelihoodOfApproval ?? undefined,
    [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_BUCKET]: likelihoodOfApprovalType,
    [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_LABEL]: approvalChance ?? undefined,
    [ANALYTICS_ATTRIBUTES.LIMITED_TIME_OFFER]: !!offerEndDate && !isOfferExpired(offerEndDate),
    [ANALYTICS_ATTRIBUTES.LIMITED_TIME_OFFER_END_DATE]: !isOfferExpired(offerEndDate) ? offerEndDate : null,
    [ANALYTICS_ATTRIBUTES.SPONSORED]: Boolean(sponsoredPlacement),
    [ANALYTICS_ATTRIBUTES.SPONSORED_PLACEMENT]: sponsoredPlacement ?? -1,
    [ANALYTICS_ATTRIBUTES.CASHBACK]: Boolean(cashbackBannerText),
    [ANALYTICS_ATTRIBUTES.RANK]: rank ?? undefined,
    [ANALYTICS_ATTRIBUTES.QUICK_APPLY]: false,
    [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: false,
    [ANALYTICS_ATTRIBUTES.PRESELECTED]: "No",
    [ANALYTICS_ATTRIBUTES._CALL_TO_ACTION]: "Apply",
  };
};

/**
 * Maps a {@link ImpressionedProductVerticalCreditCard} to {@link MarketplaceProductEventProps} for use in an event.
 * @param param0 The credit card to map to {@link MarketplaceProductEventProps}.
 * @returns The {@link MarketplaceProductEventProps} instance.
 */
export const mapCreditCardToProductEventProps = (
  product: ImpressionedProductVerticalCreditCard,
): MarketplaceProductEventProps => {
  const baseProps = mapToProductEventProps(product);
  return {
    ...baseProps,
    [ANALYTICS_ATTRIBUTES.QUICK_APPLY]: product.isQuickApply,
    [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: product.isQuickApply,
    [ANALYTICS_ATTRIBUTES.PRESELECTED]: product.isPreSelection ? "Yes" : "No",
    [ANALYTICS_ATTRIBUTES._CALL_TO_ACTION]: product.isQuickApply ? "Quick Apply" : "Apply",
  };
};

/**
 * Maps a {@link ImpressionedProductVerticalBankAccount} to {@link MarketplaceBankAccountEventProps} for use in an event.
 * @param param0 The bank account to map to {@link MarketplaceBankAccountEventProps}.
 * @returns The {@link MarketplaceBankAccountEventProps} instance.
 */
export const mapBankAccountToProductEventProps = (
  product: ImpressionedProductVerticalBankAccount,
): MarketplaceBankAccountEventProps => {
  const baseProps = mapToProductEventProps(product);
  return {
    ...baseProps,
    [ANALYTICS_ATTRIBUTES.IS_BANNER]: Boolean(getPromoBannerText(product)),
    [ANALYTICS_ATTRIBUTES.BANNER_NAME]: getPromoBannerText(product),
    [ANALYTICS_ATTRIBUTES.IS_MINIMUM_BALANCE]: product.minimumBalanceText ? true : false,
    [ANALYTICS_ATTRIBUTES.HIGHLIGHTED_VALUE]: product.majorValuePropositionText ?? "",
    [ANALYTICS_ATTRIBUTES.ACCOUNT_TYPE]: product.productSegments[0].toString().toLowerCase() ?? "",
  };
};

/**
 * Maps a {@link ImpressionedProductVerticalMortgage} to {@link MarketplaceMortgageEventProps} for use in an event.
 * @param param0 The bank account to map to {@link MarketplaceMortgageEventProps}.
 * @returns The {@link MarketplaceMortgageEventProps} instance.
 */
export const mapMortgageToProductEventProps = (
  product: ImpressionedProductVerticalMortgage,
): MarketplaceProductEventProps => {
  const baseProps = mapToProductEventProps(product);
  return {
    ...baseProps,
    [ANALYTICS_ATTRIBUTES._CALL_TO_ACTION]: product.callToAction ?? "",
  };
};
