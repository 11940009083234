import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FullScreenScreenContainer = styled.View<{ backgroundColor: string }>`
  width: 100vw;
  height: 100vh;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  justify-content: center;
  align-items: stretch;
  pointer-events: auto;
`;

export const ContentContainer = styled.View<{ screenHeight: number }>`
  width: 100%;
  height: 100%;
  padding-top: ${({ screenHeight }) => styledValue(screenHeight * 0.2)};
  padding-bottom: ${styledValue(spacing.xl)};
  padding-horizontal: ${styledValue(10)};
`;

export const CenteredImage = styled.Image`
  width: 100%;
  max-width: ${styledValue(200)};
  max-height: 60vh;
  aspect-ratio: 2;
`;

export const CenterAlign = styled.View`
  align-items: center;
`;
