import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Body1, Icon } from "@bwll/bw-components/next";
import { useCreditCardFiltersExperiment } from "@bwll/bw-hooks";
import { fontSize } from "@bwll/bw-styles";

import * as Styled from "./MarketplaceFilterButton.styles";
import { MarketplaceFilterButtonProps } from "./MarketplaceFilterButton.types";

export const MarketplaceFilterButton = ({ onPress, testID }: MarketplaceFilterButtonProps) => {
  const i18next = useTranslation();
  const { showFilterButtonTitle } = useCreditCardFiltersExperiment();

  return (
    <Styled.FilterButton type={BUTTON_TYPE.INPUT} onPress={onPress} testID={testID}>
      <Icon icon="filter_inactive" size={fontSize.xl} />
      {showFilterButtonTitle && (
        <Body1>{i18next.t("marketplace:productList:sortAndFilters:filters:title")}</Body1>
      )}
    </Styled.FilterButton>
  );
};
