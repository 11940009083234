import {
  CREDIT_CARD_CATEGORIES,
  CREDIT_CARD_CATEGORY_KEYS,
  CREDIT_CARD_CATEGORY_PATHNAMES,
  CreditCardCategoryKey,
} from "@bwll/bw-types";

/**
 * Parses a string pathname of {@link CREDIT_CARD_CATEGORY_PATHNAMES} to its matching {@link CreditCardCategoryKey}.
 * @param input The pathname to parse. Should be a member of {@link CREDIT_CARD_CATEGORY_PATHNAMES} to parse properly.
 * @returns The corresponding {@link CreditCardCategoryKey} if input can be parsed, or undefined if not.
 */
export const parseCreditCardCategoryKeyFromPathname = (input: unknown): CreditCardCategoryKey | undefined => {
  if (input && typeof input === "string") {
    const matchedCategory = Object.entries(CREDIT_CARD_CATEGORY_PATHNAMES).find(
      ([_, pathname]) => input === pathname,
    );
    return matchedCategory ? (matchedCategory[0] as CreditCardCategoryKey) : undefined;
  }

  return undefined;
};

export const parseCreditCardCategoryKeyFromId = (id: number): CreditCardCategoryKey => {
  const foundKey = Object.entries(CREDIT_CARD_CATEGORIES).find(([, categoryValue]) => categoryValue === id);

  return (foundKey ? foundKey[0] : CREDIT_CARD_CATEGORY_KEYS.OTHER) as CreditCardCategoryKey;
};
