export const historicalConfirmationPage = {
  title: "You're all set for now!",
  titleNextSteps: "Keep an eye on your email for next steps!",
  subtitle: "Keep an eye on your email for updates on next steps",
  paragraph1:
    "Next, our Member Experience team will reach out via email to assist you in securely submitting the necessary documents.",
  paragraph2:
    "Once received, we will verify your information and add your past rental payments to your credit history!",
  ctaButton: "GO TO DASHBOARD",
};
