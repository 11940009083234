import { bankAccounts } from "./bankAccounts";
import { mortgages } from "./mortgages";
import { creditCards } from "./creditCards";
import { errors } from "./errors";
import { productDetails } from "./productDetails";
import { productList } from "./productList";
import { productVerticalsNavBar } from "./productVerticalsNavBar";
import { quickApply } from "./quickApply";
import { borrowellGuarantee } from "./borrowellGuarantee";

export const marketplace = {
  mortgages,
  bankAccounts,
  creditCards,
  errors,
  productDetails,
  productList,
  productVerticalsNavBar,
  quickApply,
  borrowellGuarantee,
};
