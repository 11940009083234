import { useCallback } from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native";

import {
  BUTTON_TYPE,
  Button,
  Display2,
  Dropdown,
  Heading2,
  Spacer,
  TextInput,
} from "@bwll/bw-components/next";
import { TextInputProps } from "@bwll/bw-components/next/molecules/Input/TextInput/TextInput.types";
import { useBreakpoints, useGetMember } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";
import { DATE_FORMATS, ProfileEditFormInputs, REGIONS_ORDERED } from "@bwll/bw-types";
import {
  cityValidationPattern,
  formatDate,
  formatPhoneNumber,
  phoneNumberValidationPattern,
  postalCodeValidationPattern,
  streetAddressValidationPattern,
} from "@bwll/bw-utils";

import { PROFILE_EDIT_FORM_TEST_IDS as TEST_IDS } from "./ProfileEditForm.constants";
import * as Styled from "./ProfileEditForm.styles";
import { ProfileEditFormProps } from "./ProfileEditForm.types";

const mapRhfToTextInput = <TName extends FieldPath<ProfileEditFormInputs>>({
  field,
  fieldState,
}: {
  field: ControllerRenderProps<ProfileEditFormInputs, TName>;
  fieldState: ControllerFieldState;
}): Omit<TextInputProps, "label"> => ({
  ...field,
  onChangeText: field.onChange,
  currentState: { errored: Boolean(fieldState.error) },
  caption: fieldState.error?.message,
});

export const ProfileEditForm = ({ onSubmit, onNavigateBack }: ProfileEditFormProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const { isLoading, data: member } = useGetMember({
    transform: (response) => {
      const { legalName, birthDate, primaryPhoneNumber, email, preferredName } = response.individual;

      const { streetAddress, streetAddress2, city, regionName, regionId, postalCode } =
        response.individual.primaryAddress;
      return {
        legalName,
        preferredName,
        dateOfBirth: formatDate({
          dateString: birthDate,
          dateFormat: DATE_FORMATS.MMMM_dd_yyyy,
          ignoreOffset: true,
        }),
        phoneNumber: formatPhoneNumber(primaryPhoneNumber) ?? primaryPhoneNumber,
        email,
        streetAddress,
        streetAddress2,
        city,
        regionName,
        regionId,
        postalCode,
      };
    },
  });

  const { control, handleSubmit } = useForm<ProfileEditFormInputs>({
    defaultValues: {
      phoneNumber: member?.phoneNumber,
      email: member?.email,
      streetAddress: member?.streetAddress,
      streetAddress2: member?.streetAddress2,
      city: member?.city,
      regionId: member?.regionId,
      postalCode: member?.postalCode,
    },
  });

  const submitForm: SubmitHandler<ProfileEditFormInputs> = useCallback(
    (formData) =>
      onSubmit({
        ...formData,
        legalName: member?.legalName ?? "",
        preferredName: member?.preferredName ?? "",
        dateOfBirth: member?.dateOfBirth ?? "",
        email: member?.email ?? "",
      }),
    [member?.dateOfBirth, member?.email, member?.legalName, member?.preferredName, onSubmit],
  );

  const editHeading = i18next.t("profile:edit:headings:edit");

  return isLoading || !member ? (
    <ActivityIndicator size="small" color={COLORS.NEUTRAL.COOL["600"]} />
  ) : (
    <Styled.Container>
      {isMobile ? <Styled.H2Mobile>{editHeading}</Styled.H2Mobile> : <Display2>{editHeading}</Display2>}
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:identity")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:labels:fullName")}
              value={member.legalName}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:labels:dateOfBirth")}
              value={member.dateOfBirth}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:contact")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: i18next.t("profile:edit:form:validationMessages:phoneNumberRequired"),
                pattern: {
                  value: phoneNumberValidationPattern,
                  message: i18next.t("profile:edit:form:validationMessages:phoneNumberPattern"),
                },
              }}
              render={(renderProps) => (
                <TextInput
                  label={i18next.t("profile:edit:form:labels:phoneNumber")}
                  testID={TEST_IDS.INPUT_PHONE_NUMBER}
                  {...mapRhfToTextInput(renderProps)}
                />
              )}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={i18next.t("profile:edit:form:labels:emailAddress")}
              value={member.email}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Styled.SectionContainer>
        <Heading2>{i18next.t("profile:edit:headings:address")}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="streetAddress"
              control={control}
              rules={{
                required: i18next.t("profile:edit:form:validationMessages:streetAddressRequired"),
                pattern: {
                  value: streetAddressValidationPattern,
                  message: i18next.t("profile:edit:form:validationMessages:streetAddressPattern"),
                },
                maxLength: {
                  value: 50,
                  message: i18next.t("profile:edit:form:validationMessages:streetAddressMaxLength"),
                },
              }}
              render={(renderProps) => (
                <TextInput
                  label={i18next.t("profile:edit:form:labels:streetAddress1")}
                  testID={TEST_IDS.INPUT_STREET_ADDRESS}
                  {...mapRhfToTextInput(renderProps)}
                />
              )}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="streetAddress2"
              control={control}
              rules={{
                pattern: {
                  value: streetAddressValidationPattern,
                  message: i18next.t("profile:edit:form:validationMessages:unitPattern"),
                },
                maxLength: {
                  value: 50,
                  message: i18next.t("profile:edit:form:validationMessages:unitMaxLength"),
                },
              }}
              render={(renderProps) => (
                <TextInput
                  label={i18next.t("profile:edit:form:labels:streetAddress2")}
                  testID={TEST_IDS.INPUT_STREET_ADDRESS2}
                  {...mapRhfToTextInput(renderProps)}
                />
              )}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="city"
              control={control}
              rules={{
                required: i18next.t("profile:edit:form:validationMessages:cityRequired"),
                pattern: {
                  value: cityValidationPattern,
                  message: i18next.t("profile:edit:form:validationMessages:cityPattern"),
                },
                maxLength: {
                  value: 50,
                  message: i18next.t("profile:edit:form:validationMessages:cityMaxLength"),
                },
              }}
              render={(renderProps) => (
                <TextInput
                  label={i18next.t("profile:edit:form:labels:city")}
                  testID={TEST_IDS.INPUT_CITY}
                  {...mapRhfToTextInput(renderProps)}
                />
              )}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="regionId"
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={i18next.t("profile:edit:form:labels:region")}
                  options={REGIONS_ORDERED.map((region) => ({ label: region.name, value: region.id }))}
                  matchTextInputMargin
                  onValueChange={field.onChange}
                  {...field}
                />
              )}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Controller
              name="postalCode"
              control={control}
              rules={{
                required: i18next.t("profile:edit:form:validationMessages:postalCodeRequired"),
                pattern: {
                  value: postalCodeValidationPattern,
                  message: i18next.t("profile:edit:form:validationMessages:postalCodePattern"),
                },
              }}
              render={(renderProps) => (
                <TextInput
                  label={i18next.t("profile:edit:form:labels:postalCode")}
                  testID={TEST_IDS.INPUT_POSTAL_CODE}
                  {...mapRhfToTextInput(renderProps)}
                />
              )}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>
      <Spacer height={spacing.m} />
      <Styled.SectionContainer>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onPress={handleSubmit(submitForm)}
          title={i18next.t("profile:edit:buttons:updateProfile")}
          testID={TEST_IDS.BUTTON_UPDATE_PROFILE}
        />
        <Button
          type={BUTTON_TYPE.TERTIARY}
          onPress={onNavigateBack}
          title={i18next.t("profile:edit:buttons:discardChanges")}
          testID={TEST_IDS.BUTTON_DISCARD_CHANGES}
        />
      </Styled.SectionContainer>
    </Styled.Container>
  );
};
