import React, { createContext, useMemo, useState } from "react";

import { WithChildren } from "@bwll/bw-components/next/types";
import { CreditCardFilters, ProductVerticalCreditCard } from "@bwll/bw-types";
import { CreditCardFilterer, noop, reducePredicates } from "@bwll/bw-utils";
import { CreditCardFilterCategory } from "@bwll/bw-utils/src/helpers/marketplace/creditCards/creditCardFilterers";

import { ContextName, useContextWrapper } from "./contextWrapper";

// Context
type CreditCardFiltersProviderProps = WithChildren<{
  initialState?: CreditCardFilters;
}>;
type CreditCardFiltersContext = [CreditCardFilters, React.Dispatch<React.SetStateAction<CreditCardFilters>>];

const emptyPredicate = () => true;

// Utils
/**
 * Gets a new CreditCardFilters object with the provided filterers.
 * @param filterers Initial filters to set in the context.
 * @returns A new CreditCardFilters object with the provided filterers.
 */
export const getNewCreditCardFiltersContext = (filterers?: CreditCardFilterer[]): CreditCardFilters =>
  filterers && filterers.length
    ? {
        filterers: new Map(filterers.map((filterer) => [filterer.key, filterer])),
        predicate: reducePredicatesByCategory(groupPredicatesByCategory(filterers)),
        actions: filterers.map((filterer) => ({ type: "added", filterer })),
      }
    : {
        filterers: new Map(),
        predicate: emptyPredicate,
        actions: [{ type: "reset" }],
      };

// Contexts
export const CreditCardFiltersContext = createContext<CreditCardFiltersContext>([
  getNewCreditCardFiltersContext(),
  noop,
]);

// API
/**
 * Provides the CreditCardFiltersContext to its child components.
 */
export const CreditCardFiltersProvider = ({ initialState, children }: CreditCardFiltersProviderProps) => {
  const initial = initialState ?? getNewCreditCardFiltersContext();

  const state = useState(initial);

  return <CreditCardFiltersContext.Provider value={state}>{children}</CreditCardFiltersContext.Provider>;
};

/**
 * Gets the current CreditCardFilters state and functions to modify it.
 * @returns The CreditCardFilters context state and functions to modify it.
 */
export const useCreditCardFilters = () => {
  const [filters, setFilters] = useContextWrapper(
    CreditCardFiltersContext,
    ContextName.CreditCardFiltersContext,
  );

  return useMemo(
    () => ({
      /**
       * The current filters collection state.
       */
      filters,
      /**
       * Toggles a filterer on or off in the filters collection.
       * @param filterer The filterer to toggle.
       */
      toggleFilter: (filterer: CreditCardFilterer) => {
        const newActions = filters.filterers.has(filterer.key)
          ? [...filters.actions, { type: "removed" as const }]
          : [...filters.actions, { type: "added" as const, filterer }];
        const newFilterers = filters.filterers.has(filterer.key)
          ? new Map((filters.filterers.delete(filterer.key), filters.filterers))
          : new Map(filters.filterers.set(filterer.key, filterer));
        const predicate = reducePredicatesByCategory(
          groupPredicatesByCategory(Array.from(newFilterers.values())),
        );

        setFilters({
          filterers: newFilterers,
          predicate,
          actions: newActions,
        });
      },
      /**
       * Removes all filterers from the filters collection, and sets a predicate to include all credit cards.
       */
      resetFilters: () => {
        setFilters({
          filterers: new Map(),
          predicate: emptyPredicate,
          actions: [...filters.actions, { type: "reset" }],
        });
      },
      /**
       * Adds an action to the state to indicate the Filter Selected event has been sent.
       */
      sendFilterSelected: () => {
        setFilters({
          ...filters,
          actions: [...filters.actions, { type: "selectedFilterSent" }],
        });
      },
    }),
    [filters, setFilters],
  );
};

// Utils
const groupPredicatesByCategory = (filters: CreditCardFilterer[]) => {
  return filters.reduce((acc, filter) => {
    if (!acc[filter.category]) {
      acc[filter.category] = [];
    }
    acc[filter.category].push(filter.predicate);
    return acc;
  }, {} as Record<CreditCardFilterCategory, ((c: ProductVerticalCreditCard) => boolean)[]>);
};

const reducePredicatesByCategory = (
  predicatesByCategory: Record<CreditCardFilterCategory, ((c: ProductVerticalCreditCard) => boolean)[]>,
) => {
  const categoryPredicates = Object.values(predicatesByCategory).map((predicates) =>
    reducePredicates(predicates),
  );

  return (c: ProductVerticalCreditCard) => categoryPredicates.every((pred) => pred(c));
};
