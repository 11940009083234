import { useBreakpoints } from "@bwll/bw-hooks";

import { QUICK_APPLY_PRODUCT_HEADER_TEST_IDS as TEST_IDS } from "./QuickApplyProductHeader.constants";
import * as Styled from "./QuickApplyProductHeader.styles";
import { QuickApplyProductHeaderProps } from "./QuickApplyProductHeader.types";

/**
 * Renders a product header for the Quick Apply form.
 */
export const QuickApplyProductHeader = ({ name, image }: QuickApplyProductHeaderProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <Styled.Card isMobile={isMobile}>
      {image && <Styled.ProductImage source={image} testID={TEST_IDS.IMAGE} isMobile={isMobile} />}
      <Styled.ProductHeading>{name}</Styled.ProductHeading>
    </Styled.Card>
  );
};
