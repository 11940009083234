import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconNames } from "@bwll/bw-components/next";
import { useGetMortgages, usePrimaryAddress } from "@bwll/bw-hooks";
import {
  ProductVerticalsNavBarBase,
  ProductVerticalsNavBarLinkConfig,
} from "@bwll/bw-modules/src/marketplace/ProductVerticalsNavBarBase";

import { ROUTES } from "@app/router";

/**
 * Component for rendering Product Verticals Nav Bar.
 * It uses `ProductVerticalsNavBarBase` component to render the nav bar.
 *
 * @component
 * @example
 * return (
 *   <ProductVerticalsNavBar />
 * )
 */
export const ProductVerticalsNavBar = () => {
  const i18next = useTranslation();
  const { data: mortgagesResponse, isLoading: isMortgagesLoading } = useGetMortgages({ enabled: true });
  const { isQuebecMember, isLoading: isPrimaryAddressLoading } = usePrimaryAddress();

  const isNavBarLoading = useMemo(
    () => isMortgagesLoading || isPrimaryAddressLoading,
    [isMortgagesLoading, isPrimaryAddressLoading],
  );

  const isMortgagesVisible = useMemo(
    () => !isMortgagesLoading && (mortgagesResponse?.productVerticalMortgages ?? [])?.length > 0,
    [mortgagesResponse, isMortgagesLoading],
  );

  const isCarLoansVisible = useMemo(() => !isQuebecMember, [isQuebecMember]);
  const isInvestingVisible = useMemo(() => isQuebecMember, [isQuebecMember]);

  const linkConfigs: ProductVerticalsNavBarLinkConfig[] = useMemo(
    () => [
      {
        title: i18next.t("marketplace:productVerticalsNavBar:categories:loans"),
        icon: "loans" as IconNames,
        link: ROUTES.LOANS,
        linkId: "nav-recommendations-item-loans",
      },
      {
        title: i18next.t("marketplace:productVerticalsNavBar:categories:creditCards"),
        icon: "card" as IconNames,
        link: ROUTES.CREDIT_CARDS,
        linkId: "nav-recommendations-item-credit-cards",
      },
      ...(isCarLoansVisible
        ? [
            {
              title: i18next.t("marketplace:productVerticalsNavBar:categories:carLoans"),
              icon: "car-loans" as IconNames,
              link: ROUTES.CAR_LOANS,
              linkId: "nav-recommendations-item-car-loans",
            },
          ]
        : []),
      ...(isMortgagesVisible
        ? [
            {
              title: i18next.t("marketplace:productVerticalsNavBar:categories:mortgages"),
              icon: "house" as IconNames,
              link: ROUTES.MORTGAGES,
              linkId: "nav-recommendations-item-mortgages",
            },
          ]
        : []),
      {
        title: i18next.t("marketplace:productVerticalsNavBar:categories:bankAccounts"),
        icon: "bank_2" as IconNames,
        link: ROUTES.BANK_ACCOUNTS,
        linkId: "nav-recommendations-item-bank-accounts",
      },
      {
        title: i18next.t("marketplace:productVerticalsNavBar:categories:insurance"),
        icon: "shield_outline" as IconNames,
        link: ROUTES.INSURANCES,
        linkId: "nav-recommendations-item-insurances",
      },
      {
        title: i18next.t("marketplace:productVerticalsNavBar:categories:buildCredit"),
        icon: "score_active" as IconNames,
        link: ROUTES.BUILD_CREDIT,
        linkId: "nav-recommendations-item-credit-building",
      },
      ...(isInvestingVisible
        ? [
            {
              title: i18next.t("marketplace:productVerticalsNavBar:categories:investing"),
              icon: "invest" as IconNames,
              link: ROUTES.INVESTMENTS,
              linkId: "nav-recommendations-item-investments",
            },
          ]
        : []),
    ],
    [i18next, isCarLoansVisible, isMortgagesVisible, isInvestingVisible],
  );

  return <ProductVerticalsNavBarBase linkConfigs={linkConfigs} isLoading={isNavBarLoading} />;
};
