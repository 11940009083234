import type { LoaderFunctionArgs } from "react-router-dom";

import { CreditCardSortOptionKey, SORT_OPTIONS, creditCardSorters } from "@bwll/bw-modules";
import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";
import { parseCreditCardCategoryKeyFromPathname } from "@bwll/bw-utils";

import { getTypedLoaderDataHook } from "@app/router/utils";

const getSortBy = (searchParams: URLSearchParams): CreditCardSortOptionKey | undefined => {
  const sortBy = searchParams.get("sortBy");
  if (sortBy && Object.keys(SORT_OPTIONS).includes(sortBy)) return sortBy as CreditCardSortOptionKey;
};

/** Prepares data for rendering CreditCardProductList from react-router-dom.  */
export const creditCardProductListLoader = ({ request, params }: LoaderFunctionArgs) => {
  const searchParams = new URL(request.url).searchParams;

  // Prepare filterer
  const categoryKey =
    parseCreditCardCategoryKeyFromPathname(params.category) ?? CREDIT_CARD_CATEGORY_KEYS.ALL;

  // Prepare sorter
  const sortBy = getSortBy(searchParams);
  const sorter =
    categoryKey === "trending"
      ? creditCardSorters.Trending
      : sortBy
      ? creditCardSorters[sortBy]
      : creditCardSorters.Recommended;

  return { categoryKey, sorter };
};

export const useCreditCardProductListLoaderData =
  getTypedLoaderDataHook<typeof creditCardProductListLoader>();
