import { useCallback } from "react";

export const useSignOutCallback = (disposeRefinerClient: () => void) => {
  const onRemoveUserCallback = useCallback((): void | Promise<void> => {
    localStorage.clear();

    disposeRefinerClient();
  }, [disposeRefinerClient]);

  return onRemoveUserCallback;
};
