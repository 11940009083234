import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { LayoutAnimation } from "react-native";

import { usePlatformContext } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";

import { Icon } from "../../atoms/Icon";
import { Spacer } from "../../atoms/Spacer";
import { Body1 } from "../../atoms/Typography";
import {
  Container,
  IconContainer,
  OptionContainer,
  OptionItemContainer,
  Row,
  SelectContainer,
  SelectedOptionTextContainer,
  TextContainer,
} from "./Select.styles";
import { SelectOption, SelectProps } from "./Select.types";

export const Select = <TSelectOptionValue,>({
  prefixLabel,
  options,
  initialOption,
  onSelectOption,
  onSelectToggle,
  state,
  testID,
  showSelectedOptionShortLabel,
}: SelectProps<TSelectOptionValue>) => {
  const { platform } = usePlatformContext();
  const isWeb = platform === PLATFORM.WEB_APP;
  const [show, setShow] = useState(false);
  const [hoverStyle, setHoverStyle] = useState<SelectOption<TSelectOptionValue>>(initialOption);
  const [selectedOption, setSelectedOption] = useState<TSelectOptionValue>(initialOption.value);

  // this effect is to utilize the state prop to show/hide the options
  // without using the show state directly which will cause the flicker
  useEffect(() => {
    if (state === "inactive") {
      setShow(false);
    } else if (state === "active") {
      setShow(true);
    }
  }, [state]);

  useLayoutEffect(() => {
    !isWeb && LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [show, isWeb]);

  const toggle = useCallback(() => {
    onSelectToggle && onSelectToggle();
    setShow((prevShow) => !prevShow);
  }, [onSelectToggle]);

  const handleOptionItemHoverStyle = useCallback(
    (option: SelectOption<TSelectOptionValue>) => () => {
      setHoverStyle(option);
    },
    [],
  );

  const handleOptionItemPress = useCallback(
    (option: SelectProps<TSelectOptionValue>["initialOption"]) => {
      setSelectedOption(option.value);
      onSelectOption && onSelectOption(option.value);
      setShow(false);
    },
    [onSelectOption],
  );

  const getSelectedOptionLabel = useCallback(
    (option?: SelectOption<TSelectOptionValue>) =>
      showSelectedOptionShortLabel ? option?.shortLabel ?? option?.label ?? "" : option?.label ?? "",
    [showSelectedOptionShortLabel],
  );

  return (
    <Container isOptionsDisplayed={show} testID={testID}>
      <SelectContainer onPress={toggle} isOptionsDisplayed={show} testID={`${testID}-select`}>
        <Row>
          <TextContainer>
            <Body1 variant="semi-bold">{prefixLabel}</Body1>
          </TextContainer>
          <Spacer width={spacing.xxxs} />
          <SelectedOptionTextContainer>
            <Body1 variant="regular">
              {getSelectedOptionLabel(options.find((i) => i.value === selectedOption))}
            </Body1>
          </SelectedOptionTextContainer>
          <Spacer width={spacing.xxs} />
          <IconContainer>
            <Icon icon="arrow_down" size={fontSize.xl} color={COLORS.NEUTRAL.COOL["600"]} />
          </IconContainer>
        </Row>
      </SelectContainer>

      {show && (
        <OptionContainer>
          {options.length > 0 &&
            options.map((option: SelectProps<TSelectOptionValue>["initialOption"]) => (
              <OptionItemContainer
                key={option.label}
                onPress={() => handleOptionItemPress(option)}
                testID={`option-item-${option.label}`}
                onHoverIn={handleOptionItemHoverStyle(option)}
                onHoverOut={handleOptionItemHoverStyle(option)}
                isActive={(isWeb && hoverStyle.label === option.label) || selectedOption === option.value}
              >
                <TextContainer>
                  <Body1>{option.label}</Body1>
                </TextContainer>
                {selectedOption === option.value && (
                  <IconContainer>
                    <Icon icon="checkmark" size={fontSize.xl} color={COLORS.NEUTRAL.COOL["600"]} />
                  </IconContainer>
                )}
              </OptionItemContainer>
            ))}
        </OptionContainer>
      )}
    </Container>
  );
};
