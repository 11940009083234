import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

/**
 * Returns a boolean value for a member based on their treatment for the Split flag "sspaMarketplaceMortgages".
 * @returns `true` if the member is in the "on" treatment, `false` otherwise.
 */
export const useMarketplaceMortgagesExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_MORTGAGES]?.treatment ?? "default";

    return treatment === "on";
  }, [experimentsMap.userConfig]);
};
