export enum QUERY_KEYS {
  ENVIRONMENT = "environment",
  MEMBER_CALL = "member-api",
  TRANSITION_MEMBER_CALL = "transition-member-api",
  UPDATE_INDIVIDUAL_CLIENT_PROFILE = "update-individual-client-profile",
  SUBMIT_FORM = "submit-form",
  CREATE_RENT_REPORTING_PROFILE = "create-rent-reporting-profile",
  CREATE_RENT_TRANSACTION = "create-rent-transaction",
  REPLACE_RENT_TRANSACTION = "replace-rent-transaction",
  DELETE_RENT_TRANSACTION = "delete-rent-transaction",
  GET_RENT_REPORTING_PROFILE = "get-rent-reporting-profile",
  GET_RENT_REPORTING_MX_BANK_CONNECT_URL = "get-rent-reporting-mx-bank-connect-url",
  GET_RENT_REPORTING_MX_BANK_RECONNECT_URL = "get-rent-reporting-mx-bank-reconnect-url",
  GET_RENT_REPORTING_BANK_ACCOUNTS = "get-rent-reporting-bank-accounts",
  GET_RENT_REPORTING_BANK_CONNECTION_STATUS = "get-rent-reporting-bank-connection-status",
  GET_RENT_REPORTING_ACCOUNT_TRANSACTIONS = "get-rent-reporting-account-transactions",
  CREATE_CHECKOUT_SESSION_URL = "create-checkout-session-url",
  CREATE_RENT_REPORTING_SUBSCRIPTION = "create-rent-reporting-subscription",
  CREATE_RENT_REPORTING_CUSTOMER_PORTAL_URL = "create-rent-reporting-customer-portal-url",
  GET_RENT_REPORTING_SUBSCRIPTIONS = "get-rent-reporting-subscriptions",
  GET_RENT_REPORTING_SUBSCRIPTION_PRODUCT = "get-rent-reporting-subscription-product",
  GET_RENT_REPORTING_SUBSCRIPTION_PRODUCTS = "get-rent-reporting-subscription-products",
  GET_RENT_REPORTING_SUBSCRIPTION_PUBLISHABLE_KEY = "get-rent-reporting-subscription-publishable-key",
  GET_MOST_RECENT_APPLICATION_SUMMARY = "get-most-recent-application-summary",
  // Credit factors
  PATCH_CREDIT_FACTOR_UPDATE = "patch-credit-factor-update",
  GET_CREDIT_UTILIZATION_DETAILS = "get-credit-utilization-details",
  GET_CREDIT_INQUIRIES = "get-credit-inquiries",
  GET_CREDIT_FACTORS_SUMMARY = "get-credit-factors-summary",
  GET_CREDIT_ACCOUNTS = "get-credit-accounts",
  GET_CREDIT_COACH_UPDATES = "get-credit-coach-updates",
  // Rent reporting
  GET_RENT_REPORTING_LATEST_RENT_TRANSACTION = "get-latest-rent-transaction",
  GET_RENT_REPORTING_LATEST_RENT_PERIOD_TRANSACTIONS = "get-latest-rent-period-transactions",
  CREATE_RENT_REPORTING_RENT_TRANSACTION = "post-rent-transaction",
  PATCH_RENT_REPORTING_LATEST_RENT_TRANSACTION = "patch-rent-transaction",
  GET_LATEST_CREDIT_REPORT = "get-latest-credit-report",
  GET_PAYMENT_PROFILES_BY_TRADE_ID = "get-payment-profiles-by-trade-id",
  GET_POTENTIAL_RENT_TRANSACTIONS = "get-potential-rent-transactions",
  GET_PROMOTIONS = "get-promotions",
  GET_PRESELECTIONS = "get-preselections",
  RECORD_PROMOTION_ACTION = "record-promotion-action",
  CREATE_RENT_PROOF_SUBMISSION = "create-rent-proof-submission",
  GET_RENT_PROOF_SUBMISSION = "get-rent-proof-submission",
  REMOVE_OLD_RENT_PROOF_SUBMISSION = "remove-old-rent-proof-submission",
  UPDATE_RENT_PROOF_ATTACHMENT = "update-rent-proof-attachment",
  GET_RENT_PROOF_ATTACHMENT = "get-rent-proof-attachment",
  GET_PAST_RENT_REPORTING_DETAILS = "get-past-rent-reporting-details",
  // Credit Builder
  SEND_PAD_EMAIL = "send-pad-email",
  GET_PAD_AGREEMENT_DETAILS = "get-pad-agreement-details",
  CREATE_MANUAL_BANKING_REQUEST = "create-manual-banking-request",
  SAVE_PROGRAM_SUMMARY_OPTIONS = "save-program-summary-options",
  SAVE_PROGRAM_SUMMARY_OPTIONS_V2 = "save-program-summary-options-v2",
  GET_CROSS_SELL_ELIGIBLE_PRODUCTS = "get-cross-sell-eligible-products",
  CREATE_ADHOC_PAYMENT = "create_adhoc_payment",
  GET_NATIVE_CB_AGREEMENT_DETAILS = "get-native-cb-agreement-details",
  SUBMIT_NATIVE_CB_AGREEMENT = "submit-native-cb-agreement",
  GET_SIGNED_DOCUMENT = "signed-document",
  // Product verticals
  GET_CAR_LOANS = "get-product-verticals-car-loans",
  GET_LOANS_BY_ID = "get-product-verticals-loans-by-id",
  GET_BANK_ACCOUNTS = "get-product-verticals-bank-accounts",
  GET_IMPRESSIONED_BANK_ACCOUNTS = "get-product-verticals-impressioned-bank-accounts",
  GET_IMPRESSIONED_MORTGAGES = "get-product-verticals-impressioned-credit-cards",
  GET_CREDIT_CARDS = "get-product-verticals-credit-cards",
  GET_CREDIT_CARDS_CATEGORIES = "get-product-verticals-credit-cards-categories",
  GET_IMPRESSIONED_CREDIT_CARDS_CATEGORIES = "get-product-verticals-impressioned-credit-cards-categories",
  GET_IMPRESSIONED_CREDIT_CARDS = "get-product-verticals-impressioned-credit-cards",
  GET_CREDIT_CARD_BY_ID = "get-product-verticals-credit-cards-by-id",
  GET_PERSONAL_LOANS = "get-product-verticals-personal-loans",
  GET_RECOMMENDED_PRODUCTS = "get-product-verticals-recommended-products",
  GET_MORTGAGES = "get-product-verticals-mortgages",
  POST_QUALIFY = "post-product-verticals-qualify-v2",
  GET_PRODUCT_PICKS = "get-product-picks",
  // Engagement/Promotion features
  GET_ENGAGE_MEMBER = "get-engage-member",
  GET_USER_ENGAGEMENT_FEATURES = "get-user-engagement-features",
  GET_QUALIFIED_CREDIT_BUILDING_PRODUCTS = "qualified-credit-building-products",
  QUICK_APPLY = "quick-apply",
  GET_PREAPPROVALS = "get-preapprovals",
  GET_PROMO_CARDS_HIERARCHY = "get-promo-cards-hierarchy",
  GET_PROMO_CARDS_HIERARCHY_V3 = "get-promo-cards-hierarchy-v3",
  GET_TOP_PICKS = "get-top-picks",
  GET_PRIME_PROMO_CARDS = "get-prime-promo-cards",
  // User Config
  GET_USER_CONFIG_CALLOUTS = "get-user-config-callouts",
  DISMISS_USER_CONFIG_CALLOUTS = "dismiss-user-config-callouts",
  COMPLETE_USER_CONFIG_CALLOUTS = "complete-user-config-callouts",
  GET_USER_CONFIG_EXPERIMENTS = "get-user-config-experiements",
  GET_USER_CONFIG_USER_CONFIGURATIONS = "get-user-config-user-configurations",
  // EID
  GET_EID_QUESTIONS = "get-eid-questions",
  SUBMIT_EID_ANSWERS = "submit-eid-answers",
  GET_CREDIT_REPORT_STATUS = "get-credit-report-status",
  // Localization
  SET_LOCALIZATION_PREFERENCE = "set-localization-preference",
}
