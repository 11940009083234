import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";

import { BUTTON_TYPE, Button, Icon, Subheading2 } from "@bwll/bw-components/next";
import { useBreakpoints, useCreditCardFiltersExperiment } from "@bwll/bw-hooks";
import { fontSize } from "@bwll/bw-styles";

import { MarketplaceFilterButton } from "../../MarketplaceFilterButton";
import { CreditCardFilters } from "../CreditCardFilters";
import { CreditCardSortOptionKey, ProductSortDropdown, creditCardSorters } from "../ProductSortDropdown";
import { CREDIT_CARDS_FILTERS_SHEET_TEST_IDS } from "./CreditCardFiltersSheet.constants";
import * as Styled from "./CreditCardFiltersSheet.styles";
import { CreditCardFiltersSheetProps } from "./CreditCardFiltersSheet.types";

export const CreditCardFiltersSheet = ({
  cardCount,
  initialSort,
  onSortChanged,
  trackFilterButtonClicked,
  testID,
}: CreditCardFiltersSheetProps) => {
  const { isMobile, isTablet } = useBreakpoints();
  const isSmallScreen = isMobile || isTablet;

  const i18next = useTranslation();
  const { showFilterButtonTitle } = useCreditCardFiltersExperiment();

  const [visible, setVisible] = useState(false);
  const [resetKey, setResetKey] = useState(false);

  const sortOptions = useMemo(
    () =>
      Object.values(creditCardSorters).map(({ key, localeKey, shortLocaleKey }) => ({
        value: key,
        label: i18next.t(localeKey),
        shortLabel: i18next.t(shortLocaleKey),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [sortKey, setSortKey] = useState<CreditCardSortOptionKey | undefined>(initialSort);
  const handleSortChange = useCallback(
    (key: CreditCardSortOptionKey) => {
      setSortKey(key);
      onSortChanged(creditCardSorters[key]);
    },
    [onSortChanged],
  );

  const handleOpenFilters = useCallback(() => {
    trackFilterButtonClicked(cardCount);
    setVisible(true);
  }, [cardCount]);

  // Block scrolling when sheet is visible
  useEffect(() => {
    if (isSmallScreen && visible) document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isSmallScreen, visible]);

  return (
    <>
      {isSmallScreen && (
        <Styled.SortAndFilterContainer>
          <Styled.SortContainer>
            <ProductSortDropdown
              initialSort={sortKey}
              options={sortOptions}
              onValueChange={handleSortChange}
              testID={CREDIT_CARDS_FILTERS_SHEET_TEST_IDS.SORT_DROPDOWN}
              showSelectedOptionShortLabel={showFilterButtonTitle}
            />
          </Styled.SortContainer>
          <MarketplaceFilterButton
            onPress={handleOpenFilters}
            testID={CREDIT_CARDS_FILTERS_SHEET_TEST_IDS.SHOW_FILTERS_BUTTON}
          />
        </Styled.SortAndFilterContainer>
      )}
      <Styled.SheetPanel isMobile={isSmallScreen} isExpanded={visible} testID={testID}>
        <ScrollView>
          <Styled.SheetPanelContentContainer>
            {!isSmallScreen && (
              <Styled.SheetPanelSortContainer>
                <Styled.SheetPanelSortHeadingContainer>
                  <Icon icon="filter-lines" size={fontSize.s} />
                  <Subheading2>{i18next.t("marketplace:productList:sortAndFilters:sort:title")}</Subheading2>
                </Styled.SheetPanelSortHeadingContainer>
                <ProductSortDropdown
                  initialSort={sortKey}
                  options={sortOptions}
                  onValueChange={handleSortChange}
                  testID={CREDIT_CARDS_FILTERS_SHEET_TEST_IDS.SORT_DROPDOWN}
                />
              </Styled.SheetPanelSortContainer>
            )}
            <CreditCardFilters resetKey={resetKey} />
          </Styled.SheetPanelContentContainer>
        </ScrollView>
        {isSmallScreen && (
          <Styled.SheetPanelFooterContainer>
            <Styled.SheetPanelButtonContainer>
              <Button
                testID={CREDIT_CARDS_FILTERS_SHEET_TEST_IDS.RESET_BUTTON}
                type={BUTTON_TYPE.TERTIARY}
                onPress={() => {
                  setResetKey(!resetKey);
                  setVisible(false);
                }}
                title={i18next.t("productCatalog:creditCards:sortAndFilters:buttons:reset")}
              />
            </Styled.SheetPanelButtonContainer>
            <Styled.SheetPanelButtonContainer>
              <Button
                testID={CREDIT_CARDS_FILTERS_SHEET_TEST_IDS.SHOW_RESULTS_BUTTON}
                type={BUTTON_TYPE.PRIMARY}
                onPress={() => setVisible(false)}
                disabled={false}
                title={i18next.t("productCatalog:creditCards:sortAndFilters:buttons:showResults", {
                  count: cardCount,
                })}
              />
            </Styled.SheetPanelButtonContainer>
          </Styled.SheetPanelFooterContainer>
        )}
      </Styled.SheetPanel>
      {isSmallScreen && visible && <Styled.Backdrop />}
    </>
  );
};
