import { Icon } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import {
  NavBarButtonContainer,
  NavBarLinkContainer,
  NavBarLinkContentContainer,
  NavBarLinkIconContainer,
  NavBarLinkOuterContainer,
  NavBarLinkTitle,
} from "./NavBarLink.styles";
import { NavBarLinkProps } from "./NavBarLink.types";

/**
 * Component for rendering the NavBarLink.
 *
 * @component
 * @example
 * return (
 *   <NavBarLink
 *     title="Loans"
 *     icon="loans"
 *     link="/loans"
 *     linkId="loans-link"
 *     isSelected={false}
 *   />
 * )
 */
export const NavBarLink = ({ title, icon, link, linkId, isSelected, onLinkClick }: NavBarLinkProps) => {
  const content = (
    <NavBarLinkContentContainer>
      <NavBarLinkIconContainer isSelected={isSelected}>
        <Icon icon={icon} size={32} color={COLORS.WHITE} />
      </NavBarLinkIconContainer>
      <NavBarLinkTitle isSelected={isSelected}>{title}</NavBarLinkTitle>
    </NavBarLinkContentContainer>
  );

  // Render with styled(Link) if link is provided.
  if (link) {
    return (
      <NavBarLinkOuterContainer isSelected={isSelected}>
        <NavBarLinkContainer to={link} onClick={onLinkClick} data-cy={linkId}>
          {content}
        </NavBarLinkContainer>
      </NavBarLinkOuterContainer>
    );
  }

  // Render with styled.button if link is not provided.
  return (
    <NavBarLinkOuterContainer isSelected={isSelected}>
      <NavBarButtonContainer onClick={onLinkClick} data-cy={linkId}>
        {content}
      </NavBarButtonContainer>
    </NavBarLinkOuterContainer>
  );
};
