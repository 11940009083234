import { IndexRouteObject, NonIndexRouteObject } from "react-router-dom";

import { ROUTES } from "./routes";

import { AppShellRouteProps } from "@app/screens/AppShell";
import { CreditCardComparison } from "@app/screens/creditCards/CreditCardComparison";
import { CreditCardProductDetails } from "@app/screens/creditCards/CreditCardProductDetails";
import {
  CreditCardProductList,
  creditCardProductListLoader,
} from "@app/screens/creditCards/CreditCardProductList";
import { CreditCardShell, CreditCardShellRouteProps } from "@app/screens/creditCards/CreditCardShell";

type CombinedCreditCardRouteProps = CreditCardShellRouteProps & AppShellRouteProps;

type NonIndexCreditCardRouteObject = Omit<NonIndexRouteObject, "children"> & {
  children?: CreditCardRouteObject[];
};

export type CreditCardRouteObject = (IndexRouteObject | NonIndexCreditCardRouteObject) &
  CombinedCreditCardRouteProps;

export const creditCardRoutes: CreditCardRouteObject[] = [
  {
    Component: CreditCardShell,
    children: [
      ...[ROUTES.CREDIT_CARDS, ROUTES.CREDIT_CARDS_CATEGORY].map<CreditCardRouteObject>((path) => ({
        path,
        children: [
          {
            index: true,
            Component: CreditCardProductList,
            loader: creditCardProductListLoader,
          },
        ],
        creditCardShell: {
          showFooter: true,
        },
      })),
      { path: ROUTES.CREDIT_CARDS_COMPARE, Component: CreditCardComparison, appShell: { showNavbar: false } },
    ],
  },
  {
    path: ROUTES.CREDIT_CARDS_DETAILS,
    Component: CreditCardProductDetails,
  },
];
