import { matchRoutes, useLocation } from "react-router-dom";

import { CreditCardShellRouteProps } from "./CreditCardShell.types";

import { creditCardRoutes } from "@app/router/creditCardRoutes";

export const useCreditCardShellProps = (): Required<CreditCardShellRouteProps>["creditCardShell"] => {
  const location = useLocation();
  const match = matchRoutes(creditCardRoutes, location)
    ?.reverse()
    .find((m) => Boolean(m.route.creditCardShell));

  return (
    match?.route?.creditCardShell ?? {
      showFooter: false,
    }
  );
};
