import { UseQueryResult, useQuery } from "react-query";

import { IApiHookOptions, ImpressionedProductVerticalMortgage, QUERY_KEYS } from "@bwll/bw-types";
import { generateQueryKey, getImpressionedMortgage, queryBehavior } from "@bwll/bw-utils";

import { useSessionContext } from "../../../contexts";
import { useCreditScoreRequest } from "../../member";
import { MortgagesResponseData, useGetMortgages } from "./useGetMortgages";

type ImpressionedMortgagesResponse = {
  productVerticalMortgages: ImpressionedProductVerticalMortgage[];
};

const useGetImpressionedMortgages = <TTransformReturn = ImpressionedMortgagesResponse>(
  mortgagesResponse: MortgagesResponseData,
  individualClientIdReferenceNumber: number,
  options: IApiHookOptions<ImpressionedMortgagesResponse, TTransformReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { accessToken, expiresIn } = userData;

  const queryKey = generateQueryKey(QUERY_KEYS.GET_IMPRESSIONED_MORTGAGES, accessToken);

  const queryFn = () => ({
    productVerticalMortgages: mortgagesResponse?.productVerticalMortgages.map((mortgage) =>
      getImpressionedMortgage(mortgage, individualClientIdReferenceNumber),
    ),
  });

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
};

export const useImpressionedMortgages = <TTransformReturn = ImpressionedMortgagesResponse>(
  options: IApiHookOptions<ImpressionedMortgagesResponse, TTransformReturn> = {},
) => {
  const {
    data: creditScoreRequest,
    isLoading: isCreditScoreRequestLoading,
    isError: isCreditScoreRequestError,
  } = useCreditScoreRequest();

  const {
    data: mortgagesResponse,
    isLoading: isMortgagesLoading,
    isError: isMortgagesError,
  } = useGetMortgages({ enabled: true });

  const result = useGetImpressionedMortgages(
    mortgagesResponse || { productVerticalMortgages: [] },
    creditScoreRequest?.individualClientIdReferenceNumber || 0,
    {
      ...options,
      enabled: Boolean(mortgagesResponse) && Boolean(creditScoreRequest),
    },
  );

  return {
    ...result,
    isLoading: isMortgagesLoading || isCreditScoreRequestLoading,
    isError: isMortgagesError || isCreditScoreRequestError,
  } as UseQueryResult<TTransformReturn, unknown>;
};
