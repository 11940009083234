import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ErrorScreen } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import {
  MARKETPLACE_ERROR_SCREEN_ERROR_TYPES as ERROR_TYPES,
  IMAGE_KEYS,
} from "./MarketplaceErrorScreen.constants";
import { MarketplaceErrorScreenProps } from "./MarketplaceErrorScreen.types";

/**
 * Component for rendering fullscreen error screen on the app.
 *
 * @param {ErrorType} errorType - The title of the error screen.
 *
 * @component
 * @example
 * return (
 *   <MarketplaceErrorScreen
 *     errorType={"default"}
 *   />
 * )
 */
export const MarketplaceErrorScreen = ({ errorType }: MarketplaceErrorScreenProps) => {
  const i18next = useTranslation();
  const { title, description1, description2, imageKey } = useMemo(() => {
    switch (errorType) {
      case ERROR_TYPES.EMPTY_BANK_ACCOUNTS:
        return {
          title: i18next.t("marketplace:errors:emptyBankAccounts:title"),
          imageKey: IMAGE_KEYS.EMPTY_RESULTS,
          description1: i18next.t("marketplace:errors:emptyBankAccounts.description1"),
          description2: i18next.t("marketplace:errors:emptyBankAccounts.description2"),
        };
      case ERROR_TYPES.EMPTY_CREDIT_CARDS:
        return {
          title: i18next.t("marketplace:errors:emptyCreditCards.title"),
          imageKey: IMAGE_KEYS.EMPTY_RESULTS,
          description1: i18next.t("marketplace:errors:emptyCreditCards.description1"),
          description2: i18next.t("marketplace:errors:emptyCreditCards.description2"),
        };
      default:
        return {
          title: i18next.t("marketplace:errors:default.title"),
          imageKey: IMAGE_KEYS.LOADING,
          description1: i18next.t("marketplace:errors:default.description1"),
          description2: i18next.t("marketplace:errors:default.description2"),
        };
    }
  }, [i18next, errorType]);

  return (
    <ErrorScreen
      title={title}
      description1={description1}
      description2={description2}
      imageKey={imageKey}
      backgroundColor={COLORS.BRIGHT_GREEK["100"]}
    />
  );
};
