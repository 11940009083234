import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

import { BUTTON_TYPE, Button, Display2, Label } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";

import { QuickApplyBanner } from "../QuickApplyBanner";
import { QuickApplyConsent } from "../QuickApplyConsent";
import { QuickApplyPersonalDetailsForm } from "../QuickApplyPersonalDetailsForm";
import { QuickApplyProductHeader } from "../QuickApplyProductHeader";
import { QUICK_APPLY_FORM_TEST_IDS as TEST_IDS } from "./QuickApplyForm.constants";
import * as Styled from "./QuickApplyForm.styles";
import { QuickApplyFormProps } from "./QuickApplyForm.types";

/**
 * Renders the Quick Apply form.
 */
export const QuickApplyForm = ({
  quickApplyProduct,
  productVertical,
  partnerSchema,
  onPersonalDetailsUpdate,
  onSubmit,
  onBack,
  onConsentLinkClicked,
}: QuickApplyFormProps) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  const { companyName, productName, productImageUrl } = quickApplyProduct;
  const name = productName ?? "";
  const image = productImageUrl ? { uri: productImageUrl } : undefined;

  return (
    <>
      <ScrollView
        bounces={false}
        /**
         * The scrollview will introduce nested scroll container on the web
         * because the default HTML element is scrollable by itself.
         * So the scrollview should be enabled only on mobile
         */
        scrollEnabled={isMobile}
      >
        <Styled.Container>
          <Styled.HeaderContainer>
            <QuickApplyBanner verticalId={productVertical} />
            <QuickApplyProductHeader name={name} image={image} />
          </Styled.HeaderContainer>
          <Styled.PersonalDetailsContainer>
            <Styled.PersonalDetailsHeading>
              {isMobile ? (
                <Styled.H2Mobile>{t("marketplace:quickApply:personalDetails:heading")}</Styled.H2Mobile>
              ) : (
                <Display2>{t("marketplace:quickApply:personalDetails:heading")}</Display2>
              )}
              <Styled.InlineButton
                iconAlignment="left"
                iconName="edit_active"
                onPress={() => onPersonalDetailsUpdate?.()}
                title={t("marketplace:quickApply:buttons:update")}
                type={BUTTON_TYPE.TERTIARY}
                testID={TEST_IDS.BUTTON_UPDATE}
              />
            </Styled.PersonalDetailsHeading>
            <QuickApplyPersonalDetailsForm />
          </Styled.PersonalDetailsContainer>
          <QuickApplyConsent
            companyName={companyName}
            schema={partnerSchema?.consent}
            onConsentLinkClicked={onConsentLinkClicked}
          />
          {!isMobile && (
            <View>
              <Button
                type={BUTTON_TYPE.PRIMARY}
                onPress={onSubmit}
                title={t("marketplace:quickApply:buttons:quickApply")}
                testID={TEST_IDS.BUTTON_SUBMIT}
              />
              <Button
                type={BUTTON_TYPE.TERTIARY}
                onPress={onBack}
                title={t("marketplace:quickApply:buttons:backToProducts")}
                testID={TEST_IDS.BUTTON_BACK_TO_PRODUCTS}
              />
            </View>
          )}
        </Styled.Container>
      </ScrollView>
      {isMobile && (
        <Styled.Footer>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            onPress={onSubmit}
            title={t("marketplace:quickApply:buttons:quickApply")}
            testID={TEST_IDS.BUTTON_SUBMIT}
          />
          <Label>{t("marketplace:quickApply:reviewDetailsReminder")}</Label>
        </Styled.Footer>
      )}
    </>
  );
};
