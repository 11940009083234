import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import type { NavigateOptions } from "react-router-dom";

export type UseMarketplaceSearchParamsArgs = {
  navigateOpts: NavigateOptions;
};

const pushSearchParam = (name: string, value: string) => (searchParams: URLSearchParams) => {
  searchParams.set(name, value);
  return searchParams;
};

/**
 * Gets functions to push various keys on to the current search parameters of react-router-dom.
 */
export const useMarketplaceSearchParams = (args?: UseMarketplaceSearchParamsArgs) => {
  const { navigateOpts } = {
    navigateOpts: { preventScrollReset: true },
    ...args,
  };

  const [_, setSearchParams] = useSearchParams();

  return useMemo(
    () => ({
      setSortBy: (sortByKey: string) => setSearchParams(pushSearchParam("sortBy", sortByKey), navigateOpts),
    }),
    [navigateOpts, setSearchParams],
  );
};
