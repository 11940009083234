import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CreditCardCategoryContext, useMarketplaceTrendingExperiment } from "@bwll/bw-hooks";
import { CREDIT_CARD_CATEGORY_KEYS } from "@bwll/bw-types";

/**
 * Redirects the member back to Credit Cards List if they are not in the 'sspaMarketplaceTrending' experiment
 * and they happen to visit the Trending category.
 */
export const useTrendingExperimentRedirect = () => {
  const { showTrending } = useMarketplaceTrendingExperiment();
  const categoryKey = useContext(CreditCardCategoryContext);
  const navigate = useNavigate();

  if (!showTrending && categoryKey === CREDIT_CARD_CATEGORY_KEYS.TRENDING) navigate("/credit-cards");
};
