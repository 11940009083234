import { QuickApplyPreferredLanguage } from "@bwll/bw-types";
import { noop } from "@bwll/bw-utils";

import { QuickApplyMember } from "./QuickApply.types";

// Temporary mapping function until we refactor Mobile's approach which uses React Context
export const prepareFormData = (member: QuickApplyMember, language: QuickApplyPreferredLanguage) => {
  const { legalName, primaryPhoneNumber, primaryAddress, email, birthDate } = member;
  const { streetAddress, streetAddress2, city, regionId, postalCode } = primaryAddress;
  const [firstName, lastName] = legalName.split(" ");
  return {
    context: {
      address: {
        fields: {
          streetAddress,
          streetAddress2,
          city,
          regionId,
          postalCode,
        },
        updateAddressField: () => noop,
        updateAddress: () => noop,
      },
      contact: {
        fields: {
          phoneNumber: primaryPhoneNumber,
          email,
        },
        updateContactField: () => noop,
        updateContact: () => noop,
      },
      profile: {
        fields: {
          birthdate: new Date(birthDate),
          firstName,
          lastName,
          language,
        },
        updateProfileField: () => noop,
        updateProfile: () => noop,
      },
    },
  };
};
