import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationModalProps } from "@bwll/bw-modules/src/marketplace/ConfirmationModal/ConfirmationModal.types";
import { noop } from "@bwll/bw-utils";

type ModalState = Pick<
  ConfirmationModalProps,
  "visible" | "modalText" | "ctaText" | "onConfirm" | "onCancel"
>;

const hidden = {
  visible: false,
  modalText: "",
  ctaText: "",
  onConfirm: noop,
  onCancel: noop,
};

export const useComparisonModal = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<ModalState>(hidden);

  return useMemo(
    () => ({
      modal,
      showLeaveModal: (onConfirm: () => void) => {
        setModal({
          visible: true,
          modalText: t("marketplace:creditCards:compare:modal:onLeave"),
          ctaText: t("marketplace:creditCards:compare:leave"),
          onConfirm: () => {
            setModal(hidden);
            onConfirm();
          },
          onCancel: () => setModal(hidden),
        });
      },
      showRemoveModal: (onConfirm: () => void) => {
        setModal({
          visible: true,
          modalText: t("marketplace:creditCards:compare:modal:onRemove"),
          ctaText: t("marketplace:creditCards:compare:remove"),
          onConfirm: () => {
            setModal(hidden);
            onConfirm();
          },
          onCancel: () => setModal(hidden),
        });
      },
    }),
    [modal, t],
  );
};
