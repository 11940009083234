import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMarketplaceQuickApplyExperiment } from "@bwll/bw-hooks";

import { ROUTES } from "../router/routes";

/**
 * Redirects the member back to Credit Cards List if they are not in the 'sspaMarketplaceQuickApply' experiment
 * and they happen to visit the Quick Apply screen.
 */
export const useQuickApplyExperimentRedirect = () => {
  const { showQuickApply } = useMarketplaceQuickApplyExperiment();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showQuickApply) navigate(ROUTES.CREDIT_CARDS);
  }, [navigate, showQuickApply]);
};
