import { ImpressionedProductVerticalCreditCard } from "@bwll/bw-types";
import {
  getCreditCardCategories,
  getCreditCardsByCategory,
  sortCreditCardsByPreSelectedOrder,
} from "@bwll/bw-utils";

export const getTransformCreditCardsResponse =
  (productId?: string) =>
  ({
    productVerticalCreditCards,
  }: {
    productVerticalCreditCards: ImpressionedProductVerticalCreditCard[];
  }) => {
    const selectedCard = productVerticalCreditCards.find((cc) => cc.id === productId);
    if (!selectedCard)
      return {
        selectedCard,
        similarCards: undefined,
      };

    const categories = getCreditCardCategories(selectedCard);
    const possibleSimilarCards =
      categories.length === 0
        ? productVerticalCreditCards
        : getCreditCardsByCategory(categories[0], productVerticalCreditCards);
    const similarCards = sortCreditCardsByPreSelectedOrder(
      possibleSimilarCards.filter((card) => card.id !== productId),
    ).slice(0, 5);

    return {
      selectedCard,
      similarCards,
    };
  };
