import React, { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";

import { SplashScreen } from "@bwll/bw-components/next";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import { useCreditScoreRequest } from "@bwll/bw-hooks";
import { APP_INSIGHTS_EVENT_NAMES, appInsightsService } from "@bwll/bw-utils";

import { useAuthRedirectContext } from "./AuthRedirectContextProvider";

export const APP_INSIGHTS_PRE_MEMBER_CHECK_REDIRECT_EVENT_NAME = "PreMemberCheckRedirect";
export const BORROWELL_MEMBER_ROLE = "Borrowell Member";

interface IProps {
  children: React.ReactNode;
  redirectUrl: string;
  loadingComponent?: React.ReactNode;
}

/**
 * This is an optional component that can redirect users who are "pre-member"
 * back to the `redirectUrl` provided, where they can complete the necessary
 * steps to become a full Borrowell member. The check includes:
 * 1. If the user's role is "Borrowell Member".
 * 2. If the user's Identity Verification is completed.
 */
export const PreMemberCheck = ({ children, redirectUrl, loadingComponent }: IProps) => {
  const { isAuthenticated, user, removeUser } = useAuth();
  const { isLoading: isCreditScoreRequestLoading, isIdentityVerified } = useCreditScoreRequest();
  const { setIsRedirecting } = useAuthRedirectContext();

  const removeUserAndRedirect = useCallback(() => {
    setIsRedirecting(true);
    removeUser();
    window.location.assign(redirectUrl);
  }, [setIsRedirecting, removeUser, redirectUrl]);

  useEffect(() => {
    if (!isAuthenticated || !user || isCreditScoreRequestLoading) {
      return;
    }

    // Check if the auth user has a role is "Borrowell Member".
    const isUserPreMemberRole = user.profile.role !== BORROWELL_MEMBER_ROLE;
    if (isUserPreMemberRole) {
      appInsightsService.trackEvent({ name: APP_INSIGHTS_EVENT_NAMES.AUTH_CHECK_REDIRECT });
      removeUserAndRedirect();
      return;
    }

    // Check if the member Identity Verification is completed.
    if (!isIdentityVerified) {
      appInsightsService.trackEvent({ name: APP_INSIGHTS_EVENT_NAMES.PRE_MEMBER_CHECK_REDIRECT });
      removeUserAndRedirect();
      return;
    }
  }, [isAuthenticated, user, isCreditScoreRequestLoading, isIdentityVerified, removeUserAndRedirect]);

  if (!isAuthenticated || !user || isCreditScoreRequestLoading || !isIdentityVerified) {
    return loadingComponent ? <>{loadingComponent}</> : <SplashScreen image={borrowellLogoWhite} />;
  }

  return <>{children}</>;
};
