import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";

import { BUTTON_TYPE, Body2, Button, Expandable, Icon, Markdown, Spacer } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { useBreakpoints, useSimilarOffersExperiment } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { LIKELIHOOD_OF_APPROVAL } from "@bwll/bw-types";
import { isOfferExpired, makeTestIdGenerator } from "@bwll/bw-utils";

import { SimilarOffers } from "../../..";
import { LearnMoreShowMore } from "../../LearnMoreShowMore";
import { OfferEndDate } from "../../OfferEndDate";
import { PreQualifiedBanner } from "../../PreQualifiedBanner";
import { ProductBanner } from "../../ProductBanner";
import { ProductHighlights } from "../../ProductHighlights";
import { ApprovalChance } from "../ApprovalChance";
import { CreditCardImage } from "../CreditCardImage";
import { CreditCardRatesTable } from "../CreditCardRatesTable";
import { Rewards } from "../Rewards";
import {
  CREDIT_CARD_PRODUCT_DETAILS_TEST_IDS,
  CREDIT_CARD_PRODUCT_DETAILS_TEST_IDS as TEST_IDS,
} from "./CreditCardProductDetails.constants";
import * as Styled from "./CreditCardProductDetails.styles";
import type { CreditCardProductDetailsProps } from "./CreditCardProductDetails.types";

export const CreditCardProductDetails = ({
  product,
  onApplyPress,
  onLegalDetailsToggled,
  HeaderComponent,
  externalLinkNavigation,
  similarCards,
  enableSimilarOffers,
}: CreditCardProductDetailsProps) => {
  const i18next = useTranslation();

  const {
    id,
    isPreSelection,
    toolTipTitle,
    toolTipTextMarkdown,
    productName,
    productImageUrl,
    companyName,
    companyLogo,
    likelihoodOfApprovalType,
    approvalChance,
    annualFee,
    purchaseInterestRate,
    balanceTransferRate,
    isFirstYearWaived,
    cashAdvanceRate,
    perksDescriptionMarkdown,
    rewardsCategoryDescriptionMarkdown,
    rewardsCategoryMarkdown,
    rewardsCategoryType,
    signupBonusDescriptionMarkdown,
    signupBonusMarkdown,
    firstYearValueAmount,
    keyBenefits = [],
    learnMoreMarkdown,
    legalMarkdown,
    isQuickApply,
    promotionalBadges,
    cashbackBannerText,
    offerEndDate,
  } = product;

  const scrollRef = useRef<ScrollView>(null);
  const currentId = useRef("");

  // Scroll to top when credit card product changes
  useEffect(() => {
    if (currentId.current !== id) {
      currentId.current = id;
      scrollRef.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id]);

  const applyButtonText = isQuickApply
    ? i18next.t("productCatalog:all:buttons:quickApply")
    : i18next.t("productCatalog:all:buttons:apply");

  const generateTestId = useMemo(() => makeTestIdGenerator(id), [id]);

  const { isDesktop } = useBreakpoints();

  // Rates
  const ratesTableData = useMemo(
    () => ({
      annualFee,
      purchaseInterestRate,
      balanceTransferRate,
      isFirstYearWaived,
      cashAdvanceRate,
    }),
    [annualFee, purchaseInterestRate, balanceTransferRate, isFirstYearWaived, cashAdvanceRate],
  );
  // TODO remove this when a backend solution is implemented to support string formatted rates/fee table
  // Currently hardcoding for GMS-14240
  const isCapitalOne = useMemo(() => companyName === "Capital One", [companyName]);

  const hasRewards = Boolean(
    (rewardsCategoryMarkdown && rewardsCategoryDescriptionMarkdown && rewardsCategoryType) ||
      (signupBonusMarkdown && signupBonusDescriptionMarkdown) ||
      perksDescriptionMarkdown,
  );

  const shouldShowExpandable = useMemo(
    () => legalMarkdown || !!firstYearValueAmount,
    [firstYearValueAmount, legalMarkdown],
  );
  const { showLearnMoreExpandable, showSimilarOffers } = useSimilarOffersExperiment();

  const learnMoreMarkdownComponent = (
    <Markdown
      withSuperscript
      color={COLORS.NEUTRAL.WARM["800"]}
      onLinkPress={externalLinkNavigation}
    >{`${learnMoreMarkdown}`}</Markdown>
  );

  const hasPromotionalBadges = promotionalBadges.filter(Boolean).length > 0;
  const hasCashBackBannerText = !!cashbackBannerText;
  const hasActiveOffer = offerEndDate ? !isOfferExpired(offerEndDate) : false;

  const productBannerText = hasCashBackBannerText
    ? cashbackBannerText
    : hasPromotionalBadges
    ? promotionalBadges[0]
    : null;

  const showLikelihoodOfApproval =
    approvalChance !== LIKELIHOOD_OF_APPROVAL.NONE && approvalChance !== LIKELIHOOD_OF_APPROVAL.NOTELIGIBLE;

  return (
    <>
      <ScrollView
        testID={generateTestId(TEST_IDS.SCROLLVIEW)}
        bounces={false}
        /**
         * The scrollview will introduce nested scroll container on the web
         * because the default HTML element is scrollable by itself.
         * So the scrollview should be enabled only on mobile
         */
        scrollEnabled={!isDesktop}
        ref={scrollRef}
      >
        {!isWeb && (hasPromotionalBadges || hasCashBackBannerText) && (
          <ProductBanner testID={generateTestId(TEST_IDS.PRODUCT_BANNER_MOBILE)}>
            {productBannerText}
          </ProductBanner>
        )}
        <Styled.Content>
          {isWeb && HeaderComponent}
          <Styled.ProductData isDesktop={isDesktop} showSimilarOffers={showSimilarOffers}>
            <Styled.LeftContainer isDesktop={isDesktop}>
              {isDesktop && (hasPromotionalBadges || hasCashBackBannerText) && (
                <ProductBanner tall={true} testID={generateTestId(TEST_IDS.PRODUCT_BANNER_DESKTOP)}>
                  {productBannerText}
                </ProductBanner>
              )}
              <Styled.TitleContainer isDesktop={isDesktop}>
                <Styled.TitleText variant="semi-bold" numberOfLines={2} color={COLORS.NEUTRAL.WARM["800"]}>
                  {productName}
                </Styled.TitleText>
              </Styled.TitleContainer>
              <Styled.ImageContainer>
                <CreditCardImage height={120} width={216} uri={productImageUrl || companyLogo || ""} />
              </Styled.ImageContainer>
              {isDesktop && (
                <>
                  {offerEndDate && (
                    <Styled.OfferContainer>
                      <OfferEndDate date={offerEndDate} />
                    </Styled.OfferContainer>
                  )}
                  <Spacer height={spacing.xxs} />
                  {/* TODO: Replace with CreditCardApplyButton */}
                  <Button
                    title={applyButtonText}
                    iconName="new_tab"
                    onPress={onApplyPress}
                    type={BUTTON_TYPE.PRIMARY}
                    testID={generateTestId(TEST_IDS.APPLY_BUTTON_DESKTOP)}
                  />
                </>
              )}
              <Styled.BannerContainer centered={!isPreSelection}>
                {isPreSelection ? (
                  <PreQualifiedBanner
                    testID={generateTestId(TEST_IDS.PRE_QUALIFIED_BANNER)}
                    toolTipTitle={toolTipTitle}
                    toolTipTextMarkdown={toolTipTextMarkdown}
                  />
                ) : (
                  showLikelihoodOfApproval && (
                    <ApprovalChance
                      testID={generateTestId(TEST_IDS.APPROVAL_CHANCE)}
                      likelihoodOfApprovalType={likelihoodOfApprovalType}
                      approvalChance={approvalChance}
                    />
                  )
                )}
              </Styled.BannerContainer>
              {(!!firstYearValueAmount || !!rewardsCategoryMarkdown || !!signupBonusMarkdown) && (
                <Styled.HighlightsContainer>
                  <ProductHighlights
                    rewardsCategoryMarkdown={rewardsCategoryMarkdown}
                    signupBonusMarkdown={signupBonusMarkdown}
                    firstYearValueAmount={firstYearValueAmount}
                  />
                </Styled.HighlightsContainer>
              )}
              <Styled.RatesContainer>
                <CreditCardRatesTable data={ratesTableData} isCapitalOne={isCapitalOne} />
              </Styled.RatesContainer>
              {hasRewards && (
                <Styled.RewardsContainer testID={generateTestId(TEST_IDS.REWARDS)}>
                  <Rewards
                    perksDescriptionMarkdown={perksDescriptionMarkdown}
                    rewardsCategoryDescriptionMarkdown={rewardsCategoryDescriptionMarkdown}
                    rewardsCategoryMarkdown={rewardsCategoryMarkdown}
                    rewardsCategoryType={rewardsCategoryType}
                    signupBonusDescriptionMarkdown={signupBonusDescriptionMarkdown}
                    signupBonusMarkdown={signupBonusMarkdown}
                  />
                </Styled.RewardsContainer>
              )}
              <Styled.KeyBenefitsContainer>
                {keyBenefits.length > 0 &&
                  keyBenefits.map((benefit, index) => (
                    <React.Fragment key={benefit}>
                      <Styled.KeyBenefitRow key={benefit}>
                        <Icon icon="checkmark" size={fontSize.xl} color={COLORS.PRIMARY.DEFAULT} />
                        <Spacer width={spacing.xxs} />
                        <Styled.BoldSubheading>{benefit}</Styled.BoldSubheading>
                      </Styled.KeyBenefitRow>
                      {index !== keyBenefits.length - 1 && <Spacer height={spacing.xxs} />}
                    </React.Fragment>
                  ))}
              </Styled.KeyBenefitsContainer>
            </Styled.LeftContainer>
            <Styled.RightContainer isDesktop={isDesktop}>
              <Styled.BoldSubheading>
                {i18next.t("productCatalog:creditCards:details:title")}
              </Styled.BoldSubheading>
              <Spacer height={spacing.xs} />
              <Styled.MarkdownContainer>
                {showLearnMoreExpandable ? (
                  <LearnMoreShowMore testID={generateTestId(TEST_IDS.LEARN_MORE_SHOW_MORE)}>
                    {learnMoreMarkdownComponent}
                  </LearnMoreShowMore>
                ) : (
                  learnMoreMarkdownComponent
                )}
              </Styled.MarkdownContainer>
              {shouldShowExpandable && (
                <Expandable
                  testID={generateTestId(TEST_IDS.LEGAL)}
                  title={i18next.t("productCatalog:creditCards:details:legalDetails")}
                  onExpandableToggled={onLegalDetailsToggled}
                >
                  <>
                    {legalMarkdown && (
                      <Markdown
                        withSuperscript
                        color={COLORS.NEUTRAL.WARM["800"]}
                        onLinkPress={externalLinkNavigation}
                      >{`${legalMarkdown}`}</Markdown>
                    )}
                    {!!firstYearValueAmount && (
                      <Body2 color={COLORS.NEUTRAL.COOL["800"]}>
                        {i18next.t("productCatalog:creditCards:firstYearValue:legalCopy")}
                      </Body2>
                    )}
                  </>
                </Expandable>
              )}
            </Styled.RightContainer>
          </Styled.ProductData>
        </Styled.Content>
        {enableSimilarOffers && similarCards && (
          <>
            <Spacer height={spacing.xs} />
            <SimilarOffers
              products={similarCards}
              testID={CREDIT_CARD_PRODUCT_DETAILS_TEST_IDS.SIMILAR_OFFERS}
            />
          </>
        )}
      </ScrollView>
      {!isDesktop && (
        <Styled.Footer hasActiveOffer={hasActiveOffer}>
          {offerEndDate && (
            <Styled.OfferContainer>
              <OfferEndDate date={offerEndDate} />
            </Styled.OfferContainer>
          )}
          <Styled.ButtonContainer>
            <Button
              title={applyButtonText}
              iconName="new_tab"
              onPress={onApplyPress}
              type={BUTTON_TYPE.PRIMARY}
              testID={generateTestId(TEST_IDS.APPLY_BUTTON_MOBILE)}
            />
          </Styled.ButtonContainer>
        </Styled.Footer>
      )}
    </>
  );
};
