import type { LoaderFunctionArgs } from "react-router-dom";

import { ProfileEditParams } from "./ProfileEdit.types";

import { getTypedLoaderDataHook } from "@app/router/utils";

/**
 * Prepares parameters for rendering ProfileEdit from react-router-dom.
 */
export const profileEditLoader = ({ request }: LoaderFunctionArgs): ProfileEditParams => {
  const { searchParams } = new URL(request.url);
  const returnUrl = searchParams.get("returnUrl");

  return { returnUrl };
};

/**
 * Returns the parameters for the ProfileEdit component.
 */
export const useProfileEditLoaderData = getTypedLoaderDataHook<typeof profileEditLoader>();
