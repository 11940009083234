import styled from "@emotion/styled";

import { BREAKPOINTS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const SortAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${styledValue(spacing.xs)} 0;

  @media (max-width: ${BREAKPOINTS.MOBILE_MAXIMUM_WIDTH}px) {
    gap: ${styledValue(spacing.xs)};
    flex-direction: column;
    align-items: normal;
  }
`;
