import styled from "@emotion/native";
import { Platform } from "react-native";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FlexLayout = styled.View`
  width: 100%;
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${COLORS.WHITE};
`;

export const InnerContent = styled.View<{ minHeight: number }>`
  max-width: ${styledValue(554 + spacing.xs * 2)};
  min-height: ${({ minHeight }) => styledValue(minHeight)};
  width: 100%;
  margin: 0 auto;
  align-items: center;
  padding: ${styledValue(spacing.xs)} ${styledValue(spacing.xs)};
  justify-content: space-between;
`;

export const Container = styled.View<{ isMobile: boolean }>`
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? styledValue(370) : styledValue(500))};
  max-width: 100%;
`;

export const TitleContainer = styled.View<{ dataSet?: HTMLElement["dataset"] }>`
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled(Body2)<{ isMobile: boolean; testId?: string }>`
  font-size: ${({ isMobile }) => styledValue(isMobile ? fontSize.xs : fontSize.s)};
`;

export const ImageContainer = styled.View`
  max-width: 100%;
  width: 100%;
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const CompanyImage = styled.Image<{
  isDesktop: boolean;
}>`
  height: ${({ isDesktop }) => styledValue(isDesktop ? 128 : 112)};
  width: ${({ isDesktop }) => (isDesktop ? styledValue(205) : styledValue(180))};
  margin: auto;
  padding: ${styledValue(spacing.xxxs)};
`;

export const ButtonGroup = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${styledValue(spacing.none)} ${styledValue(spacing.none)};
  height: 48px;
`;

export const ExpandableInformationContainer = styled.Pressable<{ isExpanded: boolean }>`
  overflow: ${({ isExpanded }) => (isExpanded ? "none" : "hidden")};
  height: ${({ isExpanded }) => (isExpanded ? "unset" : styledValue(spacing.xl))};
  cursor: pointer;
  margin-bottom: ${({ isExpanded }) =>
    styledValue(
      isExpanded && (Platform.OS === "android" || Platform.OS === "ios")
        ? spacing.xxl
        : isExpanded
        ? spacing.xs
        : spacing.none,
    )};
`;

export const InformationContainer = styled.View`
  min-height: ${styledValue(spacing.xl)};
`;

export const IconContainer = styled.Pressable`
  cursor: pointer;
  margin-bottom: ${styledValue(spacing.xxs)};
  width: 100%;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${COLORS.NEUTRAL.WARM["200"]};
`;

export const ButtonContainer = styled.View`
  flex: 1;
`;

export const Footer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  align-self: stretch;
`;
