import { useMemo } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, PressableProps, View } from "react-native";

import { BUTTON_TYPE, Body1, Body2, Button, Tooltip } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { ProductVerticalCreditCard } from "@bwll/bw-types";

import { PreQualifiedBanner } from "../../PreQualifiedBanner";
import { DollarText, PercentageText } from "../../RatesTable/components";
import { ApprovalChance } from "../ApprovalChance";
import { CreditCardApplyButton } from "../CreditCardApplyButton";
import { CreditCardImage } from "../CreditCardImage";
import {
  IMAGE_DIMENSIONS,
  CREDIT_CARD_COMPARE_TABLE_TEST_IDS as TEST_IDS,
} from "./CreditCardCompareTable.constants";
import * as Styled from "./CreditCardCompareTable.styles";
import { CreditCardCompareTableProps } from "./CreditCardCompareTable.types";

const emptyArray = [null, null, null, null];

const EmptyCard = ({ onPress, testID }: Pick<PressableProps, "onPress" | "testID">) => (
  <Styled.EmptyCardContainer>
    <Styled.EmptyCard onPress={onPress} testID={testID}>
      <Styled.EmptyCardIconContainer>
        <Styled.EmptyCardIcon />
      </Styled.EmptyCardIconContainer>
    </Styled.EmptyCard>
  </Styled.EmptyCardContainer>
);

const HorizontalScrollWrapper = ({ children }: { children: React.ReactNode }) => {
  if (Platform.OS === "web") {
    return <Styled.Table>{children}</Styled.Table>;
  }

  return (
    <Styled.HorizontalScroll horizontal>
      <Styled.MobileTable>{children}</Styled.MobileTable>
    </Styled.HorizontalScroll>
  );
};

/**
 * A table component to compare various credit card properties against each other.
 */
export const CreditCardCompareTable = <TCreditCard extends ProductVerticalCreditCard>({
  cards,
  onApplyPress,
  onDetailsPress,
  onRemoveCardPress,
  onAddCardPress,
  viewTracker = () => null,
}: CreditCardCompareTableProps<TCreditCard>) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const tableCards = useMemo(() => [...cards, ...emptyArray].slice(0, 4), [cards]);
  const ThIcon = Styled.ThIcon(isMobile);

  return (
    <HorizontalScrollWrapper>
      <Styled.Thead>
        {tableCards.map((card, index) => (
          <Styled.TheadTd key={card?.id ?? index}>
            {card ? (
              <>
                <Styled.TheadImageContainer>
                  <CreditCardImage
                    height={IMAGE_DIMENSIONS.HEIGHT}
                    width={IMAGE_DIMENSIONS.WIDTH}
                    uri={card.productImageUrl || card.companyLogo || ""}
                  />
                  <Styled.RemoveButtonContainer>
                    <Styled.RemoveButton
                      onPress={() => onRemoveCardPress(card)}
                      testID={`${card.id}-${TEST_IDS.REMOVE_CARD_BUTTON}`}
                    />
                  </Styled.RemoveButtonContainer>
                  {viewTracker?.(card)}
                </Styled.TheadImageContainer>
                <Styled.TheadHeading isMobile={isMobile}>{card.productName}</Styled.TheadHeading>
                <CreditCardApplyButton
                  isQuickApply={card.isQuickApply}
                  onPress={() => onApplyPress(card)}
                  testID={`${card.id}-${TEST_IDS.APPLY_BUTTON}`}
                />
              </>
            ) : (
              <>
                <EmptyCard onPress={onAddCardPress} testID={`${index}-${TEST_IDS.ADD_CARD_IMAGE}`} />
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  onPress={onAddCardPress}
                  title={t("marketplace:creditCards:compare:addCard")}
                  testID={`${index}-${TEST_IDS.ADD_CARD_BUTTON}`}
                />
              </>
            )}
          </Styled.TheadTd>
        ))}
      </Styled.Thead>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="check_circle_filled" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:approvalChance")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3} alignCenter>
              {!card ? null : card.isPreSelection ? (
                <PreQualifiedBanner
                  testID={`${card.id}-${TEST_IDS.PREQUALIFIED_BANNER}`}
                  toolTipTitle={card.toolTipTitle}
                  toolTipTextMarkdown={card.toolTipTextMarkdown}
                />
              ) : (
                <ApprovalChance
                  testID={`${card.id}-${TEST_IDS.APPROVAL_CHANCE}`}
                  likelihoodOfApprovalType={card.likelihoodOfApprovalType}
                  approvalChance={card.approvalChance}
                  shortened
                />
              )}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="annual-fee" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:annualFees")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3} alignCenter>
              {card && <DollarText value={card.annualFee} isWaived={card.isFirstYearWaived} />}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="percent" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:interestRates")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => {
            const isCapitalOne = card?.companyName === "Capital One";

            return (
              <Styled.Td key={card?.id ?? index} lastItem={index === 3}>
                {card && (
                  <View>
                    <Body2>
                      {t("marketplace:creditCards:compare:table:interestRatesTitles:purchase")}:&nbsp;
                      {isCapitalOne ? (
                        <Styled.RateText>
                          {t("productCatalog:creditCards:metrics:purchaseInterestCapOne")}
                        </Styled.RateText>
                      ) : (
                        <PercentageText value={card.purchaseInterestRate} />
                      )}
                    </Body2>
                    <Body2>
                      {t("marketplace:creditCards:compare:table:interestRatesTitles:cashAdvance")}:&nbsp;
                      {isCapitalOne ? (
                        <Styled.RateText>
                          {t("productCatalog:creditCards:metrics:cashAdvanceCapOne")}
                        </Styled.RateText>
                      ) : (
                        <PercentageText value={card.cashAdvanceRate} />
                      )}
                    </Body2>
                    <Body2>
                      {t("marketplace:creditCards:compare:table:interestRatesTitles:balanceTransfer")}
                      :&nbsp;
                      {isCapitalOne ? (
                        <Styled.RateText>
                          {t("productCatalog:creditCards:metrics:balanceTransferCapOne")}
                        </Styled.RateText>
                      ) : (
                        <PercentageText value={card.balanceTransferRate} />
                      )}
                    </Body2>
                  </View>
                )}
              </Styled.Td>
            );
          })}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="award" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:highlights")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3}>
              {card && <Body2>{card.signupBonusMarkdown}</Body2>}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="rewards" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:rewards")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3}>
              {card && <Body2>{card.rewardsCategoryMarkdown}</Body2>}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="member_star" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:signUpOffer")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3}>
              {card && <Body2>{card.signupBonusDescriptionMarkdown}</Body2>}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="3hearts" />
          <Styled.ThHeading isMobile={isMobile}>
            {t("marketplace:creditCards:compare:table:headings:perks")}
          </Styled.ThHeading>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3}>
              {card && <Body2>{card.perksDescriptionMarkdown}</Body2>}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
      <Styled.RowWrapper>
        <Styled.Th>
          <ThIcon icon="dollar" />
          <Tooltip content={<Body1>{t("productCatalog:creditCards:firstYearValue:tooltipCopy")}</Body1>}>
            <Styled.ThHeading isMobile={isMobile} underline>
              {t("marketplace:creditCards:compare:table:headings:firstYearValue")}
            </Styled.ThHeading>
          </Tooltip>
        </Styled.Th>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3} alignCenter>
              {card && card.firstYearValueAmount && (
                <DollarText value={card.firstYearValueAmount} isWaived={false} />
              )}
            </Styled.Td>
          ))}
        </Styled.Tr>
        <Styled.Tr>
          {tableCards.map((card, index) => (
            <Styled.Td key={card?.id ?? index} lastItem={index === 3} alignCenter hasBorderTop>
              {card && (
                <Button
                  testID={`${card.id}-${TEST_IDS.DETAILS_BUTTON}`}
                  type={BUTTON_TYPE.TERTIARY}
                  title={t("marketplace:creditCards:compare:table:buttons:viewDetails")}
                  onPress={() => onDetailsPress(card)}
                />
              )}
            </Styled.Td>
          ))}
        </Styled.Tr>
      </Styled.RowWrapper>
    </HorizontalScrollWrapper>
  );
};
