import React from "react";
import { View } from "react-native";

import { Icon, Markdown, Spacer, Subheading2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";

import { Container, TextContainer } from "./HighlightTile.styles";
import { HighlightTileProps } from "./HighlightTile.types";

export const HighlightTile = ({ title, testID, content, icon }: HighlightTileProps) => {
  return (
    <Container testID={testID}>
      <View testID={icon}>
        <Icon icon={icon} size={fontSize.xl} color={COLORS.BLACK} />
      </View>
      <Spacer width={spacing.xxs} />
      <TextContainer>
        <Spacer width={spacing.xxs} />
        <Subheading2 color={COLORS.NEUTRAL.WARM["800"]}>{title}</Subheading2>
        <Markdown color={COLORS.NEUTRAL.WARM["800"]}>{content}</Markdown>
      </TextContainer>
    </Container>
  );
};
