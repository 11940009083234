import { UseQueryResult, useQuery } from "react-query";

import { IApiHookOptions, ImpressionedProductVerticalCreditCard, QUERY_KEYS } from "@bwll/bw-types";
import { generateQueryKey, getImpressionedCreditCard, queryBehavior } from "@bwll/bw-utils";

import { useSessionContext } from "../../../contexts";
import { useCreditScoreRequest } from "../../member";
import { CreditCardsResponseData, useGetCreditCards } from "./useGetCreditCards";

type ImpressionedCreditCardsResponse = {
  productVerticalCreditCards: ImpressionedProductVerticalCreditCard[];
};

const useGetImpressionedCreditCards = <TTransformReturn = ImpressionedCreditCardsResponse>(
  creditCardsResponse: CreditCardsResponseData,
  individualClientIdReferenceNumber: number,
  options: IApiHookOptions<ImpressionedCreditCardsResponse, TTransformReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { accessToken, expiresIn } = userData;

  const queryKey = generateQueryKey(QUERY_KEYS.GET_IMPRESSIONED_CREDIT_CARDS, accessToken);

  const queryFn = () => ({
    productVerticalCreditCards: creditCardsResponse?.productVerticalCreditCards.map((creditCard) =>
      getImpressionedCreditCard(creditCard, individualClientIdReferenceNumber),
    ),
  });

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
};

export const useImpressionedCreditCards = <TTransformReturn = ImpressionedCreditCardsResponse>(
  options: IApiHookOptions<ImpressionedCreditCardsResponse, TTransformReturn> = {},
) => {
  const {
    data: creditScoreRequest,
    isLoading: isCreditScoreRequestLoading,
    isError: isCreditScoreRequestError,
  } = useCreditScoreRequest();

  const {
    data: creditCardsResponse,
    isLoading: isCreditCardsLoading,
    isError: isCreditCardsError,
  } = useGetCreditCards();

  const result = useGetImpressionedCreditCards(
    creditCardsResponse || { productVerticalCreditCards: [] },
    creditScoreRequest?.individualClientIdReferenceNumber || 0,
    {
      ...options,
      enabled: Boolean(creditCardsResponse) && Boolean(creditScoreRequest),
    },
  );

  return {
    ...result,
    isLoading: isCreditCardsLoading || isCreditScoreRequestLoading,
    isError: isCreditCardsError || isCreditScoreRequestError,
  } as UseQueryResult<TTransformReturn, unknown>;
};
