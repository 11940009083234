export const edit = {
  headings: {
    edit: "Edit your personal details",
    identity: "Identity",
    contact: "Contact",
    address: "Home address",
  },
  form: {
    labels: {
      fullName: "Full name",
      dateOfBirth: "Date of birth",
      phoneNumber: "Phone number",
      emailAddress: "Email address",
      streetAddress1: "Street address",
      streetAddress2: "Unit number (Optional)",
      city: "City",
      region: "Province/Territory",
      postalCode: "Postal code",
    },
    validationMessages: {
      phoneNumberRequired: "Phone number is required",
      phoneNumberPattern: "Invalid phone number",
      emailRequired: "Email address is required",
      emailPattern: "Invalid email address",
      streetAddressRequired: "Street address is required",
      streetAddressPattern: "Invalid street address",
      streetAddressMaxLength: "Street address must be fewer than 50 characters",
      unitPattern: "Invalid unit number",
      unitMaxLength: "Unit number must be fewer than 50 characters",
      cityRequired: "City is required",
      cityPattern: "Invalid city name",
      cityMaxLength: "City name must be fewer than 50 characters",
      postalCodeRequired: "Postal code is required",
      postalCodePattern: "Invalid postal code",
    }
  },
  buttons: {
    updateProfile: "Update Profile",
    discardChanges: "Discard changes",
  },
};
