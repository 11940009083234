export const checkoutSummaryPage = {
  edit: "Edit",
  month: "month",
  title: "Summary",
  button: "CHECKOUT WITH STRIPE",
  change: "Change",
  changePeriod: "Change",
  changeTransactions: "Select different transactions",
  startingOn: "Starting ",
  rentalAddressTitle: "Rental address",
  termsAndConditions: "Rent Advantage Terms & Conditions",
  checkAgreementPart1: "I understand and agree to the",
  checkAgreementPart2:
    "and provide consent for Borrowell to report my rent payments and missed rent payments to credit reporting agencies.",
  plusApplicableTaxes: "Plus applicable taxes",
  selectedTransactionTitle: "Selected transaction",
  selectedTransactionsTitle: "Amount to be reported",
  subscriptionSummaryTitle: "Subscription",
  termsAndConditionsCheckBoxMd:
    "I understand and agree to the [__Rent Advantage Terms & Conditions__]() and provide consent for Borrowell to report my rent payments and missed rent payments to credit reporting agencies.",
  paymentPeriodTitle: "Payment period",
  paymentPeriodDescriptionCurrentPeriod:
    "The transactions you selected are to pay rent for **{{paymentPeriod}}**.",
  paymentPeriodDescriptionNonCurrentPeriod:
    "The transactions you selected were to pay rent for **{{paymentPeriod}}**.",
  selectedPaymentPeriodDescriptionCurrentPeriod:
    "The transactions you selected are to pay rent for **{{selectedPaymentPeriod}}**.",
  selectedPaymentPeriodDescriptionNonCurrentPeriod:
    "The transactions you selected were to pay rent for **{{selectedPaymentPeriod}}**.",
  updateRentPeriod: "Update rent period",
  updateRentPeriodDescription: "Select the month that you paid rent for with the transactions you selected.",
  // Non-markdown usage
  paymentPeriodDescriptionFuture: "The transactions you selected are to pay rent for {{paymentPeriod}}.",
  paymentPeriodDescriptionPresent: "The transactions you selected were to pay rent for {{paymentPeriod}}.",
  changePaymentPeriod: {
    title: "Change payment period",
    description:
      "Select the month that you paid rent for with the transactions you selected. You can only report your rent for the followng months.",
  },
  saveChangesButton: "SAVE CHANGES",
  totalAmountLabel: "Total amount",
  amountTooltipTitle: "What does this mean?",
  amountTooltipContent:
    "This is the amount we’ll report to Equifax Canada based on the transactions you selected.",
  pastRentPaymentSummaryTitle: "Payment details",
  pastRentPaymentsProductName: "Past Rent Reporting",
  pastRentPaymentCost: "one-time payment",
};
