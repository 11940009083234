/**
 * Represents an enum of "origin" Analytics attribute values in the Marketplace app.
 */
export const MARKETPLACE_ANALYTICS_ORIGINS = {
  BANK_ACCOUNTS_DETAILS: "bank_accounts_details",
  BANK_ACCOUNTS_LIST: "bank_accounts_list",
  CREDIT_CARDS_CATEGORIES: "credit_cards_categories",
  CREDIT_CARDS_LIST: "credit_cards_list",
  CREDIT_CARDS_DETAILS: "credit_cards_details",
  CREDIT_CARDS_COMPARE: "credit_cards_compare",
} as const;

/**
 * Represents possible values of {@link MARKETPLACE_ANALYTICS_ORIGINS}.
 */
export type MarketplaceAnalyticsOrigin =
  (typeof MARKETPLACE_ANALYTICS_ORIGINS)[keyof typeof MARKETPLACE_ANALYTICS_ORIGINS];
