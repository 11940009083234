import { Platform } from "react-native";

import { BankAccount } from "@bwll/bw-types";

export const WEB_CONTENT_MAX_WIDTH = 560;
export const MX_CONNECTION_SYNC_DELAY_MS = 3000;

export const BANK_ACCOUNTS_TEST_IDS = {
  SUBMIT: Platform.select({ web: "selectAccount-button", default: "select-account-button" }),
  CHANGE_LINK: Platform.select({ web: "change-account-selection-button", default: "change-button" }),
  SETTINGS_ICON: Platform.select({ web: "settings-button", default: "rr-onboarding-settings-icon" }),
  INSTITUTION_IMAGE: "institution-image",
  INSTITUTION_NAME: "institution-name",
  ACCOUNTS_SECTION: "accounts-section",
  ACCOUNT_ITEM: (type: BankAccount["type"], index: number) => `ACT-${type}-${index}`,
};
