import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

const treatments = {
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE].OFF]: {
    showGuaranteeCTA: false,
    showGuaranteeDisclaimer: false,
    showGuaranteeFilter: false,
    showGuaranteeIntermediaryScreen: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE].V1]: {
    showGuaranteeCTA: false,
    showGuaranteeDisclaimer: false,
    showGuaranteeFilter: false,
    showGuaranteeIntermediaryScreen: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE].V2]: {
    showGuaranteeCTA: true,
    showGuaranteeDisclaimer: true,
    showGuaranteeFilter: true,
    showGuaranteeIntermediaryScreen: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE].V3]: {
    showGuaranteeCTA: true,
    showGuaranteeDisclaimer: true,
    showGuaranteeFilter: true,
    showGuaranteeIntermediaryScreen: true,
  },
};

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceBorrowellGuarantee".
 */
export const useMarketplaceBorrowellGuaranteeExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE]?.treatment ??
      "off";

    return (
      treatments[treatment] ??
      treatments[WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE].OFF]
    );
  }, [experimentsMap.userConfig]);
};
