import { useEnvironmentContext } from "@bwll/bw-hooks";

import { HistoricalReportingDashboard as HistoricalReportingDashboardUI } from "./HistoricalReportingDashboard.component";
import { HistoricalReportingDashboardProps } from "./HistoricalReportingDashboard.types";

export const HistoricalReportingDashboardContainer = (props: HistoricalReportingDashboardProps) => {
  const { envConfigs } = useEnvironmentContext();

  // TODOS: analytics here (ticket: https://borrowell.atlassian.net/browse/RR-1714)
  return (
    <>
      <HistoricalReportingDashboardUI {...props} />
    </>
  );
};
