import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceTrending".
 * https://app.split.io/org/99385f60-da53-11e9-b7b8-0e6b6ef4c55e/ws/b0e53290-2e55-11ea-9413-12f2f63694e5/splits/5a8df230-5a45-11ef-b61b-9efc8e722b38/env/b0e72e60-2e55-11ea-9413-12f2f63694e5/definition
 */
export const useMarketplaceTrendingExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_TRENDING]?.treatment ?? "off";

    return { showTrending: treatment === "on" };
  }, [experimentsMap.userConfig]);
};
