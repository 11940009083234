import React, { useCallback, useEffect, useState } from "react";

import { COLORS } from "@bwll/bw-styles";

import { StyledPillContainer, StyledPillText } from "./Pill.styles";
import { PillProps } from "./Pill.types";

/**
 * Component for showing the Pill
 *
 * @param {string} label - The text displayed in the Pill.
 * @param {boolean} isActive - Determines if the Pill is active or not.
 * @param {function} onPress - The function to be called when the Pill is pressed.
 */
export const Pill = ({ label, isActive = false, onPress, testID }: PillProps) => {
  const [active, setActive] = useState(isActive);
  const [isPressedOrHovered, setPressOrHover] = useState(false);

  const handlePress = () => {
    setActive((currentValue) => !currentValue);
    onPress();
  };

  const handleOn = useCallback(() => {
    if (!active) {
      setPressOrHover(true);
    }
  }, [active]);

  const handleOff = useCallback(() => {
    if (!active) {
      setPressOrHover(false);
    }
  }, [active]);

  const androidRippleConfig = { color: COLORS.NEUTRAL.COOL["100"] };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <StyledPillContainer
      onPress={handlePress}
      onPressIn={handleOn}
      onPressOut={handleOff}
      onHoverIn={handleOn}
      onHoverOut={handleOff}
      isActive={active}
      isPressedOrHovered={isPressedOrHovered}
      android_ripple={androidRippleConfig}
      testID={testID}
    >
      <StyledPillText isActive={active}>{label}</StyledPillText>
    </StyledPillContainer>
  );
};
