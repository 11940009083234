import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { useNavigate } from "react-router-dom";

import { Heading2, Icon } from "@bwll/bw-components/next";
import {
  CreditCardCategoryContext,
  CreditCardFiltersProvider,
  useBreakpoints,
  useCreditCardComparison,
  useCreditCardComparisonAnalytics,
  useCreditCardFilters,
  useCreditCardFiltersExperiment,
  useCreditCardProductListAnalytics,
  useImpressionedCreditCards,
} from "@bwll/bw-hooks";
import {
  ConfirmationModal,
  CreditCardFiltersSheet,
  CreditCardProductCard,
  CreditCardSortOptionKey,
  CreditCardsCategoryHeader,
  LoadingSkeleton,
  MARKETPLACE_ERROR_SCREEN_ERROR_TYPES,
  MarketplaceErrorScreen,
  ProductSortDropdown,
  ScreenHeader,
  creditCardSorters,
} from "@bwll/bw-modules";
import { COLORS, fontSize } from "@bwll/bw-styles";
import {
  CREDIT_CARD_CATEGORY_KEYS,
  ImpressionedProductVerticalCreditCard,
  MARKETPLACE_ANALYTICS_ORIGINS,
} from "@bwll/bw-types";
import { generateTo, getCreditCardsByCategory, sortCreditCardsByPreSelectedOrder } from "@bwll/bw-utils";

import { CreditCardTrendingTipCard } from "../CreditCardTrendingTipCard";
import { CREDIT_CARD_PRODUCT_LIST_TEST_IDS as TEST_IDS } from "./CreditCardProductList.constants";
import { useCreditCardProductListLoaderData } from "./CreditCardProductList.loader";
import * as Styled from "./CreditCardProductList.styles";

import { ScrollTrigger } from "@app/components/ScrollTrigger";
import {
  useMarketplaceProductApplication,
  useMarketplaceSearchParams,
  useMarketplaceTrackEvent,
  useTrendingExperimentRedirect,
} from "@app/hooks";
import { ROUTES } from "@app/router";

const originSearchParams = {
  origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_LIST,
};

const CARD_WIDTH = "100%";
const CARD_HEIGHT = 460;

/** Renders a category header for CreditCardProductList, if applicable */
const CreditCardProductListHeader = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const categoryKey = useContext(CreditCardCategoryContext);
  const navigate = useNavigate();

  const headerTitle = t("marketplace:creditCards:title");
  const breadcrumbItems = useMemo(
    () =>
      categoryKey !== CREDIT_CARD_CATEGORY_KEYS.ALL
        ? [
            {
              label: t("marketplace:creditCards:title"),
              onPress: () =>
                navigate(
                  generateTo(ROUTES.CREDIT_CARDS, {
                    searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_LIST },
                  }),
                ),
              testID: TEST_IDS.BREADCRUMB_CREDIT_CARDS,
            },
            {
              label: t(`marketplace:creditCards:categories:${categoryKey}:title`),
              testID: TEST_IDS.BREADCRUMB_CATEGORY,
            },
          ]
        : undefined,
    [categoryKey, navigate, t],
  );

  return !isMobile ? (
    <ScreenHeader
      title={
        categoryKey === CREDIT_CARD_CATEGORY_KEYS.ALL ? (
          headerTitle
        ) : (
          <CreditCardsCategoryHeader categoryKey={categoryKey} isScreenHeader />
        )
      }
      isAdDisclosureHidden={false}
      breadcrumbItems={breadcrumbItems}
    />
  ) : null;
};

const CreditCardProductListInternal = () => {
  useTrendingExperimentRedirect();

  const i18next = useTranslation();
  const { sorter } = useCreditCardProductListLoaderData();
  const categoryKey = useContext(CreditCardCategoryContext);
  const navigate = useNavigate();

  const { isMobile, isTablet } = useBreakpoints();
  const isSmallScreen = isMobile || isTablet;

  // Sorting
  const { setSortBy } = useMarketplaceSearchParams();

  const [{ key: sortKey, sortFn }, setSorter] = useState(sorter);
  const sortOptions = useMemo(
    () =>
      Object.values(creditCardSorters).map(({ key, localeKey, shortLocaleKey }) => ({
        value: key,
        label: i18next.t(localeKey),
        shortLabel: i18next.t(shortLocaleKey),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleSortChange = useCallback(
    (sortOptionKey: CreditCardSortOptionKey) => {
      setSorter(creditCardSorters[sortOptionKey]);
      setSortBy(sortOptionKey);
    },
    [setSortBy],
  );

  // Filtering
  const { showFilters } = useCreditCardFiltersExperiment();
  const { filters, sendFilterSelected } = useCreditCardFilters();
  const [filteredCardCount, setFilteredCardCount] = useState<number | undefined>(undefined);

  // Comparing
  const { addCreditCard, removeCreditCard, clear, comparison } = useCreditCardComparison();

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  // Need for navigating from modal to details page
  const [selectedCard, setSelectedCard] = useState<ImpressionedProductVerticalCreditCard | undefined>(
    undefined,
  );

  const handleOnModalConfirm = useCallback(() => {
    if (selectedCard) {
      clear();
      navigate(
        generateTo(ROUTES.CREDIT_CARDS_DETAILS, {
          pathParams: { productId: selectedCard.id },
          searchParams: originSearchParams,
        }),
      );
    }
  }, [clear, navigate, selectedCard]);

  const handleOnModalClose = useCallback(() => setIsConfirmationModalVisible(false), []);

  // Data fetching
  const {
    data,
    isLoading,
    isError,
    refetch: refetchImpressionIds,
  } = useImpressionedCreditCards({
    transform: ({ productVerticalCreditCards }) => {
      const filteredCards =
        categoryKey !== CREDIT_CARD_CATEGORY_KEYS.ALL
          ? getCreditCardsByCategory(categoryKey, productVerticalCreditCards)
          : productVerticalCreditCards;
      const moreFilteredCards = filteredCards.filter(filters.predicate);
      const sortedCards = sortFn(moreFilteredCards);
      const specialOffer = sortCreditCardsByPreSelectedOrder(
        productVerticalCreditCards.filter((card) => card.isEligibleForSpecialOffer),
      )[0];
      return {
        cards: sortedCards,
        specialOffer,
      };
    },
  });

  useEffect(() => {
    if (data) setFilteredCardCount(data.cards.length);
  }, [data]);

  // Analytics
  const trackEvent = useMarketplaceTrackEvent();
  const {
    trackProductViewed,
    trackProductClicked,
    trackTabSwitched,
    trackFilterSelected,
    trackFilterButtonClicked,
    trackButtonClicked,
  } = useCreditCardProductListAnalytics(trackEvent, categoryKey);

  const { trackCompareCardAdded } = useCreditCardComparisonAnalytics(trackEvent);

  const handleProductViewed = useCallback(
    (item: ImpressionedProductVerticalCreditCard, index: number) => {
      trackProductViewed(item, index, sortKey, Array.from(filters.filterers));
    },
    [filters.filterers, sortKey, trackProductViewed],
  );

  useEffect(() => {
    const lastAction = filters.actions[filters.actions.length - 1];

    if (data && lastAction.type === "added") {
      const count = data.cards.length;
      trackFilterSelected(
        lastAction.filterer.category,
        lastAction.filterer.key,
        Array.from(filters.filterers),
        filteredCardCount ?? count,
        count,
      );
      sendFilterSelected();
    }
  }, [data, filteredCardCount, filters.actions, filters.filterers, sendFilterSelected, trackFilterSelected]);

  // Event handling
  const productApply = useMarketplaceProductApplication();

  const handleApplyPress = useCallback(
    async (item: ImpressionedProductVerticalCreditCard, index: number) => {
      await productApply(item, () =>
        trackProductClicked(item, index, sortKey, Array.from(filters.filterers)),
      );
    },
    [filters.filterers, productApply, sortKey, trackProductClicked],
  );

  const handleDetailsPress = useCallback(
    (item: ImpressionedProductVerticalCreditCard) => {
      const to = generateTo(ROUTES.CREDIT_CARDS_DETAILS, {
        pathParams: { productId: item.id },
        searchParams: originSearchParams,
      });

      trackButtonClicked(item, to.pathname);

      if (comparison.cards.length > 0) {
        setIsConfirmationModalVisible(true);
        setSelectedCard(item);
      } else {
        navigate(to);
      }
    },
    [navigate, comparison, trackButtonClicked],
  );

  const handleOnAddComparePress = useCallback(
    (item: ImpressionedProductVerticalCreditCard) => {
      addCreditCard(item);
      trackCompareCardAdded(item);
    },
    [addCreditCard, trackCompareCardAdded],
  );

  /**
   * For analytics purposes, we need to refetch the impression ids when users go back to this screen
   * or when the screen is reloaded.
   * (This is to ensure we can compare the performance between Angular web.
   * We should remove this once we are finished with the comparison)
   */
  useEffect(() => {
    if (isLoading || isError) return;

    refetchImpressionIds();
  }, [refetchImpressionIds, isLoading, isError]);

  const customCtaCopy = i18next.t("misc:common:viewProduct");

  const sortComponent = useMemo(
    () => (
      <Styled.SortAndFilterContainer>
        <Styled.SortContainer>
          <ProductSortDropdown initialSort={sortKey} options={sortOptions} onValueChange={handleSortChange} />
        </Styled.SortContainer>
      </Styled.SortAndFilterContainer>
    ),
    [handleSortChange, sortKey, sortOptions],
  );

  // Markup - Infinite scroll
  const [infiniteScrollCardLimit, setInfiniteScrollCardLimit] = useState(5);
  const onInfiniteScrollUpdate = useCallback(
    () => setInfiniteScrollCardLimit((currentCardLimit) => currentCardLimit + 5),
    [],
  );

  if (isError) {
    return (
      <Styled.ProductListContainer>
        <CreditCardProductListHeader />
        <MarketplaceErrorScreen errorType="default" />
      </Styled.ProductListContainer>
    );
  }

  return (
    <Styled.ScreenContainer>
      {showFilters && !isSmallScreen && <Styled.PanelSpacer />}
      <Styled.ProductListContainer>
        <CreditCardProductListHeader />
        {showFilters ? (
          <CreditCardFiltersSheet
            cardCount={data?.cards.length ?? 0}
            initialSort={sortKey}
            onSortChanged={(s) => {
              setSorter(s);
              setSortBy(s.key);
            }}
            trackFilterButtonClicked={trackFilterButtonClicked}
            testID={TEST_IDS.FILTER_SHEET_PANEL}
          />
        ) : (
          sortComponent
        )}
        {isLoading || data?.cards === undefined ? (
          <LoadingSkeleton testID={TEST_IDS.SKELETON} cardHeight={CARD_HEIGHT} cardWidth={CARD_WIDTH} />
        ) : !isLoading && data?.cards.length ? (
          <>
            <CreditCardTrendingTipCard />
            {data.specialOffer && filters.filterers.size === 0 && (
              <>
                <Styled.SpecialOfferContainer>
                  <Styled.SpecialOfferHeading>
                    <Icon icon="flag_thin" size={fontSize.xl} />
                    <Heading2>{i18next.t("productCatalog:creditCards:specialOfferCard:title")}</Heading2>
                  </Styled.SpecialOfferHeading>
                  <CreditCardProductCard<ImpressionedProductVerticalCreditCard>
                    product={data.specialOffer}
                    index={0}
                    onApplyPress={handleApplyPress}
                    onDetailsPress={handleDetailsPress}
                    onAddComparePress={handleOnAddComparePress}
                    onRemoveComparePress={({ id }) => removeCreditCard(id)}
                    onProductViewed={handleProductViewed}
                    onTabSwitched={trackTabSwitched}
                    customCtaCopy={customCtaCopy}
                    hideIcon={true}
                    flex
                  />
                </Styled.SpecialOfferContainer>
                <Styled.OtherOffersHeading>
                  {i18next.t("productCatalog:creditCards:specialOfferCard:otherOffers")}
                </Styled.OtherOffersHeading>
              </>
            )}
            {data.cards.slice(0, infiniteScrollCardLimit).map((creditCard, index) => (
              <View key={creditCard.id}>
                <CreditCardProductCard
                  product={creditCard}
                  index={index}
                  onApplyPress={handleApplyPress}
                  onDetailsPress={handleDetailsPress}
                  onAddComparePress={handleOnAddComparePress}
                  onRemoveComparePress={({ id }) => removeCreditCard(id)}
                  onProductViewed={handleProductViewed}
                  onTabSwitched={trackTabSwitched}
                  customCtaCopy={customCtaCopy}
                  hideIcon={true}
                  flex
                />
              </View>
            ))}
            {infiniteScrollCardLimit < data.cards.length && (
              <>
                <ScrollTrigger onVisible={onInfiniteScrollUpdate} />
                <ActivityIndicator size="large" color={COLORS.NEUTRAL.COOL["600"]} />
              </>
            )}
          </>
        ) : (
          <MarketplaceErrorScreen errorType={MARKETPLACE_ERROR_SCREEN_ERROR_TYPES.EMPTY_CREDIT_CARDS} />
        )}
      </Styled.ProductListContainer>
      <ConfirmationModal
        modalText={i18next.t("marketplace:creditCards:compare:modal:onProceed")}
        ctaText={i18next.t("marketplace:creditCards:compare:proceed")}
        onCancelText={i18next.t("marketplace:creditCards:compare:cancel")}
        visible={isConfirmationModalVisible}
        onConfirm={handleOnModalConfirm}
        onCancel={handleOnModalClose}
      />
    </Styled.ScreenContainer>
  );
};

export const CreditCardProductList = () => {
  const { categoryKey } = useCreditCardProductListLoaderData();

  return (
    <CreditCardFiltersProvider>
      <CreditCardCategoryContext.Provider value={categoryKey}>
        <CreditCardProductListInternal />
      </CreditCardCategoryContext.Provider>
    </CreditCardFiltersProvider>
  );
};
