import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Pill } from "@bwll/bw-components/next";
import { BankAccountFilterer, bankAccountFilterers } from "@bwll/bw-utils";

import { BANK_ACCOUNT_FILTER_PILL_TEST_IDS as TEST_IDS } from "./BankAccountFilterPills.constants";
import * as Styled from "./BankAccountFilterPills.styles";
import { BankAccountFilterPillsProps } from "./BankAccountFilterPills.types";

const toggleFilter = (filterMap: Map<BankAccountFilterer, boolean>, filterer: BankAccountFilterer) =>
  filterMap.set(filterer, !filterMap.get(filterer));

const collectActiveFilters = (filterMap: Map<BankAccountFilterer, boolean>) =>
  Array.from(filterMap.entries())
    .filter(([_, isActive]) => isActive)
    .map(([filterer]) => filterer);

/**
 * Toggleable pills that, when clicked, raise the `onFilterChanged` event with a list of the active filters for filtering Bank Accounts.
 *
 * @component
 * @example
 * return (
 * <BankAccountFilterPills
 *   onFilterChanged={(filters) => setFilters(filters)}
 * />
 * )
 *
 * @param {Array} [initialFilters] - An array of {@link BankAccountFilterOption} that pre-set pills as active.
 * @param {function} [onFilterChanged] - Callback that is called when a pill is toggled (clicked).
 */
export const BankAccountFilterPills = ({
  initialFilters = [],
  onFilterChanged,
}: BankAccountFilterPillsProps) => {
  const i18next = useTranslation();

  const [filters, setFilters] = useState(
    new Map([
      [bankAccountFilterers.Chequing, initialFilters.includes(bankAccountFilterers.Chequing.key)],
      [bankAccountFilterers.Savings, initialFilters.includes(bankAccountFilterers.Savings.key)],
    ]),
  );

  const onPress = useCallback(
    (filterer: BankAccountFilterer) => () => {
      const newFilters = toggleFilter(filters, filterer);
      setFilters(newFilters);
      onFilterChanged(collectActiveFilters(newFilters));
    },
    [filters, onFilterChanged],
  );

  return (
    <Styled.FilterPillsContainer>
      <Pill
        testID={TEST_IDS.CHEQUING_PILL}
        label={i18next.t(bankAccountFilterers.Chequing.localeKey)}
        isActive={initialFilters.includes(bankAccountFilterers.Chequing.key)}
        onPress={onPress(bankAccountFilterers.Chequing)}
      />
      <Pill
        testID={TEST_IDS.SAVINGS_PILL}
        label={i18next.t(bankAccountFilterers.Savings.localeKey)}
        isActive={initialFilters.includes(bankAccountFilterers.Savings.key)}
        onPress={onPress(bankAccountFilterers.Savings)}
      />
    </Styled.FilterPillsContainer>
  );
};
