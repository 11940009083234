import { matchRoutes, useLocation } from "react-router-dom";

import { AppShellRouteProps } from "./AppShell.types";

import { marketplaceRoutes } from "@app/router/getAppRoutes";

export const useAppShellProps = (): Required<AppShellRouteProps>["appShell"] => {
  const location = useLocation();
  const match = matchRoutes(marketplaceRoutes, location)
    ?.reverse()
    .find((m) => Boolean(m.route.appShell));

  return (
    match?.route?.appShell ?? {
      showNavbar: true,
    }
  );
};
