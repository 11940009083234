import { Fragment } from "react";

import { BREADCRUMB_ITEM_SEPARATOR } from "./Breadcrumbs.constants";
import { BreadcrumbSeparator, BreadcrumbsContainer } from "./Breadcrumbs.styles";
import { BreadcrumbsProps } from "./Breadcrumbs.types";
import { BreadcrumbItem } from "./components/BreadcrumbItem";

/**
 * Component for showing the Breadcrumbs
 *
 * @param {BreadcrumbItemProps[]} items - List of breadcrumb items to be shown.
 */
export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <BreadcrumbsContainer>
      {items.map(({ label, linkTo, onPress, testID }, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <Fragment key={testID}>
            <BreadcrumbItem label={label} linkTo={linkTo} onPress={onPress} testID={testID} />
            {!isLastItem && <BreadcrumbSeparator>{BREADCRUMB_ITEM_SEPARATOR}</BreadcrumbSeparator>}
          </Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
};
