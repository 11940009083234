import { Nullable } from "@bwll/bw-types";

/**
 * Formats a decimal as a percentage.
 * @example
 * formatPercentage(0.5); // "50.00%"
 * formatPercentage(23.45, "fr-CA"); // "2 345,00 %"
 * @param {number} decimal - The number to format as a percentage.
 * @param {string} locale - The current locale. Defaults to "en". Not supported on Android.
 * @returns {string} The percentage representation of decimal.
 */
export const formatPercentage = (
  decimal: Nullable<number>,
  locale = "en",
  intlNotSupported = false,
): string => {
  if (Number.isNaN(decimal) || decimal === null) return "N/A";

  if (intlNotSupported) {
    // TODO: Intl is not supported on Android due to an outdated JSC version
    return (decimal * 100).toFixed(2) + "%";
  }
  return decimal.toLocaleString(locale, { style: "percent", minimumFractionDigits: 2 });
};
