/**
 * The set of utils for internal usage in the DateTime module
 */
import { DateTimeUnit } from "../types";

const mutableResetBySecond = (date: Date, included = false): Date => {
  date.setMilliseconds(0);
  if (included) {
    date.setSeconds(0);
  }
  return date;
};

const mutableResetByMinute = (date: Date, included = false): Date => {
  date.setSeconds(0);
  if (included) {
    date.setMinutes(0);
  }
  return mutableResetBySecond(date, included);
};

const mutableResetByHour = (date: Date, included = false): Date => {
  date.setMinutes(0);
  if (included) {
    date.setHours(0);
  }
  return mutableResetByMinute(mutableResetBySecond(date, included));
};

const mutableResetByDate = (date: Date, included = false): Date => {
  date.setHours(0);
  if (included) {
    date.setDate(1);
  }
  return mutableResetByHour(mutableResetByMinute(mutableResetBySecond(date, included)));
};

const mutableResetByMonth = (date: Date, included = false): Date => {
  date.setDate(1);
  if (included) {
    date.setMonth(0);
  }
  return mutableResetByDate(mutableResetByHour(mutableResetByMinute(mutableResetBySecond(date, included))));
};

const mutableResetByYear = (date: Date, included = false): Date => {
  date.setMonth(0);
  return mutableResetByMonth(
    mutableResetByDate(mutableResetByHour(mutableResetByMinute(mutableResetBySecond(date, included)))),
  );
};

export const mutateByDateTimeUnit = (date: Date, unit: DateTimeUnit, included = false): void => {
  if (unit === "second") {
    mutableResetBySecond(date, included);
  } else if (unit === "minute") {
    mutableResetByMinute(date, included);
  } else if (unit === "hour") {
    mutableResetByHour(date, included);
  } else if (unit === "day") {
    mutableResetByDate(date, included);
  } else if (unit === "month") {
    mutableResetByMonth(date, included);
  } else if (unit === "year") {
    mutableResetByYear(date, included);
  }
};
