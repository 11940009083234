import { Platform } from "react-native";

import { SvgImageKey } from "@bwll/bw-components/next";

export const WEB_CONTENT_MAX_WIDTH = 560;

export const RENT_SELECTION_METHODS_TEST_IDS = {
  BANK_CONNECTION_BUTTON: Platform.select({ web: "connect-bank-link", default: "bank-connection-button" }),
  MANUAL_UPLOAD_BUTTON: Platform.select({ web: "add-manually-link", default: "manual-upload-button" }),
  SETTINGS_ICON: Platform.select({ web: "settings-button", default: "rr-onboarding-settings-icon" }),
};

export const ILLUSTRATION_KEY: SvgImageKey = "ra-rent-selection-methods";
