import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  gap: ${styledValue(spacing.xxs)};
`;

export const ExpandableContainer = styled.View<{ isExpanded: boolean; showBorder: boolean; $height: number }>(
  ({ isExpanded, $height, showBorder }) => ({
    ...(!isExpanded && {
      maxHeight: $height,
      ...(showBorder && { borderBottomColor: COLORS.NEUTRAL.COOL["100"], borderBottomWidth: 1 }),
    }),
    ...(Platform.OS !== "web" && { flex: 1 }),
    overflow: "hidden",
  }),
);

export const ShowMoreLabel = styled.View`
  display: flex;
  flex-direction: row;
  gap: ${styledValue(spacing.xxs)};
`;
