import { useExperiments } from "@bwll/bw-hooks";
import { GEMSTONE_APP_ROUTES, WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

/**
 * Hook that redirects the member to Gemstone Web if they are not in the `sspaMarketplaceBankAccounts` Split flag experiment.
 */
export const useBankAccountExperimentRedirect = () => {
  const url = new URL(`#${GEMSTONE_APP_ROUTES.BANK_ACCOUNTS}`, ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL);
  const isSspaMarketplaceBankAccountsOn = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS,
    treatments: [
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V2,
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V3,
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V4,
    ],
  });

  if (!isSspaMarketplaceBankAccountsOn) window.location.replace(url.href);
};
