import React from "react";
import { useNavigate } from "react-router-dom";

import { useBreakpoints } from "@bwll/bw-hooks";
import { ProfileEditForm } from "@bwll/bw-modules";

import { useProfileEditLoaderData } from "./ProfileEdit.loader";
import * as Styled from "./ProfileEdit.styles";

import { useQuickApplyExperimentRedirect } from "@app/hooks";
import { ROUTES } from "@app/router";

/**
 * Renders the ProfileEdit component on Marketplace Web.
 *
 * @returns The rendered ProfileEdit component.
 */
export const ProfileEdit: React.FC = () => {
  useQuickApplyExperimentRedirect();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  const { returnUrl } = useProfileEditLoaderData();

  return (
    <Styled.ResetMargin isMobile={isMobile}>
      <Styled.InnerContainer isMobile={isMobile}>
        <ProfileEditForm
          onNavigateBack={() => navigate(returnUrl || ROUTES.CREDIT_CARDS)}
          // TODO actual form submission to be implemented in GMS-14106
          onSubmit={(formData) => console.log(formData)}
        />
      </Styled.InnerContainer>
    </Styled.ResetMargin>
  );
};
