import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, ProductVerticalCreditCard, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

/**
 * Represents the response data from Product Verticals v3/credit-cards endpoint.
 */
export interface CreditCardsResponseData {
  /**
   * The credit cards to display.
   */
  productVerticalCreditCards: ProductVerticalCreditCard[];
}

/**
 * Represents the standard options provided by IApiHookOptions with the addition of
 * sponsoredCatalogProductCopyExperimentValue, which is required to inform the BE if the user is part of the copy
 * experiment or not.
 */
interface ICreditCardApiHookOptions<TData, TReturn = TData> extends IApiHookOptions<TData, TReturn> {
  sponsoredCatalogProductCopyExperimentValue?: string;
}

enum SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT {
  v1 = 1,
  v2 = 2,
}

/**
 * Translates the value of the sponsoredCatalogProductCopyExperimentValue to the query string value expected by the BE.
 * This value concerns only the credit cards API, if other parts of the app want to use the experiment, they can
 * safely rely on the treatment value from split.
 */
const getSponsoredProductQuery = (
  sponsoredCatalogProductCopyExperimentValue: SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT,
): string => {
  const queryStringValue =
    SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT[sponsoredCatalogProductCopyExperimentValue];

  return `?sponsoredPlacementsNumber=${queryStringValue}`;
};

async function getCreditCards(
  accessToken: string,
  baseUrl: string,
  sponsoredCatalogProductCopyExperimentValue: SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT,
): Promise<CreditCardsResponseData> {
  const sponsoredProductQuery = SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT[
    sponsoredCatalogProductCopyExperimentValue
  ]
    ? getSponsoredProductQuery(sponsoredCatalogProductCopyExperimentValue)
    : "";

  const url = new URL(`/api/product-verticals/products/v3/credit-cards${sponsoredProductQuery}`, baseUrl);

  const response = await axios.get<CreditCardsResponseData>(url.href, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
}

/**
 * Declares a Query to get credit cards from the Product Verticals API.
 * @param options The API request configuration object.
 * @returns A QueryResult with query state and returned data if query is in "success" state or error if query is in "error" state.
 */
export function useGetCreditCards<TTransformReturn = CreditCardsResponseData>(
  options: ICreditCardApiHookOptions<CreditCardsResponseData, TTransformReturn> = {},
) {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();
  const { accessToken, expiresIn } = userData;

  const queryKey = generateQueryKey(QUERY_KEYS.GET_CREDIT_CARDS, accessToken);

  const sponsoredCatalogProductCopyExperimentValue =
    options.sponsoredCatalogProductCopyExperimentValue as unknown as SPONSORED_CATALOG_PRODUCTS_COPY_EXPERIMENT;

  const queryFn = () =>
    getCreditCards(accessToken, envConfigs.API_GATEWAY_URL, sponsoredCatalogProductCopyExperimentValue);

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: Boolean(accessToken) && options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
}
