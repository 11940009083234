import styled, { css } from "@emotion/native";

import { ExpressiveLabel } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ImageContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const OptionContainer = styled.Pressable`
  border: 1px solid ${COLORS.NEUTRAL.COOL["600"]};
  border-radius: ${styledValue(borderRadius.s)};
  padding-vertical: ${styledValue(spacing.m)};
  padding-horizontal: ${styledValue(spacing.xs)};
`;

export const IconsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const OptionLabel = styled(ExpressiveLabel)`
  text-align: center;
  color: ${COLORS.PRIMARY.DEFAULT};
`;

const IMAGE_WIDTH = 353;
const IMAGE_MAX_HEIGHT = 120;

export const imageStyles = css`
  width: ${styledValue(IMAGE_WIDTH)};
  max-height: ${styledValue(IMAGE_MAX_HEIGHT)};
  margin-top: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xl)};
`;
