import styled from "@emotion/native";

import { Heading2 } from "@bwll/bw-components/next";
import { COLORS, WEB_SCREEN_PADDING, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const panelWidth = styledValue(350);

export const ScreenContainer = styled.View`
  flex-direction: row;
  z-index: auto;
`;

export const PanelSpacer = styled.View`
  width: ${panelWidth};
`;

export const ProductListContainer = styled.View`
  gap: ${styledValue(spacing.xs)};
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: auto;
`;

export const SortAndFilterPanelContainer = styled.View`
  position: fixed;
  left: 0;
  width: ${panelWidth};
  height: 100%;
  background-color: ${COLORS.WHITE};
  gap: ${styledValue(spacing.m)};

  padding: ${styledValue(spacing.m)};
  margin-top: ${styledValue(-WEB_SCREEN_PADDING)};
`;

export const SortHeadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
`;

export const SortPanelContainer = styled.View`
  gap: ${styledValue(spacing.xxs)};
  z-index: 1;
`;

export const SortAndFilterContainer = styled.View`
  flex-direction: row;
  gap: ${styledValue(spacing.xs)};
  z-index: 1;
`;

export const SortContainer = styled.View`
  flex: 1 1 auto;
`;

export const SpecialOfferContainer = styled.View`
  gap: ${styledValue(spacing.xxs)};
`;

export const SpecialOfferHeading = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxs)};
`;

export const OtherOffersHeading = styled(Heading2)`
  margin-bottom: ${styledValue(-spacing.xxs)};
`;
