import { ThemeProvider } from "@emotion/react";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";

import { SplashScreen, ToastStackProvider } from "@bwll/bw-components/next";
import { AuthCheck, AuthProvider, PreMemberCheck } from "@bwll/bw-components/next/contexts/authorization";
import { QualifyCheck } from "@bwll/bw-components/next/molecules/QualifyCheck";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import {
  AppInsightsProvider,
  ENV_TYPE,
  EnvironmentProvider,
  ExperimentStoreProvider,
  PlatformContextProvider,
  SessionProvider,
} from "@bwll/bw-hooks";
import { baseTheme } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";

import { ContextProvidersProps } from "./ContextProviders.types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

const environment = ENVIRONMENT_VARIABLES.ENVIRONMENT as ENV_TYPE;

const authConfig = {
  authority: ENVIRONMENT_VARIABLES.IDENTITY_AUTHORITY,
  clientId: ENVIRONMENT_VARIABLES.OIDC_CLIENT,
  redirectUriPath: ENVIRONMENT_VARIABLES.REDIRECT_URI_PATH,
};

export const ContextProviders = ({ children, queryClient }: ContextProvidersProps) => {
  return (
    <AuthProvider authConfig={authConfig} cookiesDomain={ENVIRONMENT_VARIABLES.COOKIES_DOMAIN}>
      <SessionProvider>
        <AuthCheck>
          <PlatformContextProvider platform={PLATFORM.WEB_APP}>
            <EnvironmentProvider environment={environment} queryClient={queryClient}>
              <AppInsightsProvider connectionString={ENVIRONMENT_VARIABLES.APP_INSIGHTS_CONNECTION_STRING}>
                <ThemeProvider theme={baseTheme}>
                  <PreMemberCheck redirectUrl={ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}>
                    <Suspense fallback={<SplashScreen image={borrowellLogoWhite} />}>
                      <ExperimentStoreProvider loadingComponent={<SplashScreen image={borrowellLogoWhite} />}>
                        <QualifyCheck>
                          <HelmetProvider>
                            <ToastStackProvider>{children}</ToastStackProvider>
                          </HelmetProvider>
                        </QualifyCheck>
                      </ExperimentStoreProvider>
                    </Suspense>
                  </PreMemberCheck>
                </ThemeProvider>
              </AppInsightsProvider>
            </EnvironmentProvider>
          </PlatformContextProvider>
        </AuthCheck>
      </SessionProvider>
    </AuthProvider>
  );
};
