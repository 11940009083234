// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-size: 1.66rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  & > .web-tooltip-root {
    height: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,+BAA+B;EAC/B,0BAA0B;EAC1B,8BAA8B;EAC9B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;;EAEhB;IACE,SAAS;EACX;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap\");\n\nhtml {\n  font-size: 62.5%;\n  font-family: \"Lato\", sans-serif;\n  -ms-text-size-adjust: 100%;\n  -webkit-text-size-adjust: 100%;\n  scroll-behavior: smooth;\n  overflow-x: hidden;\n}\n\nbody {\n  margin: 0;\n  font-size: 1.66rem;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow: hidden;\n\n  & > .web-tooltip-root {\n    height: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
