export enum ANALYTICS_ATTRIBUTES {
  // base
  ID = "ID",
  PLATFORM = "platform",
  LAYOUT_TYPE = "layoutType",
  // Adding this based on this ticket: https://borrowell.atlassian.net/browse/GMS-13543
  // It should cover for the release of Redesigned Dashboard V1
  // TODO: Investigate why do we have the same property with different casing(layoutType) and if it is necessary
  GEMSTONE_LAYOUT_TYPE = "layout_type",
  APPLICATION_TYPE = "applicationType",
  VERSION = "version",
  EVENT_LABEL = "eventLabel",
  EVENT_ACTION = "eventAction",
  EVENT_CATEGORY = "eventCategory",
  EVENT_TYPE = "eventType",
  _EVENT_TYPE = "Event_type",
  LANGUAGE = "language",
  SHOULD_EMIT_TO_SEGMENT = "shouldEmitToSegment",
  AUTHENTICATED_SESSION_ID = "authenticatedSessionId",
  SESSION_ID = "sessionId",
  INDIVIDUAL_CLIENT_ID = "individualClientId",
  MEMBER_GUID = "member_guid",
  USER_GUID = "user_guid",
  SESSION_GUID = "session_guid",
  FLOW_NAME = "flowName",
  _FLOW_NAME = "flow_name",
  FLOW_TYPE = "flowType",
  DEFAULT_VALUE = "default_value",

  // step
  CURRENT_STEP_NAME = "currentStepName",
  CURRENT_STEP_NUMBER = "currentStepNumber",
  CURRENT_STEP_NUMBER_GRANULAR = "currentStepNumberGranular",
  NUMBER_OF_STEPS = "numberOfSteps",
  SUCCESS_STEP_NAME = "completedStepName",
  SUCCESS_STEP_NUMBER = "completedStepNumber",
  ONBOARDING_TYPE = "onboardingType",

  // click
  CALL_TO_ACTION = "callToAction",
  EVENT_TIMESTAMP = "eventTimestamp",
  EVENT_TIMESTAMP_ = "event_timestamp",
  // navigation click
  ORIGIN = "origin",
  DESTINATION = "destination",
  DESTINATION_URL = "destination_url",
  // page
  SCREEN_NAME = "screenName",
  // sources id
  REFERRER = "referrer",
  IMPRESSION_ID = "impressionId",
  CLICK_ID = "clickId",
  // button
  BUTTON_NAME = "buttonName",
  // link
  LINK_NAME = "linkName",
  LINK_TEXT = "linkText",
  // checkbox
  CHECKBOX_NAME = "checkboxName",
  // mx
  BANK_NAME = "bankName",
  BANK_ID = "bank_id",
  CONNECTION_STATUS = "connection_status",
  ERROR_TYPE = "error_type",
  // custom
  PAGE = "page",
  PAGE_ROUTE = "pageRoute",
  PREVIOUS_PAGE_ROUTE = "previousPageRoute",
  TRANSACTION_COUNT = "numberOfTransactionsReturned",
  POPUP_CATEGORY = "popup_category",
  POPUP_TEXT_BODY = "popup_text_body",
  POPUP_IMPRESSION_ID = "pop_up_impression_id",
  NAME = "name",
  PLACEMENT = "placement",
  PRODUCT_ID = "product_id",
  POSITION = "position",
  LAYOUT = "layout",
  IMAGE = "image",
  PROMO_TEXT_HEADER = "promo_text_header",
  PROMO_TEXT_BODY = "promo_text_body",
  PROMO_CATEGORY = "promo_category",
  PROMO_CARD_NAME = "promo_card_name",
  PROMO_IMAGE = "promo_image",
  CARD_RANK = "card_rank",
  PROMO_IMPRESSION_ID = "promocardImpressionId",
  MX_FLOW_NAME = "MX_flow_name",
  MX_INITIAL_CONNECTION = "Initial connection",
  MX_RECONNECTION = "Reconnection",
  CONTENT_TITLE = "content_title",
  CONTENT_LINK_URL = "content_link_url",
  CONTENT_POSITION = "content_position",
  CONTENT_TYPE = "content_type",
  CARD_LOCATION = "card_location",
  CARD_POSITION = "card_position",
  PRODUCT_VERTICAL = "product_vertical",
  TRANSACTION_MATCHES_CRITERIA = "transaction_matches_criteria",
  VALIDATION_ERRORS = "validation_errors",
  // Top Picks
  BUTTON_TEXT = "button_text",
  // Snackbar
  SNACKBAR_TEXT = "snackbar_text",
  SNACKBAR_TYPE = "snackbar_type",
  SNACKBAR_IMPRESSION_ID = "snackbar_impression_id",
  IS_REDESIGNED_DASHBOARD = "is_redesigned_dashboard",
  SNACKBAR_EXPANDED_STATE = "is_expanded",
  SNACKBAR_DESTINATION_URL = "destinationUrl",
  SNACKBAR_BUTTON_TEXT = "buttonText",
  SNACKBAR_BODY = "body",
  // Product catalog
  BUTTON_CONTEXT = "button_context",
  _BUTTON_NAME = "button_name",
  _CALL_TO_ACTION = "call_to_action",
  CASHBACK = "is_cashback",
  IS_CASH_BACK = "is_cash_back",
  _CLICK_ID = "click_id",
  CTA_TEXT = "cta_text",
  DISPLAY_ORDER = "display_order",
  DISPLAY_ORIENTATION = "display_orientation",
  FEATURES_SHOWN = "is_features_shown",
  FILTER_BY_VALUE = "filter_by_value",
  _IMPRESSION_ID = "impression_id",
  LEGAL_DETAILS_EXPANDED = "is_legal_details_expanded",
  LIMITED_TIME_OFFER = "is_limited_time_offer",
  LIMITED_TIME_OFFER_END_DATE = "limited_time_offer_end_date",
  LIKELIHOOD_OF_APPROVAL = "likelihood_of_approval",
  LIKELIHOOD_OF_APPROVAL_BUCKET = "likelihood_of_approval_bucket",
  LIKELIHOOD_OF_APPROVAL_LABEL = "likelihood_of_approval_label",
  NUMBER_OF_ADDITIONAL_PRODUCTS_SHOWN = "number_of_additional_products_shown",
  NUMBER_OF_PRODUCTS_SHOWN = "number_of_products_showing",
  PREFERRED_ORDER = "preferred_order",
  PREPOPULATION_FLOW = "is_prepopulation_flow",
  PRESELECTED = "is_preselected",
  PRODUCT_CAROUSEL_NAME = "product_carousel_name",
  QUICK_APPLY = "is_quick_apply",
  RANK = "rank",
  RANKING = "ranking",
  SHOWN_IN_CAROUSEL = "is_shown_in_carousel",
  SORT_BY_VALUE = "sort_by_value",
  SPONSORED = "is_sponsored",
  SPONSORED_PLACEMENT = "sponsored_placement_order_property",
  TAB_SWITCHED = "is_tab_switched",
  TRACKING_TAG = "tracking_tag",
  VERTICAL = "vertical",
  SUBVERTICAL = "subvertical",
  TAB_VIEWED = "tab_viewed",
  NUMBER_OF_TABS = "number_of_tabs",
  TAB_TITLE = "tab_title",
  IS_BANNER = "is_banner",
  IS_LIMITED_TIME_OFFER = "is_limited_time_offer",
  BANNER_NAME = "banner_name",
  IS_MINIMUM_BALANCE = "is_minimum_balance",
  HIGHLIGHTED_VALUE = "highlighted_value",
  ACCOUNT_TYPE = "account_type",
  DROPDOWN_CONTEXT = "dropdown_context",
  DROPDOWN_NAME = "dropdown_name",
  OPTION_VALUE = "option_value",
  FILTER_NAME = "filter_name",
  FILTER_CATEGORY = "filter_category",
  FILTER_LOCATION = "filter_location",
  FILTER_VALUE = "filter_value",
  FILTER_SELECTED = "filter_selected",
  IS_FROM_SIMILAR_CARDS = "is_from_similar_cards",
  NUMBER_RESULTS_BEFORE = "number_results_before",
  NUMBER_RESULTS_AFTER = "number_results_after",
  ACTIVE_FILTERS_SELECTED = "active_filters_selected",
  ACTIVE_PROVIDERS_SELECTED = "provider_selected",
  IS_TRENDING = "is_trending",
  // Comparison
  PRODUCTS_SELECTED = "products_selected",
  COMPARISON_CARDS_COUNT = "compare_cards_count",
  // Quick Apply
  _NUMBER_OF_STEPS = "number_of_steps",
  _CURRENT_STEP_NAME = "current_step_name",
  _CURRENT_STEP_NUMBER = "current_step_number",
  RESPONSE_CODE = "response_code",
  ERROR_MESSAGE = "error_message",
  FIELD_NAME = "field_name",
  INPUT_TYPE = "input_type",
  INPUT_VALUE = "input_value",
  // CS Graph
  SESSION_START_TIME = "sessionStartTime",
  HIT_TYPE = "hitType",
  USER_EMAIL = "userEmail",
  TOOLTIP_NAME = "tooltip_name",
  CREDIT_FACTOR_IMPRESSION_ID = "credit_factor_impression_id",
  CREDIT_FACTOR = "credit_factor",
  CREDIT_FACTOR_UPDATE_COLOUR = "credit_factor_update_colour",
  CREDIT_FACTOR_UPDATE_REMARK = "credit_factor_update_remark",
  // Sponsored carousel
  BELOW_SHOW_ALL = "below_show_all",
  CASH_BACK_OFFER = "cash_back_offer",
  PRODUCT_IMPRESSION_ID = "product_impression_id",
  ACTIVE_OFFER = "active_offer",
  INFO_CARD_NAME = "info_card_name",
  NUMBER_OF_UPDATES_ON_CARD = "number_of_updates_on_card",
  RED_BADGE_NUMBER = "red_badge_number",
  RED_BADGE_PRESENT = "red_badge_present",
  // PV PreSelectedProduct
  AMOUNT = "amount",
  PARTNER = "partner",
  CARD_PROMO_CATEGORY = "promo_category",
  CARD_PROMO_TEXT_BODY = "promo_text_body",
  CARD_PROMO_TEXT_HEADER = "promo_text_header",
  HIERARCHY_IMPRESSION_ID = "impression_id",
  HIERARCHY_PROMO_CARD_IMPRESSION_ID = "promocardImpressionID",
  // Historical Reporting
  ONBOARDING_STATE = "onboardingState",
  TREATMENT = "treatment",
}
// (typeof MARKETPLACE_ROUTES)[keyof typeof MARKETPLACE_ROUTES]
export type IAnalyticsAttributes = {
  [key in ANALYTICS_ATTRIBUTES]?: any;
};

export type AnalyticsEvent = {
  [P in ANALYTICS_ATTRIBUTES]?: string | number | boolean | string[];
};
