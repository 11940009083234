import styled from "@emotion/native";

import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const styles = {
  badgeIcon: {
    alignSelf: "flex-start",
    flex: "0 0 1.4em",
  },
};

export const HistoricalReportingStatusBadge = styled.View<{
  backgroundColor?: string;
}>`
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : COLORS.NEUTRAL.COOL[200]};
  padding: ${styledValue(spacing.xxxs)};
  border-radius: ${styledValue(spacing.xxxs)};
  font-size: ${styledValue(fontSize.xxs)};
`;

export const StatusText = styled.View`
  padding-right: ${styledValue(spacing.xxs)};
`;
