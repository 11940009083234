import i18next from "i18next";
import { useCallback, useMemo, useState } from "react";
import { LayoutChangeEvent } from "react-native";
import { useLocation } from "react-router-dom";

import { IconNames } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";

import {
  NAV_BAR_ITEM_MIN_WIDTH,
  NAV_BAR_ITEM_MIN_WIDTH_STACKED,
  NavBarActivityIndicator,
  NavBarDropdownCloseOverlay,
  NavBarDropdownContainer,
  NavBarGridContainer,
  NavBarHeightSpacer,
  NavBarMaxWidthContainer,
  NavBarOuterContainer,
} from "./ProductVerticalsNavBarBase.styles";
import {
  ProductVerticalsNavBarBaseProps,
  ProductVerticalsNavBarLink,
} from "./ProductVerticalsNavBarBase.types";
import { NavBarDropdownLink } from "./components/NavBarDropdownLink";
import { NavBarLink } from "./components/NavBarLink";

/**
 * Base component for the ProductVerticalsNavBar used in the web Marketplace app.
 * The Base component manages the display logic whereas the ProductVerticalsNavBar component
 * on the Marketplace app manages the data fetching and business logic.
 * The links will be displayed on the main row if the size of the container is enough to fit all the links.
 * Otherwise, links on the trailing end will be tucked in the "More" dropdown.
 * The use of this component is currently limited to web.
 *
 * @component
 * @example
 * return (
 *   <ProductVerticalsNavBarBase
 *     linkConfigs={[
 *       { title: "Loans", icon: "loans" as IconNames, link: `/loans` },
 *       { title: "Credit Cards", icon: "link" as IconNames, link: `/credit-cards` },
 *     ]}
 *     isLoading={false}
 *   />
 * )
 */
export const ProductVerticalsNavBarBase = ({ linkConfigs, isLoading }: ProductVerticalsNavBarBaseProps) => {
  const location = useLocation();
  const { isMobile, isDesktop } = useBreakpoints();

  const [maxWidthContainerLayout, setMaxWidthContainerLayout] = useState({ width: Infinity, height: 80 });
  const [showDropdown, setShowDropdown] = useState(false);

  const links: ProductVerticalsNavBarLink[] = useMemo(
    () =>
      linkConfigs.map(({ title, icon, link, linkId }) => ({
        title,
        icon,
        link,
        linkId,
        isSelected: location.pathname.includes(link),
      })),
    [linkConfigs, location.pathname],
  );

  const { mainRowLinks, dropDownLinks } = useMemo(() => {
    setShowDropdown(false);
    const linkMinWidth = isDesktop ? NAV_BAR_ITEM_MIN_WIDTH : NAV_BAR_ITEM_MIN_WIDTH_STACKED;
    const linksOnMainRowCount = Math.floor(maxWidthContainerLayout.width / linkMinWidth);

    // Display all links on main row if they all can fit.
    // Otherwise, take at minimum last 2 links to be displayed in the dropdown.
    const isAllLinksFitOnMainRow = linksOnMainRowCount >= links.length;
    const linksOnMainRow = isAllLinksFitOnMainRow ? links : links.slice(0, linksOnMainRowCount);
    const linksOnMoreDropdown = isAllLinksFitOnMainRow ? [] : links.slice(linksOnMainRowCount);

    return { mainRowLinks: linksOnMainRow, dropDownLinks: linksOnMoreDropdown };
  }, [isDesktop, maxWidthContainerLayout.width, links]);

  const handleMaxWidthContainerLayoutChanged = useCallback((event: LayoutChangeEvent) => {
    setMaxWidthContainerLayout(event.nativeEvent.layout as unknown as any);
  }, []);

  const mainRowLinksCount = mainRowLinks.length + Math.min(dropDownLinks.length, 1);
  const isDropdownLinkClicked = useMemo(() => dropDownLinks.some((link) => link.isSelected), [dropDownLinks]);

  if (isLoading) {
    return (
      <>
        <NavBarOuterContainer isMobile={isMobile}>
          <NavBarMaxWidthContainer linksCount={1} onLayout={handleMaxWidthContainerLayoutChanged}>
            <NavBarActivityIndicator
              color={isMobile ? COLORS.NEUTRAL.WARM[700] : COLORS.WHITE}
              size="large"
            />
          </NavBarMaxWidthContainer>
        </NavBarOuterContainer>
        <NavBarHeightSpacer containerHeight={maxWidthContainerLayout.height} />
      </>
    );
  }

  return (
    <>
      <NavBarOuterContainer isMobile={isMobile}>
        <NavBarMaxWidthContainer linksCount={links.length} onLayout={handleMaxWidthContainerLayoutChanged}>
          <NavBarGridContainer linksCount={mainRowLinksCount}>
            {mainRowLinks.map(({ title, icon, link, linkId, isSelected }) => (
              <NavBarLink
                key={title}
                title={title}
                icon={icon}
                linkId={linkId}
                link={link}
                isSelected={isSelected}
              />
            ))}
            {dropDownLinks.length > 0 && (
              <>
                <NavBarLink
                  title={i18next.t("marketplace:productVerticalsNavBar:showMore")}
                  icon={"ellipsis" as IconNames}
                  linkId={"nav-recommendations-item-show-more"}
                  isSelected={showDropdown || isDropdownLinkClicked}
                  onLinkClick={() => setShowDropdown((isShown) => !isShown)}
                />
                <NavBarDropdownCloseOverlay
                  showDropdown={showDropdown}
                  onPress={() => setShowDropdown(false)}
                  data-cy={"nav-recommendations-item-hide-show-more"}
                />
                <NavBarDropdownContainer showDropdown={showDropdown}>
                  {dropDownLinks.map(({ title, icon, link, linkId, isSelected }) => (
                    <NavBarDropdownLink
                      key={title}
                      title={title}
                      icon={icon}
                      link={link}
                      linkId={linkId}
                      isSelected={isSelected}
                      onLinkClick={() => setShowDropdown(false)}
                    />
                  ))}
                </NavBarDropdownContainer>
              </>
            )}
          </NavBarGridContainer>
        </NavBarMaxWidthContainer>
      </NavBarOuterContainer>
      <NavBarHeightSpacer containerHeight={maxWidthContainerLayout.height} />
    </>
  );
};
