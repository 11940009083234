import { useMemo } from "react";

import { ANALYTICS_ATTRIBUTES, TrackEventFuncAsync } from "@bwll/bw-types";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

export interface IQuickApplyAnalyticsAttributes {
  [ANALYTICS_ATTRIBUTES._CURRENT_STEP_NAME]: string;
  [ANALYTICS_ATTRIBUTES.PARTNER]: string;
  [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: string;
}

export const useQuickApplyAnalytics = (
  trackEvent: TrackEventFuncAsync,
  defaultAttributes: IQuickApplyAnalyticsAttributes,
  impressionId: string,
  trackingTag: string,
) => {
  const {
    prepopulationFlowStarted,
    prepopulationFlowSubmitted,
    prepopulationFlowSuccess,
    prepopulationFlowError,
    prepopulationFlowStepCompleted,
    validationErrorShown,
    editProfileInformation,
    inputOptionSelected,
    inputOptionDeselected,
    inputFieldReceivedFocus,
    inputFieldLostFocus,
  } = useMarketplaceAnalyticsEvents(trackEvent);

  const commonAttributes = useMemo(
    () => ({
      [ANALYTICS_ATTRIBUTES._CURRENT_STEP_NUMBER]: 1,
      [ANALYTICS_ATTRIBUTES._NUMBER_OF_STEPS]: 1,
      ...defaultAttributes,
    }),
    [defaultAttributes],
  );

  return useMemo(
    () => ({
      trackPrepopulationFlowStarted: () =>
        prepopulationFlowStarted({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impressionId,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: trackingTag,
        }),
      trackPrepopulationFlowSubmitted: () =>
        prepopulationFlowSubmitted({
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impressionId,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: trackingTag,
          ...commonAttributes,
        }),
      trackPrepopulationFlowSuccess: () =>
        prepopulationFlowSuccess({
          ...commonAttributes,
        }),
      trackPrepopulationFlowError: (responseCode: number, errorMessage: string) =>
        prepopulationFlowError({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.RESPONSE_CODE]: responseCode,
          [ANALYTICS_ATTRIBUTES.ERROR_MESSAGE]: errorMessage,
        }),
      trackEditProfileInformationClicked: (buttonName: string, buttonContext: string) =>
        editProfileInformation({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES._BUTTON_NAME]: buttonName,
          [ANALYTICS_ATTRIBUTES.BUTTON_CONTEXT]: buttonContext,
        }),
      trackPrepopulationFlowStepCompleted: () =>
        prepopulationFlowStepCompleted({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impressionId,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: trackingTag,
        }),
      trackValidationErrorShown: (validationErrors: string[]) =>
        validationErrorShown({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.VALIDATION_ERRORS]: validationErrors.join(),
        }),
      trackInputOptionSelected: (fieldName: string, inputType: string, inputValue: string) =>
        inputOptionSelected({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.FIELD_NAME]: fieldName,
          [ANALYTICS_ATTRIBUTES.INPUT_TYPE]: inputType,
          [ANALYTICS_ATTRIBUTES.INPUT_VALUE]: inputValue,
        }),
      trackInputOptionDeselected: (fieldName: string, inputType: string, inputValue: string) =>
        inputOptionDeselected({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.FIELD_NAME]: fieldName,
          [ANALYTICS_ATTRIBUTES.INPUT_TYPE]: inputType,
          [ANALYTICS_ATTRIBUTES.INPUT_VALUE]: inputValue,
        }),
      trackInputFieldReceivedFocus: (fieldName: string, inputValue: string) =>
        inputFieldReceivedFocus({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.FIELD_NAME]: fieldName,
          [ANALYTICS_ATTRIBUTES.INPUT_VALUE]: inputValue,
        }),
      trackInputFieldLostFocus: (fieldName: string, inputValue: string) =>
        inputFieldLostFocus({
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.FIELD_NAME]: fieldName,
          [ANALYTICS_ATTRIBUTES.INPUT_VALUE]: inputValue,
        }),
    }),
    [],
  );
};
