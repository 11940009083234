import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button } from "@bwll/bw-components/next";
import { useCreditCardComparison } from "@bwll/bw-hooks";
import { ProductVerticalCreditCard } from "@bwll/bw-types";

import { CreditCardCompareButtonProps } from "./CreditCardCompareButton.types";

/**
 * A button for credit cards that represents their state in the Compare collection.
 * Must be used within a CreditCardComparisonProvider.
 */
export const CreditCardCompareButton = <TCreditCard extends ProductVerticalCreditCard>({
  creditCard,
  onAddComparePress,
  onRemoveComparePress,
  testID,
}: CreditCardCompareButtonProps<TCreditCard>) => {
  const { t } = useTranslation();

  const { comparison } = useCreditCardComparison();
  const isComparing = useMemo(
    () => comparison.cards.some((c) => c.id === creditCard.id),
    [comparison.cards, creditCard.id],
  );

  const handleComparePress = useCallback(
    () => (isComparing ? onRemoveComparePress(creditCard) : onAddComparePress(creditCard)),
    [creditCard, isComparing, onAddComparePress, onRemoveComparePress],
  );

  return (
    <Button
      onPress={handleComparePress}
      testID={testID}
      type={BUTTON_TYPE.SECONDARY}
      title={
        isComparing
          ? t("marketplace:creditCards:compare:compareButton:added")
          : comparison.cards.length > 3
          ? t("marketplace:creditCards:compare:compareButton:max")
          : t("marketplace:creditCards:compare:compareButton:compare")
      }
      iconName={isComparing ? "checkmark" : comparison.cards.length < 4 ? "plus" : undefined}
      isAdded={isComparing}
      disabled={!isComparing && comparison.cards.length > 3}
    />
  );
};
