import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button } from "@bwll/bw-components/next";
import { useBreakpoints, useCreditCardComparison } from "@bwll/bw-hooks";

import { CreditCardCompareCounterButton } from "../CreditCardCompareCounterButton";
import * as Styled from "./CreditCardCompareFooter.styles";
import { CreditCardCompareFooterProps } from "./CreditCardCompareFooter.types";

export const CreditCardCompareFooter = ({ testID, onComparePress }: CreditCardCompareFooterProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useBreakpoints();

  const {
    comparison: { cards },
    clear,
  } = useCreditCardComparison();

  return cards.length ? (
    <Styled.Footer testID={testID} isDesktop={isDesktop}>
      <Styled.ClearButtonContainer isDesktop={isDesktop}>
        <Button
          type={BUTTON_TYPE.TERTIARY}
          onPress={clear}
          title={t("marketplace:creditCards:compare:clear")}
        />
      </Styled.ClearButtonContainer>
      <Styled.CompareButtonContainer isDesktop={isDesktop}>
        <CreditCardCompareCounterButton
          cardCount={cards.length}
          onPress={() => {
            onComparePress(cards.map((item) => (item.trackingTag ? item.trackingTag : "")));
          }}
        />
      </Styled.CompareButtonContainer>
    </Styled.Footer>
  ) : null;
};
