import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbItemProps, IGenericCarouselData, LoadingScreen } from "@bwll/bw-components/next";
import {
  useBreakpoints,
  useCreditCardComparisonExperiment,
  useCreditCardProductDetailsAnalytics,
  useImpressionedCreditCards,
  useSimilarOffersExperiment,
} from "@bwll/bw-hooks";
import {
  CreditCardProductCard,
  CreditCardProductCardProps,
  CreditCardProductDetails as ModulesCreditCardProductDetails,
  ProductBanner,
  ScreenHeader,
  SimilarOffers,
} from "@bwll/bw-modules";
import { MarketplaceErrorScreen } from "@bwll/bw-modules/";
import { ImpressionedProductVerticalCreditCard, MARKETPLACE_ANALYTICS_ORIGINS } from "@bwll/bw-types";
import { generateTo, noop } from "@bwll/bw-utils";

import { CREDIT_CARD_PRODUCT_DETAILS_TEST_IDS as TEST_IDS } from "./CreditCardProductDetails.constants";
import * as Styled from "./CreditCardProductDetails.styles";
import { getTransformCreditCardsResponse } from "./CreditCardProductDetails.utils";

import { useMarketplaceProductApplication, useMarketplaceTrackEvent } from "@app/hooks";
import { ROUTES } from "@app/router";

const originSearchParams = {
  origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_DETAILS,
};

export const CreditCardProductDetails = () => {
  const { showSimilarOffers } = useSimilarOffersExperiment();
  const { showComparison } = useCreditCardComparisonExperiment();

  const i18next = useTranslation();
  const { productId } = useParams();
  const { isDesktop } = useBreakpoints();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useImpressionedCreditCards({
    transform: getTransformCreditCardsResponse(productId),
  });

  const screenHeaderSection = useMemo(() => {
    const breadcrumbItems: BreadcrumbItemProps[] = data?.selectedCard
      ? [
          {
            label: i18next.t("marketplace:creditCards:title"),
            onPress: () =>
              navigate(
                generateTo(ROUTES.CREDIT_CARDS, {
                  searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_DETAILS },
                }),
              ),
            testID: TEST_IDS.CREDIT_CARDS_BREADCRUMB,
          },
          {
            label: data?.selectedCard.productName ?? "",
            testID: data?.selectedCard.productName ?? "",
          },
        ]
      : [];

    return <ScreenHeader breadcrumbItems={breadcrumbItems} />;
  }, [data?.selectedCard, i18next, navigate]);

  const trackEvent = useMarketplaceTrackEvent();
  const {
    trackProductViewed,
    trackSimilarProductViewed,
    trackProductClicked,
    trackProductExpanded,
    trackButtonClicked,
  } = useCreditCardProductDetailsAnalytics(trackEvent, data?.selectedCard);

  const productApply = useMarketplaceProductApplication(originSearchParams.origin);

  const handleApply = useCallback(async () => {
    if (!!data?.selectedCard) {
      await productApply(data?.selectedCard, trackProductClicked);
    }
  }, [data?.selectedCard, productApply, trackProductClicked]);

  const handleLegalDetailsToggled = useCallback(
    (isExpanded: boolean) => {
      trackProductExpanded({ areLegalDetailsExpanded: isExpanded });
    },
    [trackProductExpanded],
  );

  const handleDetailsPress = useCallback(
    (similarCreditCard: ImpressionedProductVerticalCreditCard) => {
      const to = generateTo(ROUTES.CREDIT_CARDS_DETAILS, {
        pathParams: { productId: similarCreditCard.id },
        searchParams: originSearchParams,
      });
      trackButtonClicked(similarCreditCard, to.pathname);

      navigate(to);
    },
    [navigate, trackButtonClicked],
  );

  const handleComparePress = useCallback(
    (card: ImpressionedProductVerticalCreditCard) => {
      if (data?.selectedCard) {
        navigate(
          generateTo(ROUTES.CREDIT_CARDS_COMPARE, {
            searchParams: {
              origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_DETAILS,
              comparisonCards: [data.selectedCard, card].map((c) => c.id).join(","),
            },
          }),
        );
      }
    },
    [data?.selectedCard, navigate],
  );

  const openExternalUrl = useCallback((url: string) => window.open(url), []);

  useEffect(() => {
    trackProductViewed();
    trackProductExpanded({ areLegalDetailsExpanded: false });
  }, [trackProductViewed, trackProductExpanded]);

  if (isLoading)
    return (
      <>
        {screenHeaderSection}
        <LoadingScreen />
      </>
    );

  if (isError)
    return (
      <>
        {screenHeaderSection}
        <MarketplaceErrorScreen errorType="default" />
      </>
    );

  // Empty Results
  if (!data?.selectedCard)
    return (
      <>
        {screenHeaderSection}
        <MarketplaceErrorScreen errorType="empty-credit-cards" />
      </>
    );

  const { selectedCard, similarCards } = data;

  const hasPromotionalBadges = selectedCard.promotionalBadges.filter(Boolean).length > 0;
  const hasCashBackBannerText = !!selectedCard.cashbackBannerText;

  const productBannerText = hasCashBackBannerText
    ? selectedCard.cashbackBannerText
    : hasPromotionalBadges
    ? selectedCard.promotionalBadges[0]
    : null;

  const similarCardsCarouselData: IGenericCarouselData<
    CreditCardProductCardProps<ImpressionedProductVerticalCreditCard>
  >[] =
    similarCards?.map((cc, index) => ({
      params: {
        product: cc,
        // Index will be replaced by FlatList
        index: -1,
        onApplyPress: async (card) => await productApply(card, trackProductClicked),
        onDetailsPress: handleDetailsPress,
        onAddComparePress: handleComparePress,
        onRemoveComparePress: noop,
        onProductViewed: (card) => trackSimilarProductViewed(card, index),
        onTabSwitched: noop,
        hideApply: true,
        trackingKey: selectedCard.id,
        showComparison: showComparison,
      },
      ItemComponent: CreditCardProductCard,
    })) ?? [];

  return isDesktop ? (
    <Styled.DesktopContainer>
      {screenHeaderSection}
      <div>
        <ModulesCreditCardProductDetails
          product={selectedCard}
          onApplyPress={handleApply}
          onLegalDetailsToggled={handleLegalDetailsToggled}
          externalLinkNavigation={openExternalUrl}
        />
        {showSimilarOffers && (
          <SimilarOffers products={similarCardsCarouselData} testID={TEST_IDS.SIMILAR_OFFERS} />
        )}
      </div>
    </Styled.DesktopContainer>
  ) : (
    <Styled.ResetMargin>
      {productBannerText && <ProductBanner>{productBannerText}</ProductBanner>}
      <Styled.NonDesktopContainer>
        <ModulesCreditCardProductDetails
          product={selectedCard}
          onApplyPress={handleApply}
          onLegalDetailsToggled={handleLegalDetailsToggled}
          externalLinkNavigation={openExternalUrl}
          HeaderComponent={screenHeaderSection}
        />
      </Styled.NonDesktopContainer>
      {showSimilarOffers && (
        <SimilarOffers products={similarCardsCarouselData} testID={TEST_IDS.SIMILAR_OFFERS} />
      )}
    </Styled.ResetMargin>
  );
};
