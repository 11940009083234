import React from "react";

import { StyledContent } from "./Content.styles";
import { ContentProps } from "./Content.types";

/**
 * Component for showing the Content.
 * Accepts "children" as element.
 */
export const Content = ({ children, testID, style }: ContentProps) => {
  return (
    <StyledContent style={style} accessibilityLabel="none" testID={testID}>
      {children}
    </StyledContent>
  );
};
