import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { COLORS, mq } from "@bwll/bw-styles";
import { fontSize, fontWeights } from "@bwll/bw-styles/src/typography";

export const NavBarLinkOuterContainer = styled.div<{ isSelected: boolean }>(({ isSelected }) =>
  mq({
    backgroundColor: isSelected
      ? ["transparent", COLORS.PRIMARY["700"], COLORS.PRIMARY["700"]]
      : "transparent",
    "&:hover": {
      backgroundColor: [COLORS.NEUTRAL.WARM["100"], COLORS.PRIMARY["700"], COLORS.PRIMARY["700"]],
    },
    borderStyle: "solid",
    borderColor: ["transparent", COLORS.PRIMARY["600"], COLORS.PRIMARY["600"]],
    borderWidth: "0 0 0 1px",
    "&:last-child": {
      borderRightWidth: 1,
    },
  }),
);

export const NavBarLinkContainer = styled(Link)`
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;
export const NavBarButtonContainer = styled.button`
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
`;

export const NavBarLinkContentContainer = styled.div(() =>
  mq({
    minHeight: 60,
    padding: "10px 5px",
    display: "flex",
    flexDirection: ["column", "column", "row"],
    justifyContent: "center",
    alignItems: "center",
  }),
);

export const NavBarLinkIconContainer = styled.div<{ isSelected: boolean }>(({ isSelected }) =>
  mq({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: [0, 0, 8],
    paddingBottom: [0, 8, 0],
    "& svg": {
      color: isSelected
        ? [COLORS.PRIMARY[700], COLORS.WHITE, COLORS.WHITE]
        : [COLORS.NEUTRAL.WARM[700], COLORS.WHITE, COLORS.WHITE],
    },
  }),
);

export const NavBarLinkTitle = styled.div<{ isSelected: boolean }>(({ isSelected }) =>
  mq({
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato-Regular, Lato, sans-serif",
    fontSize: [fontSize.xxxs, fontSize.xs, fontSize.xs],
    textAlign: "center",
    color: isSelected
      ? [COLORS.PRIMARY[700], COLORS.WHITE, COLORS.WHITE]
      : [COLORS.NEUTRAL.WARM[700], COLORS.WHITE, COLORS.WHITE],
    fontWeight: isSelected
      ? [fontWeights.bold, fontWeights.regular, fontWeights.regular]
      : fontWeights.regular,
  }),
);
