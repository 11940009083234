import styled from "@emotion/native";

import { Body1, Heading1 } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const TextContainer = styled.View<{ isMobile: boolean }>`
  margin-top: ${styledValue(spacing.xs)};
  gap: ${styledValue(spacing.xxxs)};
  align-items: center;
  ${({ isMobile }) => isMobile && `padding-horizontal: ${styledValue(spacing.l)};`}
`;

export const Heading = styled(Heading1)`
  color: ${COLORS.WHITE};
  text-align: center;
`;

export const Subheading = styled(Body1)`
  color: ${COLORS.WHITE};
  text-align: center;
`;
