import { ProductVerticalCreditCard } from "@bwll/bw-types";
import { CreditCardFilterCategories, CreditCardFilterer } from "@bwll/bw-utils";

export const createCompanyNameFilterer = (companyName: string): CreditCardFilterer => ({
  key: companyName,
  category: CreditCardFilterCategories.Providers,
  predicate: (creditCard: ProductVerticalCreditCard) => creditCard.companyName === companyName,
});

export const getUniqueCompanyNames = (creditCards: ProductVerticalCreditCard[]): string[] => {
  const sortedCompanyNames = creditCards
    .map(({ companyName }) => companyName)
    .filter((companyName): companyName is string => companyName !== null)
    .sort();
  return Array.from(new Set(sortedCompanyNames));
};
