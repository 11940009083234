import React from "react";
import { useTranslation } from "react-i18next";

import { Body2, Spacer } from "@bwll/bw-components/next";
import { fontSize, spacing } from "@bwll/bw-styles";
import { formatCurrencyDollar } from "@bwll/bw-utils";

import { Highlights } from "../Highlights";
import * as Styled from "./ProductHighlights.styles";
import type { ProductHighlightsProps } from "./ProductHighlights.types";

export const ProductHighlights = ({
  signupBonusMarkdown,
  rewardsCategoryMarkdown,
  firstYearValueAmount,
}: ProductHighlightsProps) => {
  const i18next = useTranslation();

  const rewardMarkdown = signupBonusMarkdown || rewardsCategoryMarkdown;

  return (
    <Styled.HighlightsContainer>
      {rewardMarkdown && (
        <Highlights
          hasTooltip={false}
          iconProps={{
            iconName: "highlight_medal",
            iconSize: fontSize.xl,
          }}
          centeredVertically={false}
        >
          {rewardMarkdown}
        </Highlights>
      )}
      {firstYearValueAmount && (
        <>
          <Spacer height={spacing.xs} />
          <Highlights
            hasTooltip
            tooltipTextProps={{
              TypographyComponent: Body2,
              tooltipKey: i18next.t("productCatalog:creditCards:firstYearValue:copy"),
              tooltipText: i18next.t("productCatalog:creditCards:firstYearValue:tooltipCopy"),
            }}
            iconProps={{
              iconColor: undefined,
              iconName: "highlight_dollar",
              iconSize: fontSize.xl,
            }}
            centeredVertically={false}
          >
            {formatCurrencyDollar({ amount: firstYearValueAmount, decimals: 0 }) +
              " " +
              i18next.t("productCatalog:creditCards:firstYearValue:copy")}
          </Highlights>
        </>
      )}
    </Styled.HighlightsContainer>
  );
};
