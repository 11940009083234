import styled from "@emotion/styled";

import { COLORS, WEB_SCREEN_PADDING, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ResetMargin = styled.div<{ hasOfferEndDate: boolean }>`
  margin: ${styledValue(-WEB_SCREEN_PADDING)};
  padding-bottom: ${({ hasOfferEndDate }) => (hasOfferEndDate ? styledValue(80) : styledValue(52))};
`;

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${styledValue(spacing.xl)};
  margin-top: ${styledValue(spacing.xl)};
`;

export const NonDesktopContainer = styled.div`
  background-color: ${COLORS.WHITE};
  padding: ${styledValue(spacing.xxs)} ${styledValue(spacing.m)};
`;
