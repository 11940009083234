import React, { useCallback } from "react";

import { useBreakpoints, useUpdateIndividualClientProfile } from "@bwll/bw-hooks";
import { ProfileEditForm } from "@bwll/bw-modules";
import { ProfileEditFormInputs, UpdateIndividualClientProfile } from "@bwll/bw-types";

import * as Styled from "./ProfileEdit.styles";

import { useGoBack, useQuickApplyExperimentRedirect } from "@app/hooks";
import { ROUTES } from "@app/router";

/**
 * Renders the ProfileEdit component on Marketplace Web.
 *
 * @returns The rendered ProfileEdit component.
 */
export const ProfileEdit: React.FC = () => {
  useQuickApplyExperimentRedirect();
  const goBack = useGoBack(ROUTES.CREDIT_CARDS);
  const { isMobile } = useBreakpoints();

  const { mutate: updateIndividualClientProfile } = useUpdateIndividualClientProfile();

  const handleProfileEditSubmit = useCallback(
    (formData: ProfileEditFormInputs) => {
      const { phoneNumber, streetAddress, streetAddress2, city, postalCode, regionId, preferredName } =
        formData;

      const profileData: UpdateIndividualClientProfile = {
        phoneNumber: phoneNumber.replace(/-/g, ""),
        provinceId: regionId,
        streetAddress,
        streetAddress2,
        city,
        postalCode,
        preferredName,
      };

      updateIndividualClientProfile(profileData);
      goBack();
    },
    [updateIndividualClientProfile, goBack],
  );

  return (
    <Styled.ResetMargin isMobile={isMobile}>
      <Styled.InnerContainer isMobile={isMobile}>
        <ProfileEditForm onNavigateBack={goBack} onSubmit={handleProfileEditSubmit} />
      </Styled.InnerContainer>
    </Styled.ResetMargin>
  );
};
