export enum EXPERIMENT_TRAFFIC_TYPE {
  ANONYMOUS = "anonymous",
  LOGGED_IN = "user",
}

export enum WEB_EXPERIMENT_IDS {
  ENABLE_REFER_A_FRIEND = "enableReferAFriend",
  ENABLE_WEB_CBL_CREATION = "enableCBLCreation",
  PRESELECTION_CARD_FAIRSTONE = "preselectionCardFairstone",
  ENABLE_CBL_PROMO_CARD_V2 = "cbl2DashboardPromoCard",
  ENABLE_SPONSORED_PLACEMENTS_CAROUSEL = "enableSponsoredPlacements",
  ENABLE_RENT_REPORTING = "enableRentReporting",
  ENABLE_SPLIT_PAYMENTS = "splitPayments",
  ENABLE_RENT_PERIOD = "rentPeriod",
  ENABLE_RA_PROMOTION = "raPromotion",
  ENABLE_CHAGE_BANK_ACCOUNT = "changeBankAccount",
  ENABLE_RA_LEASE_DETAILS = "raLeaseDetails",
  ENABLE_RA_PRICE_TEST = "raPriceTest",
  ENABLE_RA_MANUAL_UPLOAD = "raManualRentConfirmation",
  ENABLE_RA_PREPOP_ADDRESS = "raPrepopAddress",
  ENABLE_RA_PREPOP_EXP = "raPrepopExp",
  ENABLE_PRIME_DASHBOARD_LTOS = "primeDashboardLTOs",
  MORTGAGE_RENEW_SURVEY = "mortgageRenewalSurvey",
  REFINER_SURVEY = "refinerSurvey",
  SIGNUP_RA_EXTERNAL_FUNNEL = "signUpRaExternalFunnel",
  RA_CHECKOUT_SUMMARY = "raCheckoutSummary",
  RA_HISTORICAL_REPORTING = "raHistoricalReporting",
  RA_HISTORICAL_REPORTING_V1 = "pastRentPaymentsV1Release1",
  ENABLE_SHARED_ANALYTICS_EVENTS = "enableSharedAnalyticsEvents",
  SSPA_MARKETPLACE_CREDIT_CARD_FILTERS = "sspaMarketplaceCreditCardFilters",
  SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS = "sspaMarketplaceCreditCardSimilarOffers",
  SSPA_MARKETPLACE_CREDIT_CARD_COMPARISON = "sspaMarketplaceCreditCardComparison",
  SSPA_MARKETPLACE_BANK_ACCOUNTS = "sspaMarketplaceBankAccounts",
  SSPA_MARKETPLACE_MORTGAGES = "sspaMarketplaceMortgages",
  SSPA_MARKETPLACE_TRENDING = "sspaMarketplaceTrending",
  SSPA_MARKETPLACE_QUICK_APPLY = "sspaMarketplaceQuickApply",
  SSPA_MARKETPLACE_BORROWELL_GUARANTEE = "sspaMarketplaceBorrowellGuarantee",
  SSPA_ENABLE_DASHBOARD_V2_WEB = "sspaDashboardWeb",
  SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB = "sspaDashboardWebPhase2",
  SSPA_DASHBOARD_TRAFFIC_ALLOCATION = "sspaDashboardTrafficAllocation",
}

export enum STANDARD_TREATMENTS {
  ON = "on",
  OFF = "off",
  CONTROL = "control",
}

const createStandardTreatments = () => ({
  ON: STANDARD_TREATMENTS.ON,
  OFF: STANDARD_TREATMENTS.OFF,
});

export enum EXPERIMENT_SOURCE {
  SPLIT = "split",
  ANON = "anon",
  USER_CONFIG = "userConfig",
}

export const WEB_TREATMENT_IDS = {
  [WEB_EXPERIMENT_IDS.ENABLE_RENT_REPORTING]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_SPLIT_PAYMENTS]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RENT_PERIOD]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_PROMOTION]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_CHAGE_BANK_ACCOUNT]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_LEASE_DETAILS]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_MANUAL_UPLOAD]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_PREPOP_ADDRESS]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_PREPOP_EXP]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_PRIME_DASHBOARD_LTOS]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.SSPA_DASHBOARD_TRAFFIC_ALLOCATION]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_WEB]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.SIGNUP_RA_EXTERNAL_FUNNEL]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_RA_PRICE_TEST]: {
    OFF: "off",
    V1: "v1",
    V2: "v2",
    V3: "v3",
  },
  [WEB_EXPERIMENT_IDS.RA_CHECKOUT_SUMMARY]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_SHARED_ANALYTICS_EVENTS]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.ENABLE_REFER_A_FRIEND]: {
    OFF: "off",
    CONTEST: "contest",
    INCENTIVE: "incentive",
    FEBRUARY: "february",
    MARCH: "march",
    JUNE: "june",
    NOVEMBER: "november",
    REFER_A_FRIEND_5_DOLLARS: "referrer-5-dollars",
  },
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS]: {
    OFF: "off",
    V1: "v1",
    V2: "v2",
    V3: "v3",
    V4: "v4",
  },
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_MORTGAGES]: {
    OFF: "off",
    ON: "on",
    DEFAULT: "default",
  },
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS]: {
    OFF: "off",
    V1: "v1",
    V2: "v2",
    V3: "v3",
    V4: "v4",
    V5: "v5",
  },
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS]: {
    OFF: "off",
    V1: "v1",
    V2: "v2",
    V3: "v3",
  },
  [WEB_EXPERIMENT_IDS.MORTGAGE_RENEW_SURVEY]: {
    OFF: "off",
    DEFAULT: "default",
    V1: "v1",
    V2: "v2",
    V3: "v3",
  },
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_COMPARISON]: createStandardTreatments(),
  [WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BORROWELL_GUARANTEE]: {
    OFF: "off",
    V1: "v1",
    V2: "v2",
    V3: "v3",
  },
};

export type WebTreatmentIdsKeys = keyof typeof WEB_TREATMENT_IDS;
export type WebTreatmentIdsValuesKey = keyof (typeof WEB_TREATMENT_IDS)[WebTreatmentIdsKeys];
export type WebTreatmentIdsType = (typeof WEB_TREATMENT_IDS)[WebTreatmentIdsKeys][WebTreatmentIdsValuesKey];

export interface IExperimentJson {
  name: string;
  value: string;
  config: string | null;
  updatedDateUtc: string;
}

export interface ICallOut {
  callOutType: string;
  isActive: boolean;
  callOutActionHistories?: {
    actionId: number;
    action: string;
    actionDateTime: string;
  };
}

export type ExperimentsJson = IExperimentJson[];
export type CallOutsJson = ICallOut[];

export interface IExperimentTreatment {
  treatment: string;
  type?: EXPERIMENT_TRAFFIC_TYPE;
  config: {
    [key: string]: string | number | boolean;
  };
}

export type IExperiments = {
  [key in WEB_EXPERIMENT_IDS]?: IExperimentTreatment;
};
