import { useEffect, useMemo, useRef, useState } from "react";
import { Animated, Easing, Pressable } from "react-native";

import { COLORS } from "@bwll/bw-styles";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { Icon } from "../../atoms/Icon";
import { Body2 } from "../../atoms/Typography";
import { SHOW_MORE_TEST_IDS as TEST_IDS } from "./ShowMore.constants";
import * as Styled from "./ShowMore.styles";
import { ShowMoreProps } from "./ShowMore.types";

/**
 * Component that truncates the provided content, with a toggle to let user expand to see the full content.
 *
 * @component
 * @example
 * return (
 *  <ShowMore showMoreLabel="Show more" showLessLabel="Show less" heightCutoff="48">
 *    <Text>Long content that should be cut off!</Text>
 *    <Text>Long content that should be cut off!</Text>
 *    <Text>Long content that should be cut off!</Text>
 *    <Text>Long content that should be cut off!</Text>
 *    <Text>Long content that should be cut off!</Text>
 *    <Text>Long content that should be cut off!</Text>
 *  </ShowMore>
 * )
 */

export const ShowMore = ({
  children,
  showMoreLabel,
  showLessLabel,
  heightCutoff = 20,
  showBorder = true,
  testID,
  onShowMoreToggled,
}: ShowMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const animatedValue = useRef(new Animated.Value(0)).current;

  const toggle = () => {
    onShowMoreToggled?.(!isExpanded);
    setIsExpanded(!isExpanded);
  };

  const animatedRotation = useMemo(
    () => ({
      transform: [
        {
          rotate: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: ["0deg", "180deg"],
          }),
        },
      ],
    }),
    [animatedValue],
  );

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isExpanded ? 1 : 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  }, [isExpanded, animatedValue]);

  const defaultTestId = useRef(`${Date.now()}`);
  const getTestId = makeTestIdGenerator(testID ?? defaultTestId.current);

  return (
    <Styled.Container testID={testID}>
      <Styled.ExpandableContainer
        isExpanded={isExpanded}
        showBorder={showBorder}
        $height={heightCutoff}
        testID={getTestId(TEST_IDS.EXPANDABLE_CONTAINER)}
      >
        {children}
      </Styled.ExpandableContainer>
      <Pressable onPress={toggle} testID={getTestId(TEST_IDS.TOUCHABLE_OPACITY)}>
        <Styled.ShowMoreLabel>
          <Animated.View style={animatedRotation}>
            <Icon icon={"arrow_down"} size={20} color={COLORS.PRIMARY.DEFAULT} />
          </Animated.View>
          <Body2 color={COLORS.PRIMARY.DEFAULT}>{isExpanded ? showLessLabel : showMoreLabel}</Body2>
        </Styled.ShowMoreLabel>
      </Pressable>
    </Styled.Container>
  );
};
