import { useCallback } from "react";
import type { Path } from "react-router-dom";

import {
  ImpressionedApplyableProduct,
  ImpressionedQuickApplyableProduct,
  LOAN_KNACK_ID,
  MARKETPLACE_ROUTES,
  NullableString,
  PRODUCT_VERTICAL_GUID,
  PreapprovalStatus,
} from "@bwll/bw-types";
import { generateTo } from "@bwll/bw-utils";

import { useMarketplaceQuickApplyExperiment } from "../marketplace/useMarketplaceQuickApplyExperiment";

export const PRODUCT_APPLICATION_DESTINATION_TYPES = {
  EXTERNAL_WEBSITE: "externalWebsite",
  GEMSTONE_WEB_QUICK_APPLY: "gemstoneWebQuickApply",
  MARKETPLACE_QUICK_APPLY: "marketplaceQuickApply",
} as const;

type ApplicationDestination =
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["MARKETPLACE_QUICK_APPLY"];
      localTo: Partial<Path>;
      sspaUrl: string;
    }
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["GEMSTONE_WEB_QUICK_APPLY"];
      url: string;
    }
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["EXTERNAL_WEBSITE"];
      url: NullableString;
    };

const isQuickApplyable = (
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
): product is ImpressionedQuickApplyableProduct => {
  return "isQuickApply" in product && "productReferenceNumber" in product;
};

const isFigPreApproved = (
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
): boolean => {
  const isFigLoan =
    "productReferenceNumber" in product && product.productReferenceNumber === LOAN_KNACK_ID.FIG_LOANS_OFFER;

  const isPreApproved =
    "preApproval" in product &&
    product.preApproval instanceof Object &&
    "status" in product.preApproval &&
    product.preApproval.status === PreapprovalStatus.Preapproved;

  return isFigLoan && isPreApproved;
};

const getGemstoneWebQuickApplyDestination = (
  gemstoneBaseUrl: string,
  { productVerticalId, productReferenceNumber, id, productImpressionId }: ImpressionedQuickApplyableProduct,
  { origin }: Record<string, string>,
): ApplicationDestination => {
  const vertical = productVerticalId === PRODUCT_VERTICAL_GUID.CREDIT_CARD ? "creditcards" : "loans";

  // Only impressionId and origin for Gemstone Web
  const urlSearchParams = new URLSearchParams({
    impressionId: productImpressionId,
    ...(origin ? { origin } : {}),
  });

  return {
    type: PRODUCT_APPLICATION_DESTINATION_TYPES.GEMSTONE_WEB_QUICK_APPLY,
    url: `${gemstoneBaseUrl}/#/app/product-partnership/${vertical}/${productReferenceNumber}/${id}?${urlSearchParams.toString()}`,
  };
};

const getMarketplaceQuickApplyDestination = (
  gemstoneBaseUrl: string,
  product: ImpressionedQuickApplyableProduct,
  searchParams: Record<string, string>,
): ApplicationDestination => {
  const route =
    product.productVerticalId === PRODUCT_VERTICAL_GUID.CREDIT_CARD
      ? MARKETPLACE_ROUTES.CREDIT_CARDS_QUICK_APPLY
      : undefined;

  if (!route) {
    // Have not set up this vertical for Quick Apply yet
    return getGemstoneWebQuickApplyDestination(gemstoneBaseUrl, product, searchParams);
  }

  const localTo = generateTo(route, {
    pathParams: {
      productId: product.id,
    },
    searchParams,
  });

  const sspaSearchParams = new URLSearchParams(searchParams);
  sspaSearchParams.set("impressionId", product.productImpressionId);

  return {
    type: PRODUCT_APPLICATION_DESTINATION_TYPES.MARKETPLACE_QUICK_APPLY,
    localTo,
    sspaUrl: `/marketplace/${localTo.pathname.slice(1)}?${sspaSearchParams.toString()}`,
  };
};

/**
 * Gets a callback that, when called, determines the destination to apply for a product.
 * @param gemstoneBaseUrl The base URL for the Gemstone Web application, retrieved from app's `ENVIRONMENT_VARIABLES`.
 * @returns A callback that, when called, determines the destination to apply for a product.
 */
export const useProductApplication = (
  gemstoneBaseUrl: string,
  { searchParams = {} }: { searchParams?: Record<string, string> } = {},
): ((
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
) => ApplicationDestination) => {
  const { showQuickApply } = useMarketplaceQuickApplyExperiment();

  return useCallback(
    (product) => {
      return !isQuickApplyable(product) || !product.isQuickApply || isFigPreApproved(product)
        ? {
            type: PRODUCT_APPLICATION_DESTINATION_TYPES.EXTERNAL_WEBSITE,
            url: product.impressionedProductLink,
          }
        : showQuickApply
        ? getMarketplaceQuickApplyDestination(gemstoneBaseUrl, product, searchParams)
        : getGemstoneWebQuickApplyDestination(gemstoneBaseUrl, product, searchParams);
    },
    [gemstoneBaseUrl, searchParams, showQuickApply],
  );
};
