export const historicalReportingDashboard = {
  prpStatusCard: {
    title: "Past rent reporting",
    cardInnerTitle: "Next Steps",
    iconSections: {
      email: {
        text: "Keep an eye on your email for next steps!",
      },
      submitDocuments: {
        text: "Submit necessary documents",
      },
      waitForProcessing: {
        text: "Wait for processing",
        subText: "Don't worry if your status remains incomplete, if you submitted all your documents, it may take some time for the report to be reflected on your dasbhoard."
      },
    }
  },
};