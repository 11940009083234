import styled from "@emotion/native";

import { Heading2 } from "@bwll/bw-components/next";
import { fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  row-gap: ${styledValue(spacing.m)};
`;

export const H2Mobile = styled(Heading2)`
  font-size: ${styledValue(fontSize.m)};
  line-height: ${styledValue(lineHeight.xl)};
`;

export const SectionContainer = styled.View`
  display: flex;
  flex-direction: column;
  gap: ${styledValue(spacing.xxs)};
`;

export const InputSection = styled.View<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: ${styledValue(spacing.xxs)};
`;

export const InputSectionItem = styled.View<{ isMobile: boolean }>`
  ${({ isMobile }) => !isMobile && "flex-basis: 48%"};
`;
