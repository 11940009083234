import { IndexRouteObject, NonIndexRouteObject } from "react-router-dom";

import { ROUTES } from "./routes";

import { ProfileEdit } from "@app/screens/Profile";

export const profileRoutes: (IndexRouteObject | NonIndexRouteObject)[] = [
  {
    path: ROUTES.PROFILE_EDIT,
    Component: ProfileEdit,
  },
];
