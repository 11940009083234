import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { Individual, MutationOptions, QUERY_KEYS, UpdateIndividualClientProfile } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey } from "@bwll/bw-utils";

const updateIndividualClientProfile = (
  accessToken: string,
  apiGatewayUrl: string,
  data: UpdateIndividualClientProfile,
): Promise<AxiosResponse<Individual>> => {
  const endpoint = new URL("/public/UpdateIndividualClientProfile", apiGatewayUrl).href;
  return axios.post<Individual>(endpoint, data, {
    headers: generateApiHeaders(accessToken),
  });
};

export const useUpdateIndividualClientProfile = ({
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: MutationOptions = {}) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();
  const queryClient = useQueryClient();
  const { accessToken } = userData;

  return useMutation(
    (data: UpdateIndividualClientProfile) =>
      updateIndividualClientProfile(accessToken, envConfigs.API_GATEWAY_URL, data),
    {
      mutationKey: generateQueryKey(QUERY_KEYS.UPDATE_INDIVIDUAL_CLIENT_PROFILE, accessToken),
      onSuccess: async (response) => {
        await queryClient.invalidateQueries(generateQueryKey(QUERY_KEYS.MEMBER_CALL, accessToken));
        onSuccess?.(response.data);
      },
      onError,
      onSettled,
      onMutate,
    },
  );
};
