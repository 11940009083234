import React from "react";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";
import { formatCurrencyDollar } from "@bwll/bw-utils";

import * as Styled from "./DollarText.styles";
import { DollarTextProps } from "./DollarText.types";

export const DollarText = ({ value, isWaived, testID }: DollarTextProps) => (
  <Styled.Row>
    <Styled.Value
      variant="semi-bold"
      color={COLORS.NEUTRAL.WARM["800"]}
      isDecorated={isWaived}
      testID={testID}
    >
      {formatCurrencyDollar({ amount: value })}
    </Styled.Value>
    {isWaived && (
      <Body2 variant="semi-bold" color={COLORS.RED["700"]} testID={`${testID}-waived`}>
        {` ${formatCurrencyDollar({ amount: 0 })}`}
      </Body2>
    )}
  </Styled.Row>
);
