import React from "react";
import { View } from "react-native";

import { Body1, Body2, Button, Cell, Heading1, Image, Link1, Spacer } from "@bwll/bw-components/next";

import { ImageWrapper } from "./ProductCatalogState.styles";
import { SECTION_TYPES, TProductCatalogSection, TProductCatalogState } from "./ProductCatalogState.types";

export const ProductCatalogState = ({ sections, testID }: TProductCatalogState) => {
  const render = (item: TProductCatalogSection, id?: string | number) => {
    switch (item?.type) {
      case SECTION_TYPES.HEADING1:
        return <Heading1 {...item.data} />;
      case SECTION_TYPES.BODY1:
        return <Body1 {...item.data} />;
      case SECTION_TYPES.BODY2:
        return <Body2 {...item.data} />;
      case SECTION_TYPES.IMAGE:
        return (
          <ImageWrapper>
            <Image {...item.data} />
          </ImageWrapper>
        );
      case SECTION_TYPES.LINK1:
        return <Link1 {...item.data} />;
      case SECTION_TYPES.BUTTON:
        return <Button {...item.data} />;
      case SECTION_TYPES.CELL:
        return (
          <Cell {...item.data}>
            {item?.data?.children?.map((child: TProductCatalogSection, _index: number) => (
              <React.Fragment key={`${item.type}-${id}-${child.type}-${_index}`}>
                {child.topSpacing && <Spacer height={child.topSpacing} />}
                {render(child, id)}
                {child.bottomSpacing && <Spacer height={child.bottomSpacing} />}
              </React.Fragment>
            ))}
          </Cell>
        );
      default:
        return <></>;
    }
  };

  return (
    <View testID={testID}>
      {sections?.map((section, sectionIndex: number) => (
        <React.Fragment key={`${section.type}-${sectionIndex}`}>
          {section.topSpacing && <Spacer height={section.topSpacing} />}
          {render(section)}
          {section.bottomSpacing && <Spacer height={section.bottomSpacing} />}
        </React.Fragment>
      ))}
    </View>
  );
};
