import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Body1, Icon } from "@bwll/bw-components/next";
import { COLORS, fontSize } from "@bwll/bw-styles";

import * as Styled from "./MarketplaceFilterButton.styles";
import { MarketplaceFilterButtonProps } from "./MarketplaceFilterButton.types";

export const MarketplaceFilterButton = ({
  onPress,
  testID,
  showFilterButtonTitle,
}: MarketplaceFilterButtonProps) => {
  const i18next = useTranslation();

  return (
    <Styled.FilterButton type={BUTTON_TYPE.INPUT} onPress={onPress} testID={testID}>
      <Icon icon="filter_inactive" size={fontSize.xl} color={COLORS.NEUTRAL.WARM[800]} />
      {showFilterButtonTitle && (
        <Body1>{i18next.t("marketplace:productList:sortAndFilters:filters:title")}</Body1>
      )}
    </Styled.FilterButton>
  );
};
