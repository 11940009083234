import { isBefore } from "date-fns";

import { getISO } from "./getISO";
import { isDateValid } from "./isDateValid";
import { DateTimeUnit, PossiblyValidDate } from "./types";
import { mutateByDateTimeUnit } from "./utils";

/**
 * Checks if the given date is before another date by the unit of time
 *
 * @example
 * isDateBefore('2024-01-01', '2024-01-02, 'month') // false
 * isDateBefore('2024-01-01', '2024-01-02, 'day') // true
 */
export const isDateBefore = (
  date: PossiblyValidDate,
  dateToCompare: PossiblyValidDate,
  unit?: DateTimeUnit,
) => {
  const convertedDate = new Date(date);
  const convertedDateToCompare = new Date(dateToCompare);

  if (!isDateValid(convertedDate) || !isDateValid(convertedDateToCompare)) {
    return false;
  }

  const safeDate = new Date(getISO(convertedDate).slice(0, -1));
  const safeDateToCompare = new Date(getISO(convertedDateToCompare).slice(0, -1));

  if (unit) {
    mutateByDateTimeUnit(safeDate, unit);
    mutateByDateTimeUnit(safeDateToCompare, unit);
  }

  return isBefore(safeDate, safeDateToCompare);
};
