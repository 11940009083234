import { usePostQualify } from "@bwll/bw-hooks";

import { QualifyCheckProps } from "./QualifyCheck.types";

/**
 * Blocks children rendering until Qualify returns using Suspense.
 *
 * @component
 * @example
 *
 * <Suspense>
 *   <QualifyCheck>
 *     <ComponentThatReliesOnQualify />
 *   </QualifyCheck>
 * </Suspense>
 */
export const QualifyCheck = ({ children }: QualifyCheckProps) => {
  usePostQualify({ suspense: true });

  return <>{children}</>;
};
