import { ImpressionedProductVerticalCreditCard } from "@bwll/bw-types";
import { getSimilarCreditCards } from "@bwll/bw-utils";

export const getTransformCreditCardsResponse =
  (productId?: string) =>
  ({
    productVerticalCreditCards,
  }: {
    productVerticalCreditCards: ImpressionedProductVerticalCreditCard[];
  }) => {
    const selectedCard = productVerticalCreditCards.find((cc) => cc.id === productId);
    if (!selectedCard)
      return {
        selectedCard,
        similarCards: undefined,
      };

    const similarCards = getSimilarCreditCards(productVerticalCreditCards, selectedCard);

    return {
      selectedCard,
      similarCards,
    };
  };
