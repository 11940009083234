import { useTranslation } from "react-i18next";

import { BUTTON_TYPE } from "@bwll/bw-components/next";

import { CREDIT_CARD_COMPARE_COUNTER_BUTTON_TEST_IDS as TEST_IDS } from "./CreditCardCompareCounterButton.constants";
import * as Styled from "./CreditCardCompareCounterButton.styles";
import { CreditCardCompareButtonProps } from "./CreditCardCompareCounterButton.types";

export const CreditCardCompareCounterButton = ({ cardCount, onPress }: CreditCardCompareButtonProps) => {
  const { t } = useTranslation();
  const disabled = cardCount < 2;

  return (
    <Styled.CounterButton
      type={BUTTON_TYPE.PRIMARY}
      onPress={onPress}
      state="dark"
      disabled={disabled}
      testID={TEST_IDS.BUTTON}
    >
      <Styled.CounterTextContainer>
        <Styled.CounterButtonText disabled={disabled}>
          {t("marketplace:creditCards:compare:counterButton:compare")}
        </Styled.CounterButtonText>
        <Styled.CounterCircleTextWrapper disabled={disabled}>
          <Styled.CounterCircleText>{cardCount}</Styled.CounterCircleText>
        </Styled.CounterCircleTextWrapper>
        <Styled.CounterButtonText disabled={disabled}>
          {t("marketplace:creditCards:compare:counterButton:cards", { count: cardCount })}
        </Styled.CounterButtonText>
      </Styled.CounterTextContainer>
    </Styled.CounterButton>
  );
};
