import { IconNames } from "@bwll/bw-components/next";

export enum HISTORICAL_BADGE_STATUSES_ENUM {
  INCOMPLETE,
  COMPLETE,
}

export const statusBadgeState = {
  [HISTORICAL_BADGE_STATUSES_ENUM.INCOMPLETE]: {
    iconName: "dot" as IconNames,
    localeText: "rentReporting:historicalReportingStatus:status:incomplete",
  },
  [HISTORICAL_BADGE_STATUSES_ENUM.COMPLETE]: {
    iconName: "checkmark" as IconNames,
    localeText: "rentReporting:historicalReportingStatus:status:complete",
  },
};
