import { SvgImageKey } from "@bwll/bw-components/next";

export const MARKETPLACE_ERROR_SCREEN_ERROR_TYPES = {
  DEFAULT: "default",
  EMPTY_CREDIT_CARDS: "empty-credit-cards",
  EMPTY_BANK_ACCOUNTS: "empty-bank-accounts",
} as const;

export const IMAGE_KEYS: Record<string, SvgImageKey> = {
  EMPTY_RESULTS: "marketplace-empty-results",
  LOADING: "marketplace-loading",
} as const;
