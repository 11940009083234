import { ActivityIndicator } from "react-native";

import { COLORS } from "@bwll/bw-styles";

import { FullScreenContainer, SpinnerContainer } from "./LoadingScreen.styles";
import { LoadingScreenProps } from "./LoadingScreen.types";

/**
 * Component for rendering fullscreen loading screen on the app.
 *
 * @param {string} [backgroundColor] - The background color of the loading screen.
 * @param {string} [spinnerColor] - The color of the spinner.
 *
 * @component
 * @example
 * return (
 *   <LoadingScreen />
 * )
 */
export const LoadingScreen = ({ backgroundColor, spinnerColor }: LoadingScreenProps) => {
  return (
    <FullScreenContainer backgroundColor={backgroundColor ?? COLORS.TRANSPARENT}>
      <SpinnerContainer>
        <ActivityIndicator size="large" color={spinnerColor ?? COLORS.NEUTRAL.COOL["600"]} />
      </SpinnerContainer>
    </FullScreenContainer>
  );
};
