import styled from "@emotion/native";

import { Checkbox, Content, Markdown, Subheading1 } from "@bwll/bw-components/next";
import { COLORS, fontSize, fontWeights, letterSpacing, lineHeight, spacing } from "@bwll/bw-styles";

export const Container = styled.View({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: "100%",
});

export const ContentContainer = styled(Content)({
  maxWidth: 560,
  margin: "auto",
});

export const Tooltip = styled(Markdown)({
  fontSize: fontSize.xxxs,
  fontWeight: fontWeights.regular,
  color: COLORS.NEUTRAL.COOL[600],
  margin: spacing.none,
});

export const PaymentDetailsContainer = styled.View({
  backgroundColor: COLORS.NEUTRAL.COOL["050"],
  borderColor: COLORS.NEUTRAL.COOL["200"],
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 8,
});

export const ProductDescriptionColumn = styled.View({
  padding: spacing.xs,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginRight: spacing.xxxs,
});

export const ProductCostColumn = styled.View({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  alignItems: "flex-end",
});

export const ProductInfo = styled(Subheading1)({
  fontSize: fontSize.xs,
  fontWeight: "600",
});

export const ProductInfoCallout = styled.Text({
  margin: spacing.none,
  color: COLORS.NEUTRAL.COOL["600"],
  fontSize: fontSize.xxs,
  fontWeight: "400",
  lineHeight: lineHeight.xxs,
  letterSpacing: letterSpacing.s,
  fontStyle: "italic",
});

export const AgreementContainer = styled.View({
  marginTop: spacing.xxs,
  display: "flex",
  flexDirection: "row",
});

export const StyledCheckbox = styled(Checkbox)({
  minWidth: spacing.l,
  marginTop: spacing.xxxs,
});

export const Agreement = styled.Text({
  marginTop: spacing.xxxs,
  fontWeight: "400",
  fontSize: fontSize.xs,
  lineHeight: lineHeight.m,
  letterSpacing: 0.24,
  color: COLORS.NEUTRAL.WARM["700"],
  paddingRight: spacing.s,
});
