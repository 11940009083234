import {
  CREDIT_CARD_CATEGORY_CARDTYPES,
  CreditCardCategoryKey,
  ProductVerticalCreditCard,
} from "@bwll/bw-types";

import { sortCreditCardsByPreSelectedOrder } from "../sorting";
import { isTrendingProduct } from "./creditCardFilterersUtils";

/**
 * Returns the credit card's categories.
 * @param param0 The credit card.
 * @returns An array of categories the Credit Card is in.
 */
export const getCreditCardCategories = ({ cardTypes }: ProductVerticalCreditCard): CreditCardCategoryKey[] =>
  cardTypes.map((ct) => CREDIT_CARD_CATEGORY_CARDTYPES[ct]).filter(Boolean);

/**
 * Returns a filtered array of credit cards belonging to the provided credit card category.
 * @param category The category to filter by.
 * @param cards The credit cards.
 * @returns An array of credit cards of the provided category.
 */
export const getCreditCardsByCategory = <TCreditCard extends ProductVerticalCreditCard>(
  category: CreditCardCategoryKey,
  cards: TCreditCard[],
): TCreditCard[] =>
  cards.filter(
    (card) =>
      getCreditCardCategories(card).includes(category) ||
      (category === "preQualifiedOffers" && card.isPreSelection) ||
      (category === "trending" && isTrendingProduct(card)),
  );

/**
 * Returns an array of up to 5 similar credit cards based on the selected card.
 *
 * @template TCreditCard - The type of credit card.
 * @param {TCreditCard[]} cards - The array of credit cards to search from.
 * @param {TCreditCard} selectedCard - The selected credit card.
 * @returns {TCreditCard[]} - An array of similar credit cards.
 */
export const getSimilarCreditCards = <TCreditCard extends ProductVerticalCreditCard>(
  cards: TCreditCard[],
  selectedCard: TCreditCard,
): TCreditCard[] => {
  const categories = getCreditCardCategories(selectedCard);
  const possibleSimilarCards =
    categories.length === 0 ? cards : getCreditCardsByCategory(categories[0], cards);
  return sortCreditCardsByPreSelectedOrder(
    possibleSimilarCards.filter((card) => card.id !== selectedCard.id),
  ).slice(0, 5);
};
