import LibraryMarkdown, { MarkdownToJSX } from "markdown-to-jsx";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";

import { Body2, Heading1, Heading2, Link2, List } from "../../../next";
import { IMarkdown, LinkStyleProps } from "./Markdown.types";
import { Superscript } from "./components";
import { formatLineBreaks } from "./utils";

type LinkRendererProps = LinkStyleProps & {
  href: string;
  onPress: (url: string) => void;
  handlers?: { [key: string]: (url: string) => void };
};

interface IImageRenderer {
  alt: string;
  className: string;
  src: string;
  title: string;
}

const responsiveImageStyle = {
  maxWidth: "100%",
  height: "auto",
};

const ImageRenderer = (props: IImageRenderer) => {
  return <img {...props} style={responsiveImageStyle} alt={props.alt} />;
};

const noop = () => null;

const LinkRenderer = (props: LinkRendererProps) => {
  const { handlers, href, onPress } = props;

  const onLinkPress = useCallback(() => {
    if (handlers?.[href]) return handlers?.[href](href);
    if (onPress) onPress(href);
    return null;
  }, [handlers, href, onPress]);

  return (
    <Link2
      {...props}
      {...(handlers?.[href] || !!onPress ? { onPress: onLinkPress, href: "#" } : { onPress: noop })}
    />
  );
};

export const Markdown = ({
  children = "",
  onLinkPress,
  handlers,
  color,
  style,
  testID,
  customTextStyles,
}: IMarkdown & { withSuperscript?: boolean }) => {
  const overrides = useMemo<MarkdownToJSX.Overrides>(
    () => ({
      h1: {
        component: Heading1,
        props: {
          color,
        },
      },
      h2: {
        component: Heading2,
        props: {
          color,
        },
      },
      h3: {
        component: Heading2,
        props: {
          color,
        },
      },
      h4: {
        component: Heading2,
        props: {
          color,
        },
      },
      h5: {
        component: Heading2,
        props: {
          color,
        },
      },
      a: {
        component: LinkRenderer,
        props: {
          handlers,
          onPress: onLinkPress ?? null,
          ...customTextStyles?.linkProps,
          testID: `${testID}-link`,
        },
      },
      p: {
        component: Body2,
        props: {
          color,
          style,
        },
      },
      img: ImageRenderer,
      span: {
        component: Body2,
        props: {
          color,
        },
      },
      ol: {
        component: List,
        props: {
          isOrdered: true,
          color,
        },
      },
      sup: {
        component: Superscript,
      },
      ul: {
        component: List,
        props: {
          isOrdered: false,
          color,
        },
      },
    }),
    [color, customTextStyles?.linkProps, handlers, onLinkPress, style, testID],
  );

  const markdownOptions = useMemo(
    () => ({
      forceBlock: true,
      wrapper: View,
      overrides: overrides,
    }),
    [overrides],
  );

  return (
    <LibraryMarkdown options={markdownOptions} testID={testID}>
      {formatLineBreaks(children)}
    </LibraryMarkdown>
  );
};
