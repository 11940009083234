import { RefCallback, useCallback, useEffect, useRef } from "react";

// Types
export type ScrollTriggerProps = {
  onVisible?: () => void;
};

// Component
export const ScrollTrigger = ({ onVisible }: ScrollTriggerProps) => {
  const observer = useRef<IntersectionObserver>();

  const observe: RefCallback<Element> = useCallback(
    (el: Element) => {
      if (!observer.current) {
        observer.current = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            onVisible?.();
          }
        });
      }

      try {
        observer.current.observe(el);
      } catch {}
    },
    [onVisible],
  );

  useEffect(() => () => observer.current?.disconnect(), []);

  return <div ref={observe} />;
};
