import { IndexRouteObject, NonIndexRouteObject } from "react-router-dom";

import { ROUTES } from "./routes";

import { QuickApplyCreditCard, quickApplyLoader } from "@app/screens/QuickApply";

export const quickApplyRoutes: (IndexRouteObject | NonIndexRouteObject)[] = [
  {
    path: ROUTES.CREDIT_CARDS_QUICK_APPLY,
    Component: QuickApplyCreditCard,
    loader: quickApplyLoader,
  },
];
