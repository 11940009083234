import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { BreadcrumbItemProps, Skeleton, Spacer } from "@bwll/bw-components/next";
import {
  useCreditCardComparison,
  useCreditCardComparisonAnalytics,
  useImpressionedCreditCards,
} from "@bwll/bw-hooks";
import { ConfirmationModal, CreditCardCompareTable, ScreenHeader } from "@bwll/bw-modules";
import { spacing } from "@bwll/bw-styles";
import { ImpressionedProductVerticalCreditCard, MARKETPLACE_ANALYTICS_ORIGINS } from "@bwll/bw-types";
import { generateTo } from "@bwll/bw-utils";

import { CREDIT_CARD_COMPARISON_TEST_IDS as TEST_IDS } from "./CreditCardComparison.constants";
import { useComparisonModal } from "./CreditCardComparison.hooks";

import { navigateToProduct } from "@app/helpers";
import { useMarketplaceTrackEvent } from "@app/hooks";
import { ROUTES } from "@app/router";

export const CreditCardComparison = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const trackEvent = useMarketplaceTrackEvent();
  const { trackProductClicked, trackProductViewed } = useCreditCardComparisonAnalytics(trackEvent);

  const [_, setSearchParams] = useSearchParams();

  const { modal, showLeaveModal, showRemoveModal } = useComparisonModal();

  // Data fetching
  const { comparison, removeCreditCard, clear, addCreditCardRange } = useCreditCardComparison();

  const { data, isLoading } = useImpressionedCreditCards();
  const [searchParams] = useSearchParams();

  const comparisonCardsSearchParam = "comparisonCards";

  const selectedCardIds = useMemo(
    () => searchParams.get(comparisonCardsSearchParam)?.split(","),
    [searchParams],
  );

  useEffect(() => {
    if (!isLoading && data?.productVerticalCreditCards && selectedCardIds) {
      // Maps card IDs to cards, retaining their order from the URL
      const selectedCards = selectedCardIds.reduce((acc, id) => {
        const card = data.productVerticalCreditCards.find((pvcc) => pvcc.id === id);
        if (card) acc.push(card);
        return acc;
      }, [] as ImpressionedProductVerticalCreditCard[]);

      if (comparison.cards.length === 0) {
        addCreditCardRange(selectedCards);
      }
    }
  }, [isLoading, data, selectedCardIds, comparison.cards.length, addCreditCardRange]);

  useEffect(() => {
    if (!selectedCardIds) {
      const existingSearchParams = Object.fromEntries(searchParams.entries());
      setSearchParams(
        {
          ...existingSearchParams,
          comparisonCards: comparison.cards.map((c) => c.id).join(","),
        },
        { replace: true },
      );
    }
  }, [comparison, selectedCardIds, setSearchParams]);

  useEffect(() => {
    if (comparison.cards.length < 2 && !selectedCardIds)
      return navigate(
        generateTo(ROUTES.CREDIT_CARDS, {
          searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_COMPARE },
        }),
      );
  }, []);

  // Event handlers
  const handleListPress = useCallback(() => {
    showLeaveModal(() => {
      clear();
      navigate(
        generateTo(ROUTES.CREDIT_CARDS, {
          searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_COMPARE },
        }),
      );
    });
  }, [clear, navigate, showLeaveModal]);

  const handleApplyPress = useCallback(
    (card: ImpressionedProductVerticalCreditCard) =>
      navigateToProduct(card, trackProductClicked.bind(this, card)),
    [trackProductClicked],
  );

  const handleDetailsPress = useCallback(
    ({ id }: ImpressionedProductVerticalCreditCard) => {
      clear();
      navigate(
        generateTo(ROUTES.CREDIT_CARDS_DETAILS, {
          pathParams: { productId: id },
          searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_COMPARE },
        }),
      );
    },
    [navigate],
  );

  const handleAddCardPress = useCallback(
    () =>
      navigate(
        generateTo(ROUTES.CREDIT_CARDS, {
          searchParams: { origin: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_COMPARE },
        }),
      ),
    [navigate],
  );

  const handleRemoveCardPress = useCallback(
    ({ id }: ImpressionedProductVerticalCreditCard) => {
      if (comparison.cards.length > 1) {
        showRemoveModal(() => {
          removeCreditCard(id);
        });
      } else {
        handleListPress();
      }
    },
    [comparison.cards.length, handleListPress, removeCreditCard, showRemoveModal],
  );

  const screenHeaderSection = useMemo(() => {
    const breadcrumbItems: BreadcrumbItemProps[] = [
      {
        label: t("marketplace:creditCards:title"),
        onPress: handleListPress,
        testID: TEST_IDS.CREDIT_CARDS_BREADCRUMB,
      },
      {
        label: t("marketplace:creditCards:compare:title"),
        testID: TEST_IDS.COMPARE_BREADCRUMB,
      },
    ];
    return <ScreenHeader breadcrumbItems={breadcrumbItems} />;
  }, [handleListPress, t]);

  useEffect(() => {
    comparison.cards.forEach((card) => {
      trackProductViewed(card);
    });
  }, []);

  if (isLoading)
    return (
      <>
        {screenHeaderSection}
        <Spacer height={spacing.xxs} />
        <Skeleton width="100%" height="800px" variant="rounded" />
      </>
    );

  return (
    <>
      {screenHeaderSection}
      <Spacer height={spacing.xxs} />
      <CreditCardCompareTable
        cards={comparison.cards}
        onDetailsPress={handleDetailsPress}
        onAddCardPress={handleAddCardPress}
        onApplyPress={handleApplyPress}
        onRemoveCardPress={handleRemoveCardPress}
      />
      <ConfirmationModal
        {...modal}
        testID={TEST_IDS.CONFIRM_MODAL}
        onCancelText={t("marketplace:creditCards:compare:cancel")}
      />
    </>
  );
};
