export const PRODUCT_DETAILS_TEST_IDS = {
  SCROLLVIEW: "scrollview",
  PRODUCT_BANNER_DESKTOP: "product-banner-desktop",
  PRODUCT_BANNER_MOBILE: "product-banner-mobile",
  APPLY_BUTTON_DESKTOP: "apply-button-desktop",
  APPLY_BUTTON_MOBILE: "apply-button-mobile",
  APPROVAL_CHANCE: "approval-chance",
  PRE_QUALIFIED_BANNER: "pre-qualified-banner",
  LEGAL: "legal",
  REWARDS: "rewards",
  LEARN_MORE_SHOW_MORE: "learn-more-show-more",
} as const;
