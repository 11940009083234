import React from "react";
import { QueryClient } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { initializeLocalization } from "@bwll/bw-locale";
import { SingleSpaCustomProps } from "@bwll/bw-types";

import { getAppRoutes } from "./router";

const { localizationReady } = initializeLocalization({ interpolation: {} });

const getRouter = (basename: string, queryClient: QueryClient) =>
  createBrowserRouter(getAppRoutes({ queryClient }), { basename });

export const App = ({
  activatedPath,
  queryClient,
}: Pick<SingleSpaCustomProps, "activatedPath" | "queryClient">) => {
  localizationReady();
  return (
    <React.StrictMode>
      <RouterProvider router={getRouter(activatedPath ?? "/", queryClient)} />
    </React.StrictMode>
  );
};

export default App;
