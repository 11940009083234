import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";
import { ENGLISH_LANGUAGE } from "@bwll/bw-types";
import { formatPercentage } from "@bwll/bw-utils";

export const PercentageText = ({ value, testID }: { value: number | null; testID?: string }) => {
  const i18next = useTranslation();

  const fallback = i18next.t("productCatalog:creditCards:notApplicable");

  const percentageValue =
    Number.isNaN(value) || value === null
      ? fallback
      : `${formatPercentage(Number(value), ENGLISH_LANGUAGE, Platform.OS === "android")}`;

  return (
    <Body2 variant="semi-bold" color={COLORS.NEUTRAL.WARM["800"]} testID={testID}>
      {percentageValue}
    </Body2>
  );
};
