import styled from "@emotion/native";

import { COLORS, WEB_SCREEN_MAX_WIDTH_WITH_PADDING } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const NAV_BAR_ITEM_MIN_WIDTH = 140;
export const NAV_BAR_ITEM_MIN_WIDTH_STACKED = 60;
const NAV_BAR_ITEM_MAX_WIDTH = 160;

export const NavBarActivityIndicator = styled.ActivityIndicator`
  height: 80px;
`;

// Used to take up the same amount of height as NavBarOuterContainer.
// This is used to prevent content from being hidden behind the NavBar,
// as the NavBarOuterContainer has position: fixed.
export const NavBarHeightSpacer = styled.View<{ containerHeight: number }>`
  height: ${({ containerHeight }) => styledValue(containerHeight)};
`;

export const NavBarOuterContainer = styled.View<{ isMobile: boolean }>`
  width: 100%;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ isMobile }) => (isMobile ? COLORS.WHITE : COLORS.PRIMARY["800"])};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

export const NavBarMaxWidthContainer = styled.View<{ linksCount: number }>`
  width: 100%;
  max-width: ${({ linksCount }) =>
    styledValue(Math.min(NAV_BAR_ITEM_MAX_WIDTH * linksCount, WEB_SCREEN_MAX_WIDTH_WITH_PADDING))};
`;

export const NavBarGridContainer = styled.View<{ linksCount: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ linksCount }) => `repeat(${linksCount}, 1fr)`};
  justify-content: center;
  align-items: stretch;
`;

// This component covers the whole screen to allow clicking on any part of the
// screen to close the dropdown
export const NavBarDropdownCloseOverlay = styled.Pressable<{ showDropdown: boolean }>`
  display: ${({ showDropdown }) => (showDropdown ? "block" : "none")};
  cursor: initial;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`;

export const NavBarDropdownContainer = styled.View<{ showDropdown: boolean }>`
  display: ${({ showDropdown }) => (showDropdown ? "block" : "none")};
  position: absolute;
  top: calc(100% + ${styledValue(2)});
  right: ${styledValue(2)};
  background-color: ${COLORS.WHITE};
  width: ${styledValue(120)};
  max-width: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: ${styledValue(4)};
  padding: ${styledValue(10)};
  z-index: 100;
`;
