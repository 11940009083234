import styled from "@emotion/native";

import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const styles = {
  nextStepsSection: {
    title: { fontSize: fontSize.xs },
    nextStepIcon: {
      alignSelf: "flex-start",
      paddingTop: 0,
      flex: "0 0 1.4em",
    },
    iconTextRowWrapper: { marginBottom: spacing.xxxs, flex: 1 },
    iconText: { marginLeft: spacing.xxs, marginBottom: spacing.xxxs },
    iconSubText: { marginLeft: spacing.xxs, fontSize: fontSize.xxs, lineHeight: spacing.xs },
  },
  aboutPymtHistorySection: {
    bulletIcon: {
      alignSelf: "flex-start",
      flex: "0 0 1.4em",
    },
    bulletIconText: { marginBottom: spacing.xxs, marginLeft: spacing.xxs },
  },
};

export const Container = styled.View`
  flex: 1 1 100%;
`;

export const TextContainer = styled.View`
  border-top-width: 1px;
  border-top-color: ${COLORS.NEUTRAL.WARM["200"]};
  padding: ${styledValue(spacing.xxs)} ${styledValue(spacing.m)};
`;

export const PaddingContainer = styled.View<{
  padding?: number;
}>`
  padding: ${({ padding }) => (padding !== undefined ? styledValue(padding) : styledValue(spacing.xxs))};
`;

export const CardContainer = styled.View<{
  padding?: number;
  borderColor?: string;
  backgroundColor?: string;
}>`
  padding: ${({ padding }) => (padding !== undefined ? styledValue(padding) : styledValue(spacing.xs))};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.WHITE};
  border: 1px solid ${({ borderColor }) => borderColor ?? COLORS.WHITE};
  border-radius: ${styledValue(spacing.xxs)};
`;

export const CardTitle = styled.View<{
  marginBottom?: number;
}>`
  font-weight: bold;
  color: ${COLORS.BLACK};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom !== undefined ? styledValue(marginBottom) : styledValue(spacing.xs)};
`;

export const StatusTitleInnerWrapper = styled.View`
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: row;
`;

export const PlainView = styled.View``;

export const RowWrapper = styled.View<{ gap?: number }>(({ gap }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: gap ?? 0,
}));

export const ImageContainer = styled.View<{ paddingTop?: number }>`
display: "flex";
justifyContent: "center";
alignItems: "center";
paddingTop: ${({ paddingTop }) => (paddingTop ? styledValue(paddingTop) : "1.2rem")};
height: imageSize ?? "3.2rem";
minHeight: "2.6rem";
"&>*:first-of-type": {
  marginRight: ${styledValue(spacing.xxs)},
  color: ${COLORS.NEUTRAL.WARM["700"]},
},
`;
