import styled, { css } from "@emotion/native";
import { Picker, PickerProps } from "@react-native-picker/picker";
import { Platform } from "react-native";

import { Caption, Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderRadius, borderWidth, fontSize, fonts, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { isWeb } from "../../../constants";

export const StyledDropdownContainer = styled.View(() => ({
  flex: 1,
  position: "relative",
}));

const StringPicker = (args: PickerProps<string>) => <Picker<string> {...args} />;

const commonDropdownStyle = ({
  focused,
  disabled,
  errored,
}: {
  focused: boolean;
  disabled: boolean;
  errored: boolean;
}) => ({
  padding: spacing.xxs,
  minHeight: spacing.xl + spacing.xs,
  fontFamily: isWeb ? `${fonts.lato.regular}, Lato` : fonts.lato.regular,
  fontSize: fontSize.xs,
  borderWidth: borderWidth.small,
  borderRadius: borderRadius.s,
  borderColor: errored ? COLORS.RED[700] : focused ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL[600],
  backgroundColor: disabled ? COLORS.NEUTRAL.COOL[200] : COLORS.WHITE,
  marginVertical: spacing.xxxs,
  color: disabled ? COLORS.NEUTRAL.COOL[600] : undefined,
  // Disables the default outline styles on the web
  ...Platform.select({
    web: {
      outlineColor: "transparent",
      outlineStyle: "solid",
    },
  }),
});

export const StyledDropdownComponent = styled(StringPicker, {
  // Picker writes all props to HTML, causing a warning
  shouldForwardProp: (prop) => prop !== "errored",
})(commonDropdownStyle);

export const StyledDropdownNativeComponent = styled.Pressable(commonDropdownStyle);

export const StyledLabelComponent = styled(Label)(({ errored }: { errored: boolean }) => ({
  color: errored ? COLORS.RED[700] : COLORS.NEUTRAL.WARM["700"],
}));

export const StyledCaptionComponent = styled(Caption)(
  ({ errored, color }: { errored: boolean; color?: string }) => ({
    color: errored ? COLORS.RED[700] : color ?? COLORS.NEUTRAL.WARM["700"],
  }),
);

export const androidPickerStyles = css`
  position: absolute;
  bottom: -2px;
  left: -8px;
  right: 0px;
`;

export const androidPickerItemStyles = css`
  color: ${COLORS.NEUTRAL.WARM[800]};
  font-size: ${styledValue(fontSize.xs)};
  font-family: ${fonts.lato.regular};
  top: ${styledValue(spacing.xs)};
`;

export const StyledDropdownComponentValueIOS = styled.View`
  flex: 1;
  justify-content: center;
`;

export const PickerContainerIOS = styled.View`
  background-color: ${COLORS.WHITE};
`;

export const BackdropIOS = styled.View`
  background-color: ${toRGBAString(COLORS.BLACK, 0.4)};
  flex-grow: 1;
`;

export const ModalContentIOS = styled.View`
  flex: 1;
`;

export const VerticalMarginFix = styled.View<{ matchTextInputMargin: boolean }>`
  ${({ matchTextInputMargin }) =>
    matchTextInputMargin &&
    `
  margin-top: ${styledValue(spacing.xxxs)};
  margin-bottom: ${styledValue(spacing.xxxs)};
  `}
`;
