import { ReactNode } from "react";

import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

import { TImpressionAnalyticsAttributes, TRAOnboardingAnalyticsAttributes } from "./featuresAttributes";
import { useHistoricalReportingEvents } from "./featuresEvents";
import { useCreditCoachUpdatesCardAnalytics } from "./useCreditCoachUpdatesCardAnalytics";
import { usePopupTrackEvents } from "./usePopupTrackEvents";
import { useProductTrackEvents } from "./useProductTrackEvents";
import { useRentAdvantageWidgetTrackEvents } from "./useRentAdvantageWidgetTrackEvents";
import { useSponsoredCarouselTrackEvents } from "./useSponsoredCarouselTrackEvents";

// Application common attributes that appear on any event.
type TCommonAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.INDIVIDUAL_CLIENT_ID]?: string;
  [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]?: boolean;
};

/**
 * Component-Level Attributes: Button
 *
 * Attributes from the component properties that are relevant to be tracked
 */
export type TButtonAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.BUTTON_NAME]: string;
};

/**
 * Component-Level Attributes: ElementTracker
 *
 * Possible event names for Impression Tracking
 */
export const enum IMPRESSION_EVENT_NAMES {
  IMPRESSION_VIEW = "Impression View",
  PRODUCT_VIEWED = "Product Viewed",
  PROMO_CARD_VIEWED = "Promo Card Viewed",
  SNACKBAR_VIEWED = "Snackbar Viewed",
  CREDIT_SCORE_VIEWED = "Credit Score - View Score",
  CREDIT_FACTOR_UPDATE_VIEWED = "Credit Factors Dashboard Summary",

  /** Needed to support the old data tracking on the dashboard (sponsored-carousel) */
  INSTANT_APPLY_VIEWED = "Instant Apply Viewed",
  RENT_REPORTING_CARD_VIEWED_MOBILE = "Rent Reporting Card Viewed",
  RENT_REPORTING_CARD_VIEWED_WEB = "Rent reporting card viewed",
}

/**
 * Component-Level Attributes: Link
 *
 * Possible event names for Impression Tracking
 */
export const enum ON_LINK_CLICK_EVENT_NAMES {
  BUTTON_CLICKED = "Button Clicked",
  LINK_CLICK = "Link Click",
  PRODUCT_CLICKED = "Product Clicked",
  PRODUCT_EXPANDED = "Product Expanded",
  PROMO_CARD_CLICKED = "Promo Card Clicked",
  SNACKBAR_CLICKED = "Snackbar Clicked",
  SNACKBAR_EXPANDED = "Snackbar Expanded",
  SNACKBAR_COLLAPSED = "Snackbar Collapsed",
  TAB_SWITCHED = "Tab Switched",
  DROPDOWN_CLICKED = "Dropdown Clicked",
  DROPDOWN_OPTION_CLICKED = "Dropdown Option Clicked",
  FILTER_SELECTED = "Filter Selected",
  FILTER_BUTTON_CLICKED = "Filter Button Clicked",
  TOOLTIP_OPENED = "Tooltip Opened",
  COMPARE_BUTTON_CLICKED = "Compare Button Clicked",
  COMPARE_CARD_ADDED = "Compare Card Added",

  /** Needed to support the old data tracking on the dashboard (sponsored-carousel) */
  INFO_CARD_CLICKED = "Info Card Clicked",
  INSTANT_APPLY_CLICKED = "Instant Apply Clicked",
  RENT_REPORTING_CARD_CLICKED_MOBILE = "Rent Reporting Card Clicked",
  RENT_REPORTING_CARD_CLICKED_WEB = "Rent reporting card clicked",
  /**
   * This weird and inconsistent event name should be included here because of this
   * magic happening in the mobile app under the hood: apps/mobile/src/utils/analytics/helpers.ts, line #17
   */
  __DEPRECATED_CLICK = "Click",
}

/**
 * Component-Level Attributes: Quick Apply
 *
 * Possible event names for Quick Apply / Prepopulation Flow
 */
export const enum PREPOPULATION_FLOW_EVENT_NAMES {
  EVENT_PREPOPULATION_FLOW_STARTED = "Prepopulation Flow Started",
  EVENT_PREPOPULATION_FLOW_SUBMITTED = "Prepopulation Flow Submitted",
  EVENT_PREPOPULATION_FLOW_SUCCESS = "Prepopulation Flow Success",
  EVENT_PREPOPULATION_FLOW_ERROR = "Prepopulation Flow Error",
  EVENT_PREPOPULATION_STEP_COMPLETED = "Prepopulation Step Completed",
  EVENT_VALIDATION_ERROR_SHOWN = "Validation Error Shown",
  EVENT_INPUT_OPTION_SELECTED = "Input Option Selected",
  EVENT_INPUT_OPTION_DESELECTED = "Input Option Deselected",
  EVENT_INPUT_FIELD_RECEIVED_FOCUS = "Input Field Received Focus",
  EVENT_INPUT_FIELD_LOST_FOCUS = "Input Field Lost Focus",
}

export type TLinkAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.LINK_TEXT]: string;
};

/**
 * Flow Names is used to complement event names, thereby, it's a required fields for shared analytics events.
 * The reasoning for that is to help organizing tables on Looker, which are separated by event names.
 *
 * E.g.:
 *      EVENT_NAME = "Button Click"
 *      FLOW_NAME = "Sign Up"
 *      ---------------------------
 *      Analytics Event Name = "Button Click: Sign Up"
 *
 */

export const enum FLOW_NAMES {
  RENT_ADVANTAGE_ONBOARDING = "Rent Advantage Onboarding",
  CREDIT_BUILDER = "Credit Builder",
  SIGN_UP = "Sign Up",
}

// Feature attributes that appear on any feature flow throughout the applications.
type TCommonFeatureAttributes = {
  [ANALYTICS_ATTRIBUTES.FLOW_NAME]?: FLOW_NAMES;
};

/**
 * List of all the feature types using shared analytics provider.
 * The features should be added with the | operator, so TypeScript is able to detect which attributes the event is using.
 *
 * E.g.:
 * export type TFeaturesAttributes = TRAOnboardingAnalyticsAttributes | TCreditBuilderAttributes;
 *
 */

export type TFeaturesAttributes = TRAOnboardingAnalyticsAttributes;

/**
 * TFeatureAnalyticsAttributes combines common attributes, which are sent for any feature flow, such as flowName.
 *
 * Generic TFeature purpose is to help selecting the required attributes on the screen level component.
 * The generics types are not mandatory, their goal is to serve as a guideline.
 *
 * E.g.:
 * const rentAdvantageAttributes: TFeaturesAttributes<TRAOnboardingAnalyticsAttributes> = {
 *   ...
 * }
 */
export type TFeatureAnalyticsAttributes<TFeature = TFeaturesAttributes> = TCommonFeatureAttributes & TFeature;

export type TSharedAnalyticsAttributes = {
  commonAttributes?: TCommonAnalyticsAttributes;
  trackEvent: (eventName: string, eventAttributes: TFeatureAnalyticsAttributes) => void;
};

export type TAnalyticsProviderProps = {
  children: ReactNode;
} & TSharedAnalyticsAttributes;

export type TAnalyticsContext = {
  onButtonClick: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  impressionView: <TCustomComponentAttributes = TImpressionAnalyticsAttributes>(args: {
    eventName?: IMPRESSION_EVENT_NAMES;
    eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
  }) => void;
  onLinkClick: <TCustomComponentAttributes = TLinkAnalyticsAttributes>(args: {
    eventName?: ON_LINK_CLICK_EVENT_NAMES;
    eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
  }) => void;
  // Dashboard
  snackbarViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  snackbarClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  snackbarExpanded: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  snackbarCollapsed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  promoCardViewed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => void;
  promoCardClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => void;
  creditFactorUpdateViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  sponsoredCarouselAnalytics: ReturnType<typeof useSponsoredCarouselTrackEvents>;
  productAnalytics: ReturnType<typeof useProductTrackEvents>;
  creditCoachUpdatesAnalytics: ReturnType<typeof useCreditCoachUpdatesCardAnalytics>;
  rentAdvantageWidgetAnalytics: ReturnType<typeof useRentAdvantageWidgetTrackEvents>;
  popupAnalytics: ReturnType<typeof usePopupTrackEvents>;

  // Rent Advantage
  onRentAdvantageScreenViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onRentAdvantageClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;

  // Marketplace
  onMarketplaceProductViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onMarketplaceProductClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onMarketplaceProductExpanded: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onMarketplaceDropdownOptionClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onMarketplaceDropdownClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onMarketplaceFilterSelected: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;

  // Credit Cards comparison
  onComparisonProductViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onComparisonProductApplyPressed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onComparisonProductDetailsPressed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onCompareProductClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;
  onCompareButtonClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => void;

  // Historical Reporting
  historicalReportingEvents: ReturnType<typeof useHistoricalReportingEvents>;
};
