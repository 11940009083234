import { IQuickApplyContext } from "@bwll/bw-hooks/src/contexts";

import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE } from "../../config";
import { PRODUCT_VERTICAL_GUID } from "../../promotion/productVertical";
import { NullableString } from "../helpers";
import { Accommodation } from "./fig";

export interface IQuickApplyRequest {
  firstname: string;
  lastName: string;
  birthdate: string;
  phoneNumber: string;
  email: string;
  streetAddress: string;
  streetAddress2: string;
  regionId: string;
  postalCode: string;
  city: string;
  productImpressionToken: string;
  productReferenceNumber: number;
  monthlyHousingCost?: number;
  timeAtAddress?: {
    months: number;
    years: number;
  };
  annualIncome?: number;
  housingStatus?: Accommodation;
}

export interface IQuickApplyErrorResponse {
  error: string;
}

export interface IQuickApplySuccessResponse {
  redirectUrl?: string;
  successDescriptionKey?: string;
}

export type MappedResponse = IQuickApplySuccessResponse | IQuickApplyErrorResponse;

export type ResponseMapper = (
  data: unknown,
  product: QuickApplyProduct,
  individualClientIdReferenceNumber: number,
) => MappedResponse;

/**
 * Represents a product that supports Quick Apply.
 */
export interface QuickApplyProduct {
  /**
   * The company offering the product.
   */
  companyName: NullableString;

  /**
   * The product vertical reference ID.
   */
  id: string;

  /**
   * The product image URL.
   */
  productImageUrl: NullableString;

  /**
   * A key for tracking user impressions of the product.
   */
  productImpressionId: string;

  /**
   * The product name.
   */
  productName: NullableString;

  /**
   * The product catalog ID (Knack ID).
   */
  productReferenceNumber: number;

  /**
   * The product vertical ID.
   */
  productVerticalId: NullableString;

  /**
   * A key for tracking the product in analytics.
   */
  trackingTag: NullableString;

  /**
   * The application URL.
   */
  websiteLink: NullableString;
}

/**
 * Represents a member's preferred communication language with a partner.
 */
export type QuickApplyPreferredLanguage = typeof ENGLISH_LANGUAGE | typeof FRENCH_LANGUAGE;

/**
 * Represents a member's employment status.
 * Used by LendingArch's leads API.
 */
export enum LendingArchQuickApplyEmploymentStatus {
  Employed = "EMPLOYED",
  Selfemployed = "SELF EMPLOYED",
  Retired = "RETIRED",
  Disability = "DISABILITY",
  Other = "OTHER",
  Unemployed = "UNEMPLOYED",
}

/**
 * Represents a member's employment status.
 * Used by Spring Financial's leads API.
 */
export enum SpringFinancialQuickApplyEmploymentStatus {
  Employed = "Employed",
  SelfEmployed = "Self Employed",
  Retired = "Retired/Pension",
  LongTermDisability = "Long Term Disability",
  MaternityLeave = "Maternity Leave",
  Seasonal = "Seasonal/Shortage of work",
  Temporary = "Temporary Layoff",
  Layoff = "Layoff",
  SpousalSupport = "Spousal Support/Alimony",
  ChildSupport = "Child Support",
  SocialAssistance = "Social Assistance",
  BabyBonus = "Baby Bonus/Child Tax",
  Other = "Other",
  NoCurrentIncome = "No Current Income",
}

export type QuickApplyEmploymentStatus =
  | LendingArchQuickApplyEmploymentStatus
  | SpringFinancialQuickApplyEmploymentStatus;

/**
 * Represents a member's duration. Used for Lending Arch duration inputs, which have the same structure.
 */
export enum QuickApplyLendingArchDuration {
  LessThanThreeMonths = 0,
  ThreeToSixMonths = 3,
  SixToTwelveMonths = 6,
  TwelveToTwentyFourMonths = 12,
  TwentyFourMonthsPlus = 24,
}

/**
 * Represents a member's desired vehicle type.
 */
export enum QuickApplyDesiredVehicle {
  Car = "CAR",
  Suv = "SUV",
  Truck = "TRUCK",
  Van = "VAN",
}

export const formFields = [
  "firstName",
  "lastName",
  "address1",
  "address2",
  "city",
  "region",
  "postalCode",
  "dob",
  "language",
  "phone",
  "email",
  "annualIncome",
  "housingStatus",
  "housingMonthlyCost",
  "housingTimeAtAddress",
  "loanAmount",
  "employmentStatus",
  "springEmploymentStatus",
  "monthlyIncome",
  "employmentDuration",
  "housingDuration",
  "companyName",
  "desiredVehicle",
] as const;

/**
 * The possible fields in a Quick Apply form.
 */
export type QuickApplyFormFields = (typeof formFields)[number];

export type MapRequestFormData = {
  context: IQuickApplyContext;
  annualIncome?: number;
  employmentStatus?: QuickApplyEmploymentStatus;
  springEmploymentStatus?: QuickApplyEmploymentStatus;
  housingStatus?: Accommodation;
  housingMonthlyCost?: number;
  housingTimeAtAddress?: Date;
  loanAmount?: number;
  monthlyIncome?: number;
  employmentDuration?: number;
  housingDuration?: number;
  companyName?: string;
  desiredVehicle?: QuickApplyDesiredVehicle;
  websiteDomain?: string;
};

export type PartnerSchema = {
  consent?: PartnerConsentSchema;
  formSchema: QuickApplyFormFields[];
  mapRequest: (formData: MapRequestFormData) => IQuickApplyRequest;
  onSuccess: (response: unknown) => void;
  onError: (response: IQuickApplyErrorResponse) => void;
};

export type QuickApplyPartner = (typeof QUICK_APPLY_PARTNERS)[keyof typeof QUICK_APPLY_PARTNERS];

export type PartnerLookup = {
  [key: string]: Record<string, QuickApplyPartner>;
};

export type UsePartnerLookupOptions = {
  onQuickApplyLoaded?: () => void;
  product: QuickApplyProduct;
  individualClientIdReferenceNumber: number;
};

export type PartnerConsentLink = {
  linkTranslationKey: string;
  url: string;
};

export type PartnerConsentSchema = {
  messageTranslationKey: string;
  links?: PartnerConsentLink[];
};

export const QUICK_APPLY_PARTNERS = {
  CapitalOne: "CapitalOne",
  Fairstone: "Fairstone",
  Carsfast: "Carsfast",
  SpringFinancial: "SpringFinancial",
  AutoLoans: "Autoloans",
  CarLoans: "Carloans",
} as const;

export const AutoLoansWebsiteDomains = {
  Autoloans: "Autoloans.ca",
  Carloans: "Carloans.ca",
} as const;

export const QUICK_APPLY_PARTNER_PRODUCTS: PartnerLookup = {
  [PRODUCT_VERTICAL_GUID.CREDIT_CARD as string]: {
    76: QUICK_APPLY_PARTNERS.CapitalOne,
    78: QUICK_APPLY_PARTNERS.CapitalOne,
    89: QUICK_APPLY_PARTNERS.CapitalOne,
    94: QUICK_APPLY_PARTNERS.CapitalOne,
    231: QUICK_APPLY_PARTNERS.CapitalOne,
    232: QUICK_APPLY_PARTNERS.CapitalOne,
    233: QUICK_APPLY_PARTNERS.CapitalOne,
  },
  [PRODUCT_VERTICAL_GUID.LOAN as string]: {
    9: QUICK_APPLY_PARTNERS.Fairstone,
    10: QUICK_APPLY_PARTNERS.Fairstone,
    39: QUICK_APPLY_PARTNERS.SpringFinancial,
    69: QUICK_APPLY_PARTNERS.AutoLoans,
    90: QUICK_APPLY_PARTNERS.AutoLoans,
    92: QUICK_APPLY_PARTNERS.CarLoans,
    93: QUICK_APPLY_PARTNERS.Fairstone,
    126: QUICK_APPLY_PARTNERS.Fairstone,
    127: QUICK_APPLY_PARTNERS.Fairstone,
    128: QUICK_APPLY_PARTNERS.Fairstone,
    129: QUICK_APPLY_PARTNERS.Fairstone,
    139: QUICK_APPLY_PARTNERS.Fairstone,
    163: QUICK_APPLY_PARTNERS.Carsfast,
    323: QUICK_APPLY_PARTNERS.SpringFinancial,
  },
};
