import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";

import { Icon } from "@bwll/bw-components/next";
import { BREAKPOINTS, COLORS, fontSize } from "@bwll/bw-styles";

import {
  Checklist,
  ChecklistHeader,
  ChecklistItem,
  Container,
  ItemText,
  Summary,
  SummaryText,
} from "./OfferChecklist.styles";
import { OfferChecklistProps } from "./OfferChecklist.types";

export const OfferChecklist = ({ summary, checklist, testID }: OfferChecklistProps) => {
  const i18next = useTranslation();
  const { width } = useWindowDimensions();
  const isMobileView = width <= BREAKPOINTS.MOBILE_MAXIMUM_WIDTH;
  return (
    <Container testID={testID}>
      <Summary isMobileView={isMobileView}>
        <SummaryText isMobileView={isMobileView}>{summary}</SummaryText>
      </Summary>
      <ChecklistHeader isMobileView={isMobileView}>
        {i18next.t("productCatalog:all:offerChecklist")}
      </ChecklistHeader>
      <Checklist isMobileView={isMobileView}>
        {checklist.map((checklistItem, id) => (
          <ChecklistItem isMobileView={isMobileView} key={id}>
            <View>
              <Icon
                icon="check_circle_filled"
                size={isMobileView ? fontSize.s : fontSize.xl}
                color={COLORS.BRIGHT_GREEK["400"]}
              />
            </View>
            <ItemText isMobileView={isMobileView}>{checklistItem}</ItemText>
          </ChecklistItem>
        ))}
      </Checklist>
    </Container>
  );
};
