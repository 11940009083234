import i18next from "i18next";

import { LikelihoodOfApprovalType } from "@bwll/bw-types";

export const getApprovalChanceTooltipText = (likelihoodOfApproval: LikelihoodOfApprovalType) => {
  const approvalChanceKeys: Record<string, string> = {
    [LikelihoodOfApprovalType.NOTELIGIBLE]: i18next.t("productCatalog:tooltip:approvalChance:notEligible"),
    [LikelihoodOfApprovalType.NONE]: i18next.t("productCatalog:tooltip:approvalChance:none"),
    [LikelihoodOfApprovalType.LOW]: i18next.t("productCatalog:tooltip:approvalChance:low"),
    [LikelihoodOfApprovalType.FAIR]: i18next.t("productCatalog:tooltip:approvalChance:fair"),
    [LikelihoodOfApprovalType.GOOD]: i18next.t("productCatalog:tooltip:approvalChance:good"),
    [LikelihoodOfApprovalType.EXCELLENT]: i18next.t("productCatalog:tooltip:approvalChance:excellent"),
  };

  return (
    approvalChanceKeys[likelihoodOfApproval as LikelihoodOfApprovalType] ??
    i18next.t("productCatalog:tooltip:approvalChance:notAvailable")
  );
};
