export const TEST_IDS = {
  CARD: "card",
  PRODUCT_BANNER: "product-banner",
  APPLY_BUTTON: "apply-button",
  DETAILS_BUTTON: "details-button",
  VALUE_PROPOSITION: "value-proposition",
  SAVING_OFFER: "saving-offers",
  CHEQUING_OFFERS: "chequing-offers",
  MONTHLY_FEE: "monthly-fee",
  TRANSACTIONS: "transactions",
  MAJOR_PROPOSITION_TEXT: "major-proposition",
  OFFER_END_DATE: "offer-end-date",
  VIEW_PRODUCT_BUTTON: "view-product-button",
  PRODUCT_NAME: "product-name",
  PRODUCT_CARD: "product-card",
  SAVINGS_OFFER_TEXT: "savings-offer-text",
  COMPANY_ICON: "company-icon",
  DISCLAIMER_TEXT: "disclaimer-text",
};

export const STYLED = {
  CARD_MARGIN_TOP: 24,
};
