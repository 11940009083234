import type { TooltipTextProps } from "./TooltipText.types";

/**
 * Component for showing the TooltipText.
 *
 * @component
 * @example
 * return (
 *   <TooltipText
 *     TypographyComponent={Body1}
 *     tooltipKey="World"
 *     color={COLORS.NEUTRAL.WARM["700"]}
 *     variant={"regular"}
 *   >
 *     Hello World
 *   </TooltipText>
 * )
 */

export const TooltipText = ({
  TypographyComponent,
  color,
  variant = "regular",
  children,
  tooltipKey,
}: TooltipTextProps) => {
  const [head, tail] = children.split(tooltipKey);

  return (
    <TypographyComponent color={color} variant={variant}>
      {head}
      <TypographyComponent underline color={color} variant={variant}>
        {tooltipKey}
      </TypographyComponent>
      {tail}
    </TypographyComponent>
  );
};
