import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Display2 } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";

import { QuickApplyBanner } from "../QuickApplyBanner";
import { QuickApplyPersonalDetailsForm } from "../QuickApplyPersonalDetailsForm";
import { QuickApplyProductHeader } from "../QuickApplyProductHeader";
import { QUICK_APPLY_FORM_TEST_IDS as TEST_IDS } from "./QuickApplyForm.constants";
import * as Styled from "./QuickApplyForm.styles";
import { QuickApplyFormProps } from "./QuickApplyForm.types";

/**
 * Renders the Quick Apply form.
 */
export const QuickApplyForm = ({
  quickApplyProduct,
  productVertical,
  onPersonalDetailsUpdate,
}: QuickApplyFormProps) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  const { productName, productImageUrl } = quickApplyProduct;
  const name = productName ?? "";
  const image = productImageUrl ? { uri: productImageUrl } : undefined;

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <QuickApplyBanner verticalId={productVertical} />
        <QuickApplyProductHeader name={name} image={image} />
      </Styled.HeaderContainer>
      <Styled.PersonalDetailsContainer>
        <Styled.PersonalDetailsHeading>
          {isMobile ? (
            <Styled.H2Mobile>{t("marketplace:quickApply:personalDetails:heading")}</Styled.H2Mobile>
          ) : (
            <Display2>{t("marketplace:quickApply:personalDetails:heading")}</Display2>
          )}
          <Styled.InlineButton
            iconAlignment="left"
            iconName="edit_active"
            onPress={() => onPersonalDetailsUpdate?.()}
            title={t("marketplace:quickApply:buttons:update")}
            type={BUTTON_TYPE.TERTIARY}
            testID={TEST_IDS.BUTTON_UPDATE}
          />
        </Styled.PersonalDetailsHeading>
        <QuickApplyPersonalDetailsForm />
      </Styled.PersonalDetailsContainer>
    </Styled.Container>
  );
};
