import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { COLORS, mq } from "@bwll/bw-styles";
import { fontSize } from "@bwll/bw-styles/src/typography";

export const NavBarDropdownLinkContainer = styled(Link)(() =>
  mq({
    minHeight: 40,
    padding: 5,
    display: "flex",
    textDecoration: "none",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.NEUTRAL.WARM[100],
    },
  }),
);

export const NavBarDropdownLinkIconContainer = styled.div<{ isSelected: boolean }>(({ isSelected }) =>
  mq({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 8,
    paddingBottom: 0,
    "& svg": {
      color: isSelected ? COLORS.PRIMARY[700] : COLORS.NEUTRAL.WARM[700],
    },
  }),
);

export const NavBarDropdownLinkTitle = styled.div<{ isSelected: boolean }>(({ isSelected }) =>
  mq({
    fontFamily: "Lato-Regular, Lato, sans-serif",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    color: isSelected ? COLORS.PRIMARY[700] : COLORS.NEUTRAL.WARM[700],
    fontSize: fontSize.xxxs,
  }),
);
