export const dashboard = {
  "lastUpdated": "Votre cote a été mise à jour",
  "scrollTitle": "Borrowell",
  "subTitle": "Voici un aperçu de votre dossier en date du {{lastUpdated}}",
  "title": "Bienvenue, {{firstName}}",
  "applyButton": "FAIRE UNE DEMANDE",
  "backToDashboard": "Dashboard",
  "verticalCards": {
    "title": "Explore all your offers",
    "loans": {
      "title": "Loans"
    },
    "creditCards": {
      "title": "Credit Cards"
    },
    "autoLoans": {
      "title": "Auto Loans"
    },
    "mortgages": {
      "title": "Mortgages"
    },
    "bankAccounts": {
      "title": "Bank Accounts"
    },
    "investings": {
      "title": "Investings"
    },
    "insurance": {
      "title": "Insurance"
    }
  },
  "illustrativePromo": {
    "v1": {
      "title": "Vos recommandations",
      "body": "{{username}}, nous avons des recommandations basées sur votre profil de crédit.",
      "cta": "Voir les recommandations",
      "ctaBadgeContent": "6"
    },
    "v2": {
      "title": "Vos recommandations",
      "body": "{{username}}, nous avons des recommandations basées sur votre profil de crédit",
      "cta": "EXPLORER MAINTENANT",
      "ctaBadgeContent": "6"
    },
    "v3": {
      "title": "Cartes de crédit, prêts et bien plus encore!",
      "body": "Explorer une gamme de produits financiers",
      "cta": "Explorer maintenant",
      "ctaBadgeContent": "6"
    },
    "v4": {
      "title": "Cartes de crédit, prêts et bien plus encore!",
      "body": "Explorer une gamme de produits financiers",
      "cta": "EXPLORER MAINTENANT",
      "ctaBadgeContent": "6"
    }
  },
  "cblPromoCardV2": {
    "title": "Établissez votre historique de crédit",
    "body": "Établissez votre historique et votre combinaison de crédit, ce qui vous permettra de prétendre à plus de prêts, de cartes de crédit et bien plus encore!",
    "cta": "EN SAVOIR PLUS"
  },
  "rentReportingPromoCard": {
    "default": {
      "title": "Établissez votre historique de crédit",
      "body": "Déclarez votre loyer sur votre dossier de crédit Equifax Canada et commencez à établir votre historique de crédit!",
      "cta": "EXPLORER MAINTENANT"
    },
    "v1": {
      "title": "Établissez votre historique de crédit pour atteindre vos objectifs financiers",
      "body": "Déclarez votre loyer sur votre dossier de crédit Equifax Canada et commencez à établir votre historique de crédit!",
      "cta": "EXPLORER MAINTENANT"
    },
    "v2": {
      "title": "Établissez votre historique de crédit pour atteindre vos objectifs financiers",
      "body": "Établissez votre historique de crédit grâce aux paiements de loyer que vous effectuez déjà!",
      "cta": "EXPLORER MAINTENANT"
    }
  },
  "poweredByBorrowell": "Propulsé par Borrowell",
  "loading": "Chargement du tableau de bord...",
  "creditCoachTitle": "Je suis Molly, votre coach en crédit. Je peux vous aider à comprendre votre cote de crédit.",
  "creditCoachLink": "Visiter mon coach en crédit",
  "cards": {
    "appReview": {
      "title": "Vous aimez l'application?",
      "yes": "Oui!",
      "no": "Pas vraiment"
    },
    "appReviewV2": {
      "title": "How are you enjoying the app?",
      "subtitle": "Your feedback plays an important role in our continuous efforts to improve our app for you.",
      "yes": "It's Great!",
      "no": "Not really",
    },
    "appRate": {
      "title": "Nous sommes ravis que vous l'appréciez 😃",
      "prompt": "Nous vous serions reconnaissants de nous laisser une note sur {{storeName}}!",
      "confirmButton": "NOTER L'APPLICATION",
      "notNowButton": "PAS MAINTENANT"
    },
    "feedback": {
      "title": "Aidez-nous à nous améliorer 🙂",
      "prompt": "Pourriez-vous nous faire part de vos commentaires sur ce que nous pourrions améliorer?",
      "confirmButton": "BIEN SÛR",
      "notNowButton": "PAS MAINTENANT"
    },
    "notificationsPrompt": {
      "titlePrefix": "Votre cote sera mise à jour dans ",
      "titleSuffix": ".\nSoyez averti quand la nouvelle cote sera disponible!",
      "prompt": "RECEVOIR DES NOTIFICATIONS"
    },
    "notificationsPromptV2": {
      "title": "Get notifications to stay informed",
      "subtitle": "Get notified when your score updates today, and never miss important changes in the future.",
      "buttonCtaCopy": "GET NOTIFICATIONS",
    },
    "creditCoachTips": {
      "moreUpdate": "VOIR 1 AUTRE MISE À JOUR",
      "moreUpdates": "VOIR {{numberOfUpdates}} MISES À JOUR SUPPLÉMENTAIRES",
      "viewUpdates": "VIEW UPDATES"
    },
    "promotedProduct": {
      "sponsored": "Commandité",
      "applyNow": "FAIRE UNE DEMANDE",
      "approvalChance": "Probabilité d'approbation",
      "annualFee": "Frais annuels",
      "purchaseRate": "Taux d'achat",
      "cashAdvance": "Avance de fonds",
      "productDisclosure": "Déclaration à propos des produits commandités",
      "disclosureDescription": "Les offres qui apparaissent sur cette application proviennent d'annonceurs tiers dont Borrowell reçoit une compensation. Cette compensation peut avoir un impact sur la manière dont les produits apparaissent sur ce site (y compris, par exemple, l'ordre dans lequel ils apparaissent). C'est cette compensation qui permet à Borrowell de vous fournir des services tels que l'accès gratuit à votre cote et à votre dossier de crédit. Borrowell s'efforce de proposer un large éventail d'offres à ses membres, mais nos offres ne représentent pas toutes les sociétés de services financiers ou tous les produits."
    },
    "scoreTracker": {
      "pts": "{{points}} pts",
      "scoreState": "Your score is {{state}}",
      "noCreditScore": "No credit score",
      "state": {
        "POOR": "poor",
        "FAIR": "fair",
        "GOOD": "good",
        "VERY_GOOD": "very good",
        "EXCELLENT": "excellent"
      },
      "updated": "Updated {{date}}",
      "nextUpdate": "Next in a day",
      "nextUpdate_plural": "Next in {{days}} days",
      "nextUpdate_today": "Next update today",
      "tooltip": {
        "title": "About credit score range",
        "body": {
          "zero": "Your credit score may be zero due to two common reasons: Firstly, if you lack open credit accounts beyond a cell phone bill, Equifax lacks data to generate a score. Secondly, Equifax may have multiple files for you, resulting in incomplete reports. Contact Equifax at 1-866-828-5961 to merge files for accurate scores and reports.",
          "POOR": "300 to 599: Poor\n\nIndividuals in this range often have difficulty being approved for new credit. If you find yourself in the poor category, it's likely you'll need to take steps to improve your credit scores before you can secure any new credit.",
          "FAIR": "600 to 659: Fair\n\nIndividuals in this category are often considered “subprime” borrowers. Lenders may consider them higher-risk, and they may have trouble qualifying for new credit.",
          "GOOD": "660 to 724: Good\n\nLenders generally view those with credit scores of 660 and up as acceptable or lower-risk borrowers.",
          "VERY_GOOD": "725 to 759: Very good\n\nIndividuals in this range have demonstrated a history of positive credit behavior and may have an easier time being approved for additional credit.",
          "EXCELLENT": "760 to 900: Excellent\n\nIndividuals in this range are considered to be low-risk borrowers. They may have an easier time securing a loan than borrowers with lower scores."
        },
        "footNote": {
          "title": "Calculated with ERS 2.0",
          "content": "ERS 2.0 (the Equifax Risk Score) is based on Equifax's proprietary model and may not be the same score used by third parties to assess your creditworthiness. The provision of this score to you is intended for your own educational use. Third parties will take into consideration other information in addition to a credit score when evaluating your creditworthiness.",
          "link": "Learn more about credit scores"
        }
      },
      "noChangeMarkdown": "**Pas de changement**",
      "upMarkdown": "Hausse de **{{count}} point **",
      "downMarkdown": "Baisse de **{{count}} point**",
      "upMarkdown_plural": "Hausse de **{{count}} points **",
      "downMarkdown_plural": "Baisse de **{{count}} points**",
      "zeroMarkdown": "**Cote zéro**",
      "noChange": "Pas de changement",
      "up": "Hausse",
      "down": "Baisse",
      "zero": "Cote zéro",
      "pointsDifference": "{{ count }} point",
      "pointsDifference_plural": "{{ count }} points",
      "whatDoesScoreMean": "Que signifie ma cote de crédit?",
      "whatDoesZeroScoreMean": "Que signifie une cote de crédit à zéro?",
      "ers": {
        "shortTitle": "Calculée avec ERS 2.0",
        "title": "Cote calculée à l'aide de ERS 2.0",
        "tooltip": {
          "title": "À propos d'ERS 2.0",
          "content": "ERS 2.0 (la cote de risque d'Equifax) est basé sur le modèle exclusif d'Equifax et peut ne pas être la même cote que celle utilisée par des tiers pour évaluer votre solvabilité. Cette cote est destinée à votre propre usage éducatif. Les tiers prendront en considération d'autres informations en plus de la cote de crédit pour évaluer votre solvabilité.",
          "link": "En savoir plus sur les cotes de crédit"
        }
      },
      "today": "Aujourd'hui",
      "nextUpdateIs": "La prochaine mise à jour est",
      "learnMore": "En savoir plus",
      "inNumDays": "sur {{count}} jours",
      "inNumDaysToday": "aujourd'hui",
      "inNumDaysTomorrow": "demain",
      "help": {
        "title": "Que signifie ma cote de crédit?",
        "zeroScore": "Que signifie une cote de crédit à zéro?",
        "paragraph1": "Votre cote de crédit est ",
        "excellent": "Avec une excellente cote, vous pouvez être admissible à une grande variété de produits financiers aux meilleurs taux possibles. Continuez sur cette bonne lancée.",
        "good": "Avec une bonne cote de crédit, vous pouvez bénéficier d'une variété d'options de crédit avec des taux d'intérêt parmi les plus bas du marché.",
        "fair": "Avec une cote de crédit acceptable, vous pouvez être admissible à du crédit. Vos options peuvent toutefois être quelque peu limitées et vous n'obtiendrez peut-être pas le meilleur taux.",
        "below": "Avec une cote de crédit inférieure à la moyenne, vous pouvez être admissible à du crédit. Vos options peuvent toutefois être quelque peu limitées et vous n'obtiendrez peut-être pas le meilleur taux.",
        "poor": "Avec une mauvaise cote de crédit, vous risquez d'avoir du mal à obtenir du crédit."
      },
      "toDoNext": {
        "title": "Ce que vous pourriez faire maintenant",
        "creditCoach": "Consultez le coach en crédit pour voir comment vous améliorer",
        "recommendations": "Voyez les produits financiers auxquels vous êtes admissible"
      }
    },
    "sponsoredCarousel": {
      "yourOffers": "Vos offres",
      "cardShowing": "Affichage de {{curCard}} de {{numCards}}",
      "percentageTransform": "{{value}} %",
      "dollarSignTransform": "{{value}} $",
      "sponsoredHeaderSuffix": " | Commandité",
      "sponsoredCards": {
        "approvalChance": "Probabilité d'approbation :",
        "headerToolTip": "Borrowell reçoit une rémunération supplémentaire pour les produits portant la mention « Commandité ». Cette rémunération permet à Borrowell de vous fournir des outils gratuits comme votre cote de crédit hebdomadaire.",
        "preSelectedBanner": "PRÉSÉLECTIONNÉ",
        "cta": "VOIR LE PRODUIT",
        "loans": {
          "header": "Prêt",
          "payment": "Paiement",
          "loanAmount": "Montant du prêt",
          "apr": "TAEG",
          "term": "Terme"
        },
        "creditCards": {
          "header": "Carte de crédit",
          "preSelectedBanner": "Présélectionné",
          "annualFee": "Frais annuels",
          "interest": "Intérêt",
          "cashAdvance": "Avance de fonds",
          "balanceTransfer": "Transfert de solde"
        },
        "autoLoans": {
          "header": "Prêt automobile"
        },
        "figLoan": {
          "cta": {
            "preApproved": "INSTANT APPLY",
            "notStarted": "GET MY INSTANT QUOTE"
          },
          "title": "Borrowell Personal Loan",
          "preSelectedBanner": "You’re pre-approved!",
          "titleTooltip": "Based on your credit report and Borrowell account profile information, you’ve been pre-approved for this loan from Fig Financial. You meet the qualification criteria and have an excellent chance of being approved for this loan!",
          "loanAmount": "Loan Amount",
          "monthlyPayment": "Monthly Payment",
          "monthlyPaymentAmount": "{{amount}}/mo",
          "apr": "APR",
          "term": "Term",
          "termDuration": "{{term}} months",
          "daysLeft": "Act fast! Your offer will expire in {{count}} day",
          "daysLeft_plural": "Act fast! Your offer will expire in {{count}} days"
        },
        "insurance": {
          "header": "Assurances"
        },
        "bankAccounts": {
          "header": "Comptes bancaires"
        },
        "mortgages": {
          "header": "Hypothèque"
        },
        "investments": {
          "header": "Investir"
        }
      }
    },
    "ltoCarousel": {
      "label": "Showing {{currentItem}} of {{totalItems}}",
      "primaryAction": "VIEW PRODUCT",
      "primaryActionQuickApply": "APPLY",
      "secondaryAction": "DETAILS",
      "offerEndDatePrefix": "ENDS {{offerEndDate}}"
    },
    "sponsoredCarouselV2": {
      "title": "More offers",
      "label": "Showing {{currentItem}} of {{totalItems}}",
      "cta": "VIEW PRODUCT",
      "sponsoredHeaderSuffix": "Commandité"
    },
    "firstLoginCarousel": {
      "title": "Comment nous pouvons vous aider"
    },
    "preSelected": {
      "approvalChance": "Probabilité d'approbation",
      "loans": {
        "payment": "Paiement",
        "amount": "Montant",
        "apr": "TAEG",
        "term": "Terme"
      },
      "creditCards": {
        "header": "Carte de crédit | Commandité",
        "headerToolTip": "Borrowell reçoit une rémunération supplémentaire pour les produits portant la mention « Commandité ». Cette rémunération permet à Borrowell de vous fournir des outils gratuits comme votre cote de crédit hebdomadaire.",
        "preSelectedBanner": "Présélectionné",
        "annualFee": "Frais annuels",
        "interest": "Intérêt",
        "cashAdvance": "Avance de fonds",
        "balanceTransfer": "Transfert de solde",
        "cta": "FAIRE UNE DEMANDE"
      },
      "fairstone": {
        "quickApply": "Demande rapide",
        "v1": {
          "title": "Vous avez été présélectionné pour un prêt de Fairstone!",
          "description": "Utilisez-le pour consolider vos dettes, couvrir des dépenses imprévues ou offrir une certaine flexibilité à votre budget.",
          "cta": "VOIR L'OFFRE"
        },
        "v2": {
          "title": "Fairstone",
          "description": "Utilisez ce prêt pour consolider vos dettes.",
          "quickApplyCta": "FAIRE UNE DEMANDE",
          "topBanner": "VOUS ÊTES PRÉSÉLECTIONNÉ",
          "cta": "VOIR TOUS LES DÉTAILS"
        },
        "v3": {
          "title": "Vous êtes présélectionné!",
          "description": "Utilisez ce prêt pour consolider vos dettes.",
          "cta": "VOIR L'OFFRE"
        }
      },
      "ccc": {
        "title": "Vous avez été présélectionné pour un prêt de Consumer Capital Canada",
        "description": "Faites votre demande de prêt en ligne dès aujourd'hui et empruntez jusqu'à {{amount}} à des taux compétitifs.",
        "cta": "VOIR L'OFFRE"
      },
      "mbna": {
        "title": "Vous avez été présélectionné pour demander une carte de crédit auprès de MBNA!",
        "description": "Vous pourriez obtenir un taux d'intérêt annuel (« TIA »)† promotionnel de 0 % pendant 12 mois sur les transferts de solde effectués dans les 90 jours suivant l'ouverture du compte. Des conditions et frais s'appliquent.",
        "cta": "VOIR L'OFFRE"
      },
      "amex": {
        "title": "Vous êtes préqualifié pour {{ cardName }}",
        "description": "Profitez des avantages de devenir titulaire de la Carte American Express®. Des conditions s'appliquent.",
        "cta": "VOIR LES DÉTAILS",
        "dynamicDescription": {
          "cobalt": "Obtenez jusqu'à 50 000 points Membership Rewards® la première année. Des conditions de dépenses et d'autres conditions s'appliquent.",
          "aeroplanReserve": "Rehausser chaque instant de votre expérience.",
          "aeroplan": "Trouvez votre bonheur partout où vous allez.",
          "platinum": "Découvrez des récompenses qui peuvent vous emmener loin, avec l'accès à des avantages qui rehaussent chaque expérience.",
          "gold": "Obtenez des points sur vos voyages et vos achats quotidiens pour arrêter de rêver et commencer à vivre vos vacances parfaites.",
          "green": "Obtenez une prime de bienvenue de 10 000 points Membership Rewards®. Des conditions de dépenses et d'autres conditions s'appliquent.",
          "simplyCashPreferred": "Obtenez jusqu'à 400 $ de remise en argent au cours des 4 premiers mois. Des conditions de dépenses et d'autres conditions s'appliquent.",
          "simplyCash": "Obtenez 4 % de remise en argent au cours des 6 premiers mois (jusqu'à 200 $ de remise en argent). Des conditions de dépenses et d'autres conditions s'appliquent.",
          "marriott": "Près de chez vous ou à l'étranger, accumulez des points où que vous alliez et échangez-les quand vous le souhaitez.",
          "ram": "Bénéficiez d'avantages exceptionnels qui font de chaque escapade un souvenir inoubliable, tout en obtenant des Miles.",
          "pam": "Obtenez une prime de bienvenue de 2 000 milles AIR MILES®* en prime. Des conditions de dépenses et d'autres conditions s'appliquent.",
          "sam": "Obtenez une prime de bienvenue de 500 milles AIR MILES®* en prime. Des conditions de dépenses et d'autres conditions s'appliquent."
        }
      },
      "bmo": {
        "title": "Vous êtes préqualifié pour {{ cardName }}",
        "description": "Bénéficiez des avantages de la carte BMO.",
        "cta": "VOIR L'OFFRE",
        "dynamicDescription": {
          "noFeeCashBack": "Profitez de la plus importante remise en argent sur l'épicerie au Canada sans frais annuels.*",
          "noFeeAmex": "Voyagez plus loin, plus vite, en obtenant des milles de récompense AIR MILES pour chaque achat.",
          "eclipseVisaRoc": "Une seule carte. Une infinité de façons d'être récompensé.",
          "eclipseVisaQc": "Une seule carte. Une infinité de façons d'être récompensé.",
          "cashBackWorldElite": "La seule carte qui donne droit à une remise en argent de 5 % sur les achats en épicerie.*",
          "airMilesWorldElite": "Maximisez vos Miles! Profitez plus rapidement de vos récompenses et de vos avantages de voyage.",
          "worldElite": "Profitez d'un monde de récompenses supplémentaires à la maison et à l'étranger grâce à un taux de remise élevé et à des avantages pour les voyageurs.",
          "bmoRewards": "Gagnez des points à chaque fois que vous dépensez et voyez les possibilités s'accumuler.",
          "preferred": "Accordez-vous un peu de répit avec notre taux d'intérêt le plus bas."
        }
      },
      "skycap": {
        "title": "SkyCap Financial vous a présélectionné pour un prêt!",
        "description": "Vous avez besoin d'aide pour faire face à des moments difficiles? Chez SkyCap, nous sommes là pour vous. Nous acceptons quand les banques refusent.",
        "cta": "VOIR L'OFFRE"
      }
    },
    "creditFactors": {
      "title": "Qu’est-ce qui affecte votre cote",
      "missedPaymentsTitle": "Paiements manqués",
      "creditInquiriesTitle": "Enquêtes de crédit",
      "creditInquiriesV2Title": "Enquêtes approfondies",
      "creditUtilizationTitle": "Utilisation du crédit",
      "derogatoryMarksTitle": "Marques dérogatoires",
      "avgCreditAgeTitle": "L’ancienneté moyenne du crédit",
      "totalAccountsTitle": "Nombre total de comptes",
      "viewDetails": "VOIR LES DÉTAILS",
      "viewMoreDetails": "VOIR PLUS DE DÉTAILS",
      "updates": {
        "placeholder": "{{amount}} élément(s) modifié(s)",
        "missedPayment": "{{amount}} paiement manqué",
        "missedPayment_plural": "{{amount}} paiements manqués",
        "creditInquiriesNew": "{{amount}} nouvelle enquête de crédit"
      },
      "tooltips": {
        "missedPayments": "Les paiements manqués sont l'ensemble des paiements de factures que le prêteur a signalé comme n'ayant pas été effectués à temps.",
        "missedPaymentsImpact": "Les paiements manqués ont un impact important sur votre cote de crédit et compte pour environ 35 % de votre cote.",
        "creditInquiries": "Une enquête de crédit est une demande d'examen de votre dossier de crédit par un prêteur ou une entreprise. Elle peut rester dans votre dossier pendant trois ans. Les enquêtes de crédit que vous voyez ici sont uniquement des enquêtes de crédit approfondies.",
        "creditInquiriesImpact": "Les enquêtes de crédit ont un faible impact sur votre cote de crédit et compte pour environ 10 % de votre cote.",
        "creditUtilization": "L'utilisation du crédit détermine la proportion de la limite totale de votre carte de crédit et de votre marge de crédit utilisée.",
        "creditUtilizationImpact": "L'utilisation du crédit a un impact important sur votre cote de crédit et compte pour environ 30 % de votre cote.",
        "derogatoryMarks": "Les marques dérogatoires sont des événements comme des recouvrements, des faillites ou des éléments juridiques sur votre dossier de crédit qui indiquent que vous n'avez pas rempli vos obligations en matière de crédit. Ces événements peuvent rester sur votre dossier de crédit de 6 à 10 ans et ont un impact négatif sur votre crédit.",
        "derogatoryMarksImpact": "Les marques dérogatoires ont un impact important sur votre cote de crédit et la présence d'une seule marque dérogatoire peut avoir un impact négatif significatif sur votre cote.",
        "avgCreditAge": "L'ancienneté moyenne du crédit correspond à la durée moyenne pendant laquelle vous avez eu des comptes de crédit ouverts.",
        "avgCreditAgeImpact": "L'ancienneté moyenne du crédit a un impact moyen sur votre cote de crédit et compte pour environ 15 % de votre cote.",
        "totalAccounts": "Il s'agit du nombre de comptes ouverts que vous possédez. Les prêteurs cherchent à savoir si vous avez utilisé une combinaison de comptes de crédit de manière responsable.",
        "totalAccountsImpact": "Le nombre total de comptes que vous possédez n'a qu'un faible impact sur votre cote de crédit et compte pour environ 10 % de votre cote."
      },
      "month": "{{count}} mois",
      "month_plural": "{{count}} mois",
      "year": "{{count}} année",
      "year_plural": "{{count}} ans",
      "yr": "{{count}} année",
      "yr_plural": "{{count}} ans",
      "mo": "{{count}} mois",
      "mo_plural": "{{count}} mois",
      "impact": {
        "low": "Faible",
        "medium": "Moyen",
        "high": "Élevé"
      },
      "scoreImpactTitle": "IMPACT SUR LA COTE"
    }
  },
  "reengagingMemberBannerMessage": "Nous sommes en train de mettre à jour votre cote de crédit et cela peut prendre un certain temps. Voici votre cote de crédit en date du {{date}}",
  "systemIssueMessage": "En raison de la panne d'Equifax, il peut y avoir un retard dans la précision de votre score de crédit.",
  "topPicks": {
    "title": "Top picks for Prime Credit Score"
  },
  "topPicksV2": {
    "title": "Top picks for your credit score",
    "cluPromoCard": {
      "title": "Lower your credit utilization",
      "body": "Spending less and exploring better credit card options could help lower your {{utilization}}% credit utilization.",
      "cta": "GET MY OFFER"
    }
  },
  "bestValueCreditCards": {
    "headerSuffix": " FIRST-YEAR VALUE"
  },
  "recommendationsSnackbar": {
    "body": "Compare personalized product recommendations based on your credit profile",
    "bodyBadCredit": "Compare product recommendations to help you build credit",
    "buttonText": "VIEW OFFERS"
  },
  "productRecommendationsPromoCard": {
    "body": "{{username}}, we have recommendations based on your credit profile",
    "title": "Recommendations",
    "buttonText": "VIEW ALL"
  },
  "marketplaceSnackbar": {
    "accordionCtaTitleCollapsed": "SHOW MORE",
    "accordionCtaTitleExpanded": "SHOW LESS"
  },
  "hierarchyPromoCard": {
    "buttonText": "View Offer"
  },
  "popup": {
    "newBadgeText": "New",
    "dismissButton": "Close"
  }
}
