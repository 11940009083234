import { PRODUCT_VERTICAL_GUID } from "../../promotion";
import { Nullable, NullableString } from "../helpers";

export enum LikelihoodOfApprovalType {
  "NONE",
  "LOW",
  "FAIR",
  "GOOD",
  "EXCELLENT",
  "NOTELIGIBLE",
}

export enum LIKELIHOOD_OF_APPROVAL {
  NONE = "None",
  LOW = "Low",
  FAIR = "Fair",
  GOOD = "Good",
  EXCELLENT = "Excellent",
  NOTELIGIBLE = "Not eligible",
}

export const LIKELIHOOD_OF_APPROVAL_LABEL_MAPPING = {
  [LikelihoodOfApprovalType.EXCELLENT]: LIKELIHOOD_OF_APPROVAL.EXCELLENT,
  [LikelihoodOfApprovalType.FAIR]: LIKELIHOOD_OF_APPROVAL.FAIR,
  [LikelihoodOfApprovalType.GOOD]: LIKELIHOOD_OF_APPROVAL.GOOD,
  [LikelihoodOfApprovalType.LOW]: LIKELIHOOD_OF_APPROVAL.LOW,
  [LikelihoodOfApprovalType.NONE]: LIKELIHOOD_OF_APPROVAL.NONE,
  [LikelihoodOfApprovalType.NOTELIGIBLE]: LIKELIHOOD_OF_APPROVAL.NOTELIGIBLE,
};

/** Based on https://github.com/Borrowell/bw-product-verticals-ms/blob/master/server/src/Gemstone.ProductVerticals.Api/Models/ProductVerticalViewModel.cs */
export interface ProductVerticalBase {
  approvalChance: NullableString;
  customerNeeds: string[];
  gpsObjective: NullableString;
  gpsObjectives: string[];
  gpsRelevanceOrder: Nullable<number>;
  isBackupProduct: boolean;
  likelihoodOfApproval: Nullable<number>;
  likelihoodOfApprovalType: number;
  preMemberLink: NullableString;
  preMemberRank: Nullable<number>;
  productVerticalId: Nullable<PRODUCT_VERTICAL_GUID>;
  productContext: NullableString;
  productContextHtml: NullableString;
  productContextMarkdown: NullableString;
  preferredOrder: Nullable<number>;
  rank: Nullable<number>;
  sponsoredPlacement: Nullable<number>;
  sponsoredProductLocations: string[];
  preMemberEnabled: boolean;
}
