export const PROFILE_EDIT_FORM_TEST_IDS = {
  INPUT_PHONE_NUMBER: "pef-phone-number",
  INPUT_EMAIL: "pef-email",
  INPUT_POSTAL_CODE: "pef-postal-code",
  INPUT_STREET_ADDRESS: "pef-street-address",
  INPUT_STREET_ADDRESS2: "pef-street-address2",
  INPUT_CITY: "pef-city",
  BUTTON_UPDATE_PROFILE: "pef-update-profile",
  BUTTON_DISCARD_CHANGES: "pef-discard-changes",
};
