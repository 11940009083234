const pushSearchParam = (name: string, value: string) => {
  const url = new URL(window.location.href);
  if (!value) {
    url.searchParams.delete(name);
  } else {
    url.searchParams.set(name, value);
  }
  window.history.pushState({}, "", url);
};

/**
 * Sets the "sortBy" URL search parameter. This does not prompt a navigation and bypasses react-router-dom.
 * @param sortByKey The sort by key to set in the URL.
 */
export const setSortBy = (sortByKey: string) => pushSearchParam("sortBy", sortByKey);

/**
 * Sets the "filterBy" URL search parameter. This does not prompt a navigation and bypasses react-router-dom.
 * @param filterByKeys The filter by keys to set in the URL.
 */
export const setFilterBy = (filterByKeys: string[] | string) =>
  pushSearchParam("filterBy", Array.isArray(filterByKeys) ? filterByKeys.join(",") : filterByKeys);
