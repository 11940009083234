import { Modal as ReactNativeModal } from "react-native";

import { BUTTON_TYPE, Button } from "@bwll/bw-components/next";

import { ButtonGroup, CenteredView, ModalContent, ModalText } from "./ConfirmationModal.styles";
import { ConfirmationModalProps } from "./ConfirmationModal.types";

export const ConfirmationModal = ({
  modalText,
  ctaText,
  onCancelText,
  onConfirm,
  onCancel,
  visible,
  testID,
}: ConfirmationModalProps) => (
  <ReactNativeModal testID={`${testID}-modal`} visible={visible} transparent={true} animationType="fade">
    <CenteredView>
      <ModalContent>
        <ModalText>{modalText}</ModalText>
        <ButtonGroup>
          <Button
            onPress={onCancel}
            type={BUTTON_TYPE.TERTIARY}
            title={onCancelText}
            testID={`${testID}-on-cancel`}
          />
          <Button
            onPress={onConfirm}
            type={BUTTON_TYPE.DANGER}
            title={ctaText}
            testID={`${testID}-on-confirm`}
          />
        </ButtonGroup>
      </ModalContent>
    </CenteredView>
  </ReactNativeModal>
);
