import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import { BUTTON_TYPE, Button, Spacer } from "@bwll/bw-components/next";
import {
  useBreakpoints,
  useGetMember,
  useImpressionedCreditCards,
  usePostQuickApply,
  useQuickApplyPartnerLookup,
} from "@bwll/bw-hooks";
import { QuickApplyForm } from "@bwll/bw-modules";
import { spacing } from "@bwll/bw-styles";
import { PRODUCT_VERTICAL_GUID, QuickApplyPreferredLanguage } from "@bwll/bw-types";
import { generateTo, getQuickApplyCreditCardProduct } from "@bwll/bw-utils";

import { QUICK_APPLY_TEST_IDS as TEST_IDS } from "./QuickApply.constants";
import { useQuickApplyLoaderData } from "./QuickApply.loader";
import * as Styled from "./QuickApply.styles";
import { QuickApplyMember, QuickApplyProps } from "./QuickApply.types";
import { prepareFormData } from "./QuickApply.utils";

import { useQuickApplyExperimentRedirect } from "@app/hooks";
import { ROUTES } from "@app/router";

/**
 * Renders the Quick Apply form on Marketplace Web.
 */
const QuickApplyInternal = ({ product, vertical }: QuickApplyProps) => {
  useQuickApplyExperimentRedirect();
  const { isMobile } = useBreakpoints();

  const {
    isLoading: isMemberLoading,
    data: member,
    error: memberError,
  } = useGetMember({
    transform: ({ individual }): QuickApplyMember => {
      const { legalName, birthDate, primaryPhoneNumber, email, primaryAddress } = individual;
      return {
        legalName,
        birthDate,
        primaryPhoneNumber,
        primaryAddress,
        email,
      };
    },
  });

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // TODO: Implement callbacks and remove experiment
  const getRedirectToSuccessPage: any = () => null;
  const getRedirectToExternalUrl: any = () => null;
  const redirectToErrorPage: any = () => null;
  const isSpringFinancialV2ApiOn = true;

  const partner = useQuickApplyPartnerLookup({
    product,
    getRedirectToSuccessPage,
    getRedirectToExternalUrl,
    redirectToErrorPage,
    isSpringFinancialV2ApiOn,
  });

  const { mutate: submitQuickApply } = usePostQuickApply({
    onSuccess: partner.onSuccess,
    onError: partner.onError,
  });

  const handleSubmitApplication = useCallback(() => {
    if (!member) return;
    const language = i18n.language as QuickApplyPreferredLanguage;
    const formData = prepareFormData(member, language);

    submitQuickApply({
      ...partner.mapRequest(formData),
      productImpressionToken: product.productImpressionId,
      productReferenceNumber: product.productReferenceNumber,
    });
  }, [submitQuickApply, member, partner, product, i18n.language]);

  // Event handling
  const navigateToProfileEdit = useCallback(() => {
    const to = generateTo(ROUTES.PROFILE_EDIT, {
      searchParams: { returnUrl: generatePath(ROUTES.CREDIT_CARDS_QUICK_APPLY, { productId: product.id }) },
    });
    navigate(to);
  }, [navigate, product.id]);

  // TODO: Handle errors
  if (memberError) {
    return <div>Error occurred. Please try again later.</div>;
  }

  // TODO: Handle loading
  if (isMemberLoading || !member) {
    return <div>Loading</div>;
  }

  return (
    <Styled.ResetMargin isMobile={isMobile}>
      <Styled.InnerContainer isMobile={isMobile}>
        <QuickApplyForm
          quickApplyProduct={product}
          productVertical={vertical}
          onPersonalDetailsUpdate={navigateToProfileEdit}
        />
        <Spacer height={spacing.m} />
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onPress={handleSubmitApplication}
          title={t("marketplace:quickApply:buttons:submitApplication")}
          testID={TEST_IDS.SUBMIT_APPLICATION}
        />
        <Button
          type={BUTTON_TYPE.TERTIARY}
          onPress={() => navigate(ROUTES.CREDIT_CARDS)}
          title={t("marketplace:quickApply:buttons:backToProducts")}
          testID={TEST_IDS.BUTTON_BACK_TO_PRODUCTS}
        />
      </Styled.InnerContainer>
    </Styled.ResetMargin>
  );
};

/**
 * Wrapper component for {@link QuickApplyInternal} that fetches credit card product data.
 */
export const QuickApplyCreditCard = () => {
  const { productId } = useQuickApplyLoaderData();

  const {
    isLoading: isProductLoading,
    data: product,
    error: productError,
  } = useImpressionedCreditCards({
    transform: (response) => {
      const creditCard = response.productVerticalCreditCards.find((card) => card.id === productId);
      if (!creditCard) return undefined;
      return getQuickApplyCreditCardProduct(creditCard);
    },
  });

  // TODO: Handle errors
  if (productError) {
    return <div>Error occurred. Please try again later.</div>;
  }

  // TODO: Handle loading
  if (isProductLoading || !product) {
    return <div>Loading</div>;
  }

  return <QuickApplyInternal product={product} vertical={PRODUCT_VERTICAL_GUID.CREDIT_CARD} />;
};
